import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';

const RejectSubmissionModal = ({ modalId, onSubmit, providerFirstName }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => getIsModalOpen(state, modalId));
  return (
    <Form
      onSubmit={values => onSubmit({ ...values, reject: true })}
      render={({ handleSubmit }) => (
        <ModalConfirm
          body={(
            <>
              <p>Are you sure you want to reject this onboarding form?</p>
              <p>
                Rejecting the user&apos;s onboarding form means that they will not have access to
                your organisation. If you are unhappy with the submission, you could  always leave a
                comment and request an amendment for the respective question.
              </p>
              <p>
                If you would like to reject the onboarding form, you are required to enter a
                descriptive reason below.
              </p>
              <TextAreaField label="Reason" name="reason" required />
            </>
          )}
          closeOnConfirm={false}
          confirmLabel="Reject"
          confirmStyle={BS_STYLE.DANGER}
          data-testid="review-form-reject-modal"
          heading={`Reject ${providerFirstName}'s onboarding form`}
          key="rejectOnboardingFormModal"
          onClose={() => dispatch(modalCloseAC())}
          onConfirm={handleSubmit}
          open={isOpen}
        />
      )}
    />
  );
};

RejectSubmissionModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  providerFirstName: PropTypes.string.isRequired,
};

export default RejectSubmissionModal;
