import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { deleteOrganizationApiKeyDS } from 'settings/assets/js/data-services/organizations';
import { COMPONENT_NAME as componentName } from 'settings/assets/js/OrgManageApiKeysView.jsx';

export const ORG_API_KEY_REVOKE_MODAL_ID = 'org-api-key-revoke-modal';


const OrgApiKeyRevokeModal = () => {
  const { alias: orgAlias } = useSelector(selectActiveOrg);
  const payload = useSelector(state => getModalPayload(state, ORG_API_KEY_REVOKE_MODAL_ID));
  const isOpen = useSelector(state => getIsModalOpen(
    state,
    ORG_API_KEY_REVOKE_MODAL_ID,
  ));
  const dispatch = useDispatch();

  return (
    <ModalConfirm
      confirmLabel="Revoke"
      confirmStyle={BS_STYLE.DANGER}
      size={BS_STYLE.DEFAULT}
      heading="Revoke API Key"
      open={isOpen}
      onClose={() => dispatch(modalCloseAC())}
      onConfirm={async () => dispatch(
        deleteOrganizationApiKeyDS(orgAlias, payload.id, componentName),
      )}
    >
      Are you sure you want to revoke this API key?
      <br />
      Any integration that is using this key will no longer function.
    </ModalConfirm>
  );
};

export default OrgApiKeyRevokeModal;
