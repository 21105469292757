import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import FinancialChartCountsList from 'analytics/assets/js/components/FinancialChartCountsList.jsx';
import { FINANCIAL_CHART_TYPES } from 'analytics/assets/js/constants';
import { financialChartApiUrl } from 'analytics/urls';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';

const SpendByTask = ({ max, min }) => {
  const params = useParams();

  const { hasLoaded, isLoading, item } = fetchDataHook({
    changesArray: [max, min],
    componentName: SpendByTask.GetComponentName(),
    url: financialChartApiUrl(params.orgAlias, FINANCIAL_CHART_TYPES.SPEND_BY_TASK),
    queryStringParams: { max, min },
  });

  return (
    <FinancialChartCountsList
      entries={(item?.tasks || []).map(t => ({
        count: parseFloat(t.amount),
        formattedCount: <NumberTpl currency={item.currency} value={t.amount} />,
        text: t.title,
      }))}
      isLoading={isLoading || !hasLoaded}
      title="Spend by Task"
      total={item.currency && <NumberTpl currency={item.currency} value={item.total} />}
    />
  );
};

SpendByTask.GetComponentName = () => 'SpendByTask';

SpendByTask.propTypes = {
  max: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
};

export default SpendByTask;
