/* globals FormData */
import { FORM_ERROR } from 'final-form';
import { minBy, omit, pick } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { IS_FILE } from 'core/assets/js/config/settings';
import { BS_STYLE, ICON, USER_TYPE } from 'core/assets/js/constants';
import { hideHubSpotChat } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { documentOptionsApiUrl } from 'documents/urls';
import ImportCreateDownloadTemplate from 'importer/assets/js/components/create/ImportCreateDownloadTemplate.jsx';
import ImportCreateType from 'importer/assets/js/components/create/ImportCreateType.jsx';
import ImportUserType from 'importer/assets/js/components/create/ImportUserType.jsx';
import ImportCreateMapFields from 'importer/assets/js/components/create/ImportCreateMapFields.jsx';
import ImportCreateUpload from 'importer/assets/js/components/create/ImportCreateUpload.jsx';
import {
  IMPORT_COLUMNS, IMPORT_DATE_FORMAT, IMPORT_TYPE, IMPORT_TYPE_LABEL, IMPORT_TYPE_VALUES,
} from 'importer/assets/js/constants';
import { importAnalyseCSVApiUrl, importCreateApiUrl, importsListUrl } from 'importer/urls';
import { interviewListApiUrl } from 'interviews/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import InvitePeopleDetails from 'people/assets/js/components/steps/InvitePeopleDetails.jsx';
import { PEOPLE_TYPE, USER_EMPLOYMENT_TYPE } from 'people/assets/js/constants';
import { getInviteUsersFormDuplicateDocumentError } from 'people/assets/js/lib/utils';

const importTypeIsUsers = values => values.type === IMPORT_TYPE.USERS;

const ImportCreateView = ({ history }) => {
  const activeOrg = useSelector(selectActiveOrg);
  const [completeType, setCompleteType] = useState(null);
  const [interviews, setInterviews] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  hideHubSpotChat();
  const onCancel = useCallback(
    () => history.push(importsListUrl(activeOrg.alias)),
    [history, activeOrg],
  );

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Imports', url: importsListUrl(activeOrg.alias) },
          { title: 'Import', url: null },
        ]}
      />
      <div className="page page--imports-view">
        <div className="container">
          {completeType && (
            <div
              className="rounded shadow-sm p-4 bg-white d-flex flex-column align-items-center justify-content-center"
            >
              <i className={`${ICON.IMPORT_FILE_DUOTONE} fa-5x my-5`} />
              <h2 className="mb-5">Almost done!</h2>
              <p>
                {`We are importing your ${IMPORT_TYPE_LABEL[completeType]} in the background.`}
              </p>
              <p>When the import is complete, you will be notified by email.</p>
              <TDButton
                className="my-5"
                label="View imports"
                onClick={() => history.push(importsListUrl(activeOrg.alias))}
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          )}
          {!completeType && (
            <Wizard
              initialValues={{ dateFormat: IMPORT_DATE_FORMAT.ISO, colMapping: csvHeaders }}
              onCancel={onCancel}
              onSubmit={async formValues => {
                const {
                  approved, colMapping, dateFormat, employmentType, file, interviewIds, type,
                } = formValues;
                try {
                  const url = importCreateApiUrl(activeOrg.alias);
                  const formData = new FormData();
                  formData.append('csv', file);
                  formData.append('dateFormat', dateFormat);
                  formData.append('type', type);
                  const getIndex = columnName => {
                    const mapping = colMapping.find(m => m.colName === columnName);
                    return mapping?.csvColumnName ? csvHeaders.indexOf(mapping.csvColumnName) : -1;
                  };
                  const configuration = {
                    colMapping: IMPORT_COLUMNS[type].reduce(
                      (acc, columnName, index) => {
                        const newIndex = getIndex(columnName);
                        if (newIndex > -1) {
                          acc[index] = newIndex;
                        }
                        return acc;
                      },
                      {},
                    ),
                    customFieldsMapping: customFields.reduce(
                      (acc, cf) => {
                        const newIndex = getIndex(cf.label);
                        if (newIndex > -1) {
                          acc[cf.id] = newIndex;
                        }
                        return acc;
                      },
                      {},
                    ),
                  };
                  if (type === IMPORT_TYPE.USERS) {
                    Object.assign(
                      configuration,
                      pick(
                        formValues,
                        'aor',
                        'documents',
                        'expiration_days',
                        'message',
                        'userType',
                      ),
                    );
                    if (
                      activeOrg.onboarding_forms_are_mandatory
                      || (
                        Array.isArray(formValues.onboardingFormEnabled)
                        && formValues.onboardingFormEnabled.length === 1
                        && formValues.onboardingFormEnabled[0].value
                      )
                    ) {
                      configuration.interviewIds = interviewIds;
                    }
                    configuration.approved = (
                      Array.isArray(approved) && approved.length === 1 && approved[0].value
                    );
                    configuration.is_employee = employmentType === USER_EMPLOYMENT_TYPE.EMPLOYEE;
                  }
                  formData.append('configuration', JSON.stringify(configuration));
                  await axios.post(url, formData);
                  setCompleteType(type);
                  return null;
                } catch (err) {
                  const errors = omit(err.response?.data || {}, '_error', '_meta');
                  if (Object.keys(errors).length > 0) {
                    return errors;
                  }
                  return { [FORM_ERROR]: err.response?.data._error || err.message };
                }
              }}
              keepDirtyOnReinitialize
              submitBtnTitle="Import"
              vertical
              includeArrayMutators
            >
              <Wizard.Page
                beforePageChange={async formValues => {
                  if (!IMPORT_TYPE_VALUES.includes(formValues.type)) {
                    return { type: 'Invalid import type selected' };
                  }
                  if (formValues.type === IMPORT_TYPE.USERS) {
                    try {
                      const { data } = await axios.get(interviewListApiUrl(activeOrg.alias));
                      setInterviews(data);
                      if (data.length > 0) {
                        const interviewIds = [];
                        const defaultInterviewId = minBy(data, 'id').id;
                        if (defaultInterviewId) {
                          interviewIds.push(defaultInterviewId);
                        }
                        return { values: { ...formValues, interviewIds } };
                      }
                    } catch (err) {
                      toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                    }
                  }
                  return null;
                }}
                component={ImportCreateType}
                nextBtnTitle="Continue"
                title="Import type"
              />
              <Wizard.Page
                beforePageChange={async formValues => {
                  const errors = {};
                  if (![USER_TYPE.PROVIDER, USER_TYPE.MANAGER].includes(formValues.userType)) {
                    errors.userType = 'Invalid user type selected';
                  }
                  if (
                    ![
                      USER_EMPLOYMENT_TYPE.CONTRACTOR, USER_EMPLOYMENT_TYPE.EMPLOYEE,
                    ].includes(formValues.employmentType)
                  ) {
                    errors.employmentType = 'Invalid employment type selected';
                  }
                  if (
                    activeOrg.aor_onboarding_invitations_enabled
                    && !activeOrg.all_contractors_are_aor
                    && formValues.employmentType === USER_EMPLOYMENT_TYPE.CONTRACTOR
                    && ![0, 1].includes(formValues.aor)
                  ) {
                    errors.aor = 'Invalid AOR type selected';
                  }
                  if (Object.keys(errors).length > 0) {
                    return errors;
                  }
                  return null;
                }}
                component={ImportUserType}
                nextBtnTitle="Continue"
                showStep={importTypeIsUsers}
                title="User type"
              />
              <Wizard.Page
                beforePageChange={async formValues => {
                  if (
                    !Array.isArray(formValues.interviewIds) || formValues.interviewIds.length === 0
                  ) {
                    return null;
                  }
                  let documents = [];
                  try {
                    ({ data: documents } = await axios.get(documentOptionsApiUrl(
                      activeOrg.alias, activeOrg.documents_with_countersigning_enabled,
                    )));
                  } catch (err) {
                    toastr.error('Oh Snap!', err.response?.data?._error || err.message);
                  }
                  const selectedInterviews = interviews.filter(
                    i => formValues.interviewIds.includes(i.id),
                  );
                  if (selectedInterviews.length === 0) {
                    return null;
                  }
                  if (
                    selectedInterviews.some(interview => (
                      interview.documentIds.some(documentId => {
                        const document = documents.find(d => d.id === parseInt(documentId, 10));
                        return document?.countersignersCount;
                      })
                    ))
                  ) {
                    return {
                      interview: [
                        'You cannot use an onboarding form with contract templates with',
                        'countersigning',
                      ].join(' '),
                    };
                  }
                  const documentError = getInviteUsersFormDuplicateDocumentError(
                    activeOrg, formValues, selectedInterviews, documents,
                  );
                  return documentError ? { documents: documentError } : null;
                }}
                component={InvitePeopleDetails}
                componentProps={values => ({
                  countersigningEnabled: false,
                  formValues: {
                    employment_type: values.employmentType,
                    onboardingFormEnabled: values.onboardingFormEnabled,
                  },
                  headerText: 'Great! Fill in details',
                  interviews,
                  peopleType: values.userType === USER_TYPE.PROVIDER
                    ? PEOPLE_TYPE.PROVIDERS
                    : PEOPLE_TYPE.MANAGERS,
                })}
                nextBtnTitle="Continue"
                showStep={importTypeIsUsers}
                title="Details"
              />
              <Wizard.Page
                component={ImportCreateDownloadTemplate}
                nextBtnTitle="Continue"
                title="Download template"
              />
              <Wizard.Page
                beforePageChange={async (formValues, form) => {
                  if (!IS_FILE(formValues.file)) {
                    return { file: 'Please select a csv' };
                  }
                  const { file, type } = formValues;
                  try {
                    const formData = new FormData();
                    formData.append('csv', file);
                    formData.append('type', type);
                    const { data } = await axios.post(
                      importAnalyseCSVApiUrl(activeOrg.alias), formData,
                    );
                    setCsvHeaders(data.headers);
                    setCustomFields(data.customFields);
                    const columns = [
                      ...IMPORT_COLUMNS[type], ...data.customFields.map(cf => cf.label),
                    ];
                    form.change('colMapping', columns.map((colName, idx) => ({
                      index: idx,
                      colName,
                      csvColumnName: data.headersMatch ? colName : null,
                    })));
                    return null;
                  } catch (err) {
                    const errors = omit(err.response?.data || {}, '_error', '_meta');
                    if (Object.keys(errors).length > 0) {
                      return errors;
                    }
                    return { [FORM_ERROR]: err.response?.data._error || err.message };
                  }
                }}
                component={ImportCreateUpload}
                nextBtnTitle="Continue"
                title="Upload template"
              />
              <Wizard.Page
                beforePageChange={({ colMapping }) => {
                  if (
                    !Array.isArray(colMapping)
                    || !colMapping.some(mapping => mapping.csvColumnName)
                  ) {
                    return { colMapping: 'You must map at least one column' };
                  }
                  return null;
                }}
                component={ImportCreateMapFields}
                componentProps={{ csvHeaders }}
                nextBtnTitle="Continue"
                title="Map fields"
              />
            </Wizard>
          )}
        </div>
      </div>
    </>
  );
};

ImportCreateView.GetComponentName = () => 'ImportCreateView';

ImportCreateView.propTypes = {
  history: routerHistorySpec.isRequired,
};

export default withRouter(ImportCreateView);
