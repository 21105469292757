import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';

import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { DASHBOARD_WIDGET, DASHBOARD_WIDGET_PAGE_SIZE } from 'settings/assets/js/constants';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { PERMISSIONS } from 'roles/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { fetchProviderAnalyticsApiUrl, orgPeopleInviteUrl, orgPeopleProvidersUrl } from 'people/urls';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';

const COMPONENT_NAME = 'ContractorsCounterWidget';

const ContractorsCounterWidget = ({
  counterItems, hasPermission, history, match: { params: { orgAlias } },
}) => {
  const sortedCounter = counterItems.sort((a, b) => ((a.count > b.count) ? -1 : 1));
  const slicedCounter = sortedCounter.slice(
    0, DASHBOARD_WIDGET_PAGE_SIZE[DASHBOARD_WIDGET.CONTRACTORS],
  );

  const headerActionBtn = !hasPermission(PERMISSIONS.CAN_INVITE_PROVIDERS) ? null : {
    onClick: () => history.push({
      pathname: orgPeopleInviteUrl(orgAlias, PEOPLE_TYPE.PROVIDERS),
    }),
    label: 'Invite providers',
    variant: BS_STYLE.PRIMARY,
  };

  const footerActionBtn = {
    onClick: () => history.push(orgPeopleProvidersUrl(orgAlias)),
    label: 'View providers',
    variant: BS_STYLE.LINK,
  };

  const emptyPlaceholder = {
    content: 'Get work done by inviting providers to your organisation',
    icon: ICON.USER_DUOTONE,
  };

  const navigateToProvidersList = (countryCode) => {
    history.push({
      pathname: orgPeopleProvidersUrl(orgAlias),
      search: queryString.stringify({
        countryCode,
      }),
    });
  };

  const widgetlTitle = 'Providers';

  return (
    <WidgetWrapper
      headerActionBtn={headerActionBtn}
      footerActionBtn={footerActionBtn}
      isEmpty={isEmpty(counterItems)}
      placeholder={emptyPlaceholder}
      title={widgetlTitle}
      popOverTitle={widgetlTitle}
      popOverContent={(
        <>
          <p>
            Providers are the users who you will invite to your organization in order
            to deliver the various projects &amp; tasks. When inviting a provider you
            will have to choose an onboarding form to send them to complete.
          </p>
        </>
      )}
    >
      {slicedCounter.map((counter) => {
        const { country_code: countryCode, count } = counter;

        if (!countryCode) {
          return null;
        }

        return (
          <a key={countryCode} onClick={() => navigateToProvidersList(countryCode)}>
            <div className="d-flex align-items-center mb-1">
              <div>
                <CountryFlagIcon countryISOCode={countryCode} />
                <span className="ml-3">{countryNames[countryCode]}</span>
              </div>
              <p className="ml-auto pt-3">{count}</p>
            </div>
          </a>
        );
      })}
    </WidgetWrapper>
  );
};

ContractorsCounterWidget.propTypes = {
  activeOrg: orgSpec.isRequired,
  counterItems: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number, country_code: PropTypes.string,
  })),
  hasPermission: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: routerMatchSpec.isRequired,
};

ContractorsCounterWidget.defaultProps = {
  counterItems: [],
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  hasOrgAccess: getHasOrgAccess(state),
  hasPermission: getActiveUserCardPermissionChecker(state),
  counterItems: getListState(state, COMPONENT_NAME)?.items?.counting,
});

const ContractorsCounterWidgetConnected = connect(mapStateToProps)(ContractorsCounterWidget);

const TdApiConnectedContractorsCounterWidget = withTDApiConnected({
  fetchData: ({ dispatch, params: { orgAlias }, url, authedAxios }) => dispatch(
    fetchListDS({
      authedAxios,
      componentName: COMPONENT_NAME,
      url: fetchProviderAnalyticsApiUrl(orgAlias, url),
    }),
  ),
  duck: 'list',
  storeKey: COMPONENT_NAME,
})(ContractorsCounterWidgetConnected);

export default withRouter(TdApiConnectedContractorsCounterWidget);
