import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { pick } from 'lodash';

import Confirmations from 'documents/assets/js/components/DocumentViewTabs/Confirmations.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import DocumentListView from 'documents/assets/js/DocumentListView.jsx';
import DocumentNewVersionConfirmModal, { MODAL_ID_PREFIX as NEW_VERSION_MODAL_ID_PREFIX } from 'documents/assets/js/components/DocumentNewVersionConfirmModal.jsx';
import Overview from 'documents/assets/js/components/DocumentViewTabs/Overview.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { DOCUMENT_VIEW_TABS } from 'documents/assets/js/constants';
import DocumentRequestSignaturesModal, { MODAL_ID as REQUEST_SIGNATURES_MODAL_ID } from 'documents/assets/js/components/DocumentRequestSignaturesModal.jsx';
import { archiveDocumentDS, duplicateDocumentDS } from 'documents/assets/js/data-services/form';
import { documentsUrl, documentEditUrl, documentViewUrl } from 'documents/urls';
import { fetchDocumentDS } from 'documents/assets/js/data-services/view';
import { getViewState } from 'core/assets/js/ducks/view';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { getActiveUserCardPermissionChecker, selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

const DocumentAssignmentView = ({
  activeOrg, dispatch, document, hasPermission, match: { params },
}) => {
  const componentName = DocumentAssignmentView.GetComponentName({ params });
  const { activeTab = DOCUMENT_VIEW_TABS.OVERVIEW, id } = params;
  const { allowedActions, title } = document;
  const listComponentName = DocumentListView.GetComponentName();
  const history = useHistory();
  const ctaButtonItems = [];
  const {
    canArchive,
    canCreateNewVersion,
    canDuplicate,
    canEdit,
    canRequestSignatures,
  } = allowedActions || {};

  const canViewTemplates = hasPermission(PERMISSIONS.CAN_VIEW_TEMPLATES_AND_CONFIRMATIONS);

  if (!canViewTemplates) {
    return (
      <div className="page page--document-view">
        <div className="container">
          <TDSystemMessage title="Access dinied" type={BS_STYLE.DANGER}>
            You do not have permission to view this document.
          </TDSystemMessage>
        </div>
      </div>
    );
  }

  if (canCreateNewVersion) {
    ctaButtonItems.push({
      label: 'Create a new version',
      onClick: () => dispatch(modalOpenAC(`${NEW_VERSION_MODAL_ID_PREFIX}${document.id}`)),
      variant: BS_STYLE.PRIMARY,
    });
  }

  if (canRequestSignatures) {
    ctaButtonItems.push({
      label: 'Request signatures',
      onClick: () => dispatch(modalOpenAC(
        REQUEST_SIGNATURES_MODAL_ID,
        { document: pick(document, 'countersignersCount', 'id', 'title') },
      )),
      variant: BS_STYLE.PRIMARY,
    });
  }

  if (canDuplicate) {
    ctaButtonItems.push({
      label: 'Duplicate',
      onClick: async () => {
        try {
          await dispatch(duplicateDocumentDS({
            id: document.id, listComponentName, orgAlias: activeOrg.alias,
          }));
          toastr.success('Well Done!', `"${document.title}" was duplicated successfully`);
          history.push(documentsUrl(activeOrg.alias));
        } catch (error) {
          toastr.error('Oh Snap!', error._error || error.message);
        }
      },
      variant: BS_STYLE.PRIMARY,
    });
  }

  if (canArchive) {
    ctaButtonItems.push({
      label: 'Archive',
      onClick: async () => {
        try {
          await dispatch(archiveDocumentDS({
            id: document.id, listComponentName, orgAlias: activeOrg.alias,
          }));
          toastr.success('Well Done!', `"${document.title}" was archived successfully`);
          history.push(documentsUrl(activeOrg.alias));
        } catch (error) {
          toastr.error('Oh Snap!', error._error || error.message);
        }
      },
      variant: BS_STYLE.DANGER,
    });
  }

  if (canEdit) {
    ctaButtonItems.push({
      label: 'Edit',
      onClick: () => history.push(documentEditUrl(activeOrg.alias, document.id)),
    });
  }

  return (
    <>
      <ContentHeader
        ctaComponent={(
          <SplitButton
            actions={ctaButtonItems}
          />
        )}
        breadcrumbs={[
          {
            title: 'Contract Templates',
            url: documentsUrl(activeOrg.alias),
          },
          {
            title: title || (
              <>
                <RectangleSkeleton className="ml-3" width="155" height="25" />
                <RectangleSkeleton className="ml-3" width="55" height="25" />
              </>
            ),
            url: null,
          },
        ]}
      />
      <div className="page page--document-view">
        <div className="container">
          <TDApiConnected
            duck="view"
            fetchData={({ authedAxios }) => dispatch(fetchDocumentDS({
              authedAxios,
              componentName,
              id,
              orgAlias: activeOrg.alias,
            }))}
            blockingLoading
            storeKey={componentName}
          >
            <div className="horizontal-scroll">
              <TabBar
                activeKey={activeTab}
                className="mb-5"
                tabSpec={[
                  {
                    key: DOCUMENT_VIEW_TABS.OVERVIEW,
                    label: 'Overview',
                    href: documentViewUrl(activeOrg.alias, id, DOCUMENT_VIEW_TABS.OVERVIEW),
                  },
                  {
                    key: DOCUMENT_VIEW_TABS.CONFIRMATIONS,
                    label: 'Confirmations',
                    href: documentViewUrl(activeOrg.alias, id, DOCUMENT_VIEW_TABS.CONFIRMATIONS),
                  },
                ]}
              />
            </div>
            {activeTab === DOCUMENT_VIEW_TABS.OVERVIEW && <Overview document={document} />}
            {activeTab === DOCUMENT_VIEW_TABS.CONFIRMATIONS && <Confirmations />}
          </TDApiConnected>
        </div>
      </div>

      {document && (
        <DocumentNewVersionConfirmModal document={document} />
      )}
      <DocumentRequestSignaturesModal />
    </>
  );
};

DocumentAssignmentView.GetComponentName = ({ params: { id } }) => `DocumentAttachmentSignView-${id}`;

DocumentAssignmentView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  hasPermission: PropTypes.func.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapStateToProps = (state, { match: { params } }) => {
  const viewState = getViewState(state, DocumentAssignmentView.GetComponentName({ params }));

  return {
    activeOrg: selectActiveOrg(state),
    document: viewState.item,
    hasPermission: getActiveUserCardPermissionChecker(state),
  };
};

const DocumentAttachmentSignViewConnected = connect(
  mapStateToProps,
)(DocumentAssignmentView);

export default withRouter(DocumentAttachmentSignViewConnected);
