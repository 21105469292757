import axios from 'core/assets/js/lib/tdAxios';
import { toastr } from 'react-redux-toastr';
import { SubmissionError } from 'redux-form';
import { httpErrorAC } from 'core/assets/js/ducks/errors';

function postPasswordForgotDS(values, dispatch) {
  return new Promise((resolve, reject) => {
    axios.post('/api/accounts/password/forgot', values)
      .then((response) => {
        if (response.data._error) {
          reject(new SubmissionError(response.data));
        } else {
          resolve();
        }
      })
      .catch((error) => {
        if (error.response.data._meta.isValidation) {
          reject(new SubmissionError(error.response.data));
        } else {
          reject(error);
          dispatch(httpErrorAC([error.response.data]));
        }
      });
  });
}

const postPasswordResetDS = async (token, values) => {
  try {
    await axios.post('/api/accounts/password/reset', { ...values, token });
  } catch (error) {
    let { data: errorData } = error.response;
    if (errorData._meta.isValidation) {
      if (/is not pending$/.test(errorData._error)) {
        errorData = { _error: 'The reset password link has expired. Please request a new one.' };
      }
      throw new SubmissionError(errorData);
    }
    toastr.error('Oh Snap!', error.message);
  }
};

export {
  postPasswordForgotDS,
  postPasswordResetDS,
};
