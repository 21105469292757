import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import FinancialChartCountsList from 'analytics/assets/js/components/FinancialChartCountsList.jsx';
import { FINANCIAL_CHART_TYPES } from 'analytics/assets/js/constants';
import { financialChartApiUrl } from 'analytics/urls';
import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { countryNames } from 'core/assets/js/lib/isoCountries';

const SpendByCountry = ({ max, min }) => {
  const params = useParams();

  const { hasLoaded, isLoading, item } = fetchDataHook({
    changesArray: [max, min],
    componentName: SpendByCountry.GetComponentName(),
    url: financialChartApiUrl(params.orgAlias, FINANCIAL_CHART_TYPES.SPEND_BY_COUNTRY),
    queryStringParams: { max, min },
  });

  return (
    <FinancialChartCountsList
      entries={(item?.countries || []).map(c => ({
        count: parseFloat(c.amount),
        formattedCount: <NumberTpl currency={item.currency} value={c.amount} />,
        text: !c.countryCode ? 'Unknown' : (
          <>
            {c.countryCode && (
              <CountryFlagIcon className="mr-3" countryISOCode={c.countryCode} />
            )}
            {c.countryCode ? countryNames[c.countryCode] : 'Unknown'}
          </>
        ),
      }))}
      isLoading={isLoading || !hasLoaded}
      title="Spend by Country"
      total={!!item.currency && <NumberTpl currency={item.currency} value={item.total} />}
    />
  );
};

SpendByCountry.GetComponentName = () => 'SpendByCountry';

SpendByCountry.propTypes = {
  max: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
};

export default SpendByCountry;
