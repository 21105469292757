import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import FinancialCharts from 'analytics/assets/js/components/FinancialCharts.jsx';
import ReportsList from 'analytics/assets/js/components/ReportsList.jsx';
import { REPORT_TABS } from 'analytics/assets/js/constants';
import { reportsUrl } from 'analytics/urls';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { IS_PRODUCTION } from 'core/assets/js/config/settings';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { getActiveUserCardPermissionChecker } from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';

const ReportsView = () => {
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const canAccessCharts = hasPermission(PERMISSIONS.CAN_ACCESS_FINANCIAL_CHARTS) && !IS_PRODUCTION;
  const canAccessReports = hasPermission(PERMISSIONS.CAN_ACCESS_REPORTS);

  const params = useParams();

  if (!canAccessCharts && !canAccessReports) {
    return null;
  }

  const chartsUrl = reportsUrl(params.orgAlias, REPORT_TABS.FINANCIAL_CHARTS);
  const reportDownloadsUrl = reportsUrl(params.orgAlias, REPORT_TABS.REPORT_DOWNLOADS);

  let { tab } = params;
  if (!tab) {
    tab = canAccessCharts ? REPORT_TABS.FINANCIAL_CHARTS : REPORT_TABS.REPORT_DOWNLOADS;
  }

  if (tab === REPORT_TABS.FINANCIAL_CHARTS && !canAccessCharts) {
    return <Redirect to={reportDownloadsUrl} />;
  }
  if (tab === REPORT_TABS.REPORT_DOWNLOADS && !canAccessReports) {
    return <Redirect to={chartsUrl} />;
  }

  const tabSpec = [];
  if (canAccessCharts) {
    tabSpec.push({
      key: REPORT_TABS.FINANCIAL_CHARTS,
      label: 'Financial charts',
      href: chartsUrl,
    });
  }
  if (canAccessReports) {
    tabSpec.push({
      key: REPORT_TABS.REPORT_DOWNLOADS,
      label: 'Report downloads',
      href: reportDownloadsUrl,
    });
  }

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Reports', url: null }]}>
        <div className="horizontal-scroll"><TabBar activeKey={tab} tabSpec={tabSpec} /></div>
      </ContentHeader>
      <div className="page page--reports">
        <div className="container">
          {tab === REPORT_TABS.FINANCIAL_CHARTS && <FinancialCharts />}
          {tab === REPORT_TABS.REPORT_DOWNLOADS && <ReportsList />}
        </div>
      </div>
    </>
  );
};

export default ReportsView;
