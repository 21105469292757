/* globals Event */
import { pick } from 'lodash';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { UAParser } from 'ua-parser-js';

import EnterTOTP from 'accounts/assets/js/components/two-factor-auth/EnterTOTP.jsx';
import ScanQR from 'accounts/assets/js/components/two-factor-auth/ScanQR.jsx';
import { refreshAccountDS } from 'accounts/assets/js/data-services/account';
import { fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import {
  accountSettingsApiUrl,
  changePasswordUrl,
  deleteLoginDeviceApiUrl,
  deleteLoginDevicesApiUrl,
  disable2FAApiUrl,
  emailApiUrl,
  enable2FAApiUrl,
  getEnable2FASettingsApiUrl,
} from 'accounts/urls';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import LinkModal from 'core/assets/js/components/LinkModal.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import SettingsItem from 'core/assets/js/components/SettingsItem.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { DOCUMENT_GET_ELEMENT_BY_ID } from 'core/assets/js/config/settings';
import { BS_STYLE, DATE_FORMAT_DEFAULT, ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import moment from 'core/assets/js/lib/tdMoment';
import { parseAxiosErrorForFinalForm, validatePassword } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const MODAL_2FA_ID = 'modal-2fa-id';
const EMAIL_FORM_ID = 'email-form-id';
const PASSWORD_FORM_ID = 'password-form-id';

const AccountSettingsView = () => {
  const dispatch = useDispatch();
  const modal2FAIsOpen = useSelector(state => getIsModalOpen(state, MODAL_2FA_ID));
  const activeOrg = useSelector(selectActiveOrg);
  const [settings2FA, setSettings2FA] = useState(null);
  const [settings2FAEnterTOTP, setSettings2FAEnterTOTP] = useState(false);

  const componentName = AccountSettingsView.GetComponentName();
  const apiUrl = accountSettingsApiUrl();
  const { isLoading, hasLoaded, item: settings } = fetchDataHook({ componentName, url: apiUrl });

  const closeModal = () => dispatch(modalCloseAC());

  const reloadSettings = () => dispatch(fetchViewDS({ componentName, url: apiUrl }));

  const submitForm = domID => DOCUMENT_GET_ELEMENT_BY_ID(domID)?.dispatchEvent(
    // eslint-disable-next-line no-restricted-globals
    new Event('submit', { bubbles: true, cancelable: true }),
  );

  let modal2FAHeading = "Verify it's you";
  if (settings2FA) {
    if (settings2FAEnterTOTP) {
      modal2FAHeading = 'Verify authentication code';
    } else {
      modal2FAHeading = 'Scan QR code';
    }
  }

  const close2FAModal = () => {
    closeModal();
    setSettings2FA(null);
    setSettings2FAEnterTOTP(false);
  };

  const cancel2FAButton = <TDButton label="Cancel" onClick={close2FAModal} />;

  const cannotDisable2FA = settings.has2faEnabled && !!settings.orgWithMandatory2fa;

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Account', url: null }]} />
      <div className="page page--settings">
        <div className="container">
          <div className="rounded shadow-sm p-4 bg-white">
            <div className="page--settings__content clearfix">
              {(isLoading || !hasLoaded) && <SettingsPageSkeleton />}
              {!isLoading && hasLoaded && (
                <>
                  <div className="settings-row mb-4">
                    <h3 className="heading-block">Security & Login</h3>
                    <SettingsItem
                      cta={(
                        <LinkModal
                          modalId="change-email-modal"
                          modalOptions={{
                            body: (
                              <Form
                                initialValues={{ email: settings.email }}
                                onSubmit={async values => {
                                  try {
                                    const response = await axios.post(emailApiUrl(), values);
                                    if (response.data?.waitForVerification) {
                                      toastr.info(
                                        'Email verification required',
                                        `An email has been sent to ${values.email} for verification`,
                                      );
                                    } else {
                                      reloadSettings();
                                      toastr.success(
                                        'Well Done!', 'Account settings updated successfully.',
                                      );
                                    }
                                    closeModal();
                                    return null;
                                  } catch (err) {
                                    return parseAxiosErrorForFinalForm(err);
                                  }
                                }}
                                render={({ handleSubmit, submitError }) => (
                                  <form id={EMAIL_FORM_ID} onSubmit={handleSubmit}>
                                    <TextInputField label="Email" name="email" />
                                    {submitError && (
                                      <span className="text-danger mt-3">{submitError}</span>
                                    )}
                                  </form>
                                )}
                              />
                            ),
                            footer: (
                              <TDButton
                                floatRight
                                label="Update"
                                onClick={() => submitForm(EMAIL_FORM_ID)}
                                type="submit"
                                variant={BS_STYLE.PRIMARY}
                              />
                            ),
                            heading: 'Change your Email',
                          }}
                        />
                      )}
                      disclaimer={(
                        'Changing your email here will affect your log in and account-wide '
                        + 'notifications, if you are looking to just change your notification '
                        + 'settings please go to your organisation notification settings via '
                        + '"Organisation > Settings > Notifications"'
                      )}
                      label="Email"
                      preview={settings.email}
                    />
                  </div>
                  <SettingsItem
                    cta={(
                      <LinkModal
                        label={(
                          <TDButton
                            btnIcon={ICON.EDIT}
                            label="Change Password"
                            rounded
                            variant={BS_STYLE.PRIMARY}
                          />
                        )}
                        modalId="change-password-modal"
                        modalOptions={{
                          heading: 'Change your password',
                          body: (
                            <Form
                              onSubmit={async values => {
                                const errors = {};
                                const passwordError = validatePassword(values.password);
                                if (passwordError) {
                                  errors.password = passwordError;
                                } else if (values.password !== values.repeat_password) {
                                  errors.password = 'Passwords must match';
                                  errors.repeat_password = 'Passwords must match';
                                }
                                if (Object.keys(errors).length > 0) {
                                  return errors;
                                }
                                try {
                                  await axios.post(changePasswordUrl(), values);
                                  toastr.success('Well Done!', 'Password updated successfully.');
                                  closeModal();
                                  return null;
                                } catch (err) {
                                  return parseAxiosErrorForFinalForm(err);
                                }
                              }}
                              render={({ handleSubmit, submitError }) => (
                                <form id={PASSWORD_FORM_ID} onSubmit={handleSubmit}>
                                  <TextInputField
                                    label="Password"
                                    name="password"
                                    required
                                    showStrengthIndicator
                                    type="password"
                                  />
                                  <TextInputField
                                    label="Repeat Password"
                                    name="repeat_password"
                                    type="password"
                                  />
                                  {submitError && (
                                    <span className="text-danger mt-3">{submitError}</span>
                                  )}
                                </form>
                              )}
                            />
                          ),
                          footer: (
                            <TDButton
                              floatRight
                              label="Save"
                              onClick={() => submitForm(PASSWORD_FORM_ID)}
                              type="submit"
                              variant={BS_STYLE.PRIMARY}
                            />
                          ),
                        }}
                        wrapperClasses="d-flex justify-content-center"
                      />
                    )}
                    disclaimer="Set a unique password to protect your personal account."
                    label="Password"
                  />
                  <SettingsItem
                    containerClassName="mt-4"
                    cta={(
                      <div className="d-flex align-items-center">
                        <TDSwitch
                          disabled={cannotDisable2FA}
                          onClick={async () => {
                            if (!settings.has2faEnabled) {
                              dispatch(modalOpenAC(MODAL_2FA_ID));
                              return;
                            }
                            try {
                              await axios.post(disable2FAApiUrl());
                              reloadSettings();
                              toastr.success(
                                'Well Done!',
                                'Two-factor authentication has been disabled for your '
                                  + 'account',
                              );
                            } catch (e) {
                              toastr.error(
                                'Oh Snap!', e.response?.data?._error || e.message,
                              );
                            }
                          }}
                          selected={settings.has2faEnabled}
                        />
                        <div className="ml-3">
                          {settings.has2faEnabled ? 'Enabled' : 'Disabled'}
                        </div>
                      </div>
                    )}
                    disclaimer={(
                      'Keep your account secure by requiring a second method of authentication'
                    )}
                    label="Two-factor authentication"
                  />
                  {cannotDisable2FA && (
                    <TDSystemMessage
                      className="mb-4"
                      title="You cannot disable 2FA"
                      type={BS_STYLE.WARNING}
                    >
                      You cannot disable two-factor authentication for your account, because it
                      {` is mandatory for organization "${settings.orgWithMandatory2fa}"`}
                    </TDSystemMessage>
                  )}
                  <ModalSimple
                    body={(
                      <Form
                        onSubmit={async values => {
                          try {
                            if (settings2FA && settings2FAEnterTOTP) {
                              if (!/^[0-9]{6}$/.test(values.totp)) {
                                return { totp: 'Invalid code' };
                              }
                              await axios.post(enable2FAApiUrl(), {
                                ...pick(settings2FA, 'recoveryCodes', 'totpSecret'),
                                totp: values.totp,
                              });
                              reloadSettings();
                              await dispatch(refreshAccountDS());
                              await dispatch(fetchSetupStepsDS({ orgAlias: activeOrg.alias }));
                              close2FAModal();
                              toastr.success(
                                'Well Done!', 'You have enabled 2FA for your account',
                              );
                              return null;
                            }
                            if (settings2FA) {
                              if (
                                !Array.isArray(values.downloadedRecoveryCodes)
                                || values.downloadedRecoveryCodes.length === 0
                                || !values.downloadedRecoveryCodes[0].value
                              ) {
                                return {
                                  downloadedRecoveryCodes: 'Please confirm that you have '
                                    + 'downloaded your recovery codes',
                                };
                              }
                              setSettings2FAEnterTOTP(true);
                              return null;
                            }
                            const response = await axios.post(
                              getEnable2FASettingsApiUrl(), values,
                            );
                            setSettings2FA(response.data);
                            return null;
                          } catch (err) {
                            return parseAxiosErrorForFinalForm(err);
                          }
                        }}
                        render={({ handleSubmit, submitError }) => (
                          <form onSubmit={handleSubmit}>
                            {!settings2FA && (
                              <>
                                <TextInputField
                                  label="Email"
                                  name="email"
                                  required
                                  type="email"
                                />
                                <TextInputField
                                  label="Password"
                                  name="password"
                                  required
                                  type="password"
                                />
                              </>
                            )}
                            <div className="text-center">
                              {settings2FA && !settings2FAEnterTOTP && (
                                <ScanQR
                                  email={settings.email}
                                  recoveryCodes={settings2FA.recoveryCodes}
                                  secret={settings2FA.totpSecret}
                                />
                              )}
                              {settings2FA && settings2FAEnterTOTP && <EnterTOTP />}
                            </div>
                            {submitError && (
                              <span className="text-danger mt-3">{submitError}</span>
                            )}
                            <hr />
                            <div
                              className={(
                                // eslint-disable-next-line prefer-template
                                'mt-5 d-flex align-items-center justify-content-'
                                  + (settings2FAEnterTOTP ? 'between' : 'end')
                              )}
                            >
                              {settings2FAEnterTOTP && cancel2FAButton}
                              <div className="d-flex align-items-center justify-content-end">
                                {!settings2FAEnterTOTP && cancel2FAButton}
                                {settings2FAEnterTOTP && (
                                  <TDButton
                                    label="Back"
                                    onClick={() => setSettings2FAEnterTOTP(false)}
                                  />
                                )}
                                <TDButton
                                  className="ml-3"
                                  label={settings2FAEnterTOTP ? 'Verify' : 'Continue'}
                                  type="submit"
                                  variant={BS_STYLE.PRIMARY}
                                />
                              </div>
                            </div>
                          </form>
                        )}
                      />
                    )}
                    heading={modal2FAHeading}
                    noFooter
                    onClose={close2FAModal}
                    open={modal2FAIsOpen}
                  />
                  <div className="settings-row mb-4">
                    <h3 className="heading-block">Manage access and devices</h3>
                    <p className="px-3 pb-3">
                      These trusted devices have recently been active on this account. You can
                      remove any unfamiliar devices for added security.
                    </p>
                    <div className="d-flex flex-column px-3">
                      {settings.loginDevices.map(d => {
                        let device = d.ip || 'Unknown';
                        if (d.userAgent) {
                          const uap = UAParser(d.userAgent);
                          device = `${uap.device.vendor} ${uap.device.model}`
                            + ` - ${uap.browser.name}`;
                        }
                        return (
                          <div
                            className={(
                              'd-flex flex-row align-items-center justify-content-between mb-5'
                            )}
                            key={d.id}
                          >
                            <div className="d-flex flex-column">
                              <div>{device}</div>
                              <div className="discreet">
                                {`Added on ${moment(d.createdAt).format(DATE_FORMAT_DEFAULT)}`}
                              </div>
                            </div>
                            <TDButton
                              label="Remove"
                              onClick={async () => {
                                try {
                                  await axios.delete(deleteLoginDeviceApiUrl(d.id));
                                  reloadSettings();
                                  toastr.success(
                                    'Well Done!', 'You have removed that login device',
                                  );
                                } catch (e) {
                                  toastr.error(
                                    'Oh Snap!', e.response?.data?._error || e.message,
                                  );
                                }
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {settings.loginDevices.length > 0 && (
                      <TDButton
                        className="ml-3"
                        label="Remove all devices"
                        onClick={async () => {
                          try {
                            await axios.delete(deleteLoginDevicesApiUrl());
                            reloadSettings();
                            toastr.success(
                              'Well Done!', 'You have removed all login devices',
                            );
                          } catch (e) {
                            toastr.error('Oh Snap!', e.response?.data?._error || e.message);
                          }
                        }}
                        variant={BS_STYLE.DANGER}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AccountSettingsView.GetComponentName = () => 'AccountSettingsView';

export default AccountSettingsView;
