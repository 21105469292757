import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BS_STYLE, IMG_SIZE, ICON } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import WidgetEmptyPlaceHolder from 'core/assets/js/components/Dashboard/WidgetEmptyPlaceHolder.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import OrgApiKeyModal, { ORG_API_KEY_MODAL_ID } from 'settings/assets/js/components/OrgApiKeyModal.jsx';
import OrgApiKeyRevokeModal, { ORG_API_KEY_REVOKE_MODAL_ID } from 'settings/assets/js/components/OrgApiKeyRevokeModal.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec, orgApiKeySpec } from 'organizations/assets/js/lib/objectSpecs';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { orgApiKeysApiUrl } from 'organizations/urls';
import Table from 'core/assets/js/components/Table.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import TDButton from 'core/assets/js/components/TDButton';

const ORG_API_KEY_MANAGE_CONTENT = {
  title: 'API access',
  create_new_key: 'Create API key',
  empty_state: {
    content: 'Start by creating your first API Key.',
    cta: 'Create API key',
  },
  no_access: {
    title: 'Access denied!',
    description: 'API key access is not enabled for your organisation',
  },
};

export const COMPONENT_NAME = 'OrgManageApiKeysView';

const OrgManageApiKeysView = ({ activeOrg, apiKeys, dispatch }) => {
  const isApiKeyAccessEnabled = activeOrg.api_access_enabled;

  const isEmpty = apiKeys.length === 0;
  const breadcrumbs = [
    {
      title: ORG_API_KEY_MANAGE_CONTENT.title,
      url: null,
    },
  ];

  const onAddApiKey = () => {
    dispatch(modalOpenAC(ORG_API_KEY_MODAL_ID));
  };

  const ctaButtonItems = [{
    label: ORG_API_KEY_MANAGE_CONTENT.create_new_key,
    onClick: onAddApiKey,
    variant: BS_STYLE.PRIMARY,
  }];

  const emptyState = (
    <div className="rounded shadow-sm p-4 bg-white">
      <div className="py-5">
        <WidgetEmptyPlaceHolder
          content={ORG_API_KEY_MANAGE_CONTENT.empty_state.content}
          cta={{
            label: ORG_API_KEY_MANAGE_CONTENT.empty_state.cta,
            onClick: onAddApiKey,
            variant: BS_STYLE.PRIMARY,
          }}
          icon={ICON.CODE}
        />
      </div>
    </div>
  );


  if (!isApiKeyAccessEnabled) {
    return (
      <div className="container pt-2 pb-2">
        <TDSystemMessage
          type="warning"
          title={ORG_API_KEY_MANAGE_CONTENT.no_access.title}
          className="mb-4 mt-4"
        >
          <div><p>{ORG_API_KEY_MANAGE_CONTENT.no_access.description}</p></div>
        </TDSystemMessage>
      </div>
    );
  }

  const issuerColumnRender = (_, ts) => (
    <div className="d-flex align-items-center">
      <ProfilePic
        className="mr-3"
        url={ts.issuer.profile.avatar}
        alt={ts.issuer.profile.name}
        size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
      />
      {ts.issuer.profile.name}
    </div>
  );

  const friendlyNameColumnRender = friendlyName => friendlyName || '-';

  const actionsRenderer = (key, apiKey) => {
    const { id, revoked } = apiKey;
    if (revoked) {
      return null;
    }

    return (
      <TDButton
        floatRight
        label="Revoke"
        className="ml-auto"
        onClick={() => dispatch(modalOpenAC(
          ORG_API_KEY_REVOKE_MODAL_ID,
          { id, componentName: COMPONENT_NAME },
        ))}
        type="button"
        variant={BS_STYLE.DEFAULT}
      />
    );
  };

  const tableColumns = [
    { key: 'issuedAt', label: 'Created Date', isDate: true, width: '230px' },
    { key: 'issuedBy', label: 'User', dataFormat: issuerColumnRender },
    { key: 'friendlyName', label: 'Label', dataFormat: friendlyNameColumnRender },
    { key: 'actions', label: '', dataFormat: actionsRenderer, width: '130px' },
  ];

  return (
    <>
      <OrgApiKeyModal />
      <OrgApiKeyRevokeModal />

      <ContentHeader
        breadcrumbs={breadcrumbs}
        ctaButtonItems={ctaButtonItems}
      />

      <div className="page page--settings">
        <div className="container">

          {isEmpty && emptyState}
          {!isEmpty && <Table cols={tableColumns} items={apiKeys} />}
        </div>
      </div>
    </>
  );
};

OrgManageApiKeysView.GetComponentName = () => COMPONENT_NAME;

OrgManageApiKeysView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activeOrg: orgSpec.isRequired,
  apiKeys: PropTypes.arrayOf(orgApiKeySpec),
};

OrgManageApiKeysView.defaultProps = {
  apiKeys: {},
};

const mapStateToProps = (state, props) => ({
  apiKeys: getListState(state, COMPONENT_NAME)?.items,
  activeOrg: selectActiveOrg(state),
  match: props.match,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgManageApiKeysViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgManageApiKeysView);

const TdApiConnectedOrgManageApiKeysView = withTDApiConnected({
  duck: 'list',
  fetchData: ({ dispatch, params: { orgAlias }, url, authedAxios }) => dispatch(
    fetchListDS({
      authedAxios,
      componentName: COMPONENT_NAME,
      url: orgApiKeysApiUrl(orgAlias, url),
    }),
  ),
  storeKey: COMPONENT_NAME,
})(OrgManageApiKeysViewConnect);

export default TdApiConnectedOrgManageApiKeysView;

