import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import FinancialChartWrapper from 'analytics/assets/js/components/FinancialChartWrapper.jsx';
import { FINANCIAL_CHART_TYPES } from 'analytics/assets/js/constants';
import { financialChartApiUrl } from 'analytics/urls';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import moment from 'core/assets/js/lib/tdMoment';

const TotalSpendChart = ({ max, min }) => {
  const params = useParams();

  const { hasLoaded, isLoading, item } = fetchDataHook({
    changesArray: [max, min],
    componentName: TotalSpendChart.GetComponentName(),
    url: financialChartApiUrl(params.orgAlias, FINANCIAL_CHART_TYPES.TOTAL_SPEND),
    queryStringParams: { max, min },
  });

  let percentageChange = null;
  let changeIsIncrease = null;
  if (typeof item.total === 'string' && typeof item.previousTotal === 'string') {
    const parsedPreviousTotal = parseFloat(item.previousTotal);
    percentageChange = ((parseFloat(item.total) - parsedPreviousTotal) / parsedPreviousTotal) * 100;
    if (percentageChange === 0) {
      percentageChange = null;
    } else {
      changeIsIncrease = percentageChange > 0;
      if (percentageChange % 1 === 0) {
        percentageChange = parseInt(percentageChange, 10);
      } else {
        percentageChange = percentageChange.toFixed(2);
      }
    }
  }

  const isMonthly = Array.isArray(item.points)
    && item.points.some(p => /^\d{4}-\d{2}$/.test(p.date));

  const series = !Array.isArray(item.points) ? [] : [{
    data: new Array(moment(max).diff(min, isMonthly ? 'months' : 'days') + 1).fill('').map(
      (_, index) => {
        const thisDate = moment(min).add(index, isMonthly ? 'months' : 'days').format(
          `YYYY-MM${isMonthly ? '' : '-DD'}`,
        );
        const foundPoint = item.points.find(p => p.date === thisDate);
        return foundPoint ? parseFloat(foundPoint.amount) : 0;
      },
    ),
  }];

  let xAxisFormat = isMonthly ? 'M' : 'D/M';
  if (max.split('-')[0] !== min.split('-')[0]) {
    // The range crosses more than one year
    xAxisFormat += '/YY';
  }

  return (
    <FinancialChartWrapper isLoading={isLoading || !hasLoaded} title="Total Spend">
      <div className="d-flex flex-column total-spend-chart">
        <div className="d-flex flex-row align-items-center mt-2 mb-5">
          <div className="total font-weight-bold pb-2">
            <NumberTpl currency={item.currency} value={item.total} />
          </div>
          {percentageChange !== null && (
            <div
              className={(
                `total-spend-change p-2 ml-3 ${changeIsIncrease ? 'increase' : 'descrease'}`
              )}
            >
              <i className={changeIsIncrease ? ICON.ARROW_UP : ICON.ARROW_DOWN} />
              <span className="ml-2">{`${percentageChange}%`}</span>
            </div>
          )}
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              className: 'total-spend-line-chart',
              height: '270px',
              margin: [10, 0, 25, 50],
              type: 'line',
            },
            credits: { enabled: false },
            legend: { enabled: false },
            plotOptions: { line: { enableMouseTracking: false, marker: { enabled: false } } },
            series,
            subtitle: { text: null },
            xAxis: {
              labels: {
                formatter() {
                  return moment(min).add(this.value, isMonthly ? 'months' : 'days').format(
                    xAxisFormat,
                  );
                },
              },
              step: 1,
              tickInterval: 1,
              title: { enabled: false },
            },
            yAxis: {
              allowDecimals: false,
              gridLineWidth: 0,
              lineWidth: 1,
              labels: { format: `${CURRENCY_SYMBOL[item.currency]}{text}` },
              title: { enabled: false },
            },
            title: { text: '' },
            tooltip: { enabled: false },
          }}
        />
      </div>
    </FinancialChartWrapper>
  );
};

TotalSpendChart.GetComponentName = () => 'TotalSpendChart';

TotalSpendChart.propTypes = {
  max: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
};

export default TotalSpendChart;
