export const CLIENT_IDS = {
  APARTMENT_THERAPY: 485,
  ATRAIN: 44,
  PORTOBELLO_BEHAVIOURAL_HEALTH: 548,
  BERLITZ: 351,
  BERLITZ_EU: 395,
  EUROMONITOR: 91,
  LC: 120,
  LEADING_EDUCATORS: 525,
  MEMRISE: 123,
  OBAN: 59,
  OUTSIDEMAGAZINE: 83,
  PURECONTENT: 62,
  SCHOOLKIT: 74,
  SEMINAR_CO: 526,
  VEEVA: 490,
  VEEVA_OPENDATA: 563,
  WISE_PUBLISHING_INC: 461,
  FIVESTONE_STUDIOS: 524,
};

export const CALENDAR_TYPE = {
  NONE: 0,
  DATE_PICKER: 1,
  DATE_RANGE_PICKER: 2,
};

export const DATA_REPORTS = {
  ACTIVE_PROVIDERS: 'active-providers',
  ACTIVE_PROVIDERS_EUROMONITOR: 'active-providers-euromonitor',
  ACTIVE_PROVIDERS_OBAN: 'active-providers-oban',
  ACTIVE_PROVIDERS_OUTSIDEMAGAZINE: 'active-providers-outsidemagazine',
  ACTIVE_PROVIDERS_RATES_SCHOOLKIT: 'active-providers-rates-schoolkit',
  ACTIVE_PROVIDERS_SCHOOLKIT: 'active-providers-schoolkit',
  ACTIVE_TASK_ASSIGNEES: 'active-task-assignees',
  BALANCE_STATEMENT: 'balance-statement',
  BUDGET_SPENT_PER_USER: 'budget-spent-per-user',
  CONTACTS_EUROMONITOR: 'contacts-euromonitor',
  DOCUMENT_CONFIRMATIONS: 'document-confirmations',
  EXPORT_MANAGERS: 'export-managers',
  EXPERT_BALANCE_STATEMENT_EUROMONITOR: 'expert-balance-statement-euromonitor',
  FINANCE_REPORT_OUTSIDEMAGAZINE: 'finance-report-outsidemagazine',
  INVOICE_APARTMENT_THERAPY: 'invoice-apartment-therapy',
  INVOICE_BREAKDOWN_EUROMONITOR: 'invoice-breakdown-euromonitor',
  INVOICE_SAP_LC: 'invoice-sap-lc',
  MEMRISE_TASK_WORKSHEETS: 'memrise-task-worksheets',
  MONTHLY_ACTIVE_PROJECTS: 'monthly-active-projects',
  MONTHLY_BUDGET_SPENT_PER_PROJECT: 'monthly-budget-spent-per-project',
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_PROVIDER: 'monthly-budget-spent-per-project-and-provider',
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_USER_ATRAIN: 'monthly-budget-spent-per-project-and-user-atrain',
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_USER_OBAN: 'monthly-budget-spent-per-project-and-user-oban',
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_USER_WISE: 'monthly-budget-spent-per-project-and-user-wise',
  MONTHLY_TASK_ASSIGNMENTS: 'monthly-task-assignments',
  POSSIBLE_DUPLICATE_WORKSHEETS_VEEVA: 'possible-duplicate-worksheets-veeva',
  PROVIDER_PAYMENTS_REPORT_VEEVA: 'provider-payments-report-veeva',
  PROVIDERS_BERLITZ: 'providers-berlitz',
  PROVIDERS_FIVESTONE_STUDIOS: 'providers-fivestone-studios',
  PROVIDERS_PROJECTS_REPORT_VEEVA: 'providers-projects-report-veeva',
  PROVIDERS_REPORT_VEEVA: 'providers-report-veeva',
  SALES_TAX: 'sales-tax',
  SERVICE_ORDERS_PENDING_INVOICING: 'service-orders-pending-invoicing',
  TASK_ASSIGNMENT_BUDGET_USAGE_REPORT_OUTSIDEMAGAZINE: 'task-assignment-budget-usage-report-outsidemagazine',
  TD_PAYMENTS_REPORT_VEEVA: 'td-payments-report-veeva',
  TIME_TRACKER_USAGE_DETAILS: 'time-tracker-usage-details',
  USAGE_INCLUDING_RAISED_REPORT_EUROMONITOR: 'usage-including-raised-report-euromonitor',
  USAGE_REPORT_INVOICE_DATE_EUROMONITOR: 'usage-report-invoice-date-euromonitor',
  USAGE_REPORT_LEADING_EDUCATORS: 'usage-report-leading-educators',
  USAGE_REPORT_OUTSIDEMAGAZINE: 'usage-report-outsidemagazine',
  USAGE_REPORT_TASK_DEADLINE_EUROMONITOR: 'usage-report-task-deadline-euromonitor',
  USAGE_REPORT_VEEVA: 'usage-report-veeva',
  WORKSHEETS_PORTOBELLO_BEHAVIOURAL_HEALTH: 'worksheets-portobello-behavioural-health',
};

export const REPORT_TABS = {
  FINANCIAL_CHARTS: 'financial-charts',
  REPORT_DOWNLOADS: 'report-downloads',
};

export const FINANCIAL_CHART_TYPES = {
  AVERAGE_SPEND_PER_PROVIDER: 'average-spend-per-provider',
  SPEND_BY_COUNTRY: 'spend-by-country',
  SPEND_BY_PROJECT: 'spend-by-project',
  SPEND_BY_PROVIDERS: 'spend-by-providers',
  SPEND_BY_TASK: 'spend-by-task',
  SPEND_BY_TYPE: 'spend-by-type',
  TOTAL_SPEND: 'total-spend',
  UPCOMING_PAYMENTS: 'upcoming-payments',
};
