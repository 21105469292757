import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

import FinancialChartSkeleton from 'analytics/assets/js/components/skeletons/FinancialChartSkeleton.jsx';

const FinancialChartWrapper = ({ children, footer, isLoading, title }) => (
  <Card className="financial-chart-wrapper">
    <Card.Header>{title}</Card.Header>
    <Card.Body className="overflow-auto">
      {isLoading && <FinancialChartSkeleton />}
      {!isLoading && children}
    </Card.Body>
    {!isLoading && footer && <Card.Footer>{footer}</Card.Footer>}
  </Card>
);

FinancialChartWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

FinancialChartWrapper.defaultProps = {
  children: null,
  footer: null,
};

export default FinancialChartWrapper;
