import qs from 'query-string';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

/* eslint-disable max-len */
// import AverageSpendPerProvider from 'analytics/assets/js/components/financial-charts/AverageSpendPerProvider.jsx';
import ProvidersPerCountry from 'analytics/assets/js/components/financial-charts/ProvidersPerCountry.jsx';
import SpendByCountry from 'analytics/assets/js/components/financial-charts/SpendByCountry.jsx';
// import SpendByProject from 'analytics/assets/js/components/financial-charts/SpendByProject.jsx';
import SpendByProviders from 'analytics/assets/js/components/financial-charts/SpendByProviders.jsx';
import SpendByTask from 'analytics/assets/js/components/financial-charts/SpendByTask.jsx';
import SpendByType from 'analytics/assets/js/components/financial-charts/SpendByType.jsx';
import TotalSpendChart from 'analytics/assets/js/components/financial-charts/TotalSpendChart.jsx';
import UpcomingPayments from 'analytics/assets/js/components/financial-charts/UpcomingPayments.jsx';
/* eslint-enable max-len */
import { REPORT_TABS } from 'analytics/assets/js/constants';
import { reportsUrl } from 'analytics/urls';
import DateRangePickerField from 'core/assets/js/components/FinalFormFields/DateRangePickerField.jsx';
import { API_DATE_FORMAT, ICON } from 'core/assets/js/constants';
import moment from 'core/assets/js/lib/tdMoment';

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

const FinancialCharts = () => {
  const history = useHistory();
  const params = useParams();
  const { search } = useLocation();
  let { max, min } = qs.parse(search);
  if (!min || !dateRegex.test(min.toString())) {
    min = moment().subtract(6, 'months').format(API_DATE_FORMAT);
  }
  if (!max || !dateRegex.test(max.toString())) {
    max = moment().format(API_DATE_FORMAT);
  }

  return (
    <>
      <div className="row mx-0">
        <Form
          initialValues={{ dateRange: { max, min } }}
          onSubmit={() => null}
          render={() => (
            <div className="financial-charts-date-picker-wrapper d-flex mb-5">
              <DateRangePickerField
                name="dateRange"
                onApply={newValue => {
                  history.push([
                    reportsUrl(params.orgAlias, REPORT_TABS.FINANCIAL_CHARTS),
                    `?min=${newValue.min}&max=${newValue.max}`,
                  ].join(''));
                }}
                wrapperClassName="mb-0"
              />
              <div className="icon d-flex align-items-center justify-content-center px-4">
                <i className={ICON.CALENDAR_ALT} />
              </div>
            </div>
          )}
        />
      </div>
      <div className="financial-charts-wrapper">
        {[
          TotalSpendChart,
          // SpendByProject,
          SpendByProviders,
          SpendByType,
          SpendByTask,
          // AverageSpendPerProvider,
          UpcomingPayments,
          SpendByCountry,
          ProvidersPerCountry,
        ].map((Component, i) => <Component key={i} max={max} min={min} />)}
      </div>
    </>
  );
};

export default FinancialCharts;
