import { LAYOUTS } from 'core/assets/js/constants';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';

// Views
import ClassificationQuestionaireIndividualView from 'accounts/assets/js/ClassificationQuestionaireIndividualView.jsx';
import ClassificationQuestionaireView from 'accounts/assets/js/ClassificationQuestionaireView.jsx';
import ClassificationQuestionairesView from 'accounts/assets/js/ClassificationQuestionairesView.jsx';
import Enable2FAView from 'accounts/assets/js/Enable2FAView.jsx';
import GetStartedView from 'accounts/assets/js/GetStartedView.jsx';
import ProfileSettingsView from 'accounts/assets/js/ProfileSettingsView.jsx';
import TimeOffsView from 'accounts/assets/js/TimeOffsView.jsx';
import TimeOffEditView from 'accounts/assets/js/TimeOffEditView.jsx';
import TimeOffCreateView from 'accounts/assets/js/TimeOffCreateView.jsx';
import ProfileCreateExperienceView from 'accounts/assets/js/ProfileCreateExperienceView.jsx';
import ProfileEditExperienceView from 'accounts/assets/js/ProfileEditExperienceView.jsx';
import { SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';

// urls
import {
  classificationQuestionnaireUrl,
  classificationQuestionnaireIndividualUrl,
  classificationQuestionnairesUrl,
  enable2FAUrl,
  orgGetStartedUrl,
  profileCreateExperienceUrl,
  profileEditExperienceUrl,
  profileSettingsUrl,
  timeOffCreateUrl,
  timeOffEditUrl,
  timeOffUrl,
} from 'accounts/urls';

const profileSettingsTabs = [
  ...Object.values(PROFILE_SETTINGS_TABS), ...Object.values(SETTINGS_PAYMENTS_TABS),
];

const profileRoutesConfig = [
  {
    path: orgGetStartedUrl(':orgAlias'),
    requireAuth: true,
    requireUserIsApprovedOrgMember: false,
    component: GetStartedView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: timeOffUrl(':orgAlias'),
    requireUserIsApprovedOrgMember: false,
    requireAuth: true,
    component: TimeOffsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: timeOffCreateUrl(':orgAlias'),
    requireUserIsApprovedOrgMember: false,
    requireAuth: true,
    component: TimeOffCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: timeOffEditUrl(':orgAlias', ':timeOffId'),
    requireUserIsApprovedOrgMember: false,
    requireAuth: true,
    component: TimeOffEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: profileSettingsUrl(':orgAlias', `:tab(${profileSettingsTabs.join('|')})`),
    requireUserIsApprovedOrgMember: false,
    requireAuth: true,
    component: ProfileSettingsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  }, {
    path: profileCreateExperienceUrl(':orgAlias'),
    requireUserIsApprovedOrgMember: false,
    requireAuth: true,
    component: ProfileCreateExperienceView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  }, {
    path: profileEditExperienceUrl(':orgAlias', ':experienceId'),
    requireUserIsApprovedOrgMember: false,
    requireAuth: true,
    component: ProfileEditExperienceView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    component: ClassificationQuestionaireView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: classificationQuestionnaireUrl(':orgAlias'),
    requireAuth: true,
  },
  {
    component: ClassificationQuestionairesView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: classificationQuestionnairesUrl(':orgAlias'),
    requireAuth: true,
  },
  {
    component: ClassificationQuestionaireIndividualView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: classificationQuestionnaireIndividualUrl(':orgAlias', ':id'),
    requireAuth: true,
  },
  {
    component: Enable2FAView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: enable2FAUrl(':orgAlias'),
    requireAuth: true,
  },
];

export default profileRoutesConfig;
