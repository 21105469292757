import { toastr } from 'react-redux-toastr';
import { createSelector } from 'reselect';

import { getMergedGetStartedSteps } from 'accounts/assets/js/lib/helpers';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { orgGetStartedUrl, setupStepsApiUrl } from 'accounts/urls';
import {
  GET_STARTED_COMPLETE_MODAL_ID, GET_STARTED_SIDE_PANEL_MODAL_ID,
} from 'accounts/assets/js/constants';
import { TOASTR_DEFAULT_PROPS } from 'core/assets/js/constants';

export const ACCOUNT_GET_STARTED_OPEN_SECTION = 'account/GET_STARTED_OPEN_SECTION';
export const ACCOUNT_IS_LOADING = 'account/IS_LOADING';
export const ACCOUNT_FETCH = 'account/FETCH';
export const ACCOUNT_POST_SUCCESS = 'account/POST_SUCCESS';
export const ACCOUNT_RESET = 'account/RESET';
export const ACCOUNT_SETTINGS_KEY = 'settings';
export const ACCOUNT_STEPS_KEY = 'steps';

/**
 * Expected state structure
 * {
 *   account: {
 *     settings: {
 *       isLoading: false,
 *       item: {}
 *     }
 *     steps: {
 *       isLoading: false,
 *       item: {}
 *     }
 *   }
 * }
 * @param state
 * @param action
 * @returns {*}
 */

export const accountInfoInitialState = {
  isLoading: false,
  httpErrorCode: null,
  hasLoaded: false,
  item: {},
};

const initialState = {};


export const singleAccountInfoReducer = (state = accountInfoInitialState, action) => {
  switch (action.type) {
    case ACCOUNT_GET_STARTED_OPEN_SECTION:
      return {
        ...state,
        getStartedOpenSection: action.section,
      };
    case ACCOUNT_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasLoaded: !action.isLoading,
      };
    case ACCOUNT_FETCH:
      return {
        ...state,
        item: action.item,
        isLoading: false,
        hasLoaded: true,
      };
    case ACCOUNT_RESET:
      return accountInfoInitialState;
    default:
      return state;
  }
};

export const reducer = (state = initialState, action) => {
  if (action.type && action.type.startsWith('account') && !action.key) {
    throw new Error(`cannot use account duck without specifying a key on action ${action.type}`);
  }
  const { key } = action;
  const keyState = singleAccountInfoReducer(state[key], action);
  let newState = { ...state };
  switch (action.type) {
    case ACCOUNT_GET_STARTED_OPEN_SECTION:
    case ACCOUNT_IS_LOADING:
    case ACCOUNT_FETCH:
    case ACCOUNT_RESET:
      newState = {
        ...state,
        [key]: keyState,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

// Action creators

export const accountIsLoadingAC = (bool, key) => ({
  type: ACCOUNT_IS_LOADING,
  key,
  isLoading: bool,
});

export const accountFetchAC = (key, item) => ({
  type: ACCOUNT_FETCH,
  item,
  key,
});

export const accountResetAC = key => ({
  type: ACCOUNT_RESET,
  key,
});

export const settingsFetchAC = settings => accountFetchAC(ACCOUNT_SETTINGS_KEY, settings);

export const settingsResetAC = () => accountResetAC(ACCOUNT_SETTINGS_KEY);

export const settingsPostSuccessAC = ({ updated }) => ({
  type: ACCOUNT_POST_SUCCESS,
  item: updated,
  key: ACCOUNT_SETTINGS_KEY,
});

export const setupStepsFetchAC = ({ steps }) => accountFetchAC(ACCOUNT_STEPS_KEY, steps);

export const accountGetStartedOpenSectionAC = section => ({
  key: ACCOUNT_SETTINGS_KEY,
  section,
  type: ACCOUNT_GET_STARTED_OPEN_SECTION,
});

// selectors
export const selectAccountInfo = (state, key) => {
  if (!key) {
    throw new Error('cannot get account info without specifying key');
  }
  return state.account[key] || accountInfoInitialState;
};

export const selectAccountSettings = state => selectAccountInfo(state, ACCOUNT_SETTINGS_KEY);

export const selectSetupSteps = state => selectAccountInfo(state, ACCOUNT_STEPS_KEY).item;

export const selectGetStartedOpenSection = state => (
  state.account[ACCOUNT_SETTINGS_KEY]?.getStartedOpenSection
);


export const selectUserSetupSteps = createSelector(
  [selectSetupSteps],
  setupSteps => (
    setupSteps.userSetupSteps || []
  ),
);

export const selectShouldHaveCompanyDetails = createSelector(
  [selectUserSetupSteps],
  steps => !!steps.find(s => s.name === 'CompanyDetailsStep'),
);

// data-services

export const fetchSetupStepsDS = ({ authedAxios = null, orgAlias } = {}) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => setupStepsApiUrl(orgAlias),
    fetchDataAC: responseData => setupStepsFetchAC(responseData),
  })
);

export const completeStepDS = async (
  dispatch, orgAlias, stepName, { history, moveToNextStep = true } = {},
) => {
  const { steps } = await dispatch(fetchSetupStepsDS({ orgAlias }));
  const mergedSteps = getMergedGetStartedSteps(orgAlias, steps);
  const allStepsAreComplete = mergedSteps.every(s => s.completed);
  dispatch(modalOpenAC(
    allStepsAreComplete ? GET_STARTED_COMPLETE_MODAL_ID : GET_STARTED_SIDE_PANEL_MODAL_ID,
  ));
  const step = mergedSteps.find(s => s.name === stepName);
  if (step) {
    toastr.success(
      'Completed step!',
      `"${step.title}" step was completed`,
      allStepsAreComplete ? null : {
        ...TOASTR_DEFAULT_PROPS,
      },
    );
    if (history) {
      history.push({ pathname: orgGetStartedUrl(orgAlias), state: { moveToNextStep } });
    }
  }
};

export default reducer;
