import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import OnboardingFormSubmissionModals from 'interviews/assets/js/components/OnboardingFormSubmissionModals.jsx';
import ReviewForm from 'interviews/assets/js/components/ReviewForm.jsx';
import SubmissionForm from 'interviews/assets/js/components/SubmissionForm.jsx';
import {
  SUBMISSION_STATUS, SUBMISSION_STATUS_CLASS, SUBMISSION_STATUS_LABEL,
} from 'interviews/assets/js/constants';
import { getSubmissionCTAButtonActions } from 'interviews/assets/js/lib/utils';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

const OnboardingForms = ({ parentComponentName, userCard }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const activeUserCard = useSelector(selectActiveUserCard);

  const reviewOnboardingForm = queryString.parse(location.search).reviewOnboardingForm;

  let defaultOpenSubmissionId = null;
  if (
    reviewOnboardingForm && Array.isArray(userCard.submissions) && userCard.submissions.length > 0
  ) {
    let submissionRequiringReview = userCard.submissions.find(s => (
      (s.allowedActions.canReview || s.allowedActions.canSubmit)
      && (
        (
          /^\d+$/.test(reviewOnboardingForm.toString())
          && parseInt(reviewOnboardingForm, 10) === s.id
        )
        || reviewOnboardingForm === 'true'
      )
    ));
    if (!submissionRequiringReview) {
      submissionRequiringReview = userCard.submissions.find(
        s => s.allowedActions.canReview || s.allowedActions.canSubmit,
      );
    }
    if (submissionRequiringReview) {
      defaultOpenSubmissionId = submissionRequiringReview.id;
    }
  }
  const [
    openOnboardingFormSubmission, setOpenOnboardingSubmission,
  ] = useState(defaultOpenSubmissionId);

  const { isAnyManager } = activeUserCard.userRole;

  const reloadProfile = () => {
    const fetchDS = params.userType === PEOPLE_TYPE.PROVIDERS ? fetchProviderDS : fetchManagerDS;
    dispatch(fetchDS({ componentName: parentComponentName, params }));
  };

  const submissions = userCard.submissions || [];

  const hasUnsubmittedSubmission = submissions.some(
    s => [SUBMISSION_STATUS.DRAFT, SUBMISSION_STATUS.AMEND].includes(s.status),
  );
  const hasSubmissionToBeReviewed = submissions.some(
    s => s.status === SUBMISSION_STATUS.PENDING,
  );
  const hasSubmissionNeedingManagerAnswers = submissions.some(
    s => s.status === SUBMISSION_STATUS.SUBMITTED,
  );
  const submissionsAllReviewed = submissions.every(
    s => [SUBMISSION_STATUS.APPROVED, SUBMISSION_STATUS.REJECTED].includes(s.status),
  );

  let systemMessageTitle = null;
  let systemMessageBody = null;
  let systemMessageType = BS_STYLE.WARNING;
  const userType = userCard.userRole.isProvider ? 'provider' : 'manager';
  let toggleStatusLabel = null;
  let toggleStatusClass = 'pending';
  if (hasUnsubmittedSubmission) {
    systemMessageTitle = 'Pending onboarding forms!';
    systemMessageBody = `The ${userType} has not submitted all onboarding forms yet.`;
    toggleStatusLabel = 'Pending';
  } else if (hasSubmissionNeedingManagerAnswers) {
    systemMessageTitle = 'Onboarding forms are pending manager answers!';
    systemMessageBody = `The ${userType} has submitted their onboarding forms and awaits manager `
      + 'answers.';
    toggleStatusLabel = 'Pending manager answers';
  } else if (hasSubmissionToBeReviewed) {
    systemMessageTitle = 'Onboarding forms are pending approval!';
    systemMessageBody = `The ${userType} has submitted their onboarding forms and awaits approval.`;
    toggleStatusLabel = 'Pending review';
  } else if (submissionsAllReviewed) {
    systemMessageTitle = 'Onboarding forms reviewed';
    systemMessageBody = `All of the ${userType}'s onboarding forms have been reviewed.`;
    systemMessageType = BS_STYLE.SUCCESS;
    toggleStatusLabel = 'Reviewed';
    toggleStatusClass = 'approved';
  }

  const canSeeAtLeastOneOnboardingForm = submissions.some(
    s => s.allowedActions.canReview || s.allowedActions.canView || s.allowedActions.canSubmit,
  );

  if (!isAnyManager || !canSeeAtLeastOneOnboardingForm) {
    return null;
  }

  return (
    <Card className="mb-5">
      <AreaCollapsible
        baseHeadingClassName="w-100"
        className="profile-onboarding-collapsible"
        contentChildren={(
          <div className="d-flex flex-column">
            {systemMessageTitle && (
              <TDSystemMessage
                className="mb-5 mx-3"
                title={systemMessageTitle}
                type={systemMessageType}
              >
                {systemMessageBody}
              </TDSystemMessage>
            )}
            {submissions.map(submission => {
              if (!submission.allowedActions.canReview && !submission.allowedActions.canView) {
                return null;
              }
              const isOpen = openOnboardingFormSubmission === submission.id;
              const ctaButtonItems = getSubmissionCTAButtonActions(submission, dispatch);
              const toggleSubmission = () => setOpenOnboardingSubmission(
                isOpen ? null : submission.id,
              );
              return (
                <div
                  className={(
                    `profile-onboarding-submission mx-3 mb-3 p-3${isOpen ? ' open' : ''}`
                  )}
                  key={submission.id}
                >
                  <div className="header-title d-flex align-items-start align-items-sm-center">
                    <i
                      className={`${ICON.CHEVRON_UP} py-3 px-4 toggle`}
                      onClick={toggleSubmission}
                    />
                    <div
                      className={(
                        'w-100 d-flex flex-column flex-sm-row align-items-start'
                          + ' align-items-sm-center justify-content-between'
                      )}
                    >
                      <div className="font-weight-bold py-3" onClick={toggleSubmission}>
                        {submission.name}
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-start">
                        <StatusTag
                          label={SUBMISSION_STATUS_LABEL[submission.status]}
                          statusClass={SUBMISSION_STATUS_CLASS[submission.status]}
                        />
                        {ctaButtonItems.length > 0 && (
                          <TDDropChevronButton className="ml-5">
                            {ctaButtonItems.map(item => (
                              <Dropdown.Item key={item.label} onSelect={item.onClick}>
                                {item.label}
                              </Dropdown.Item>
                            ))}
                          </TDDropChevronButton>
                        )}
                      </div>
                    </div>
                  </div>
                  {isOpen && (
                    <>
                      {submission.allowedActions.canSubmit && (
                        <SubmissionForm
                          containerClassName="px-3"
                          displayName={false}
                          onSubmit={reloadProfile}
                          submission={submission}
                        />
                      )}
                      {!submission.allowedActions.canSubmit && (
                        <ReviewForm
                          displayOnboardingFormName={false}
                          onChangeManagerAnswers={reloadProfile}
                          onReOpen={reloadProfile}
                          onSubmit={reloadProfile}
                          providerFirstName={userCard.user.profile.firstName}
                          submission={submission}
                        />
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        headingChildren={(
          <div
            className={(
              'people-onboarding-collapsible-header-content d-flex align-items-center '
              + 'justify-content-between'
            )}
          >
            <div className="d-flex align-items-center justify-content-start float-left">
              <div>Onboarding forms</div>
              {toggleStatusLabel && (
                <StatusTag
                  className="ml-5"
                  label={toggleStatusLabel}
                  statusClass={toggleStatusClass}
                />
              )}
            </div>
          </div>
        )}
        headingClassName="px-5 d-flex align-items-center justify-content-between"
        initiallyExpanded={reviewOnboardingForm}
        toggleElementClassName={null}
      />
      <OnboardingFormSubmissionModals onSuccess={reloadProfile} />
    </Card>
  );
};

OnboardingForms.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
  userCard: userCardSpec.isRequired,
};

export default OnboardingForms;
