import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import GeoSuggestField from 'core/assets/js/components/FinalFormFields/GeoSuggestField.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import UserUSTaxFormsList from 'finance/assets/js/components/UserUSTaxFormsList.jsx';
import { US_TAX_FORM_TYPE_LABEL, US_TAX_FORM_TYPE_VALUES } from 'finance/assets/js/constants';
import { userUsTaxFormSpec } from 'finance/assets/js/lib/objectSpecs';
import SalesTaxFields, { SalesTaxSwitch } from 'settings/assets/js/components/SalesTaxFields.jsx';
import { orgSettingsPaymentsUrl } from 'settings/urls';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { uploaderCompanyPath } from 'core/urls';
import { ORG_SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { getInfoFromFormValues } from 'settings/assets/js/lib/sales_tax_utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { extractAddressComponent } from 'core/assets/js/lib/utils';

const yesNoOptions = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

const CompanyForm = ({
  fromGetStarted,
  hasOrgWithoutVatSet,
  hasRaisedWorksheet,
  initialValues,
  onSubmit,
  orgsWithPendingWorksheets,
  profile,
  reloadTaxInformation,
  usTaxForms,
}) => {
  const activeOrg = useSelector(selectActiveOrg);

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <div className="settings-row mt-2">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, form: { getState } }) => {
            const { dirty, submitError, submitErrors, submitting, values } = getState();
            const salesTaxInfo = getInfoFromFormValues(values);
            const {
              showAnySalesTaxField,
              companyTaxSystemInfo,
            } = salesTaxInfo;
            const {
              address,
              is_incorporated: isIncorporated,
              isUSTaxpayer,
              usTaxFormType,
            } = values;
            const showSysMsg = showAnySalesTaxField
              && !!hasRaisedWorksheet && !!hasOrgWithoutVatSet;
            const companyAddressIsUS = (
              address && extractAddressComponent(values.address, 'country_code') === 'US'
            );

            return (
              <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column flex-sm-row">
                  <div className="settings-row__content">
                    <label>Are you a legal entity/incorporated?</label>
                    <span className="d-block discreet">
                      If you offer your services as an individual, rather than as a company that
                      you have set up for this purpose, please select &quot;No&quot;.
                    </span>
                  </div>
                  <div className="settings-row__cta text-right">
                    <RadioField
                      className="float-md-right"
                      disabled={submitting}
                      name="is_incorporated"
                      options={yesNoOptions}
                      showInline
                    />
                  </div>
                </div>
                {[true, false].includes(isIncorporated) && (
                  <>
                    <hr />
                    {isIncorporated === false && (
                      <>
                        <div className="d-flex flex-column flex-sm-row">
                          <div className="settings-row__content">
                            <label>
                              Are you a United States citizen or required to pay tax in the United
                              States?
                            </label>
                            <span className="d-block discreet">
                              For US taxpayers, we will request your Social Security Number.
                              We store this information securely and only share it with the
                              IRS for 1099 tax filings. If you are a US citizen or taxpayer,
                              you must choose yes.
                            </span>
                          </div>
                          <div className="settings-row__cta text-right">
                            <RadioField
                              disabled={submitting}
                              name="isUSTaxpayer"
                              options={yesNoOptions}
                              showInline
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    <div className={`clearfix ${isIncorporated ? '' : 'd-none'}`}>
                      <div className="settings-row mb-4">
                        <div className="image-upload-row clearfix">
                          {profile && (
                            <FileUploaderField
                              disabled={submitting}
                              imgHeight={150}
                              imgWidth={390}
                              label="Company logo"
                              name="logo_metadata"
                              path={uploaderCompanyPath(profile.userId)}
                              softDelete
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <TextInputField
                            disabled={submitting}
                            label="Company name"
                            name="name"
                            required
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <TextInputField
                            disabled={submitting}
                            label="Registration number"
                            name="registration_number"
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <GeoSuggestField
                            disabled={submitting}
                            label="Address"
                            name="address"
                            required
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <TextInputField
                            disabled={submitting}
                            label="Tax identification number (TIN)"
                            name="tax_identification_number"
                            required={activeOrg.user_us_tax_number_required && companyAddressIsUS}
                            sublabel={(
                              'Please add your Taxpayer Identification Number (TIN) as per your W9.'
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {isIncorporated === false && (
                      <>
                        <div className="row mt-4">
                          <div className="col-12 col-sm-6">
                            <TextInputField
                              disabled={submitting}
                              label="Full legal name"
                              name="full_legal_name"
                              required
                              sublabel="Enter your first, middle and last name"
                            />
                          </div>
                        </div>
                        {isUSTaxpayer && (
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <TextInputField
                                disabled={submitting}
                                label="Social security number (SSN)"
                                name="social_security_number"
                                required={activeOrg.user_us_tax_number_required}
                                sublabel="Enter your social security number."
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <hr />
                    { showSysMsg && (
                      <TDSystemMessage
                        type="warning"
                        title={`Set up your ${companyTaxSystemInfo.rateLabel}`}
                        className="my-4"
                      >
                        <p>
                          You have submitted a worksheet that is due to be paid at the end
                          of the month. However, you have not yet set up your correct
                          {' '}
                          {companyTaxSystemInfo.rateLabel}
                          {' '}
                          rate for the invoices raised on your behalf by TalentDesk.io.
                        </p>
                        <p>
                          Please note that the system will use a
                          {' '}
                          {companyTaxSystemInfo.rateLabel}
                          {' '}
                          rate of 0% if you do not set it up before your invoices are generated.
                        </p>
                        {orgsWithPendingWorksheets.map(org => (
                          <p key={org.unique_alias}>
                            Click
                            {' '}
                            <Link
                              to={orgSettingsPaymentsUrl(
                                org.unique_alias, ORG_SETTINGS_PAYMENTS_TABS.SALES_TAX,
                              )}
                            >
                              here
                            </Link>
                            {` to set up your ${companyTaxSystemInfo.rateLabel} for ${org.name}.`}
                          </p>
                        ))}
                      </TDSystemMessage>
                    )}
                    <SalesTaxSwitch submitting={submitting} />
                    <SalesTaxFields
                      submitting={submitting}
                      {...salesTaxInfo}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div>
                        <TDLabel
                          label="IRS Taxpayer Identification Form"
                          name="usTaxFormType"
                          sublabel={(
                            <>
                              Please upload your tax documentation (W-9, W-8BEN, or W-8BEN-E).
                              {' Blank forms can be found on the '}
                              <a
                                href="https://www.irs.gov/forms-instructions"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                IRS.gov
                              </a>
                              {' website.'}
                            </>
                          )}
                        />
                      </div>
                      <SelectField
                        defaultOptionText="Select IRS form type"
                        name="usTaxFormType"
                        optionsMapping={US_TAX_FORM_TYPE_VALUES.map(value => ({
                          text: US_TAX_FORM_TYPE_LABEL[value], value,
                        }))}
                      />
                    </div>
                    {US_TAX_FORM_TYPE_VALUES.includes(usTaxFormType) && (
                      <FileUploaderDirectField
                        accept={['application/pdf']}
                        label={null}
                        name="usTaxForm"
                      />
                    )}
                    {submitErrors?.usTaxFormRequired && (
                      <div className="text-danger mb-3">{submitErrors.usTaxFormRequired}</div>
                    )}
                    <UserUSTaxFormsList
                      className="mt-5"
                      reload={reloadTaxInformation}
                      usTaxForms={usTaxForms}
                    />
                    {submitError && <p className="text-danger">{submitError}</p>}
                    <div className="row">
                      <div className="col-12 text-right">
                        <TDButton
                          data-testid="company-form-button-save-or-update"
                          className={`position-tweak ${dirty ? 'btn--dirty' : 'btn--pristine'}`}
                          type="submit"
                          variant={BS_STYLE.PRIMARY}
                          disabled={submitting}
                          label={fromGetStarted ? 'Save & continue' : 'Update'}
                        />
                      </div>
                    </div>
                  </>
                )}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};

CompanyForm.propTypes = {
  fromGetStarted: PropTypes.bool,
  hasOrgWithoutVatSet: PropTypes.bool.isRequired,
  hasRaisedWorksheet: PropTypes.number.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  orgsWithPendingWorksheets: PropTypes.array,
  profile: PropTypes.object,
  reloadTaxInformation: PropTypes.func.isRequired,
  usTaxForms: PropTypes.arrayOf(userUsTaxFormSpec),
};

CompanyForm.defaultProps = {
  fromGetStarted: false,
  initialValues: {},
  orgsWithPendingWorksheets: [],
  profile: null,
  usTaxForms: [],
};

const mapStateToProps = state => ({ profile: selectProfile(state) });

export default connect(mapStateToProps)(CompanyForm);
