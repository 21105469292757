import { toInteger } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { CHANGE_ONBOARDING_FORM_MODAL_ID } from 'interviews/assets/js/constants';
import { changeOnboardingFormApiUrl, interviewListApiUrl } from 'interviews/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

export const INTERVIEW_LIST_STORE_KEY = 'change-onboarding-form-modal-interviews-list';

const ChangeOnboardingFormModal = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => getIsModalOpen(state, CHANGE_ONBOARDING_FORM_MODAL_ID));
  const payload = useSelector(state => getModalPayload(state, CHANGE_ONBOARDING_FORM_MODAL_ID));
  const submission = payload?.submission || null;
  const isModalOpen = isOpen && submission;
  const { items: interviews = [] } = useSelector(
    state => getListState(state, INTERVIEW_LIST_STORE_KEY),
  );
  const activeOrg = useSelector(selectActiveOrg);

  const changeOnboardingForm = async ({ interview_id: interviewId }) => {
    try {
      const { data: newSubmission } = await axios.post(
        changeOnboardingFormApiUrl({ orgAlias: activeOrg.alias, submissionId: submission.id }),
        { interviewId },
      );
      toastr.success('Well Done!', 'Onboarding form successfully changed.');
      onSuccess(newSubmission);
    } catch (e) {
      const error = e.response?.data?._error || e.message;
      toastr.error('Oh Snap!', error);
      // This is required to prevent the modal thinking confirm was successful and then closing
      // automatically
      throw new Error(error);
    }
  };

  const onboardingFormOptions = !submission ? [] : interviews
    .filter(i => i.id !== submission.interviewId)
    .map(i => ({ text: i.name, value: i.id }));

  const hasOnboardingFormOptions = onboardingFormOptions.length > 0;

  return (
    <Form
      onSubmit={changeOnboardingForm}
      render={({ form: { getState }, handleSubmit }) => (
        <ModalConfirm
          confirmButtonDisabled={!toInteger(getState().values.interview_id)}
          confirmLabel="Send"
          data-testid="change-onboarding-form-modal"
          heading="Send a new onboarding form"
          open={isModalOpen}
          onClose={() => dispatch(modalCloseAC())}
          onConfirm={handleSubmit}
          showConfirmButton={hasOnboardingFormOptions}
          size={MODAL_SIZES.LARGE}
        >
          <TDApiConnected
            duck="list"
            fetchData={({ authedAxios, url }) => dispatch(fetchListDS({
              authedAxios,
              componentName: INTERVIEW_LIST_STORE_KEY,
              url: interviewListApiUrl(activeOrg.alias, url),
            }))}
            storeKey={INTERVIEW_LIST_STORE_KEY}
          >
            <p>
              Is there a mistake in the onboarding form sent to the user? Or maybe you
              want to send a different onboarding form?
              <br />
              Simply select the new onboarding form and then click &quot;Send&quot;.
            </p>
            <AreaCollapsible
              className="mb-5 mt-5"
              contentChildren={(
                <p>
                  If you choose to send a new onboarding form to the user, the existing
                  onboarding submission and any answers provided will be deleted and the
                  user will have to re-submit their answers.
                </p>
              )}
              headingChildren="Is it safe to send a new onboarding form?"
              solidColor
            />
            {hasOnboardingFormOptions && (
              <SelectField
                label="Onboarding form"
                name="interview_id"
                optionsMapping={onboardingFormOptions}
                required
              />
            )}
            {!hasOnboardingFormOptions && (
              <TDSystemMessage
                data-testid="change-onboarding-form-modal-cannot-message"
                title="Cannot change onboarding form"
                type={BS_STYLE.DANGER}
              >
                <p>
                  Your organization does not have any other onboarding forms, so you
                  cannot change this onboarding submission.
                </p>
              </TDSystemMessage>
            )}
          </TDApiConnected>
        </ModalConfirm>
      )}
    />
  );
};

ChangeOnboardingFormModal.GetComponentName = () => 'ChangeOnboardingFormModal';

ChangeOnboardingFormModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default ChangeOnboardingFormModal;
