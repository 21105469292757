import React from 'react';
import PropTypes from 'prop-types';
import { maxBy } from 'lodash';
import { connect } from 'react-redux';
import { Dropdown, Card } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Link, withRouter } from 'react-router-dom';

import axios from 'core/assets/js/lib/tdAxios';
import ProjectMemberStatus from 'people/assets/js/components/ProjectMemberStatus.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { formatDate } from 'core/assets/js/lib/utils';
import { reviewSpec } from 'people/assets/js/lib/objectSpecs';
import CancelInvitationForm from 'people/assets/js/components/CancelInvitationForm.jsx';
import {
  projectResendInvitationDS,
  projectCancelInvitationDS,
} from 'projects/assets/js/data-services/form';
import { STATUS as INVITATION_STATUS } from 'invitations/assets/js/constants';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { modalOpenAC, getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { projectSpec, projectAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { providerUserCardSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { USER_TYPE, IMG_SIZE, POPPER_PLACEMENT, API_DATE_FORMAT } from 'core/assets/js/constants';
import { orgUserProfileUrl } from 'people/urls';
import ReviewForm from 'people/assets/js/components/ReviewForm.jsx';
import EmploymentStatusIndicator from 'people/assets/js/components/EmploymentStatusIndicator.jsx';
import LeaveProjectConfirmModal from 'projects/assets/js/components/LeaveProjectConfirmModal.jsx';
import UserTypeIndicator from 'people/assets/js/components/UserTypeIndicator.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ContactDetailsCard from 'people/assets/js/components/ContactDetailsCard.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import RemoveProjectMemberModal from 'projects/assets/js/components/RemoveProjectMemberModal.jsx';
import Logger from 'core/assets/js/lib/Logger';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import { projectMemberRemoveUrl, projectRemoveMemberTaskDetailsApiUrl } from 'projects/urls';

const logger = new Logger('react:ProjectMemberCard');

class ProjectMemberCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenContactModal = this.handleOpenContactModal.bind(this);
    this.handleCloseContactModal = this.handleCloseContactModal.bind(this);

    this.handleOpenCancelInvitationModal = this.handleOpenCancelInvitationModal.bind(this);
    this.handleCloseCancelInvitationModal = this.handleCloseCancelInvitationModal.bind(this);
    this.handleCancelInvitation = this.handleCancelInvitation.bind(this);

    this.handleOpenResendInvitationModal = this.handleOpenResendInvitationModal.bind(this);
    this.handleCloseResendInvitationModal = this.handleCloseResendInvitationModal.bind(this);
    this.handleResendInvitation = this.handleResendInvitation.bind(this);

    this.handleOpenReviewModal = this.handleOpenReviewModal.bind(this);
    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);

    this.handleRemove = this.handleRemove.bind(this);
    this.handleCloseRemoveMemberModal = this.handleCloseRemoveMemberModal.bind(this);

    this.handleOpenLeaveProjectModal = this.handleOpenLeaveProjectModal.bind(this);
    this.handleCloseLeaveProjectModal = this.handleCloseLeaveProjectModal.bind(this);

    this.contactProviderModalId = `contact-member-modal-${props.item.id}`;
    this.cancelInvitationModalId = `cancel-invitation-modal-${props.item.id}`;
    this.resendInvitationModalId = `resend-invitation-modal-${props.item.id}`;
    this.leaveProjectModalId = `leave-project-modal-${props.item.id}`;
    this.removeMemberModalId = `remove-member-modal-${props.item.id}`;
    this.reviewUserModalId = `review-user-modal-${props.item.id}`;
  }

  handleOpenContactModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.contactProviderModalId));
  }

  handleCloseContactModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleOpenCancelInvitationModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.cancelInvitationModalId));
  }

  handleCloseCancelInvitationModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleCancelInvitation({ userId, invId, values }) {
    const data = {
      userId,
      invId,
      reason: values.reason || '',
    };

    const { onInvitationCancelled, match: { params: { orgAlias, id: projectId } } } = this.props;

    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { invitation },
        } = await projectCancelInvitationDS(orgAlias, projectId, data);

        this.handleCloseCancelInvitationModal();
        onInvitationCancelled(invitation);
        return resolve(invitation);
      } catch (err) {
        return reject(err);
      }
    });
  }

  handleOpenReviewModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.reviewUserModalId));
  }

  handleCloseReviewModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleOpenResendInvitationModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.resendInvitationModalId));
  }

  handleCloseResendInvitationModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleOpenLeaveProjectModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.leaveProjectModalId));
  }

  handleCloseLeaveProjectModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(this.leaveProjectModalId));
  }

  handleResendInvitation({ invId, userId, role }) {
    const { match, onInvitationResent } = this.props;
    return new Promise(async (resolve, reject) => {
      try {
        const { orgAlias, id } = match.params;
        const {
          data: { invitation },
        } = await projectResendInvitationDS(orgAlias, id, { invId, userId, role });
        onInvitationResent(invitation);
        return resolve(invitation);
      } catch (err) {
        this.handleCloseResendInvitationModal();
        return reject(err);
      }
    });
  }

  async handleRemove() {
    const {
      dispatch, history, item: projectMember, match: { params: { orgAlias, id: projectId } },
    } = this.props;
    try {
      if (projectMember.userCard.userRole.isProvider) {
        const { data } = await axios.get(projectRemoveMemberTaskDetailsApiUrl(
          orgAlias, projectId, projectMember.userId,
        ));
        if (Array.isArray(data.tasks) && data.tasks.length > 0) {
          history.push(projectMemberRemoveUrl(orgAlias, projectId, projectMember.userId));
          return;
        }
      }
      dispatch(modalOpenAC(this.removeMemberModalId, { projectMember }));
    } catch (e) {
      toastr.error('Oh snap!', e.response?.data?._error || e.message);
    }
  }

  handleCloseRemoveMemberModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      isLastItem,
      activeUserCard,
      isCancelInvitationModalOpen,
      isContactModalOpen,
      isResendInvitationModalOpen,
      isReviewModalOpen,
      item: {
        allowedActions: projectMemberAllowedActions,
        currencySymbol,
        joinedAt,
        invitations,
        rate,
        rateUnit,
        status,
        userCard: {
          user: { id, profile, profile: { skills }, email: userEmail },
          isEmployee,
          userRole: { ofType: userType },
          numReviews,
          rating,
          email: userCardEmail,
        },
      },
      onMemberRemoved,
      onReviewAdded,
      orgAlias,
      project,
      projectAllowedActions,
      userId: activeUserId,
      withActions,
      linkTarget,
    } = this.props;

    const canViewOtherProviders = (
      activeUserCard.userRole.isAnyManager
      || activeUserCard.organization.should_provider_view_other_providers
    );

    let profileUrl;
    if (userType === USER_TYPE.PROVIDER) {
      if (canViewOtherProviders) {
        profileUrl = orgUserProfileUrl(orgAlias, userType, id);
      }
    } else {
      profileUrl = orgUserProfileUrl(orgAlias, userType, id);
    }

    const email = userCardEmail || userEmail;
    const hasCompleteRate = currencySymbol && parseFloat(rate) > 0.00;
    const lastInvitation = maxBy(invitations, 'id');
    const showReview = projectAllowedActions.canReviewProviders
      && projectMemberAllowedActions.canBeReviewed;
    const showRemoveMember = projectMemberAllowedActions.canRemove;
    const showLeaveProject = projectMemberAllowedActions.canLeave && id === activeUserId;

    let hasPendingInvitation = false;
    let hasExpiredInvitation = false;
    let hasCancelledInvitation = false;

    if (lastInvitation) {
      hasExpiredInvitation = lastInvitation.hasExpired;
      hasPendingInvitation = lastInvitation.status === INVITATION_STATUS.PENDING && (
        !lastInvitation.hasExpired
      );
      hasCancelledInvitation = lastInvitation.status === INVITATION_STATUS.CANCELLED;
    }

    // Rate display
    const rateDisplayOpts = {
      currency: currencySymbol,
    };
    if (!hasPendingInvitation && hasCompleteRate) {
      rateDisplayOpts.showRateUnitDiscreetly = true;
    }
    const userRate = hasCompleteRate
      ? (
        <StatusTag
          statusClass="default"
          hideTitle
          hideDot
          label={getDisplayRate(rate, rateUnit, rateDisplayOpts)}
        />
      )
      : '';

    return (
      <Card className="user-item">
        <Card.Body>
          <div className="row d-flex align-items-center position-relative">
            <div className="user-item__basic-info col-12 d-flex">
              {profileUrl ? (
                <Link
                  to={profileUrl}
                  title={profile.name}
                  target={linkTarget === '_self' ? undefined : linkTarget}
                >
                  <ProfilePic
                    url={profile.avatar}
                    alt={profile.name}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  />
                  <UserTypeIndicator
                    userType={userType}
                  />
                </Link>
              ) : (
                <React.Fragment>
                  <ProfilePic
                    url={profile.avatar}
                    alt={profile.name}
                    size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                  />
                  <UserTypeIndicator
                    userType={userType}
                  />
                </React.Fragment>
              )}
              <div className="col-11 pl-3">
                {profileUrl ? (
                  <Link
                    to={profileUrl}
                    title={profile.name}
                    target={linkTarget === '_self' ? undefined : linkTarget}
                    className="user-item__title truncate"
                  >
                    {profile.name}
                    <EmploymentStatusIndicator
                      userType={userType}
                      isEmployee={isEmployee}
                    />
                  </Link>
                ) : (
                  <React.Fragment>
                    {profile.name}
                    <EmploymentStatusIndicator
                      userType={userType}
                      isEmployee={isEmployee}
                    />
                  </React.Fragment>
                )}
                <div className="user-item__extra discreet d-flex align-items-center col-auto px-0">
                  <div className="truncate truncate--100">
                    {profile.jobTitle && (
                      <span className="mr-0" title={profile.jobTitle}>
                        {profile.jobTitle }
                      </span>
                    )}
                    {profile.jobTitle && joinedAt && (
                      <span className="mx-2">&bull;</span>
                    )}
                    {joinedAt && (
                      <span title={`Joined on ${formatDate(joinedAt)}`}>
                        <time dateTime={formatDate(joinedAt, API_DATE_FORMAT)}>
                          {`Joined on ${formatDate(joinedAt)}`}
                        </time>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-auto mt-3 mt-md-0 user-item__details ml-0 ml-md-auto">
              <RatingScore
                rating={rating}
                numReviews={numReviews}
              />
              { hasCompleteRate && userRate}
            </div>
          </div>

          <ProjectMemberStatus
            status={status}
            lastInvitation={projectAllowedActions.canManageInvitations && lastInvitation}
          />
        </Card.Body>

        <Card.Footer className="bg-white d-flex flex-nowrap">
          <div className="col-10 pl-0">
            { skills && (
              <SkillList
                skills={skills}
                inline
                maxShownItems={7}
                modalId={`user-skills-${id}`}
              />
            )}
          </div>

          {withActions && (
            <TDDropButton
              className="ml-auto"
              data-testid="project-member-card-actions-dropdown"
              stopPropagation
              placement={isLastItem ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
            >
              <Dropdown.Item
                eventKey="contact-user"
                onClick={this.handleOpenContactModal}
              >
                Contact
              </Dropdown.Item>

              { projectAllowedActions.canManageInvitations
              && (hasExpiredInvitation || hasCancelledInvitation) && (
                <Dropdown.Item
                  eventKey="resend-invitation"
                  onClick={this.handleOpenResendInvitationModal}
                >
                  Resend Invitation
                </Dropdown.Item>
              )}

              { projectAllowedActions.canManageInvitations
              && (hasPendingInvitation || hasExpiredInvitation) && (
                <Dropdown.Item
                  eventKey="cancel-invitation"
                  onClick={this.handleOpenCancelInvitationModal}
                >
                  Cancel Invitation
                </Dropdown.Item>
              )}

              { showReview && (
                <Dropdown.Item
                  eventKey="leave-review"
                  onClick={() => this.handleOpenReviewModal(profile)}
                >
                  Leave Review
                </Dropdown.Item>
              )}

              {(showRemoveMember || showLeaveProject) && (
                <Dropdown.Divider />
              )}

              {showRemoveMember && (
                <Dropdown.Item
                  eventKey="remove-user"
                  onClick={this.handleRemove}
                  className="text-danger"
                >
                  Remove User
                </Dropdown.Item>
              )}

              {showLeaveProject && (
                <Dropdown.Item
                  eventKey="leave-project"
                  onClick={this.handleOpenLeaveProjectModal}
                  className="text-danger"
                >
                  Leave Project
                </Dropdown.Item>
              )}
            </TDDropButton>
          )}

          <ModalSimple
            open={isContactModalOpen}
            heading={`Contact ${profile.firstName}`}
            body={(
              <ContactDetailsCard
                email={email}
                profile={profile}
              />
            )}
            onClose={this.handleCloseContactModal}
          />

          { lastInvitation && (
            <CancelInvitationForm
              form={`cancel-invitation-${lastInvitation.id}`}
              open={isCancelInvitationModalOpen}
              heading="Cancel invitation"
              body={`Are you sure you want to cancel the invitation to ${profile.name}?`}
              confirmLabel="Cancel Invitation"
              onClose={this.handleCloseCancelInvitationModal}
              onConfirm={this.handleCancelInvitation}
              initialValues={{ reason: null }}
              payload={{
                invId: lastInvitation.id,
                userId: profile.userId,
              }}
            />
          )}

          { lastInvitation && (
            <ModalConfirm
              open={isResendInvitationModalOpen}
              heading="Resend invitation"
              body={`Are you sure you want to resend the invitation to ${profile.name}?`}
              confirmLabel="Resend"
              onClose={this.handleCloseResendInvitationModal}
              onConfirm={this.handleResendInvitation}
              payload={{
                invId: lastInvitation.id,
                userId: profile.userId,
                role: userType,
              }}
            />
          )}

          { showRemoveMember && (
            <RemoveProjectMemberModal
              modalId={this.removeMemberModalId}
              onMemberRemoved={onMemberRemoved}
            />
          )}

          { showLeaveProject && (
            <LeaveProjectConfirmModal
              modalId={this.leaveProjectModalId}
            />
          )}

          { showReview && (
            <ReviewForm
              projectOptions={[{ text: project.title, value: project.id }]}
              initialValues={{
                project_id: project.id,
              }}
              onAfterSubmit={onReviewAdded}
              onCloseModal={this.handleCloseReviewModal}
              show={isReviewModalOpen}
              revieweeFirstName={profile.firstName}
              showProjectDropdown={false}
              payload={{ subject_id: profile.userId }}
            />
          )}
        </Card.Footer>
      </Card>
    );
  }
}

ProjectMemberCard.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isCancelInvitationModalOpen: PropTypes.bool,
  isContactModalOpen: PropTypes.bool,
  isLastItem: PropTypes.bool,
  isResendInvitationModalOpen: PropTypes.bool,
  isReviewModalOpen: PropTypes.bool,
  item: providerUserCardSpec.isRequired,
  match: routerMatchSpec.isRequired,
  onInvitationCancelled: PropTypes.func,
  onInvitationResent: PropTypes.func,
  onMemberRemoved: PropTypes.func,
  onReviewAdded: PropTypes.func,
  orgAlias: PropTypes.string.isRequired,
  project: projectSpec.isRequired,
  projectAllowedActions: projectAllowedActionsSpec,
  reviews: PropTypes.arrayOf(reviewSpec),
  userId: PropTypes.number.isRequired,
  withActions: PropTypes.bool,
  linkTarget: PropTypes.string,
};

ProjectMemberCard.defaultProps = {
  isCancelInvitationModalOpen: false,
  isContactModalOpen: false,
  isLastItem: false,
  isResendInvitationModalOpen: false,
  linkTarget: '_self',
  isReviewModalOpen: false,
  onInvitationCancelled: () => logger.warn('onInvitationCancelled callback not specified'),
  onInvitationResent: () => logger.warn('onInvitationResent callback not specified'),
  onMemberRemoved: () => logger.warn('onMemberRemoved callback not specified'),
  onReviewAdded: () => logger.warn('onReviewAdded callback not specified'),
  projectAllowedActions: {},
  reviews: [],
  withActions: true,
};

const mapStateToProps = (state, props) => {
  const userId = selectProfile(state).userId;
  let reviews = [];

  if (state.projects && state.projects.projectActive) {
    reviews = state.projects.projectActive.reviews || [];
  }

  return {
    activeUserCard: selectActiveUserCard(state),
    isCancelInvitationModalOpen: getIsModalOpen(state, `cancel-invitation-modal-${props.item.id}`),
    isContactModalOpen: getIsModalOpen(state, `contact-member-modal-${props.item.id}`),
    isResendInvitationModalOpen: getIsModalOpen(state, `resend-invitation-modal-${props.item.id}`),
    isReviewModalOpen: getIsModalOpen(state, `review-user-modal-${props.item.id}`),
    reviews,
    userId,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectMemberCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectMemberCard);

export default withRouter(ProjectMemberCardConnected);
