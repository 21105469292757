import { API_DATE_FORMAT, DATETIME_FORMAT_ISO } from 'core/assets/js/constants';

/*
  moment.js has been mothballed: https://momentjs.com/docs/#/-project-status/
  So this is our implementation, replicating all of the methods that we use
*/

const formatsErrorSuffix = `in the formats ${API_DATE_FORMAT} or ${DATETIME_FORMAT_ISO}`;
const formatError = `The passed in date string must be ${formatsErrorSuffix}`;

const secondInMS = 1000;
const minuteInMS = secondInMS * 60;
const hourInMS = minuteInMS * 60;
const dayInMS = hourInMS * 24;
const weekInMS = dayInMS * 7;

/**
 * Get the number of days in a given month
 *
 * @param {Number} month
 * @param {Number} year
 * @returns {Number}
 */
const getDaysInMonth = (month, year) => {
  if ([4, 6, 9, 11].includes(month)) {
    return 30;
  }
  if (month === 2) {
    return year % 4 === 0 ? 29 : 28;
  }
  return 31;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getMonth = date => date.getMonth() + 1;

const getNumberSuffix = number => {
  if (number > 3 && number < 21) {
    return 'th';
  }
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const getDayOfYear = date => {
  const startOfYear = new Date(date.getFullYear(), 0, 0);
  const diffMS = (date - startOfYear) + (
    (startOfYear.getTimezoneOffset() - date.getTimezoneOffset()) * minuteInMS
  );
  return Math.floor(diffMS / dayInMS);
};

const dayNames = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const getAMPM = date => (date.getHours() < 12 ? 'am' : 'pm');

const getHourAMPM = date => {
  const hour = date.getHours();
  if (hour === 0) {
    return 12;
  }
  if (hour > 12) {
    return hour - 12;
  }
  return hour;
};

const padWithZeros = (number, { desiredLength = 2 } = {}) => {
  const numberString = number.toString();
  const charactersToAdd = desiredLength - numberString.length;
  if (charactersToAdd <= 0) {
    return numberString;
  }
  return [...new Array(charactersToAdd).fill('0'), number].join('');
};

const addNumberSuffix = number => `${number}${getNumberSuffix(number)}`;

// IMPORTANT: The order of these is important. e.g. so YYYY is replaced before YY etc
const formats = { // e.g. 2024-03-01T03:05:11.123Z
  // Year
  YYYY: { // 2024
    fn: date => date.getFullYear(),
  },
  YY: { // 24
    fn: date => parseInt(date.getFullYear().toString().substring(2, 4), 10),
  },
  // Day of year
  DDDD: { // 061
    fn: date => padWithZeros(getDayOfYear(date), { desiredLength: 3 }),
  },
  DDD: { // 61
    fn: getDayOfYear,
  },
  DDDo: { // 61st
    fn: date => addNumberSuffix(getDayOfYear(date)),
  },
  // Day of month
  DD: { // 01
    fn: date => padWithZeros(date.getDate()),
  },
  Do: { // 1st
    fn: date => addNumberSuffix(date.getDate()),
  },
  D: { // 1
    fn: date => date.getDate(),
  },
  // Day of week
  dddd: { // Friday
    fn: date => dayNames[date.getDay()],
  },
  ddd: { // Fri
    fn: date => dayNames[date.getDay()].substring(0, 3),
  },
  dd: { // Fr
    fn: date => dayNames[date.getDay()].substring(0, 2),
  },
  d: { // 5
    fn: date => date.getDay(),
  },
  E: { // 5
    fn: date => {
      const day = date.getDay();
      return day === 0 ? 7 : day;
    },
  },
  // Hour
  HH: { // 03
    fn: date => padWithZeros(date.getHours()),
  },
  H: { // 3
    fn: date => date.getHours(),
  },
  hh: { // 03
    fn: date => padWithZeros(getHourAMPM(date)),
  },
  h: { // 3
    fn: date => getHourAMPM(date),
  },
  // Minute
  mm: { // 05
    fn: date => padWithZeros(date.getMinutes()),
  },
  m: { // 5
    fn: date => date.getMinutes(),
  },
  // Second
  ss: { // 05
    fn: date => padWithZeros(date.getSeconds()),
  },
  s: { // 5
    fn: date => date.getSeconds(),
  },
  // Fractional seconds
  SSS: { // 123
    fn: date => padWithZeros(date.getMilliseconds(), { desiredLength: 3 }),
  },
  SS: { // 12
    fn: date => padWithZeros(Math.floor(date.getMilliseconds() / 10)),
  },
  S: { // 1
    fn: date => Math.floor(date.getMilliseconds() / 100),
  },
  // Unix timestamp
  X: { // 1709262311
    fn: date => Math.floor(date.getTime() / 1000),
  },
  x: { // 1709262311123
    fn: date => date.getTime(),
  },
  // AM/PM
  A: { // AM
    fn: date => getAMPM(date).toUpperCase(),
  },
  a: { // am
    fn: date => getAMPM(date),
  },
  // Month
  MMMM: { // March
    fn: date => monthNames[date.getMonth()],
  },
  MMM: { // Mar
    fn: date => monthNames[date.getMonth()].substring(0, 3),
  },
  MM: { // 03
    fn: date => padWithZeros(getMonth(date)),
  },
  Mo: { // 3rd
    fn: date => addNumberSuffix(getMonth(date)),
  },
  M: { // 3
    fn: getMonth,
    replaceFn: (string, replacement) => {
      // We're not going to use regex look ahead/behind, as that is not supported by some older
      // browsers
      const charactersArray = string.split('');
      const charactersArrayLength = charactersArray.length;
      charactersArray.forEach((character, index) => {
        if (character !== 'M') {
          return;
        }
        if (index > 0 && charactersArray[index - 1] === 'A') {
          // "A" may have inserted "AM", so ignore
          return;
        }
        if (
          index < charactersArrayLength - 2
          && charactersArray[index + 1] === 'a'
          && ['r', 'y'].includes(charactersArray[index + 2])
        ) {
          // "MMM" may have inserted "Mar" or "May", so ignore
          return;
        }
        charactersArray[index] = replacement;
      });
      return charactersArray.join('');
    },
  },
};

const formatSlugs = Object.keys(formats);

const setMethods = ['millisecond', 'second', 'minute', 'hour', 'date', 'month', 'year'];
const setMethodsShortForm = {
  y: 'year',
  M: 'month',
  D: 'date',
  h: 'hour',
  m: 'minute',
  s: 'second',
  ms: 'millisecond',
};

/**
 * @typedef {Date|String|Number|Void} DateLike
*/

class TDMoment {
  /**
   * @param {DateLike} dateIn
   * @returns {TDMoment}
   */
  constructor(dateIn) {
    // TODO handle the other arguments (format, locale and is strict)

    this.dateObj = new Date();

    if (!dateIn) {
      return this;
    }

    if (dateIn instanceof Date || typeof dateIn === 'number') {
      this.dateObj = new Date(dateIn);
      return this;
    }

    if (
      dateIn instanceof TDMoment
      // We don't want to use `moment.isMoment` or `instanceof moment`, as that would require using
      // the moment package itself. All moment instances have a `_isAMomentObject===true` property
      || dateIn._isAMomentObject === true
    ) {
      this.dateObj = new Date(dateIn.toDate());
      return this;
    }

    // TODO handle passing in an object

    if (typeof dateIn !== 'string') {
      throw new Error(
        'The passed in date must be an instance of Date, the number of milliseconds '
        + 'since the epoch, an instance of TDMoment, an instance of moment.js or a string'
        + ` ${formatsErrorSuffix}`,
      );
    }

    const matches = dateIn.match(/^(\d{4})-(\d{2})-(\d{2})(.*)$/);

    if (matches === null) {
      // TODO handle parsing other string formats
      throw new Error(formatError);
    }

    const year = parseInt(matches[1], 10);
    const month = parseInt(matches[2], 10);
    const day = parseInt(matches[3], 10);
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let milliseconds = 0;

    if (month === 0 || month > 12) {
      throw new Error('Invalid month');
    }

    if (day === 0 || day > getDaysInMonth(month, year)) {
      throw new Error('Invalid day');
    }

    const restOfString = matches[4];

    if (typeof restOfString === 'string' && restOfString.length > 0) {
      const timeMatches = restOfString.match(/^T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z$/);

      if (timeMatches === null) {
        throw new Error(formatError);
      }

      hours = parseInt(timeMatches[1], 10);
      minutes = parseInt(timeMatches[2], 10);
      seconds = parseInt(timeMatches[3], 10);
      milliseconds = parseInt(timeMatches[4], 10);

      if (hours > 23) {
        throw new Error('Invalid hours');
      }

      if (minutes > 59) {
        throw new Error('Invalid minutes');
      }

      if (seconds > 59) {
        throw new Error('Invalid seconds');
      }
    }

    this.dateObj = new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);

    return this;
  }

  /**
   * Get the date, as a Date instance
   *
   * @returns {Date}
   */
  toDate() {
    return new Date(this.dateObj.getTime());
  }

  /**
   * Get the date, formatted as an ISO string
   *
   * @returns {String}
   */
  toISOString() {
    return this.dateObj.toISOString();
  }

  /**
   * Get the date, formatted as seconds since the epoch
   *
   * @returns {Number}
   */
  unix() {
    return Math.floor(this.dateObj.getTime() / 1000);
  }

  /**
   * Get a clone of the current date, so we don't modify the original
   *
   * @returns {TDMoment}
   */
  clone() {
    return new TDMoment(this.dateObj.getTime());
  }

  /**
   * Change the datetime
   *
   * @param {Number} numberIn
   * @param {String} type
   * @param {Object} [options]
   * @param {Bool} [options.add]
   * @param {Bool} [options.subtract]
   * @returns {TDMoment}
   */
  _change(numberIn, type, { add = false, subtract = false } = {}) {
    // TODO add handling first arg being an object
    if (typeof numberIn !== 'number') {
      throw new Error('number must be a string');
    }
    let number = numberIn;
    if (subtract) {
      number *= -1;
    }
    if (typeof type !== 'string') {
      throw new Error('type must be a string');
    }
    if (!add && !subtract) {
      throw new Error('You must add or subtract');
    }
    let addMS = 0;
    let addedMS = false;
    if (type.toLowerCase() === 'ms' || /^mil/i.test(type)) {
      addMS = number;
      addedMS = true;
    }
    if (/^s/i.test(type)) {
      addMS = (secondInMS * number);
      addedMS = true;
    }
    if (type === 'm' || /^min/i.test(type)) {
      addMS = (minuteInMS * number);
      addedMS = true;
    }
    if (/^h/i.test(type)) {
      addMS = (hourInMS * number);
      addedMS = true;
    }
    if (/^d/i.test(type)) {
      addMS = (dayInMS * number);
      addedMS = true;
    }
    if (/^w/i.test(type)) {
      addMS = (weekInMS * number);
      addedMS = true;
    }
    if (addedMS) {
      this.dateObj = new Date(this.dateObj.getTime() + addMS);
      return this;
    }
    if (type === 'M' || /^mo/i.test(type)) {
      this.dateObj.setMonth(this.dateObj.getMonth() + number);
      return this;
    }
    if (/^y/i.test(type)) {
      this.dateObj.setFullYear(this.dateObj.getFullYear() + number);
      return this;
    }
    throw new Error(`Cannot add "${type}"`);
  }

  /**
   * Add an amount of time
   *
   * @param {Number} number
   * @param {String} type
   * @returns {TDMoment}
   */
  add(number, type) {
    this._change(number, type, { add: true });
    return this;
  }

  /**
   * Subtract an amount of time
   *
   * @param {Number} number
   * @param {String} type
   * @returns {TDMoment}
   */
  subtract(number, type) {
    this._change(number, type, { subtract: true });
    return this;
  }

  /**
   * Set date to the start or end of the given increment
   *
   * @param {String} type
   * @param {Object} [options]
   * @param {Bool} [options.start]
   * @param {Bool} [options.end]
   * @returns {TDMoment}
   */
  _startOrEndOf(type, { end = false, start = false } = {}) {
    if (typeof type !== 'string') {
      throw new Error('type must be a string');
    }
    if (!end && !start) {
      throw new Error('You must set the start or the end');
    }
    if (['second', 'minute', 'hour', 'date', 'day', 'month', 'year'].includes(type)) {
      this.dateObj.setMilliseconds(start ? 0 : 999);
      if (['minute', 'hour', 'date', 'day', 'month', 'year'].includes(type)) {
        this.dateObj.setSeconds(start ? 0 : 59);
        if (['hour', 'date', 'day', 'month', 'year'].includes(type)) {
          this.dateObj.setMinutes(start ? 0 : 59);
          if (['date', 'day', 'month', 'year'].includes(type)) {
            this.dateObj.setHours(start ? 0 : 23);
            if (type === 'month') {
              this.dateObj.setDate(start ? 1 : (
                new Date(
                  this.dateObj.getFullYear(), getMonth(this.dateObj), 0,
                ).getDate()
              ));
            } else if (type === 'year') {
              // It's important to set the month first, as not every month has 31 days
              this.dateObj.setMonth(start ? 0 : 11);
              this.dateObj.setDate(start ? 1 : 31);
            }
          }
        }
      }
      return this;
    }
    if (type === 'week') {
      this.dateObj.setMilliseconds(start ? 0 : 999);
      this.dateObj.setSeconds(start ? 0 : 59);
      this.dateObj.setMinutes(start ? 0 : 59);
      this.dateObj.setHours(start ? 0 : 23);
      const currentDay = this.dateObj.getDay();
      const mondayOfWeek = this.dateObj.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
      const sundayOfWeek = mondayOfWeek + 6;
      this.dateObj.setDate(start ? mondayOfWeek : sundayOfWeek);
      return this;
    }
    throw new Error(`Cannot set to ${start ? 'start' : 'end'} of "${type}"`);
  }

  /**
   * Set date to the start of the given increment
   *
   * @param {String} type
   * @returns {TDMoment}
   */
  startOf(type) {
    return this._startOrEndOf(type, { start: true });
  }

  /**
   * Set date to the end of the given increment
   *
   * @param {String} type
   * @returns {TDMoment}
   */
  endOf(type) {
    return this._startOrEndOf(type, { end: true });
  }

  /**
   * Returns the date formatted with the specified format string
   *
   * @param {String} format
   * @returns {String}
   */
  format(format) {
    let newFormat = `${format}`;
    formatSlugs.forEach(slug => {
      const { fn, replaceFn } = formats[slug];
      try {
        const replacement = fn(this.dateObj);
        if (replaceFn) {
          newFormat = replaceFn(newFormat, replacement);
        } else {
          newFormat = newFormat.replace(new RegExp(slug, 'g'), replacement);
        }
      } catch (e) {
        if (e.message === 'Invalid regular expression: invalid group specifier name') {
          throw new Error('We do not support your web browser version, please update to the latest version');
        }
        throw e;
      }
    });
    return newFormat;
  }

  /**
   * Test if the instance date is before the supplied one
   *
   * @param {DateLike} compare
   * @param {String} [granularity]
   * @returns {Boolean}
   */
  isBefore(compare, granularity) {
    const compareParsed = new TDMoment(compare);
    const isBeforeYear = this.year() < compareParsed.year();
    const isSameYear = this.year() === compareParsed.year();
    const isBeforeMonth = isBeforeYear || (isSameYear && this.month() < compareParsed.month());
    switch (granularity) {
      case 'year':
        return isBeforeYear;
      case 'month':
        return isBeforeMonth;
      case 'day':
        return (
          isBeforeYear
          || isBeforeMonth
          || (
            isSameYear
            && this.month() === compareParsed.month()
            && this.date() < compareParsed.date()
          )
        );
      default:
        return this.dateObj.getTime() < compareParsed.dateObj.getTime();
    }
  }

  /**
   * Test if the instance date is after the supplied one
   *
   * @param {DateLike} compare
   * @param {String} [granularity]
   * @returns {Boolean}
   */
  isAfter(compare, granularity) {
    const compareParsed = new TDMoment(compare);
    const isAfterYear = this.year() > compareParsed.year();
    const isSameYear = this.year() === compareParsed.year();
    const isAfterMonth = isAfterYear || (isSameYear && this.month() > compareParsed.month());
    switch (granularity) {
      case 'year':
        return isAfterYear;
      case 'month':
        return isAfterMonth;
      case 'day':
        return (
          isAfterYear
          || isAfterMonth
          || (
            isSameYear
            && this.month() === compareParsed.month()
            && this.date() > compareParsed.date()
          )
        );
      default:
        return this.dateObj.getTime() > compareParsed.dateObj.getTime();
    }
  }

  /**
   * Parses a provided method key
   *
   * @param {String} string
   * @returns {String|Null}
   */
  _parseSetMethod(string) {
    const stringLC = string.toLowerCase();
    const foundMethod = setMethods.find(method => [method, `${method}s`].includes(stringLC));
    if (foundMethod) {
      return foundMethod;
    }
    return setMethodsShortForm[string] || null;
  }

  /**
   * Sets the date values
   *
   * @param {Object|String} objectOrString
   * @param {Number} [value]
   */
  set(objectOrString, value) {
    if (typeof objectOrString === 'string') {
      const foundMethod = this._parseSetMethod(objectOrString);
      if (!foundMethod) {
        throw new Error(`Invalid set string "${objectOrString}"`);
      }
      return this[foundMethod](value);
    }
    const validMethodKeyMappings = Object.keys(objectOrString).reduce(
      (acc, methodKey) => {
        const foundMethod = this._parseSetMethod(methodKey);
        if (foundMethod) {
          acc.push([foundMethod, methodKey]);
        }
        return acc;
      },
      [],
    );
    if (validMethodKeyMappings.length === 0) {
      throw new Error('No valid set properties specified');
    }
    validMethodKeyMappings.forEach(([validMethod, originalMethod]) => {
      this[validMethod](objectOrString[originalMethod]);
    });
    return this;
  }

  /**
   * Gets the date value
   *
   * @param {String} string
   * @returns {Number}
   */
  get(string) {
    const foundMethod = this._parseSetMethod(string);
    if (!foundMethod) {
      throw new Error(`Invalid get string "${string}"`);
    }
    return this[foundMethod]();
  }

  /**
   * Test if the instance date is the same as the supplied one
   *
   * @param {DateLike} otherDate
   * @param {String} [granularity]
   * @returns {Boolean}
   */
  isSame(otherDate, granularity) {
    const otherDateParsed = new TDMoment(otherDate);
    const isSameYear = this.year() === otherDateParsed.year();
    const isSameMonth = this.month() === otherDateParsed.month();
    switch (granularity) {
      case 'year':
        return isSameYear;
      case 'month':
        return isSameYear && isSameMonth;
      case 'day':
        return isSameYear && isSameMonth && this.date() === otherDateParsed.date();
      default:
        return this.dateObj.getTime() === otherDateParsed.dateObj.getTime();
    }
  }

  /**
   * Test if the instance date is the same or before/after the supplied one
   *
   * @param {DateLike} otherDate
   * @param {Object} options
   * @param {Boolean} [options.after]
   * @param {Boolean} [options.before]
   * @param {String} [options.granularity]
   * @returns {Boolean}
   */
  _isSameOr(otherDate, { before = false, granularity } = {}) {
    const otherDateParsed = new TDMoment(otherDate);
    return (
      this[before ? 'isBefore' : 'isAfter'](otherDateParsed, granularity)
      || this.isSame(otherDateParsed, granularity)
    );
  }

  /**
   * Test if the instance date is the same or after the supplied one
   *
   * @param {DateLike} otherDate
   * @param {String} [granularity]
   * @returns {Boolean}
   */
  isSameOrAfter(otherDate, granularity) {
    return this._isSameOr(otherDate, { after: true, granularity });
  }

  /**
   * Test if the instance date is the same or before the supplied one
   *
   * @param {DateLike} otherDate
   * @param {String} [granularity]
   * @returns {Boolean}
   */
  isSameOrBefore(otherDate, granularity) {
    return this._isSameOr(otherDate, { before: true, granularity });
  }

  /**
   * Test if the instance date is between the supplied ones
   *
   * @param {DateLike} startDate
   * @param {DateLike} endDate
   * @param {String} [granularity]
   * @param {String} [inclusivity]
   */
  isBetween(startDate, endDate, granularity, inclusivity) {
    const inclusivityValid = typeof inclusivity === 'string' && /^[([][)\]]$/.test(inclusivity);
    const includeStartDate = inclusivityValid && inclusivity.charAt(0) === '[';
    const includeEndDate = inclusivityValid && inclusivity.charAt(1) === ']';
    return (
      this[`is${includeStartDate ? 'SameOr' : ''}After`](startDate, granularity)
      && this[`is${includeEndDate ? 'SameOr' : ''}Before`](endDate, granularity)
    );
  }

  /**
   * Calculate the rounded down difference between two dates
   *
   * @param {DateLike} otherDate
   * @param {String} [granularity]
   * @returns {Number}
   */
  diff(otherDate, granularity) {
    const parsedOtherDate = new TDMoment(otherDate);
    const diffMS = this.dateObj.getTime() - parsedOtherDate.dateObj.getTime();
    if (
      !granularity
      || typeof granularity !== 'string'
      || /^millisecond/.test(granularity)
      || granularity === 'ms'
    ) {
      return diffMS;
    }
    if (/^second/.test(granularity) || granularity === 's') {
      return Math.floor(diffMS / 1000);
    }
    if (/^minute/.test(granularity) || granularity === 'm') {
      return Math.floor(diffMS / 1000 / 60);
    }
    if (/^hour/.test(granularity) || granularity === 'h') {
      return Math.floor(diffMS / 1000 / 60 / 60);
    }
    if (/^day/.test(granularity) || granularity === 'd') {
      return Math.floor(diffMS / 1000 / 60 / 60 / 24);
    }
    if (/^week/.test(granularity) || granularity === 'w') {
      return Math.floor(diffMS / 1000 / 60 / 60 / 24 / 7);
    }
    if (/^month/.test(granularity) || granularity === 'M') {
      return ((this.year() - parsedOtherDate.year()) * 12) - parsedOtherDate.month() + this.month();
    }
    if (/^year/.test(granularity) || granularity === 'y') {
      return this.year() - parsedOtherDate.year();
    }
    throw new Error(`Unsupported granularity: "${granularity}"`);
  }

  // TODO any other methods that we currently use
}

[
  ['millisecond', 'Milliseconds'],
  ['second', 'Seconds'],
  ['minute', 'Minutes'],
  ['hour', 'Hours'],
  ['date', 'Date'],
  ['month', 'Month'],
  ['year', 'FullYear'],
].forEach(([method, jsMethod]) => {
  // Add get/set methods
  TDMoment.prototype[method] = function (newValue) {
    if (newValue || newValue === 0) {
      if (typeof newValue !== 'number' || !/^\d+$/.test(newValue.toString())) {
        throw new Error(`${method} value must be an integer`);
      }
      this.dateObj[`set${jsMethod}`](newValue);
      return this;
    }
    return this.dateObj[`get${jsMethod}`]();
  };
  // Add a duplicate get/set method for the plural of the word
  TDMoment.prototype[`${method}s`] = function (...args) {
    return this[method](...args);
  };
});

const tdMoment = dateIn => new TDMoment(dateIn);

/**
 * @param {Number} secondsSinceEpoch
 * @returns {TDMoment}
 */
tdMoment.unix = secondsSinceEpoch => {
  if (typeof secondsSinceEpoch !== 'number') {
    throw new Error('Seconds since epoch must be a number');
  }
  return new TDMoment(secondsSinceEpoch * 1000);
};

export default tdMoment;
