import { toastr } from 'react-redux-toastr';

import {
  orgBillingDetailsApiUrl,
  orgBillingDetailsCreateApiUrl,
  orgBillingDetailsUpdateApiUrl,
  orgBillingDetailsSetDefaultApiUrl,
  orgBillingDetailsCopyApiUrl,
  orgSettingsApiUrl,
  orgSettingsEmailApiUrl,
  orgSettingsFinanceEmailApiUrl,
  orgSettingsServiceOrderNotificationsApiUrl,
  orgSettingsTaskAssigneesWorksheetNotificationsApiUrl,
  orgSettingsProjectsTeamMemberAdditionNotificationsApiUrl,
  salesTaxApiUrl,
  userCardSettingsApiUrl,
  settingsPaymentMethodStatsApiUrl, systemFinancialEntitiesApiUrl,
} from 'settings/urls';
import { orgApiKeysApiUrl } from 'organizations/urls';
import {
  extrasUpdateAC,
  fetchViewDS,
  viewFetchExtrasAC,
  viewFetchAC,
  viewUpdateAC,
} from 'core/assets/js/ducks/view';
import { listFetchAC, listAppendItemAC, listReplaceItemAC, listRemoveItemAC } from 'core/assets/js/ducks/list';
import { orgUpdatedAC, userCardUpdatedAC } from 'organizations/assets/js/actions/list';
import { fetchDataDS, pushDataDS } from 'core/assets/js/lib/dataServices';
import { REQ_TYPE } from 'core/assets/js/constants';


const fetchOrganizationDS = (params, url = '', authedAxios, componentName) => (
  fetchViewDS({
    authedAxios,
    componentName,
    url: orgSettingsApiUrl(params.orgAlias, url),
  })
);

const fetchOrganizationPaymentMethodStatsDS = (params, url = '', authedAxios, componentName) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => settingsPaymentMethodStatsApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [
      viewFetchExtrasAC(responseData, componentName),
    ],
  })
);

const fetchBillingDetailsDS = (params, url = '', authedAxios, componentName) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => orgBillingDetailsApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => listFetchAC(responseData, componentName),
  })
);

const postBillingDetailsDS = (orgAlias, values, componentName) => (
  pushDataDS({
    reqType: values.id ? REQ_TYPE.PUT : REQ_TYPE.POST,
    pushApiUrl: values.id
      ? orgBillingDetailsUpdateApiUrl(orgAlias, values.id)
      : orgBillingDetailsCreateApiUrl(orgAlias),
    pushDataAC: responseData => [
      viewUpdateAC(responseData.organization, componentName),
      listFetchAC(responseData.billingDetails, componentName),
    ],
    forFinalForm: true,
    values,
  })
);

const setDefaultBillingDetailsDS = (orgAlias, id, componentName) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: orgBillingDetailsSetDefaultApiUrl(orgAlias, id),
    pushDataAC: responseData => [
      viewUpdateAC(responseData.organization, componentName),
      listFetchAC(responseData.billingDetails, componentName),
    ],
  }))
);

const copyBillingDetailsDS = (orgAlias, componentName) => dispatch => (
  dispatch(pushDataDS({
    reqType: REQ_TYPE.POST,
    pushApiUrl: orgBillingDetailsCopyApiUrl(orgAlias),
    pushDataAC: responseData => [
      viewUpdateAC(responseData.organization, componentName),
      listFetchAC(responseData.billingDetails, componentName),
    ],
  }))
);

const fetchUserCardWithVatDS = (params, url = '', authedAxios, componentName) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => salesTaxApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [
      viewFetchExtrasAC(responseData.extras, componentName),
      viewFetchAC(responseData.userCard, componentName),
    ],
  })
);

const postUserCardWithVatDS = (orgAlias, values, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: salesTaxApiUrl(orgAlias),
      pushDataAC: responseData => ([
        viewUpdateAC(responseData.userCard, componentName),
        extrasUpdateAC(responseData.extras, componentName),
      ]),
      values,
    }))
  )
);

const postUserCardDS = (orgAlias, values, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: userCardSettingsApiUrl(orgAlias),
      pushDataAC: responseData => ([
        viewUpdateAC(responseData.userCard, componentName),
        extrasUpdateAC(responseData.extras, componentName),
      ]),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Your settings were updated successfully.');
    })
  )
);

const putUserCardDS = (orgAlias, values) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: userCardSettingsApiUrl(orgAlias),
    pushDataAC: responseData => userCardUpdatedAC(responseData.userCard),
    values,
  })
);

const postOrganizationDS = (orgAlias, values, componentName, { forFinalForm = false } = {}) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: orgSettingsApiUrl(orgAlias),
      pushDataAC: responseData => {
        const actions = [orgUpdatedAC(responseData.updated)];
        if (componentName) {
          actions.push(viewUpdateAC(responseData.updated, componentName));
        }
        return actions;
      },
      forFinalForm,
      values,
    })).then((result) => {
      const { error } = result;
      if (error) {
        if (error && forFinalForm) {
          return error;
        }
        toastr.error('Oh Snap!', error._error || error.message);
      } else {
        toastr.success('Well Done!', 'Organisation settings updated successfully.');
      }
      return result;
    })
  )
);

const postUserCardEmailDS = (orgAlias, { email }, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: orgSettingsEmailApiUrl(orgAlias),
      pushDataAC: responseData => [
        viewUpdateAC(responseData.updated, componentName),
        userCardUpdatedAC(responseData.updated),
      ],
      values: { email },
    })).then((response) => {
      if (response.waitForVerification) {
        toastr.info(
          'Email verification required',
          `An email has been sent to ${email} for verification`,
        );
      } else {
        toastr.success('Well Done!', 'Account settings updated successfully.');
      }
    })
  )
);

const postOrganizationFinanceEmailDS = (orgAlias, { email }, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: orgSettingsFinanceEmailApiUrl(orgAlias),
      pushDataAC: responseData => [
        viewUpdateAC(responseData.updated, componentName),
        orgUpdatedAC(responseData.updated),
      ],
      values: { email },
    })).then((response) => {
      if (response.waitForVerification) {
        toastr.info(
          'Email verification required',
          `An email has been sent to ${email} for verification`,
        );
      } else {
        toastr.success('Well Done!', 'Account settings updated successfully.');
      }
    })
  )
);

const postServiceOrderNotificationsSettingsDS = (orgAlias, values) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: orgSettingsServiceOrderNotificationsApiUrl(orgAlias),
    pushDataAC: responseData => userCardUpdatedAC(responseData.updated),
    values,
  })
);

const postProjectsTeamMemberAdditionNotificationsSettingsDS = (orgAlias, values) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: orgSettingsProjectsTeamMemberAdditionNotificationsApiUrl(orgAlias),
    pushDataAC: responseData => userCardUpdatedAC(responseData.updated),
    values,
  })
);

const postTaskAssigneesWorksheetNotificationsSettingsDS = (orgAlias, values) => (
  pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: orgSettingsTaskAssigneesWorksheetNotificationsApiUrl(orgAlias),
    pushDataAC: responseData => orgUpdatedAC(responseData.updated),
    values,
  })
);

const postOrganizationApiKeysDS = (orgAlias, values, listComponentName, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.POST,
      pushApiUrl: orgApiKeysApiUrl(orgAlias),
      pushDataAC: responseData => [
        listAppendItemAC(responseData, listComponentName),
        viewUpdateAC(responseData, componentName),
      ],
      values,
    }))
  )
);

const putOrganizationApiKeysDS = (orgAlias, values, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: orgApiKeysApiUrl(orgAlias, values.id),
      pushDataAC: responseData => [
        listReplaceItemAC(responseData, componentName),
      ],
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Organisation API key created successfully.');
    })
  )
);

const deleteOrganizationApiKeyDS = (orgAlias, id, componentName) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.DELETE,
      pushApiUrl: orgApiKeysApiUrl(orgAlias, id),
      pushDataAC: responseData => [
        listRemoveItemAC(responseData.id, componentName),
      ],
    })).then(() => {
      toastr.success('Well Done!', 'Organisation API key revoked successfully.');
    })
  )
);

const fetchSystemFinancialEntitiesDS = (params, url = '', authedAxios, componentName) => (
  fetchDataDS({
    authedAxios,
    fetchApiUrl: () => systemFinancialEntitiesApiUrl(params.orgAlias, url),
    fetchDataAC: responseData => [
      extrasUpdateAC(responseData.extras, componentName),
    ],
  })
);

export {
  fetchOrganizationDS,
  fetchOrganizationPaymentMethodStatsDS,
  fetchUserCardWithVatDS,
  postUserCardWithVatDS,
  fetchBillingDetailsDS,
  postBillingDetailsDS,
  setDefaultBillingDetailsDS,
  copyBillingDetailsDS,
  deleteOrganizationApiKeyDS,
  putOrganizationApiKeysDS,
  postOrganizationApiKeysDS,
  postOrganizationDS,
  postOrganizationFinanceEmailDS,
  postUserCardEmailDS,
  postServiceOrderNotificationsSettingsDS,
  postTaskAssigneesWorksheetNotificationsSettingsDS,
  postProjectsTeamMemberAdditionNotificationsSettingsDS,
  postUserCardDS,
  putUserCardDS,
  fetchSystemFinancialEntitiesDS,
};
