import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { groupBy, isEmpty, flatten } from 'lodash';

import { projectMemberAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectViewUrl, projectViewTaskUrl } from 'projects/urls';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import { worksheetRenderedSpec } from 'finance/assets/js/lib/objectSpecs';
import { lineBreakToBr } from 'core/assets/js/lib/utils-jsx';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';

const WorksheetBreakdown = (
  {
    worksheet: { items, summary, attachments },
    match: { params: { orgAlias } },
    allowedActions: { canViewProjectDetails },
  },
) => {
  const itemTypes = [];
  const projectWorkItems = groupBy(
    items.filter(it => !it.task),
    item => item.project?.id,
  );
  if (!isEmpty(projectWorkItems)) {
    itemTypes.push([
      'project',
      'Project Work',
      flatten(Object.values(projectWorkItems)),
    ]);
  }
  const taskWorkItems = groupBy(
    items.filter(it => !!it.task),
    item => item.task.id,
  );
  if (!isEmpty(taskWorkItems)) {
    itemTypes.push([
      'task',
      'Tasks',
      flatten(Object.values(taskWorkItems)),
    ]);
  }

  return (
    <React.Fragment>
      {itemTypes.map(([type, title, thisItems]) => (
        <React.Fragment key={title}>
          <h3 className="mb-2">{title}</h3>
          <table className="table worksheet-breakdown mb-4">
            <tbody>
              {thisItems.map(item => {
                const exchangeRateProps = item.worksheetItemCurrency === item.organizationCurrency
                  ?  null
                  : {
                    className: 'ml-2',
                    exchangeRate: item.exchangeRate,
                    exchangeRateDate: item.exchangeRateUpdatedAt,
                    exchangeRateService: item.worksheetItemToOrganizationFxRateSource,
                    sourceCurrency: item.worksheetItemCurrency,
                    targetCurrency: item.organizationCurrency,
                  };
                const isTask = type === 'task';
                let description = isTask ? item.task.title : item.description;
                if (
                  item.project?.id
                  && (canViewProjectDetails || item.project.allowedActions?.canViewProjectDetails)
                ) {
                  description = (
                    <Link
                      className="text-dark"
                      to={
                        isTask
                          ? projectViewTaskUrl(orgAlias, item.project.id, item.task.id)
                          : projectViewUrl(orgAlias, item.project.id)
                      }
                    >
                      {description}
                    </Link>
                  );
                }
                return (
                  <tr
                    data-testid={`worksheet-breakdown-${type}-item`}
                    key={`worksheet-item-${item.id}`}
                  >
                    <td className="description">{description}</td>
                    <td>
                      {item.quantity}
                      {' '}
                      {
                        item.rateUnit !== RATE_UNIT.CUSTOM
                          ? RATE_UNIT_FORMAT[item.rateUnit].unit
                          : 'units'
                      }
                    </td>
                    <td
                      className="text-right"
                      data-testid={`worksheet-breakdown-${type}-item-rate-amount`}
                    >
                      <NumberTpl
                        prefix={item.worksheetItemCurrencySymbol}
                        value={item.worksheetItemRateAmount}
                        decimals={(
                          item.rateUnit !== RATE_UNIT.CUSTOM
                            ? RATE_UNIT_FORMAT[item.rateUnit].decimals
                            : 2
                        )}
                      />
                      {exchangeRateProps && (
                        <ExchangeRateInfoPopOver
                          {...exchangeRateProps}
                          targetAmount={item.organizationRateAmount}
                        />
                      )}
                    </td>
                    <td
                      className="text-right"
                      data-testid={`worksheet-breakdown-${type}-item-total`}
                    >
                      <NumberTpl
                        prefix={item.worksheetItemCurrencySymbol}
                        value={item.worksheetItemTotal}
                      />
                      {exchangeRateProps && (
                        <ExchangeRateInfoPopOver
                          {...exchangeRateProps}
                          targetAmount={item.organizationTotal}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      ))}
      <div>
        <h3 className="mb-2">
          Summary
        </h3>
        <p>
          {lineBreakToBr(summary, 'ws-summary-line-')}
        </p>
      </div>
      {!isEmpty(attachments) && (
        <div>
          <AttachmentsList
            attachments={attachments}
            label="Attachments"
          />
        </div>
      )}
    </React.Fragment>
  );
};

WorksheetBreakdown.propTypes = {
  allowedActions: projectMemberAllowedActionsSpec,
  match: routerMatchContentsSpec.isRequired,
  worksheet: worksheetRenderedSpec.isRequired,
};

WorksheetBreakdown.defaultProps = {
  allowedActions: {},
};

export default withRouter(WorksheetBreakdown);
