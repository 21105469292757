import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { RESET_ONBOARDING_FORM_MODAL_ID } from 'interviews/assets/js/constants';
import { resetOnboardingFormApiUrl } from 'interviews/urls';

const ResetOnboardingFormModal = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { orgAlias } = useParams();

  const isOpen = useSelector(state => getIsModalOpen(state, RESET_ONBOARDING_FORM_MODAL_ID));
  const payload = useSelector(state => getModalPayload(state, RESET_ONBOARDING_FORM_MODAL_ID));
  const submission = payload?.submission || null;
  const isModalOpen = isOpen && submission;

  const resetOnboardingForm = async () => {
    try {
      const { data: newSubmission } = await axios.post(resetOnboardingFormApiUrl({
        orgAlias, submissionId: submission.id,
      }));
      toastr.success('Well Done!', 'Onboarding form successfully reset.');
      onSuccess(newSubmission);
    } catch (e) {
      toastr.error('Oh Snap!', e.response?.data?._error || e.message);
      // This is required to prevent the modal thinking confirm was successful and then closing
      // automatically
      throw new Error(e);
    }
  };

  return (
    <ModalConfirm
      confirmLabel="Reset"
      data-testid="reset-onboarding-form-modal"
      heading="Reset this onboarding form"
      onClose={() => dispatch(modalCloseAC())}
      onConfirm={resetOnboardingForm}
      open={isModalOpen}
    >
      Are you sure you want to reset this onboarding form? The existing onboarding submission and
      any answers provided will be deleted and the user will be notified to re-submit their answers.
    </ModalConfirm>
  );
};

ResetOnboardingFormModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default ResetOnboardingFormModal;
