import { BANK_CURRENCY } from 'td-finance-ts';

import { BANK_ACCOUNT_STATUS, BANK_ACCOUNT_TYPE } from 'settings/assets/js/constants';
import dialCodes from 'core/assets/js/lib/isoDialCodes';

export const SERVICE_STATUS_CONDITION = {
  OK: 'ok',
  NOT_OK: 'not ok',
};

export const SERVICE_STATUS_SERVICE = {
  DB: 'db',
  SIGNABLE: 'signable',
  ELASTICSEARCH: 'elasticsearch',
  PAYONEER: 'payoneer',
  PAYONEER_OAUTH: 'payoneeroauth',
  PPH_API: 'pph-api',
  PUSHER: 'pusher',
  QUEUE: 'queue',
  REDIS_UNVERSIONED: 'redis:unversioned',
  REDIS_VERSIONED: 'redis:versioned',
  REDIS_SHARED_STAGING: 'redis:sharedstaging',
  REVOLUT_AUTH: 'revolutauth',
  REVOLUT: 'revolut',
  SES: 'ses',
  WISE_AUTH: 'wiseauth',
  TRANSFERWISE: 'transferwise',
  WORLD_PAY: 'worldpay',
};

export const RECIPIENT_FIELDS = [
  'address',
  'email',
  'legalType',
  'accountNumber',
  'sortCode',
  'abartn',
  'accountType',
  'bankgiroNumber',
  'ifscCode',
  'bsbCode',
  'institutionNumber',
  'transitNumber',
  'phoneNumber',
  'bankCode',
  'russiaRegion',
  'routingNumber',
  'branchCode',
  'cpf',
  'cardNumber',
  'cardToken',
  'idType',
  'idNumber',
  'idCountryIso3',
  'idValidFrom',
  'idValidTo',
  'clabe',
  'swiftCode',
  'dateOfBirth',
  'clearingNumber',
  'bankName',
  'branchName',
  'businessNumber',
  'province',
  'city',
  'rut',
  'token',
  'cnpj',
  'payinReference',
  'pspReference',
  'orderId',
  'idDocumentType',
  'idDocumentNumber',
  'targetProfile',
  'targetUserId',
  'taxId',
  'job',
  'nationality',
  'interacAccount',
  'bban',
  'town',
  'postCode',
  'language',
  'iban',
  'bic',
];

export const RECIPIENT_FIELD_ALIASES = {
  IBAN: 'iban',
  iban: 'IBAN',
  BIC: 'bic',
  bic: 'BIC',
};

export const PAYONEER_PAYEE_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
};

export const PAYOUT_STATUS = {
  PENDING_PAYEE_APPROVAL: 'Pending Payee Approval',
  PENDING_APPROVAL: 'Pending Approval',
  PENDING_LOAD: 'Pending Load',
  PAYMENT_COMPLETED: 'Payment completed',
  FUNDED: 'Funded',
  CANCELLED: 'Cancelled',
  REFUND: 'Refund',
  TRANSFERRED: 'Transferred',
};

export const PAYOUT_STATUS_CATEGORY = {
  PENDING: 'Pending',
  PENDING_PAYEE: 'Pending Payee',
  TRANSFERRED: 'Transferred',
  CANCELLED: 'Cancelled',
};

export const REVOLUT_TRANSACTION_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  FAILED: 'failed',
  REVERTED: 'reverted',
};

export const REVOLUT_TRANSACTION_STATUS_LABEL = {
  [REVOLUT_TRANSACTION_STATUS.CREATED]: 'Created',
  [REVOLUT_TRANSACTION_STATUS.PENDING]: 'Pending',
  [REVOLUT_TRANSACTION_STATUS.COMPLETED]: 'Completed',
  [REVOLUT_TRANSACTION_STATUS.DECLINED]: 'Declined',
  [REVOLUT_TRANSACTION_STATUS.FAILED]: 'Failed',
  [REVOLUT_TRANSACTION_STATUS.REVERTED]: 'Reverted',
};

export const TW_ACCOUNT_REQUIREMENT_FILTERS = {
  [BANK_CURRENCY.CNY]: [{
    propPath: 'type',
    value: 'chinese_alipay',
  }, {
    propPath: 'type',
    value: 'chinese_card',
  }],
  [BANK_CURRENCY.BRL]: [{
    propPath: 'type',
    value: 'brazil',
  }],
  [BANK_CURRENCY.COP]: [{
    propPath: 'type',
    value: 'colombia',
  }],
  [BANK_CURRENCY.PEN]: [{
    propPath: 'type',
    value: 'peru',
  }],
  [BANK_CURRENCY.SAR]: [{
    propPath: 'type',
    value: 'email',
  }],
  [BANK_CURRENCY.TWD]: [{
    propPath: 'type',
    value: 'email',
  }],
};

export const TW_ACCOUNT_REQUIREMENT_MODIFICATIONS = {
  [BANK_CURRENCY.USD]: [{
    type: 'swift_code',
    title: 'Swift ( International )',
  }],
};

// Custom bank account form fields in the format of TrW dynamic fields
// Note - these are tranmitted to the front end,
//        so values must be simple types that can be handled by JSON
//        for example validationRegexp can't be a regex literal or
//        instance.
const TW_FORM_FIELDS = {
  RECIPIENT_TYPE: {
    name: 'Recipient type',
    group: [{
      key: 'legalType',
      name: 'Recipient type',
      type: 'select',
      refreshRequirementsOnChange: true,
      required: true,
      displayFormat: null,
      example: '',
      minLength: null,
      maxLength: null,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: [{
        key: 'PRIVATE',
        name: 'Person',
      }, {
        key: 'BUSINESS',
        name: 'Business',
      }],
    }],
  },
  NAME: {
    name: 'Full name of the account holder',
    group: [{
      key: 'accountHolderName',
      name: 'Full name of the account holder',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '',
      minLength: 1,
      maxLength: 140,
      validationRegexp: "^( *[0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,.]+ +[0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,.]+([0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,. ]*))$",
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  BANK_NAME: {
    name: 'Bank name',
    group: [{
      key: 'bankName',
      name: 'Bank name',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '',
      minLength: 1,
      maxLength: 140,
      validationRegexp: "^( *[0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,.]+([0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,. ]*))$",
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  EMAIL: {
    name: 'E-mail',
    group: [{
      key: 'email',
      name: 'E-mail',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: false,
      displayFormat: null,
      example: 'example@example.ex',
      minLength: null,
      maxLength: 255,
      validationRegexp: "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  DATE_OF_BIRTH: {
    name: 'Date of birth',
    group: [{
      key: 'dateOfBirth',
      name: 'Date of birth',
      type: 'date',
      refreshRequirementsOnChange: false,
      required: false,
      displayFormat: null,
      example: 'yyyy-MM-dd',
      minLength: null,
      maxLength: null,
      validationRegexp: '^\\d{4}\\-\\d{2}\\-\\d{2}$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  DIAL_CODE: {
    name: 'Dial code',
    group: [{
      key: 'dialCode',
      name: 'Dial code',
      type: 'select',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '+40',
      minLength: null,
      maxLength: null,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: dialCodes.map(d => ({
        key: d.dial_code,
        name: `${d.dial_code} (${d.name})`,
      })),
    }],
  },
  CHINESE_NAME: {
    name: 'Full name of the account holder',
    group: [{
      key: 'accountHolderName',
      name: 'Full name of the account holder',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '',
      minLength: 1,
      maxLength: 140,
      validationRegexp: "^( *[0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,.]+ +[0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,.]+([0-9A-Za-zÀ-ÖØ-öø-ÿ-_()'*,. ]*))|((\\p{Script=Hani})+)$",
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  BIC_SWIFT: {
    name: 'Bank code (BIC/SWIFT)',
    group: [{
      key: 'BIC',
      name: 'Bank code (BIC/SWIFT)',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: false,
      displayFormat: null,
      example: '',
      minLength: 8,
      maxLength: 11,
      validationRegexp: '^[A-Za-z]{6}[A-Za-z\\d]{2}([A-Za-z\\d]{3})?$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  IBAN: {
    name: 'IBAN',
    group: [{
      key: 'IBAN',
      name: 'IBAN',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: '**** **** **** **** **** **** **** ****',
      example: 'DE89370400440532013000',
      minLength: 14,
      maxLength: 42,
      validationRegexp: '^[a-zA-Z]{2}[a-zA-Z0-9 ]{12,40}$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  CHINESE_ACCOUNT_NUMBER: {
    name: 'Account number',
    group: [{
      key: 'accountNumber',
      name: 'Account number',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '1234567890123456789',
      minLength: 16,
      maxLength: 19,
      validationRegexp: '^[0-9]+$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  CNAPS_CODE: {
    name: 'CNAPS code',
    group: [{
      key: 'cnaps',
      name: 'CNAPS code',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '123456789012',
      minLength: 12,
      maxLength: 12,
      validationRegexp: '[0-9]{12}',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  CHINESE_BUSINESS_REGISTRATION_NUMBER: {
    name: 'Business registration number',
    group: [{
      key: 'businessRegistrationNumber',
      name: 'Business registration number',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: false,
      displayFormat: null,
      example: '123456789012345678',
      minLength: 18,
      maxLength: 18,
      validationRegexp: '[0-9]{18}',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  CHINESE_PHONE_NUMBER: {
    name: 'Phone number',
    group: [{
      key: 'phoneNumber',
      name: 'Phone number',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '+xxxxxxxxxxxxx',
      minLength: 14,
      maxLength: 14,
      validationRegexp: '^\\+[0-9]{13}$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  BRAZILIAN_ACCOUNT_NUMBER: {
    name: 'Account number',
    group: [{
      key: 'accountNumber',
      name: 'Account number',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '1234567890',
      minLength: 6,
      maxLength: 10,
      validationRegexp: '^[0-9]+$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  BRAZILIAN_EMAIL: {
    name: 'E-mail',
    group: [{
      key: 'email',
      name: 'E-mail',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: 'example@example.ex',
      minLength: null,
      maxLength: 255,
      validationRegexp: "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  BRAZILIAN_BRANCH_CODE: {
    name: 'Branch code',
    group: [{
      key: 'branchCode',
      name: 'Branch code',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '12345',
      minLength: 1,
      maxLength: 5,
      validationRegexp: '^[0-9]+$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  ACCOUNT_TYPE: {
    name: 'Account type',
    group: [{
      key: 'accountType',
      name: 'Account type',
      type: 'select',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '',
      minLength: null,
      maxLength: null,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: [{
        key: 'SAVINGS',
        name: 'Savings',
      }, {
        key: 'CHECKING',
        name: 'Checking',
      }],
    }],
  },
  BRAZILIAN_CPF_CNPJ: {
    name: 'CPF/CNPJ',
    group: [{
      key: 'cpfCnpj',
      name: 'CPF/CNPJ',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '12345678901',
      minLength: 11,
      maxLength: 14,
      validationRegexp: '^[0-9]+$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  UNIONPAY_CARD_RECIPIENT_TYPE: {
    name: 'Recipient type',
    group: [{
      key: 'legalType',
      name: 'Recipient type',
      type: 'select',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '',
      minLength: null,
      maxLength: null,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: [{
        key: 'PERSON',
        name: 'Person',
      }],
    }],
  },
  UNIONPAY_CARD_NUMBER: {
    name: 'UnionPay card number',
    group: [{
      key: 'cardNumber',
      name: 'UnionPay card number',
      sublabel: 'The card number will be used once to determine the recipient and is not recorded in our system.',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: false,
      displayFormat: null,
      example: '6240008631401148',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  PERUVIAN_CCI: {
    name: 'Account number/CCI',
    group: [{
      key: 'accountNumber',
      name: 'Account number/CCI',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '12345678901234567890',
      minLength: null,
      maxLength: 22,
      validationRegexp: '^[0-9-]{1,22}$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  PERUVIAN_ACCOUNT_TYPE: {
    name: 'Account type',
    group: [{
      key: 'accountType',
      name: 'Account type',
      type: 'select',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '',
      minLength: null,
      maxLength: null,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: [{
        key: 'CHECKING',
        name: 'Checking [cuenta corriente]',
      }, {
        key: 'SAVINGS',
        name: 'Savings [cuenta de ahorro]',
      }],
    }],
  },
  PERUVIAN_PERSONAL_ID: {
    name: 'Personal ID',
    group: [{
      key: 'personalId',
      name: 'Personal ID',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: 'Please specify RUC, DNI or C. Ext number...',
      minLength: 8,
      maxLength: 11,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  COLOMBIAN_ACCOUNT_NUMBER: {
    name: 'Account number',
    group: [{
      key: 'accountNumber',
      name: 'Account number',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '1234567890123456789',
      minLength: null,
      maxLength: 18,
      validationRegexp: '^[0-9]+$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  PHONE_NUMBER: {
    name: 'Phone number',
    group: [{
      key: 'phoneNumber',
      name: 'Phone number',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: 'xxxxxxxxxxxxx',
      minLength: 5,
      maxLength: 15,
      validationRegexp: '^[0-9]+$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  COLOMBIAN_BANK_CODE: {
    name: 'Bank code',
    group: [{
      key: 'bankCode',
      name: 'Bank code',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '',
      minLength: null,
      maxLength: 3,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  COLOMBIAN_PERSONAL_ID_TYPE: {
    name: 'Personal ID type',
    group: [{
      key: 'idType',
      name: 'Personal ID type',
      type: 'select',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: 'Please specify \'CC\' for the Beneficiary ID or \'NIT\' for the Taxpayer ID',
      minLength: null,
      maxLength: null,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: [{
        key: 'cc',
        name: 'CC',
      }, {
        key: 'nit',
        name: 'NIT',
      }],
    }],
  },
  COLOMBIAN_PERSONAL_ID: {
    name: 'Personal ID',
    group: [{
      key: 'idNumber',
      name: 'Personal ID',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: 'Please specify identification document number',
      minLength: 6,
      maxLength: 10,
      validationRegexp: null,
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
  TAIWANESE_ACCOUNT_NUMBER: {
    name: 'Account number',
    group: [{
      key: 'accountNumber',
      name: 'Account number',
      type: 'text',
      refreshRequirementsOnChange: false,
      required: true,
      displayFormat: null,
      example: '1234567890',
      minLength: null,
      maxLength: 34,
      validationRegexp: '^[0-9]+$',
      validationAsync: null,
      valuesAllowed: null,
    }],
  },
};

/**
 * Flag a requirement as required by the form.
 * @param {object} requirement - requirement to make required.
 * @return {object} requirement with required set to true in each group.
 */
const makeRequirementRequired = requirement => ({
  ...requirement,
  group: requirement.group.map(group => ({ ...group, required: true })),
});

export const TW_ACCOUNT_ADDITIONAL_REQUIREMENTS = {
  [BANK_CURRENCY.CNY]: [{
    type: 'localBankAccount',
    title: 'Local Bank Account',
    bankAccountType: BANK_ACCOUNT_TYPE.CN_TYPE,
    usageInfo: null,
    showAddressFields: true,
    fields: [
      TW_FORM_FIELDS.CHINESE_NAME,
      TW_FORM_FIELDS.CHINESE_ACCOUNT_NUMBER,
      makeRequirementRequired(TW_FORM_FIELDS.BIC_SWIFT),
      TW_FORM_FIELDS.CNAPS_CODE,
      TW_FORM_FIELDS.CHINESE_BUSINESS_REGISTRATION_NUMBER,
      TW_FORM_FIELDS.CHINESE_PHONE_NUMBER,
    ],
  }, {
    // note - this is required by TrW to be 'Card' to process the card token
    type: 'Card',
    title: 'UnionPay card',
    showAddressFields: true,
    fields: [
      TW_FORM_FIELDS.UNIONPAY_CARD_RECIPIENT_TYPE,
      TW_FORM_FIELDS.EMAIL,
      TW_FORM_FIELDS.CHINESE_NAME,
      TW_FORM_FIELDS.UNIONPAY_CARD_NUMBER,
    ],
  }],
  [BANK_CURRENCY.BRL]: [{
    type: 'localBankAccount',
    title: 'Local Bank Account',
    bankAccountType: BANK_ACCOUNT_TYPE.BR_TYPE,
    usageInfo: null,
    showAddressFields: true,
    fields: [
      TW_FORM_FIELDS.PHONE_NUMBER,
      TW_FORM_FIELDS.BRAZILIAN_EMAIL,
      TW_FORM_FIELDS.ACCOUNT_TYPE,
      TW_FORM_FIELDS.NAME,
      TW_FORM_FIELDS.BRAZILIAN_ACCOUNT_NUMBER,
      TW_FORM_FIELDS.BRAZILIAN_BRANCH_CODE,
      TW_FORM_FIELDS.BRAZILIAN_CPF_CNPJ,
      TW_FORM_FIELDS.DATE_OF_BIRTH,
    ],
  }],
  [BANK_CURRENCY.COP]: [{
    type: 'localBankAccount',
    title: 'Local Bank Account',
    bankAccountType: BANK_ACCOUNT_TYPE.CO_TYPE,
    usageInfo: null,
    showAddressFields: true,
    fields: [
      TW_FORM_FIELDS.BANK_NAME,
      TW_FORM_FIELDS.NAME,
      TW_FORM_FIELDS.COLOMBIAN_ACCOUNT_NUMBER,
      TW_FORM_FIELDS.COLOMBIAN_BANK_CODE,
      TW_FORM_FIELDS.ACCOUNT_TYPE,
      TW_FORM_FIELDS.COLOMBIAN_PERSONAL_ID_TYPE,
      TW_FORM_FIELDS.COLOMBIAN_PERSONAL_ID,
      TW_FORM_FIELDS.EMAIL,
      TW_FORM_FIELDS.DIAL_CODE,
      TW_FORM_FIELDS.PHONE_NUMBER,
    ],
  }],
  [BANK_CURRENCY.PEN]: [{
    type: 'localBankAccount',
    title: 'Local Bank Account',
    bankAccountType: BANK_ACCOUNT_TYPE.PE_TYPE,
    usageInfo: null,
    showAddressFields: true,
    fields: [
      TW_FORM_FIELDS.NAME,
      TW_FORM_FIELDS.PERUVIAN_CCI,
      TW_FORM_FIELDS.PERUVIAN_ACCOUNT_TYPE,
      TW_FORM_FIELDS.PERUVIAN_PERSONAL_ID,
      TW_FORM_FIELDS.DATE_OF_BIRTH,
    ],
  }],
  [BANK_CURRENCY.SAR]: [{
    type: 'iban',
    title: 'iban',
    bankAccountType: BANK_ACCOUNT_TYPE.SA_TYPE,
    usageInfo: null,
    showAddressFields: true,
    fields: [
      TW_FORM_FIELDS.NAME,
      TW_FORM_FIELDS.IBAN,
      makeRequirementRequired(TW_FORM_FIELDS.BIC_SWIFT),
      TW_FORM_FIELDS.DATE_OF_BIRTH,
    ],
  }],
  [BANK_CURRENCY.TWD]: [{
    type: 'localBankAccount',
    title: 'Local Bank Account',
    bankAccountType: BANK_ACCOUNT_TYPE.TW_TYPE,
    usageInfo: null,
    showAddressFields: true,
    fields: [
      TW_FORM_FIELDS.BANK_NAME,
      TW_FORM_FIELDS.NAME,
      TW_FORM_FIELDS.TAIWANESE_ACCOUNT_NUMBER,
      makeRequirementRequired(TW_FORM_FIELDS.BIC_SWIFT),
      TW_FORM_FIELDS.DIAL_CODE,
      TW_FORM_FIELDS.PHONE_NUMBER,
    ],
  }],
};

/**
 * Maps Payoneer notification declined 'ReasonDesc' to a BANK_ACCOUNT_STATUS.
 */
const PAYONEER_DECLINED_REASON_GROUPS = {
  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NOT_VALID]: [
    'according to compliance issues, we’re not allowed to approve shared addresses where adult payees are involved.',
    'account blocked as withdrawal method not approved',
    'application did not meet approval requirements',
    'bank is no longer working with Payoneer',
    'customer applied with an email address we could not approve',
    'customer applied with information that is not supported by the program',
    'customer is underage',
    'incorrect information provided at registration - customer provided insufficient/altered/suspicious information during the registration process',
    'one or more of the bank account details are invalid, for more details contact account manager.',
    'the card expiration date has passed',
    'customer provided an invalid Bank account number, requested to provide a valid bank statement',
    'customer closed for applying with incorrect information. needs to re-apply.',
    'customer added a supplier bank account instead of a w2b (added bank is under ah name)',
    'customer doesn’t meet business requirements',
    'customer provided a global payments service instead of his own personal bank account',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_DETAILS_NEED_UPDATING]: [
    'customer provided a bank account number containing non-numeric characters, requested to repeat the registration process, and enter a bank account number in digits only',
    'application has been declined because costumer applied with a disposable email. needs to reapply with a valid email address.',
    'applied using nonsensical or gibberish information',
    'bank registration had been declined due to bank name mismatch',
    'customer added a bank with an invalid address',
    'customer added a bank with invalid account number',
    'customer added ps number as bank account number',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CANCELED]: [
    'canceled - ineligibility for a card',
    'canceled - ineligibility for a card , was requested to apply for account',
    'cancelled by the automated approval engine   ',
    'test account',
    'general account application decline (various reasons)',
    'decline, for more details contact account manager',
    'customer applied ba ewallet as that we do not support',
    'customer added a new bank account without receiving payments first',
    'customer chose to use a payoneer debit card instead of a global bank transfer (gbt) payment method',
    'customer chose to use global bank transfer (gbt) payment method instead of a card',
    'customer applied for both global bank transfer (gbt) and payoneer debit card with the same payee',
    'customer will be approved by partner request',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_PAYEE_CLOSED]: [
    'card closed due to risk reasons',
    'customer did not use his card for an extended period of time, so the card was canceled',
    'customer did not contact/provide requested information within 3 months',
    'application’s program is no longer active',
    'customer closed due to risk reasons',
    'new account was merged to an existing account',
    'failed to activate the card in 30 days',
    'payments that were loaded to the card were denied by the owner of the card used for the loads.',
    'we’ve received a request from the partner to close this account',
    'customer is locked out of his account, never used his card',
    'customer never provided the requested information for the approval process',
    'ah request to close receiving account and leave payer account open',
    'customer requested to close the account',
    'customer’s application was issued, however they never activated the card',
    'most of the customer’s payees are not allowed for withdrawal',
    'some of the customer’s payees are not allowed for withdrawal',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_CONTACT_PAYONEER]: [
    'contact account manager for details',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_USED]: [
    'customer added a bank that he already added (duplicate bank)',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_ALREADY_APPROVED]: [
    'customer already has an approved, enabled withdraw option for the same bank account',
    'customer already has an existing account; we were not able to approve a new application. payee was informed.',
    'customer already has an existing card; we were not able to approve a new application',
    'customer applied with several applications - only one was approved and the rest were canceled',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_UNSUPPORTED]: [
    'customer added ba details of ewallet that we do not support',
    'customer applied from crimea or used a bank account located in crimea',
    'customer from russia cannot be issued cards. they can apply for an account',
    'the customer’s country is not supported by the program',
    'card application from india is not allowed; customer may reapply through a different method of payment',
    'customer from usa cannot be approved- state not supported by program. needs to contact program.',
  ],

  [BANK_ACCOUNT_STATUS.PAYONEER_MORE_INFO_REQUIRED]: [
    'sanctioned region - needs valid documents to be cleared',
  ],
};

export const PAYONEER_DECLINED_REASON_TO_BANK_ACCOUNT_STATUS = Object
  .keys(PAYONEER_DECLINED_REASON_GROUPS)
  .reduce((acc, bankAccountStatus) => {
    PAYONEER_DECLINED_REASON_GROUPS[bankAccountStatus]
      .forEach((reason) => {
        // keys are encoded as strings, so we need to convert them back to number
        acc[reason] = parseInt(bankAccountStatus, 10) || 0;
      });
    return acc;
  }, {});

export const STRIPE_SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
};

export const STRIPE_SUBSCRIPTION_STATUS_LABEL = {
  [STRIPE_SUBSCRIPTION_STATUS.ACTIVE]: 'Active',
  [STRIPE_SUBSCRIPTION_STATUS.PAST_DUE]: 'Past due',
  [STRIPE_SUBSCRIPTION_STATUS.UNPAID]: 'Unpaid',
  [STRIPE_SUBSCRIPTION_STATUS.CANCELED]: 'Canceled',
  [STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE]: 'Incomplete',
  [STRIPE_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: 'Incomplete expired',
  [STRIPE_SUBSCRIPTION_STATUS.TRIALING]: 'On trial',
};

// https://stripe.com/docs/api/events/types
export const STRIPE_EVENT_TYPE = {
  ACCOUNT_UPDATED: 'account.updated',
  ACCOUNT_APPLICATION_AUTHORIZED: 'account.application.authorized',
  ACCOUNT_APPLICATION_DEAUTHORIZED: 'account.application.deauthorized',
  ACCOUNT_EXTERNAL_ACCOUNT_CREATED: 'account.external_account.created',
  ACCOUNT_EXTERNAL_ACCOUNT_DELETED: 'account.external_account.deleted',
  ACCOUNT_EXTERNAL_ACCOUNT_UPDATED: 'account.external_account.updated',
  APPLICATION_FEE_CREATED: 'application_fee.created',
  APPLICATION_FEE_REFUNDED: 'application_fee.refunded',
  APPLICATION_FEE_REFUND_UPDATED: 'application_fee.refund.updated',
  BALANCE_AVAILABLE: 'balance.available',
  BILLING_PORTAL_CONFIGURATION_CREATED: 'billing_portal.configuration.created',
  BILLING_PORTAL_CONFIGURATION_UPDATED: 'billing_portal.configuration.updated',
  BILLING_PORTAL_SESSION_CREATED: 'billing_portal.session.created',
  CAPABILITY_UPDATED: 'capability.updated',
  CASH_BALANCE_FUNDS_AVAILABLE: 'cash_balance.funds_available',
  CHARGE_CAPTURED: 'charge.captured',
  CHARGE_EXPIRED: 'charge.expired',
  CHARGE_FAILED: 'charge.failed',
  CHARGE_PENDING: 'charge.pending',
  CHARGE_REFUNDED: 'charge.refunded',
  CHARGE_SUCCEEDED: 'charge.succeeded',
  CHARGE_UPDATED: 'charge.updated',
  CHARGE_DISPUTE_CLOSED: 'charge.dispute.closed',
  CHARGE_DISPUTE_CREATED: 'charge.dispute.created',
  CHARGE_DISPUTE_FUNDS_REINSTATED: 'charge.dispute.funds_reinstated',
  CHARGE_DISPUTE_FUNDS_WITHDRAWN: 'charge.dispute.funds_withdrawn',
  CHARGE_DISPUTE_UPDATED: 'charge.dispute.updated',
  CHARGE_REFUND_UPDATED: 'charge.refund.updated',
  CHECKOUT_SESSION_ASYNC_PAYMENT_FAILED: 'checkout.session.async_payment_failed',
  CHECKOUT_SESSION_ASYNC_PAYMENT_SUCCEEDED: 'checkout.session.async_payment_succeeded',
  CHECKOUT_SESSION_COMPLETED: 'checkout.session.completed',
  CHECKOUT_SESSION_EXPIRED: 'checkout.session.expired',
  COUPON_CREATED: 'coupon.created',
  COUPON_DELETED: 'coupon.deleted',
  COUPON_UPDATED: 'coupon.updated',
  CREDIT_NOTE_CREATED: 'credit_note.created',
  CREDIT_NOTE_UPDATED: 'credit_note.updated',
  CREDIT_NOTE_VOIDED: 'credit_note.voided',
  CUSTOMER_CREATED: 'customer.created',
  CUSTOMER_DELETED: 'customer.deleted',
  CUSTOMER_UPDATED: 'customer.updated',
  CUSTOMER_DISCOUNT_CREATED: 'customer.discount.created',
  CUSTOMER_DISCOUNT_DELETED: 'customer.discount.deleted',
  CUSTOMER_DISCOUNT_UPDATED: 'customer.discount.updated',
  CUSTOMER_SOURCE_CREATED: 'customer.source.created',
  CUSTOMER_SOURCE_DELETED: 'customer.source.deleted',
  CUSTOMER_SOURCE_EXPIRING: 'customer.source.expiring',
  CUSTOMER_SOURCE_UPDATED: 'customer.source.updated',
  CUSTOMER_SUBSCRIPTION_CREATED: 'customer.subscription.created',
  CUSTOMER_SUBSCRIPTION_DELETED: 'customer.subscription.deleted',
  CUSTOMER_SUBSCRIPTION_PENDING_UPDATE_APPLIED: 'customer.subscription.pending_update_applied',
  CUSTOMER_SUBSCRIPTION_PENDING_UPDATE_EXPIRED: 'customer.subscription.pending_update_expired',
  CUSTOMER_SUBSCRIPTION_TRIAL_WILL_END: 'customer.subscription.trial_will_end',
  CUSTOMER_SUBSCRIPTION_UPDATED: 'customer.subscription.updated',
  CUSTOMER_TAX_ID_CREATED: 'customer.tax_id.created',
  CUSTOMER_TAX_ID_DELETED: 'customer.tax_id.deleted',
  CUSTOMER_TAX_ID_UPDATED: 'customer.tax_id.updated',
  FILE_CREATED: 'file.created',
  IDENTITY_VERIFICATION_SESSION_CANCELED: 'identity.verification_session.canceled',
  IDENTITY_VERIFICATION_SESSION_CREATED: 'identity.verification_session.created',
  IDENTITY_VERIFICATION_SESSION_PROCESSING: 'identity.verification_session.processing',
  IDENTITY_VERIFICATION_SESSION_REDACTED: 'identity.verification_session.redacted',
  IDENTITY_VERIFICATION_SESSION_REQUIRES_INPUT: 'identity.verification_session.requires_input',
  IDENTITY_VERIFICATION_SESSION_VERIFIED: 'identity.verification_session.verified',
  INVOICE_CREATED: 'invoice.created',
  INVOICE_DELETED: 'invoice.deleted',
  INVOICE_FINALIZATION_FAILED: 'invoice.finalization_failed',
  INVOICE_FINALIZED: 'invoice.finalized',
  INVOICE_MARKED_UNCOLLECTIBLE: 'invoice.marked_uncollectible',
  INVOICE_PAID: 'invoice.paid',
  INVOICE_PAYMENT_ACTION_REQUIRED: 'invoice.payment_action_required',
  INVOICE_PAYMENT_FAILED: 'invoice.payment_failed',
  INVOICE_PAYMENT_SUCCEEDED: 'invoice.payment_succeeded',
  INVOICE_SENT: 'invoice.sent',
  INVOICE_UPCOMING: 'invoice.upcoming',
  INVOICE_UPDATED: 'invoice.updated',
  INVOICE_VOIDED: 'invoice.voided',
  INVOICEITEM_CREATED: 'invoiceitem.created',
  INVOICEITEM_DELETED: 'invoiceitem.deleted',
  INVOICEITEM_UPDATED: 'invoiceitem.updated',
  ISSUING_AUTHORIZATION_CREATED: 'issuing_authorization.created',
  ISSUING_AUTHORIZATION_REQUEST: 'issuing_authorization.request',
  ISSUING_AUTHORIZATION_UPDATED: 'issuing_authorization.updated',
  ISSUING_CARD_CREATED: 'issuing_card.created',
  ISSUING_CARD_UPDATED: 'issuing_card.updated',
  ISSUING_CARDHOLDER_CREATED: 'issuing_cardholder.created',
  ISSUING_CARDHOLDER_UPDATED: 'issuing_cardholder.updated',
  ISSUING_DISPUTE_CLOSED: 'issuing_dispute.closed',
  ISSUING_DISPUTE_CREATED: 'issuing_dispute.created',
  ISSUING_DISPUTE_FUNDS_REINSTATED: 'issuing_dispute.funds_reinstated',
  ISSUING_DISPUTE_SUBMITTED: 'issuing_dispute.submitted',
  ISSUING_DISPUTE_UPDATED: 'issuing_dispute.updated',
  ISSUING_TRANSACTION_CREATED: 'issuing_transaction.created',
  ISSUING_TRANSACTION_UPDATED: 'issuing_transaction.updated',
  MANDATE_UPDATED: 'mandate.updated',
  ORDER_CREATED: 'order.created',
  ORDER_PAYMENT_FAILED: 'order.payment_failed',
  ORDER_PAYMENT_SUCCEEDED: 'order.payment_succeeded',
  ORDER_UPDATED: 'order.updated',
  ORDER_RETURN_CREATED: 'order_return.created',
  PAYMENT_INTENT_AMOUNT_CAPTURABLE_UPDATED: 'payment_intent.amount_capturable_updated',
  PAYMENT_INTENT_CANCELED: 'payment_intent.canceled',
  PAYMENT_INTENT_CREATED: 'payment_intent.created',
  PAYMENT_INTENT_PARTIALLY_FUNDED: 'payment_intent.partially_funded',
  PAYMENT_INTENT_PAYMENT_FAILED: 'payment_intent.payment_failed',
  PAYMENT_INTENT_PROCESSING: 'payment_intent.processing',
  PAYMENT_INTENT_REQUIRES_ACTION: 'payment_intent.requires_action',
  PAYMENT_INTENT_SUCCEEDED: 'payment_intent.succeeded',
  PAYMENT_LINK_CREATED: 'payment_link.created',
  PAYMENT_LINK_UPDATED: 'payment_link.updated',
  PAYMENT_METHOD_ATTACHED: 'payment_method.attached',
  PAYMENT_METHOD_AUTOMATICALLY_UPDATED: 'payment_method.automatically_updated',
  PAYMENT_METHOD_DETACHED: 'payment_method.detached',
  PAYMENT_METHOD_UPDATED: 'payment_method.updated',
  PAYOUT_CANCELED: 'payout.canceled',
  PAYOUT_CREATED: 'payout.created',
  PAYOUT_FAILED: 'payout.failed',
  PAYOUT_PAID: 'payout.paid',
  PAYOUT_UPDATED: 'payout.updated',
  PERSON_CREATED: 'person.created',
  PERSON_DELETED: 'person.deleted',
  PERSON_UPDATED: 'person.updated',
  PLAN_CREATED: 'plan.created',
  PLAN_DELETED: 'plan.deleted',
  PLAN_UPDATED: 'plan.updated',
  PRICE_CREATED: 'price.created',
  PRICE_DELETED: 'price.deleted',
  PRICE_UPDATED: 'price.updated',
  PRODUCT_CREATED: 'product.created',
  PRODUCT_DELETED: 'product.deleted',
  PRODUCT_UPDATED: 'product.updated',
  PROMOTION_CODE_CREATED: 'promotion_code.created',
  PROMOTION_CODE_UPDATED: 'promotion_code.updated',
  QUOTE_ACCEPTED: 'quote.accepted',
  QUOTE_CANCELED: 'quote.canceled',
  QUOTE_CREATED: 'quote.created',
  QUOTE_FINALIZED: 'quote.finalized',
  RADAR_EARLY_FRAUD_WARNING_CREATED: 'radar.early_fraud_warning.created',
  RADAR_EARLY_FRAUD_WARNING_UPDATED: 'radar.early_fraud_warning.updated',
  RECIPIENT_CREATED: 'recipient.created',
  RECIPIENT_DELETED: 'recipient.deleted',
  RECIPIENT_UPDATED: 'recipient.updated',
  REPORTING_REPORT_RUN_FAILED: 'reporting.report_run.failed',
  REPORTING_REPORT_RUN_SUCCEEDED: 'reporting.report_run.succeeded',
  REPORTING_REPORT_TYPE_UPDATED: 'reporting.report_type.updated',
  REVIEW_CLOSED: 'review.closed',
  REVIEW_OPENED: 'review.opened',
  SETUP_INTENT_CANCELED: 'setup_intent.canceled',
  SETUP_INTENT_CREATED: 'setup_intent.created',
  SETUP_INTENT_REQUIRES_ACTION: 'setup_intent.requires_action',
  SETUP_INTENT_SETUP_FAILED: 'setup_intent.setup_failed',
  SETUP_INTENT_SUCCEEDED: 'setup_intent.succeeded',
  SIGMA_SCHEDULED_QUERY_RUN_CREATED: 'sigma.scheduled_query_run.created',
  SKU_CREATED: 'sku.created',
  SKU_DELETED: 'sku.deleted',
  SKU_UPDATED: 'sku.updated',
  SOURCE_CANCELED: 'source.canceled',
  SOURCE_CHARGEABLE: 'source.chargeable',
  SOURCE_FAILED: 'source.failed',
  SOURCE_MANDATE_NOTIFICATION: 'source.mandate_notification',
  SOURCE_REFUND_ATTRIBUTES_REQUIRED: 'source.refund_attributes_required',
  SOURCE_TRANSACTION_CREATED: 'source.transaction.created',
  SOURCE_TRANSACTION_UPDATED: 'source.transaction.updated',
  SUBSCRIPTION_SCHEDULE_ABORTED: 'subscription_schedule.aborted',
  SUBSCRIPTION_SCHEDULE_CANCELED: 'subscription_schedule.canceled',
  SUBSCRIPTION_SCHEDULE_COMPLETED: 'subscription_schedule.completed',
  SUBSCRIPTION_SCHEDULE_CREATED: 'subscription_schedule.created',
  SUBSCRIPTION_SCHEDULE_EXPIRING: 'subscription_schedule.expiring',
  SUBSCRIPTION_SCHEDULE_RELEASED: 'subscription_schedule.released',
  SUBSCRIPTION_SCHEDULE_UPDATED: 'subscription_schedule.updated',
  TAX_RATE_CREATED: 'tax_rate.created',
  TAX_RATE_UPDATED: 'tax_rate.updated',
  TERMINAL_READER_ACTION_FAILED: 'terminal.reader.action_failed',
  TERMINAL_READER_ACTION_SUCCEEDED: 'terminal.reader.action_succeeded',
  TEST_HELPERS_TEST_CLOCK_ADVANCING: 'test_helpers.test_clock.advancing',
  TEST_HELPERS_TEST_CLOCK_CREATED: 'test_helpers.test_clock.created',
  TEST_HELPERS_TEST_CLOCK_DELETED: 'test_helpers.test_clock.deleted',
  TEST_HELPERS_TEST_CLOCK_INTERNAL_FAILURE: 'test_helpers.test_clock.internal_failure',
  TEST_HELPERS_TEST_CLOCK_READY: 'test_helpers.test_clock.ready',
  TOPUP_CANCELED: 'topup.canceled',
  TOPUP_CREATED: 'topup.created',
  TOPUP_FAILED: 'topup.failed',
  TOPUP_REVERSED: 'topup.reversed',
  TOPUP_SUCCEEDED: 'topup.succeeded',
  TRANSFER_CREATED: 'transfer.created',
  TRANSFER_FAILED: 'transfer.failed',
  TRANSFER_PAID: 'transfer.paid',
  TRANSFER_REVERSED: 'transfer.reversed',
  TRANSFER_UPDATED: 'transfer.updated',
};

export const STRIPE_EVENT_TYPE_LABEL = {
  [STRIPE_EVENT_TYPE.ACCOUNT_UPDATED]: 'account updated',
  [STRIPE_EVENT_TYPE.ACCOUNT_APPLICATION_AUTHORIZED]: 'account application authorized',
  [STRIPE_EVENT_TYPE.ACCOUNT_APPLICATION_DEAUTHORIZED]: 'account application deauthorized',
  [STRIPE_EVENT_TYPE.ACCOUNT_EXTERNAL_ACCOUNT_CREATED]: 'account external account created',
  [STRIPE_EVENT_TYPE.ACCOUNT_EXTERNAL_ACCOUNT_DELETED]: 'account external account deleted',
  [STRIPE_EVENT_TYPE.ACCOUNT_EXTERNAL_ACCOUNT_UPDATED]: 'account external account updated',
  [STRIPE_EVENT_TYPE.APPLICATION_FEE_CREATED]: 'application fee created',
  [STRIPE_EVENT_TYPE.APPLICATION_FEE_REFUNDED]: 'application fee refunded',
  [STRIPE_EVENT_TYPE.APPLICATION_FEE_REFUND_UPDATED]: 'application fee refund updated',
  [STRIPE_EVENT_TYPE.BALANCE_AVAILABLE]: 'balance available',
  [STRIPE_EVENT_TYPE.BILLING_PORTAL_CONFIGURATION_CREATED]: 'billing portal configuration created',
  [STRIPE_EVENT_TYPE.BILLING_PORTAL_CONFIGURATION_UPDATED]: 'billing portal configuration updated',
  [STRIPE_EVENT_TYPE.BILLING_PORTAL_SESSION_CREATED]: 'billing portal session created',
  [STRIPE_EVENT_TYPE.CAPABILITY_UPDATED]: 'capability updated',
  [STRIPE_EVENT_TYPE.CASH_BALANCE_FUNDS_AVAILABLE]: 'cash balance funds available',
  [STRIPE_EVENT_TYPE.CHARGE_CAPTURED]: 'charge captured',
  [STRIPE_EVENT_TYPE.CHARGE_EXPIRED]: 'charge expired',
  [STRIPE_EVENT_TYPE.CHARGE_FAILED]: 'charge failed',
  [STRIPE_EVENT_TYPE.CHARGE_PENDING]: 'charge pending',
  [STRIPE_EVENT_TYPE.CHARGE_REFUNDED]: 'charge refunded',
  [STRIPE_EVENT_TYPE.CHARGE_SUCCEEDED]: 'charge succeeded',
  [STRIPE_EVENT_TYPE.CHARGE_UPDATED]: 'charge updated',
  [STRIPE_EVENT_TYPE.CHARGE_DISPUTE_CLOSED]: 'charge dispute closed',
  [STRIPE_EVENT_TYPE.CHARGE_DISPUTE_CREATED]: 'charge dispute created',
  [STRIPE_EVENT_TYPE.CHARGE_DISPUTE_FUNDS_REINSTATED]: 'charge dispute funds reinstated',
  [STRIPE_EVENT_TYPE.CHARGE_DISPUTE_FUNDS_WITHDRAWN]: 'charge dispute funds withdrawn',
  [STRIPE_EVENT_TYPE.CHARGE_DISPUTE_UPDATED]: 'charge dispute updated',
  [STRIPE_EVENT_TYPE.CHARGE_REFUND_UPDATED]: 'charge refund updated',
  [STRIPE_EVENT_TYPE.CHECKOUT_SESSION_ASYNC_PAYMENT_FAILED]: 'checkout session async payment failed',
  [STRIPE_EVENT_TYPE.CHECKOUT_SESSION_ASYNC_PAYMENT_SUCCEEDED]: 'checkout session async payment succeeded',
  [STRIPE_EVENT_TYPE.CHECKOUT_SESSION_COMPLETED]: 'checkout session completed',
  [STRIPE_EVENT_TYPE.CHECKOUT_SESSION_EXPIRED]: 'checkout session expired',
  [STRIPE_EVENT_TYPE.COUPON_CREATED]: 'coupon created',
  [STRIPE_EVENT_TYPE.COUPON_DELETED]: 'coupon deleted',
  [STRIPE_EVENT_TYPE.COUPON_UPDATED]: 'coupon updated',
  [STRIPE_EVENT_TYPE.CREDIT_NOTE_CREATED]: 'credit note created',
  [STRIPE_EVENT_TYPE.CREDIT_NOTE_UPDATED]: 'credit note updated',
  [STRIPE_EVENT_TYPE.CREDIT_NOTE_VOIDED]: 'credit note voided',
  [STRIPE_EVENT_TYPE.CUSTOMER_CREATED]: 'customer created',
  [STRIPE_EVENT_TYPE.CUSTOMER_DELETED]: 'customer deleted',
  [STRIPE_EVENT_TYPE.CUSTOMER_UPDATED]: 'customer updated',
  [STRIPE_EVENT_TYPE.CUSTOMER_DISCOUNT_CREATED]: 'customer discount created',
  [STRIPE_EVENT_TYPE.CUSTOMER_DISCOUNT_DELETED]: 'customer discount deleted',
  [STRIPE_EVENT_TYPE.CUSTOMER_DISCOUNT_UPDATED]: 'customer discount updated',
  [STRIPE_EVENT_TYPE.CUSTOMER_SOURCE_CREATED]: 'customer source created',
  [STRIPE_EVENT_TYPE.CUSTOMER_SOURCE_DELETED]: 'customer source deleted',
  [STRIPE_EVENT_TYPE.CUSTOMER_SOURCE_EXPIRING]: 'customer source expiring',
  [STRIPE_EVENT_TYPE.CUSTOMER_SOURCE_UPDATED]: 'customer source updated',
  [STRIPE_EVENT_TYPE.CUSTOMER_SUBSCRIPTION_CREATED]: 'customer subscription created',
  [STRIPE_EVENT_TYPE.CUSTOMER_SUBSCRIPTION_DELETED]: 'customer subscription deleted',
  [STRIPE_EVENT_TYPE.CUSTOMER_SUBSCRIPTION_PENDING_UPDATE_APPLIED]: 'customer subscription pending update applied',
  [STRIPE_EVENT_TYPE.CUSTOMER_SUBSCRIPTION_PENDING_UPDATE_EXPIRED]: 'customer subscription pending update expired',
  [STRIPE_EVENT_TYPE.CUSTOMER_SUBSCRIPTION_TRIAL_WILL_END]: 'customer subscription trial will end',
  [STRIPE_EVENT_TYPE.CUSTOMER_SUBSCRIPTION_UPDATED]: 'customer subscription updated',
  [STRIPE_EVENT_TYPE.CUSTOMER_TAX_ID_CREATED]: 'customer tax id created',
  [STRIPE_EVENT_TYPE.CUSTOMER_TAX_ID_DELETED]: 'customer tax id deleted',
  [STRIPE_EVENT_TYPE.CUSTOMER_TAX_ID_UPDATED]: 'customer tax id updated',
  [STRIPE_EVENT_TYPE.FILE_CREATED]: 'file created',
  [STRIPE_EVENT_TYPE.IDENTITY_VERIFICATION_SESSION_CANCELED]: 'identity verification session canceled',
  [STRIPE_EVENT_TYPE.IDENTITY_VERIFICATION_SESSION_CREATED]: 'identity verification session created',
  [STRIPE_EVENT_TYPE.IDENTITY_VERIFICATION_SESSION_PROCESSING]: 'identity verification session processing',
  [STRIPE_EVENT_TYPE.IDENTITY_VERIFICATION_SESSION_REDACTED]: 'identity verification session redacted',
  [STRIPE_EVENT_TYPE.IDENTITY_VERIFICATION_SESSION_REQUIRES_INPUT]: 'identity verification session requires input',
  [STRIPE_EVENT_TYPE.IDENTITY_VERIFICATION_SESSION_VERIFIED]: 'identity verification session verified',
  [STRIPE_EVENT_TYPE.INVOICE_CREATED]: 'invoice created',
  [STRIPE_EVENT_TYPE.INVOICE_DELETED]: 'invoice deleted',
  [STRIPE_EVENT_TYPE.INVOICE_FINALIZATION_FAILED]: 'invoice finalization failed',
  [STRIPE_EVENT_TYPE.INVOICE_FINALIZED]: 'invoice finalized',
  [STRIPE_EVENT_TYPE.INVOICE_MARKED_UNCOLLECTIBLE]: 'invoice marked uncollectible',
  [STRIPE_EVENT_TYPE.INVOICE_PAID]: 'invoice paid',
  [STRIPE_EVENT_TYPE.INVOICE_PAYMENT_ACTION_REQUIRED]: 'invoice payment action required',
  [STRIPE_EVENT_TYPE.INVOICE_PAYMENT_FAILED]: 'invoice payment failed',
  [STRIPE_EVENT_TYPE.INVOICE_PAYMENT_SUCCEEDED]: 'invoice payment succeeded',
  [STRIPE_EVENT_TYPE.INVOICE_SENT]: 'invoice sent',
  [STRIPE_EVENT_TYPE.INVOICE_UPCOMING]: 'invoice upcoming',
  [STRIPE_EVENT_TYPE.INVOICE_UPDATED]: 'invoice updated',
  [STRIPE_EVENT_TYPE.INVOICE_VOIDED]: 'invoice voided',
  [STRIPE_EVENT_TYPE.INVOICEITEM_CREATED]: 'invoiceitem created',
  [STRIPE_EVENT_TYPE.INVOICEITEM_DELETED]: 'invoiceitem deleted',
  [STRIPE_EVENT_TYPE.INVOICEITEM_UPDATED]: 'invoiceitem updated',
  [STRIPE_EVENT_TYPE.ISSUING_AUTHORIZATION_CREATED]: 'issuing authorization created',
  [STRIPE_EVENT_TYPE.ISSUING_AUTHORIZATION_REQUEST]: 'issuing authorization request',
  [STRIPE_EVENT_TYPE.ISSUING_AUTHORIZATION_UPDATED]: 'issuing authorization updated',
  [STRIPE_EVENT_TYPE.ISSUING_CARD_CREATED]: 'issuing card created',
  [STRIPE_EVENT_TYPE.ISSUING_CARD_UPDATED]: 'issuing card updated',
  [STRIPE_EVENT_TYPE.ISSUING_CARDHOLDER_CREATED]: 'issuing cardholder created',
  [STRIPE_EVENT_TYPE.ISSUING_CARDHOLDER_UPDATED]: 'issuing cardholder updated',
  [STRIPE_EVENT_TYPE.ISSUING_DISPUTE_CLOSED]: 'issuing dispute closed',
  [STRIPE_EVENT_TYPE.ISSUING_DISPUTE_CREATED]: 'issuing dispute created',
  [STRIPE_EVENT_TYPE.ISSUING_DISPUTE_FUNDS_REINSTATED]: 'issuing dispute funds reinstated',
  [STRIPE_EVENT_TYPE.ISSUING_DISPUTE_SUBMITTED]: 'issuing dispute submitted',
  [STRIPE_EVENT_TYPE.ISSUING_DISPUTE_UPDATED]: 'issuing dispute updated',
  [STRIPE_EVENT_TYPE.ISSUING_TRANSACTION_CREATED]: 'issuing transaction created',
  [STRIPE_EVENT_TYPE.ISSUING_TRANSACTION_UPDATED]: 'issuing transaction updated',
  [STRIPE_EVENT_TYPE.MANDATE_UPDATED]: 'mandate updated',
  [STRIPE_EVENT_TYPE.ORDER_CREATED]: 'order created',
  [STRIPE_EVENT_TYPE.ORDER_PAYMENT_FAILED]: 'order payment failed',
  [STRIPE_EVENT_TYPE.ORDER_PAYMENT_SUCCEEDED]: 'order payment succeeded',
  [STRIPE_EVENT_TYPE.ORDER_UPDATED]: 'order updated',
  [STRIPE_EVENT_TYPE.ORDER_RETURN_CREATED]: 'order return created',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_AMOUNT_CAPTURABLE_UPDATED]: 'payment intent amount capturable updated',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_CANCELED]: 'payment intent canceled',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_CREATED]: 'payment intent created',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_PARTIALLY_FUNDED]: 'payment intent partially funded',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_PAYMENT_FAILED]: 'payment intent payment failed',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_PROCESSING]: 'payment intent processing',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_REQUIRES_ACTION]: 'payment intent requires action',
  [STRIPE_EVENT_TYPE.PAYMENT_INTENT_SUCCEEDED]: 'payment intent succeeded',
  [STRIPE_EVENT_TYPE.PAYMENT_LINK_CREATED]: 'payment link created',
  [STRIPE_EVENT_TYPE.PAYMENT_LINK_UPDATED]: 'payment link updated',
  [STRIPE_EVENT_TYPE.PAYMENT_METHOD_ATTACHED]: 'payment method attached',
  [STRIPE_EVENT_TYPE.PAYMENT_METHOD_AUTOMATICALLY_UPDATED]: 'payment method automatically updated',
  [STRIPE_EVENT_TYPE.PAYMENT_METHOD_DETACHED]: 'payment method detached',
  [STRIPE_EVENT_TYPE.PAYMENT_METHOD_UPDATED]: 'payment method updated',
  [STRIPE_EVENT_TYPE.PAYOUT_CANCELED]: 'payout canceled',
  [STRIPE_EVENT_TYPE.PAYOUT_CREATED]: 'payout created',
  [STRIPE_EVENT_TYPE.PAYOUT_FAILED]: 'payout failed',
  [STRIPE_EVENT_TYPE.PAYOUT_PAID]: 'payout paid',
  [STRIPE_EVENT_TYPE.PAYOUT_UPDATED]: 'payout updated',
  [STRIPE_EVENT_TYPE.PERSON_CREATED]: 'person created',
  [STRIPE_EVENT_TYPE.PERSON_DELETED]: 'person deleted',
  [STRIPE_EVENT_TYPE.PERSON_UPDATED]: 'person updated',
  [STRIPE_EVENT_TYPE.PLAN_CREATED]: 'plan created',
  [STRIPE_EVENT_TYPE.PLAN_DELETED]: 'plan deleted',
  [STRIPE_EVENT_TYPE.PLAN_UPDATED]: 'plan updated',
  [STRIPE_EVENT_TYPE.PRICE_CREATED]: 'price created',
  [STRIPE_EVENT_TYPE.PRICE_DELETED]: 'price deleted',
  [STRIPE_EVENT_TYPE.PRICE_UPDATED]: 'price updated',
  [STRIPE_EVENT_TYPE.PRODUCT_CREATED]: 'product created',
  [STRIPE_EVENT_TYPE.PRODUCT_DELETED]: 'product deleted',
  [STRIPE_EVENT_TYPE.PRODUCT_UPDATED]: 'product updated',
  [STRIPE_EVENT_TYPE.PROMOTION_CODE_CREATED]: 'promotion code created',
  [STRIPE_EVENT_TYPE.PROMOTION_CODE_UPDATED]: 'promotion code updated',
  [STRIPE_EVENT_TYPE.QUOTE_ACCEPTED]: 'quote accepted',
  [STRIPE_EVENT_TYPE.QUOTE_CANCELED]: 'quote canceled',
  [STRIPE_EVENT_TYPE.QUOTE_CREATED]: 'quote created',
  [STRIPE_EVENT_TYPE.QUOTE_FINALIZED]: 'quote finalized',
  [STRIPE_EVENT_TYPE.RADAR_EARLY_FRAUD_WARNING_CREATED]: 'radar early fraud warning created',
  [STRIPE_EVENT_TYPE.RADAR_EARLY_FRAUD_WARNING_UPDATED]: 'radar early fraud warning updated',
  [STRIPE_EVENT_TYPE.RECIPIENT_CREATED]: 'recipient created',
  [STRIPE_EVENT_TYPE.RECIPIENT_DELETED]: 'recipient deleted',
  [STRIPE_EVENT_TYPE.RECIPIENT_UPDATED]: 'recipient updated',
  [STRIPE_EVENT_TYPE.REPORTING_REPORT_RUN_FAILED]: 'reporting report run failed',
  [STRIPE_EVENT_TYPE.REPORTING_REPORT_RUN_SUCCEEDED]: 'reporting report run succeeded',
  [STRIPE_EVENT_TYPE.REPORTING_REPORT_TYPE_UPDATED]: 'reporting report type updated',
  [STRIPE_EVENT_TYPE.REVIEW_CLOSED]: 'review closed',
  [STRIPE_EVENT_TYPE.REVIEW_OPENED]: 'review opened',
  [STRIPE_EVENT_TYPE.SETUP_INTENT_CANCELED]: 'setup intent canceled',
  [STRIPE_EVENT_TYPE.SETUP_INTENT_CREATED]: 'setup intent created',
  [STRIPE_EVENT_TYPE.SETUP_INTENT_REQUIRES_ACTION]: 'setup intent requires action',
  [STRIPE_EVENT_TYPE.SETUP_INTENT_SETUP_FAILED]: 'setup intent setup failed',
  [STRIPE_EVENT_TYPE.SETUP_INTENT_SUCCEEDED]: 'setup intent succeeded',
  [STRIPE_EVENT_TYPE.SIGMA_SCHEDULED_QUERY_RUN_CREATED]: 'sigma scheduled query run created',
  [STRIPE_EVENT_TYPE.SKU_CREATED]: 'sku created',
  [STRIPE_EVENT_TYPE.SKU_DELETED]: 'sku deleted',
  [STRIPE_EVENT_TYPE.SKU_UPDATED]: 'sku updated',
  [STRIPE_EVENT_TYPE.SOURCE_CANCELED]: 'source canceled',
  [STRIPE_EVENT_TYPE.SOURCE_CHARGEABLE]: 'source chargeable',
  [STRIPE_EVENT_TYPE.SOURCE_FAILED]: 'source failed',
  [STRIPE_EVENT_TYPE.SOURCE_MANDATE_NOTIFICATION]: 'source mandate notification',
  [STRIPE_EVENT_TYPE.SOURCE_REFUND_ATTRIBUTES_REQUIRED]: 'source refund attributes required',
  [STRIPE_EVENT_TYPE.SOURCE_TRANSACTION_CREATED]: 'source transaction created',
  [STRIPE_EVENT_TYPE.SOURCE_TRANSACTION_UPDATED]: 'source transaction updated',
  [STRIPE_EVENT_TYPE.SUBSCRIPTION_SCHEDULE_ABORTED]: 'subscription schedule aborted',
  [STRIPE_EVENT_TYPE.SUBSCRIPTION_SCHEDULE_CANCELED]: 'subscription schedule canceled',
  [STRIPE_EVENT_TYPE.SUBSCRIPTION_SCHEDULE_COMPLETED]: 'subscription schedule completed',
  [STRIPE_EVENT_TYPE.SUBSCRIPTION_SCHEDULE_CREATED]: 'subscription schedule created',
  [STRIPE_EVENT_TYPE.SUBSCRIPTION_SCHEDULE_EXPIRING]: 'subscription schedule expiring',
  [STRIPE_EVENT_TYPE.SUBSCRIPTION_SCHEDULE_RELEASED]: 'subscription schedule released',
  [STRIPE_EVENT_TYPE.SUBSCRIPTION_SCHEDULE_UPDATED]: 'subscription schedule updated',
  [STRIPE_EVENT_TYPE.TAX_RATE_CREATED]: 'tax rate created',
  [STRIPE_EVENT_TYPE.TAX_RATE_UPDATED]: 'tax rate updated',
  [STRIPE_EVENT_TYPE.TERMINAL_READER_ACTION_FAILED]: 'terminal reader action failed',
  [STRIPE_EVENT_TYPE.TERMINAL_READER_ACTION_SUCCEEDED]: 'terminal reader action succeeded',
  [STRIPE_EVENT_TYPE.TEST_HELPERS_TEST_CLOCK_ADVANCING]: 'test helpers test clock advancing',
  [STRIPE_EVENT_TYPE.TEST_HELPERS_TEST_CLOCK_CREATED]: 'test helpers test clock created',
  [STRIPE_EVENT_TYPE.TEST_HELPERS_TEST_CLOCK_DELETED]: 'test helpers test clock deleted',
  [STRIPE_EVENT_TYPE.TEST_HELPERS_TEST_CLOCK_INTERNAL_FAILURE]: 'test helpers test clock internal failure',
  [STRIPE_EVENT_TYPE.TEST_HELPERS_TEST_CLOCK_READY]: 'test helpers test clock ready',
  [STRIPE_EVENT_TYPE.TOPUP_CANCELED]: 'topup canceled',
  [STRIPE_EVENT_TYPE.TOPUP_CREATED]: 'topup created',
  [STRIPE_EVENT_TYPE.TOPUP_FAILED]: 'topup failed',
  [STRIPE_EVENT_TYPE.TOPUP_REVERSED]: 'topup reversed',
  [STRIPE_EVENT_TYPE.TOPUP_SUCCEEDED]: 'topup succeeded',
  [STRIPE_EVENT_TYPE.TRANSFER_CREATED]: 'transfer created',
  [STRIPE_EVENT_TYPE.TRANSFER_FAILED]: 'transfer failed',
  [STRIPE_EVENT_TYPE.TRANSFER_PAID]: 'transfer paid',
  [STRIPE_EVENT_TYPE.TRANSFER_REVERSED]: 'transfer reversed',
  [STRIPE_EVENT_TYPE.TRANSFER_UPDATED]: 'transfer updated',
};

export const REVOLUT_PAYMENT_TYPE = {
  ATM: 'atm',
  CARD_PAYMENT: 'card_payment',
  CARD_REFUND: 'card_refund',
  CARD_CHARGEBACK: 'card_chargeback',
  CARD_CREDIT: 'card_credit',
  EXCHANGE: 'exchange',
  TRANSFER: 'transfer',
  LOAN: 'loan',
  FEE: 'fee',
  REFUND: 'refund',
  TOPUP: 'topup',
  TOPUP_RETURN: 'topup_return',
  TAX: 'tax',
  TAX_REFUND: 'tax_refund',
};

export const REVOLUT_COUNTER_PARTY_STATE = {
  CREATED: 'created',
  DRAFT: 'draft',
  DELETED: 'deleted',
};

export const REVOLUT_ACCOUNT_STATE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const OAUTH_REVOLUT_TOKEN_REDIS_KEY = 'oauth:revolut:token';
export const OAUTH_REVOLUT_TIMESTAMP_REDIS_KEY = 'oauth:revolut:timestamp';
export const OAUTH_REVOLUT_LOCK_REDIS_KEY = 'oauth:revolut:lock';
export const OAUTH_WISE_TOKEN_REDIS_KEY = 'oauth:wise:token';
export const OAUTH_WISE_TIMESTAMP_REDIS_KEY = 'oauth:wise:timestamp';
export const OAUTH_WISE_LOCK_REDIS_KEY = 'oauth:wise:lock';
export const OAUTH_WISE_CLIENT_TOKEN_REDIS_KEY = 'oauth:wise:client:token';
export const OAUTH_WISE_CLIENT_TIMESTAMP_REDIS_KEY = 'oauth:wise:client:timestamp';
export const OAUTH_PAYONEER_TOKEN_REDIS_KEY = 'oauth:payoneer:token';
export const OAUTH_PAYONEER_TIMESTAMP_REDIS_KEY = 'oauth:payoneer:timestamp';
export const OAUTH_PAYONEER_LOCK_REDIS_KEY = 'oauth:payoneer:lock';
