/* eslint-disable no-multi-spaces */
import { ICON } from 'core/assets/js/constants';

export const ORGANIZATION_LIST_FETCH = 'ORGANIZATION_LIST_FETCH';
export const ORGANIZATION_LIST_IS_LOADING = 'ORGANIZATION_LIST_IS_LOADING';
export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED';
export const ORGANIZATION_UPDATED = 'ORGANIZATION_UPDATED';
export const USERCARD_UPDATED = 'USERCARD_UPDATED';
export const PROGRESS_LOAD_SUCCESS = 'PROGRESS_LOAD_SUCCESS';
export const SUBSCRIPTIONS_LIST_FETCH = 'SUBSCRIPTIONS_LIST_FETCH';
export const SUBSCRIPTIONS_LIST_IS_LOADING = 'SUBSCRIPTIONS_LIST_IS_LOADING';

export const POSTING_TYPE = {
  ORG_ALLOC: 5,        // Inbound money from outside the system
  MANAGER_ALLOC: 10,   // Increase budget of Manager's pool
  MANAGER_DEALLOC: 15, // Reduce budget from Manager's pool
  PROJECT_ALLOC: 20,   // Add budget to Project's pool
  PROJECT_DEALLOC: 25, // Remove budget from Project's pool when a purchase order gets rejected
  // Remove funds from Project's pool and return it back to the organization owner when a project
  // is marked as completed
  PROJECT_REFUND: 26,
  PROVIDER_ALLOC: 30,  // Add funds to Provider's wallet
  PROVIDER_DEALLOC: 35, // Remove funds from Provider's wallet
};

export const POSTING_TYPE_LABEL = {
  [POSTING_TYPE.ORG_ALLOC]: 'Organisation Allocation',
  [POSTING_TYPE.MANAGER_ALLOC]: 'Manager Allocation',
  [POSTING_TYPE.MANAGER_DEALLOC]: 'Manager Deallocation',
  [POSTING_TYPE.PROJECT_ALLOC]: 'Project Allocation',
  [POSTING_TYPE.PROJECT_DEALLOC]: 'Project Deallocation',
  [POSTING_TYPE.PROJECT_REFUND]: 'Project Refund',
  [POSTING_TYPE.PROVIDER_ALLOC]: 'Provider Allocation',
  [POSTING_TYPE.PROVIDER_DEALLOC]: 'Provider Deallocation',
};

export const POSTING_LINE_TYPE = {
  CREDIT: 5,   // The amount related was credited to the subject of the posting
  DEBIT: 10,   // The amount related was debited to the subject of the posting
};

// IDs of PPH related organizations. This is only used in our admin interface so that we can
// easier filter DIRECT invoices raised by Providers of PPH organizations
export const PPH_ORG_IDS = [2, 15];

export const MAX_MANAGERS_WITH_BUDGET_RESULT_COUNT = 500;

export const ORGANIZATION_STATUS = {
  ACTIVE: 1,
  DEACTIVATED: 2,
  SUSPENDED: 3,
};

export const ORGANIZATION_STATUS_LABEL = {
  [ORGANIZATION_STATUS.ACTIVE]: 'Active',
  [ORGANIZATION_STATUS.DEACTIVATED]: 'Deactivated',
  [ORGANIZATION_STATUS.SUSPENDED]: 'Suspended',
};

export const UNKNOWN_ALIAS = 'unknown';
export const EUROMONITOR_ALIAS = 'euromonitor-expert-panel';

export const ORGANIZATION_CREATE_STEPS = {
  CREATE: 'create',
  PREFERENCES: 'preferences',
};

export const PRESERVED_ORG_ALIAS = {
  STAGING: 'staging',
  PRODUCTION: 'app',
  TD: 'talentdesk',
  WWW: 'WWW',
};

export const SITE_WIDE_BANNER_TYPE = {
  ALERT: 'alert',
  CRITICAL: 'critical',
  GENERAL: 'general',
  INFORMATION: 'information',
};

export const SITE_WIDE_BANNER_TYPE_LABEL = {
  [SITE_WIDE_BANNER_TYPE.ALERT]: 'Alert',
  [SITE_WIDE_BANNER_TYPE.CRITICAL]: 'Critical',
  [SITE_WIDE_BANNER_TYPE.GENERAL]: 'General',
  [SITE_WIDE_BANNER_TYPE.INFORMATION]: 'Information',
};

export const SITE_WIDE_BANNER_TYPE_COLOR = {
  [SITE_WIDE_BANNER_TYPE.ALERT]: '#fab152',
  [SITE_WIDE_BANNER_TYPE.CRITICAL]: '#ff595a',
  [SITE_WIDE_BANNER_TYPE.GENERAL]: '#2b304f',
  [SITE_WIDE_BANNER_TYPE.INFORMATION]: '#7dbdf9',
};

export const SITE_WIDE_BANNER_TYPE_TEXT_COLOR = {
  [SITE_WIDE_BANNER_TYPE.ALERT]: '#2b304f',
  [SITE_WIDE_BANNER_TYPE.CRITICAL]: '#fff',
  [SITE_WIDE_BANNER_TYPE.GENERAL]: '#fff',
  [SITE_WIDE_BANNER_TYPE.INFORMATION]: '#2b304f',
};

export const SITE_WIDE_BANNER_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  OFF: 'off',
};

export const SITE_WIDE_BANNER_STATUS_LABEL = {
  [SITE_WIDE_BANNER_STATUS.ACTIVE]: 'Active',
  [SITE_WIDE_BANNER_STATUS.COMPLETED]: 'Completed',
  [SITE_WIDE_BANNER_STATUS.OFF]: 'Off',
};

export const SITE_WIDE_BANNER_STATUS_COLOR = {
  [SITE_WIDE_BANNER_STATUS.ACTIVE]: '#60dc35',
  [SITE_WIDE_BANNER_STATUS.COMPLETED]: '#3f51b5',
  [SITE_WIDE_BANNER_STATUS.OFF]: '#bdbdbd',
};

export const SMART_SEARCH_RESULT = {
  FAIL: 'fail',
  PASS: 'pass',
};

export const SMART_SEARCH_RESULT_VALUES = Object.values(SMART_SEARCH_RESULT);

export const SMART_SEARCH_RESULT_LABEL = {
  [SMART_SEARCH_RESULT.FAIL]: 'Fail',
  [SMART_SEARCH_RESULT.PASS]: 'Pass',
};

export const SMART_SEARCH_RESULT_COLOR = {
  [SMART_SEARCH_RESULT.FAIL]: '#ff595a',
  [SMART_SEARCH_RESULT.PASS]: '#52cc83',
};

export const SMART_SEARCH_DOCUMENT_TYPE = {
  DRIVING_LICENCE: 'driving_licence',
  ID_CARD: 'id_card',
  PASSPORT: 'passport',
};

export const SMART_SEARCH_DOCUMENT_TYPE_VALUES = Object.values(SMART_SEARCH_DOCUMENT_TYPE);

export const SMART_SEARCH_DOCUMENT_TYPE_LABEL = {
  [SMART_SEARCH_DOCUMENT_TYPE.DRIVING_LICENCE]: 'Driving licence',
  [SMART_SEARCH_DOCUMENT_TYPE.ID_CARD]: 'ID card',
  [SMART_SEARCH_DOCUMENT_TYPE.PASSPORT]: 'Passport',
};

export const DASHBOARD_PENDING_ACTIONS_TYPE = {
  // Providers
  PROJECT_INVITATION: 'project-invitation',
  TASK_INVITATION: 'task-invitation',
  SERVICE_ORDER_NEEDS_AMENDED: 'service-order-needs-amended',
  CONTRACT_TEMPLATE: 'contract-template',
  // Managers
  SERVICE_ORDER_NEEDS_CONFIRMED: 'service-order-needs-confirmed',
  SERVICE_ORDER_NEEDS_APPROVED: 'service-order-needs-approved',
  RATE_NEEDS_APPROVED: 'rate-needs-approved',
  ONBOARDING_FORM_NEEDS_REVIEW: 'onboarding-form-needs-review',
  BUDGET_REQUEST: 'budget-request',
  TASK_COMPLETION_REQUEST: 'task-completion-request',
  PROJECT_APPLICATION: 'project-application',
  // Either
  TASK_RATE_AMENDMENT: 'task-rate-amendment',
  RATE_AMENDMENT: 'rate-amendment',
};

export const DASHBOARD_PENDING_ACTIONS_TYPE_LABEL = {
  [DASHBOARD_PENDING_ACTIONS_TYPE.PROJECT_INVITATION]: 'Project invitation',
  [DASHBOARD_PENDING_ACTIONS_TYPE.TASK_INVITATION]: 'Task invitation',
  [DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_AMENDED]: 'Service order amendment',
  [DASHBOARD_PENDING_ACTIONS_TYPE.CONTRACT_TEMPLATE]: 'Document signature',
  [DASHBOARD_PENDING_ACTIONS_TYPE.TASK_RATE_AMENDMENT]: 'Rate amendment',
  [DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_CONFIRMED]: 'Service order confirmation',
  [DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_APPROVED]: 'Service order approval',
  [DASHBOARD_PENDING_ACTIONS_TYPE.RATE_NEEDS_APPROVED]: 'Rate needs approved',
  [DASHBOARD_PENDING_ACTIONS_TYPE.RATE_AMENDMENT]: 'Rate amendment',
  [DASHBOARD_PENDING_ACTIONS_TYPE.ONBOARDING_FORM_NEEDS_REVIEW]: 'Onboarding',
  [DASHBOARD_PENDING_ACTIONS_TYPE.BUDGET_REQUEST]: 'Budget request',
  [DASHBOARD_PENDING_ACTIONS_TYPE.TASK_COMPLETION_REQUEST]: 'Task completion',
  [DASHBOARD_PENDING_ACTIONS_TYPE.PROJECT_APPLICATION]: 'Opportunity application',
};

export const DASHBOARD_PENDING_ACTIONS_TYPE_ICON = {
  [DASHBOARD_PENDING_ACTIONS_TYPE.PROJECT_INVITATION]: ICON.BAR_PROGRESS_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.TASK_INVITATION]: ICON.LIST_CHECK_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_AMENDED]: ICON.INVOICE_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.CONTRACT_TEMPLATE]: ICON.FILE_CERTIFICATE_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.TASK_RATE_AMENDMENT]: ICON.HAND_HOLDING_DOLLAR_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_CONFIRMED]: ICON.INVOICE_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_APPROVED]: ICON.INVOICE_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.RATE_NEEDS_APPROVED]: ICON.HAND_HOLDING_DOLLAR_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.RATE_AMENDMENT]: ICON.HAND_HOLDING_DOLLAR_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.ONBOARDING_FORM_NEEDS_REVIEW]: ICON.CLIPBOARD_LIST_CHECK_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.BUDGET_REQUEST]: ICON.COINS_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.TASK_COMPLETION_REQUEST]: ICON.LIST_CHECK_DUOTONE,
  [DASHBOARD_PENDING_ACTIONS_TYPE.PROJECT_APPLICATION]: ICON.BALLOT_CHECK_DUOTONE,
};
