const REGISTRATION_NUMBER = {
  PPH: '10769129',
  TALENTDESK: '11282119',
};

const basicConfig = {
  ID: 1,
  USER_ID: 1,
  UNIQUE_ALIAS: 'talentdesk',
  EMAIL: 'info_at_talentdesk.io@mailinator.com',
  WEBSITE: 'https://talentdesk.io',
  SEED: 'PPH Enterprise Solutions Ltd',
  DEFAULT_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
  TD_SERVICES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.TALENTDESK,
  TD_LIMITED_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
  AOR_FEES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.TALENTDESK,
  CODAT_FEES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
  API_FEES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
  ESIGN_FEES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
  TEN99_FEES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
  TIN_FEES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
  IDENTITY_CHECK_FEES_REGISTRATION_NUMBER: REGISTRATION_NUMBER.PPH,
};


/**
 * WARNING!
 * If you are investigating bank account override issues
 * Keep in mind that there is a system_bank_account_overrides
 * table in the database and an RC View handling the overrides
 */
const bankAccount = {
  [REGISTRATION_NUMBER.PPH]: {
    barclays_gbp: {
      BENEFICIARY: 'PPH Enterprise Solutions LTD',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '13035476',
      BANK_CODE: '20-77-67',
      BANK_NAME: 'Barclays',
      ALIAS: 'Barclays (GBP)',
    },
    barclays_eur: {
      BENEFICIARY: 'PPH E S LTD (EUR)',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '65929499',
      BANK_CODE: '20-77-67',
      BANK_NAME: 'Barclays',
      IBAN: 'GB72BARC20776765929499',
      SWIFT: 'BARCGB22',
      ALIAS: 'Barclays (EUR)',
    },
    barclays_usd: {
      BENEFICIARY: 'Talentdesk Limited (USD)',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '69565855',
      BANK_CODE: '20-77-67',
      BANK_NAME: 'Barclays',
      IBAN: 'GB17BARC20776769565855',
      SWIFT: 'BARCGB22',
      ALIAS: 'Barclays (USD)',
    },
    transferwise_gbp: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '59451695',
      BANK_CODE: '23-14-70',
      BANK_NAME: 'TransferWise',
      IBAN: 'GB97 TRWI 2314 7059 4516 95',
      ALIAS: 'Wise (GBP)',
    },
    transferwise_eur: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'BE',
      IBAN: 'BE70 9670 2961 0325',
      BANK_CODE: 'TRWIBEB1XXX',
      BANK_NAME: 'TransferWise',
      ALIAS: 'Wise (EUR)',
    },
    transferwise_eur_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'BE',
      IBAN: 'BE70 9670 2961 0325',
      BANK_NAME: 'TransferWise',
      ALIAS: 'Wise local (EUR)',
    },
    transferwise_usd: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'US',
      ACCOUNT_NUMBER: '8310474514',
      BANK_CODE: 'CMFGUS33',
      BANK_NAME: 'TransferWise',
      ADDRESS: {
        description: 'TransferWise, 30 W 26th Street, New York, NY 10010, United States',
      },
      ALIAS: 'Wise (USD)',
    },
    transferwise_usd_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'US',
      ACCOUNT_NUMBER: '8310474514',
      ROUTING_NUMBER: '026073150',
      BANK_NAME: 'TransferWise',
      ADDRESS: {
        description: 'TransferWise, 30 W 26th Street, New York, NY 10010, United States',
      },
      ALIAS: 'Wise local (USD)',
    },
    transferwise_cad_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'CA',
      ACCOUNT_NUMBER: '200110030491',
      INSTITUTION_NUMBER: '621',
      TRANSIT_NUMBER: '16001',
      ADDRESS: {
        description: 'TransferWise Canada Inc., 99 Bank Street, Suite 1420, Ottawa ON K1P 1H4, Canada',
      },
      ALIAS: 'Wise local (CAD)',
    },
    transferwise_nzd_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'NZ',
      ACCOUNT_NUMBER: '04-2021-0071210-62',
      ADDRESS: {
        description: '56 Shoreditch High Street London E1 6JJ United Kingdom',
      },
      ALIAS: 'Wise local (NZD)',
    },
  },
  [REGISTRATION_NUMBER.TALENTDESK]: {
    barclays_gbp: {
      BENEFICIARY: 'PPH Enterprise Solutions LTD',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '13035476',
      BANK_CODE: '20-77-67',
      BANK_NAME: 'Barclays',
      ALIAS: 'Barclays (GBP)',
    },
    barclays_eur: {
      BENEFICIARY: 'PPH E S LTD (EUR)',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '65929499',
      BANK_CODE: '20-77-67',
      BANK_NAME: 'Barclays',
      IBAN: 'GB72BARC20776765929499',
      SWIFT: 'BARCGB22',
      ALIAS: 'Barclays (EUR)',
    },
    barclays_usd: {
      BENEFICIARY: 'Talentdesk Limited (USD)',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '69565855',
      BANK_CODE: '20-77-67',
      BANK_NAME: 'Barclays',
      IBAN: 'GB17BARC20776769565855',
      SWIFT: 'BARCGB22',
      ALIAS: 'Barclays (USD)',
    },
    transferwise_gbp: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'GB',
      ACCOUNT_NUMBER: '59451695',
      BANK_CODE: '23-14-70',
      BANK_NAME: 'TransferWise',
      IBAN: 'GB97 TRWI 2314 7059 4516 95',
      ALIAS: 'Wise (GBP)',
    },
    transferwise_eur: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'BE',
      IBAN: 'BE70 9670 2961 0325',
      BANK_CODE: 'TRWIBEB1XXX',
      BANK_NAME: 'TransferWise',
      ALIAS: 'Wise (EUR)',
    },
    transferwise_eur_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'BE',
      IBAN: 'BE70 9670 2961 0325',
      BANK_NAME: 'TransferWise',
      ALIAS: 'Wise local (EUR)',
    },
    transferwise_usd: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'US',
      ACCOUNT_NUMBER: '8310474514',
      BANK_CODE: 'CMFGUS33',
      BANK_NAME: 'TransferWise',
      ADDRESS: {
        description: 'TransferWise, 30 W 26th Street, New York, NY 10010, United States',
      },
      ALIAS: 'Wise (USD)',
    },
    transferwise_usd_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'US',
      ACCOUNT_NUMBER: '8310474514',
      ROUTING_NUMBER: '026073150',
      BANK_NAME: 'TransferWise',
      ADDRESS: {
        description: 'TransferWise, 30 W 26th Street, New York, NY 10010, United States',
      },
      ALIAS: 'Wise local (USD)',
    },
    transferwise_cad_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'CA',
      ACCOUNT_NUMBER: '200110030491',
      INSTITUTION_NUMBER: '621',
      TRANSIT_NUMBER: '16001',
      ADDRESS: {
        description: 'TransferWise Canada Inc., 99 Bank Street, Suite 1420, Ottawa ON K1P 1H4, Canada',
      },
      ALIAS: 'Wise local (CAD)',
    },
    transferwise_nzd_local: {
      BENEFICIARY: 'TalentDesk Limited',
      COUNTRY_CODE: 'NZ',
      ACCOUNT_NUMBER: '04-2021-0071210-62',
      ADDRESS: {
        description: '56 Shoreditch High Street London E1 6JJ United Kingdom',
      },
      ALIAS: 'Wise local (NZD)',
    },
  },
};

const config = {
  development: {
    ...basicConfig,
    BANK: { ...bankAccount },
  },
  test: {
    ...basicConfig,
    BANK: { ...bankAccount },
  },
  staging: {
    ...basicConfig,
    BANK: { ...bankAccount },
  },
  production: {
    ...basicConfig,
    EMAIL: 'info@talentdesk.io',
    BANK: { ...bankAccount },
  },
};

export default config;
