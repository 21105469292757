import { FORM_ERROR } from 'final-form';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { get, isEmpty, minBy, omit } from 'lodash';
import { isEmail } from 'validator';

import Storage from 'core/assets/js/lib/Storage';
import axios from 'core/assets/js/lib/tdAxios';
import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { selectAuthenticated } from 'accounts/assets/js/reducers/auth';
import { interviewSpec } from 'interviews/assets/js/lib/objectSpecs';
import { interviewListApiUrl } from 'interviews/urls';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';
import { orgPeopleProvidersUrl, orgPeopleManagersUrl, orgPeopleInviteesUrl } from 'people/urls';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import {
  PEOPLE_INVITATION_WORDINGS, PEOPLE_INVITE_STEP, PEOPLE_TYPE, USER_EMPLOYMENT_TYPE,
} from 'people/assets/js/constants';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { documentOptionsApiUrl } from 'documents/urls';
import InvitePeopleViewHeader from 'people/assets/js/components/InvitePeopleViewHeader.jsx';
import InvitePeopleAdding from 'people/assets/js/components/steps/InvitePeopleAdding.jsx';
import InvitePeopleUserType from 'people/assets/js/components/steps/InvitePeopleUserType.jsx';
import InvitePeopleDetails from 'people/assets/js/components/steps/InvitePeopleDetails.jsx';
import InvitePeopleComplete from 'people/assets/js/components/steps/InvitePeopleComplete.jsx';
import InvitePeopleCountersigners from 'people/assets/js/components/steps/InvitePeopleCountersigners.jsx';
import { INVITATION_NEVER_EXPIRE_VALUE } from 'invitations/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { inviteProviderDS, inviteManagerDS } from 'people/assets/js/data-services/list';
import {
  getInviteUsersFormDuplicateDocumentError,
  getDocumentCountersignersFieldName,
  getSelectedDocumentIdsFromValues,
} from 'people/assets/js/lib/utils';

export const COMPONENT_NAME = 'InvitePeopleView';

const documentIdFromFieldName = fieldName => (
  parseInt(fieldName.split('document-')[1].split('-')[0], 10)
);

const counterSignersFieldNameRegex = new RegExp(`^${getDocumentCountersignersFieldName('\\d+')}$`);

const checkSelectedDocuments = (values, interviews, onboardingFormsAreMandatory, conditionFn) => {
  const documentIds = getSelectedDocumentIdsFromValues(
    values, interviews, onboardingFormsAreMandatory,
  );
  return documentIds.length > 0 && documentIds.some(documentId => conditionFn(documentId));
};

class InvitePeopleView extends Component {
  constructor(props) {
    super(props);
    this.history = props.history;
    this.props = props;
    this.state = {
      documentOptions: [],
      isFromPPH: false,
      isLoading: false,
    };

    const { orgAlias, peopleType } = props;
    this.localStorageKey = `invite-${peopleType}-${orgAlias}`;
    this.resolveInitialValues = this.resolveInitialValues.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getDefaultInterviewId = this.getDefaultInterviewId.bind(this);
    this.saveWizardState = this.saveWizardState.bind(this);
    this.validateEmails = this.validateEmails.bind(this);
  }

  async componentDidMount() {
    const { activeOrg, history } = this.props;
    const pphProfile = get(history, 'location.state.profile');
    if (pphProfile) {
      this.setState({ isFromPPH: true });
    }
    try {
      const { data } = await axios.get(documentOptionsApiUrl(
        activeOrg.alias, activeOrg.documents_with_countersigning_enabled && !pphProfile,
      ));
      this.setState({ documentOptions: data });
    } catch (err) {
      toastr.error('Oh Snap!', err.response?.data?._error || err.message);
    }
  }

  onCancel() {
    const { history, orgAlias, peopleType } = this.props;
    const isProviderInvite = peopleType === PEOPLE_TYPE.PROVIDERS;
    const returnUrl = (isProviderInvite ? orgPeopleProvidersUrl : orgPeopleManagersUrl)(orgAlias);
    history.push(returnUrl);
  }

  async onSubmit(values) {
    const { activeOrg, dispatch, history, orgAlias, peopleType } = this.props;
    const { isFromPPH } = this.state;
    const isProviderInvite = peopleType === PEOPLE_TYPE.PROVIDERS;
    const returnUrl = orgPeopleInviteesUrl(orgAlias);
    const action = isProviderInvite ? inviteProviderDS : inviteManagerDS;
    const { employment_type: employmentType } = values;
    const isEmployee = parseInt(employmentType, 10) === USER_EMPLOYMENT_TYPE.EMPLOYEE;

    let formValues = {
      ...values,
      is_employee: isEmployee,
    };

    if (!isFromPPH && activeOrg.documents_with_countersigning_enabled) {
      const emails = JSON.parse(values.emails);
      formValues.documentRoles = emails.reduce((acc, email) => {
        acc[email] = {};
        return acc;
      }, {});

      Object.keys(values).forEach(key => {
        if (!counterSignersFieldNameRegex.test(key)) {
          return;
        }
        const documentId = documentIdFromFieldName(key);
        const counterSigners = values[key];
        emails.forEach(email => {
          formValues.documentRoles[email][documentId] = { counterSigners };
        });
        delete formValues[key];
      });
    }

    if (
      !activeOrg.onboarding_forms_are_mandatory && (
        !Array.isArray(values.onboardingFormEnabled)
        || values.onboardingFormEnabled.length === 0
        || !values.onboardingFormEnabled[0].value
      )
    ) {
      formValues.interviewIds = [];
    }
    delete formValues.onboardingFormEnabled;

    if (isEmployee) {
      formValues = omit(formValues, ['interview', 'interviewIds', 'approved']);
    }
    if (!isProviderInvite) {
      delete formValues.customFieldTemplateIds;
    }
    this.setState({ isLoading: true });
    try {
      await dispatch(action({ orgAlias, values: formValues }));
      Storage.remove(this.localStorageKey);
      history.push(returnUrl);
      toastr.success(
        'Well Done!',
        `We have started inviting those ${peopleType}. We will notify you of any errors`,
      );
    } catch (err) {
      this.setState({ isLoading: false });
      toastr.error('Oh Snap!', err.errors?._error || err.message);
    }
  }

  getDefaultInterviewId() {
    const { interviews } = this.props;
    if (!interviews || !interviews.length) {
      return null;
    }
    return minBy(interviews, 'id').id;
  }

  saveWizardState(values) {
    const { isLoading } = this.state;

    if (!isLoading) {
      Storage.set(this.localStorageKey, omit(values, 'aor'));
    }
  }

  validateEmails({ emails }) {
    const { peopleType } = this.props;
    const { [peopleType]: { emailInvalid } } = PEOPLE_INVITATION_WORDINGS;
    const emailsValues = emails ? JSON.parse(emails) : null;

    if (isEmpty(emailsValues)) {
      return {
        emails: emailInvalid,
      };
    }

    return null;
  }

  resolveInitialValues() {
    const { activeOrg, peopleType } = this.props;
    const isManager = peopleType === PEOPLE_TYPE.MANAGERS;
    const initialValues = {
      employment_type: isManager ? USER_EMPLOYMENT_TYPE.EMPLOYEE : USER_EMPLOYMENT_TYPE.CONTRACTOR,
      approved: false,
      expiration_days: INVITATION_NEVER_EXPIRE_VALUE,
    };
    if (!activeOrg.onboarding_forms_are_mandatory) {
      initialValues.onboardingFormEnabled = [{ value: true }];
    }
    const { interviews, history } = this.props;
    const pphProfile = get(history, 'location.state.profile');
    const storageValues = Storage.get(this.localStorageKey);

    const emails = get(history, 'location.state.emails');

    const storedValues = {
      ...storageValues,
    };
    if (pphProfile) {
      storedValues.emails = '';
      storedValues.memberIds = [pphProfile.userId];
      storedValues.isFromPPH = true;
    } else if (!pphProfile && Array.isArray(emails)) {
      storedValues.emails = JSON.stringify(emails);
    }

    const { [peopleType]: { defaultMessage: message } } = PEOPLE_INVITATION_WORDINGS;
    let defaultValues = {
      ...initialValues,
      message,
      ...storedValues,
    };

    if (!isEmpty(interviews)) {
      const interviewIds = [];
      const defaultInterviewId = this.getDefaultInterviewId();
      if (defaultInterviewId) {
        interviewIds.push(defaultInterviewId);
      }
      defaultValues = { ...defaultValues, interviewIds };
    }

    return defaultValues;
  }

  render() {
    const { documentOptions, isLoading, isFromPPH } = this.state;
    const { activeOrg, interviews, peopleType, step } = this.props;

    const documentsWithCountersigning = documentOptions.reduce((acc, o) => {
      if (typeof o.countersignersCount === 'number' && o.countersignersCount > 0) {
        acc[o.value] = {
          countersignersCount: o.countersignersCount,
          title: o.label,
          isEsign: o.isEsign,
        };
      }
      return acc;
    }, {});

    const countersigningEnabled = !isFromPPH && activeOrg.documents_with_countersigning_enabled;

    const onboardingFormsAreMandatory = activeOrg.onboarding_forms_are_mandatory;

    const hasSelectedDocumentWithCountersigners = values => (
      countersigningEnabled
      && checkSelectedDocuments(
        values,
        interviews,
        onboardingFormsAreMandatory,
        documentId => !!documentsWithCountersigning[documentId],
      )
    );

    const inviteButtonTitle = [
      'Invite',
      isFromPPH ? peopleType.substr(0, peopleType.length - 1) : peopleType,
    ].join(' ');

    const WIZARD_STEPS = [
      ...(isFromPPH ? [] : [{
        component: InvitePeopleAdding,
        step: PEOPLE_INVITE_STEP.ADD,
        title: `Add ${peopleType}`,
        validate: this.validateEmails,
      }]),
      {
        component: InvitePeopleUserType,
        componentProps: { peopleType },
        step: PEOPLE_INVITE_STEP.USER_TYPE,
        title: 'User type',
        validate: ({ aor, employment_type }) => {
          if (
            activeOrg.aor_onboarding_invitations_enabled
            && !activeOrg.all_contractors_are_aor
            && aor === undefined
            && employment_type === USER_EMPLOYMENT_TYPE.CONTRACTOR
          ) {
            return { [FORM_ERROR]: 'Please select who will be handling the contract' };
          }
          return null;
        },
      },
      {
        component: InvitePeopleDetails,
        componentProps: { countersigningEnabled, documentOptions, interviews, peopleType },
        beforePageChange: async (values, { change }) => {
          if (countersigningEnabled) {
            // reset countersigners values, incase the user has gone back and changed selected
            // documents
            Object.keys(values).forEach(fieldName => {
              if (counterSignersFieldNameRegex.test(fieldName)) {
                change(fieldName, undefined);
              }
            });
            const documentIds = getSelectedDocumentIdsFromValues(
              values, interviews, onboardingFormsAreMandatory,
            );
            documentIds.forEach(documentId => {
              const document = documentsWithCountersigning[documentId];
              if (!document) {
                return;
              }
              for (let i = 0; i < document.countersignersCount; i += 1) {
                change(getDocumentCountersignersFieldName(documentId, i), '');
              }
            });
          }
          if (
            !Array.isArray(values.documents)
            || values.documents.length === 0
            || !Array.isArray(values.interviewIds)
            || values.interviewIds.length === 0
          ) {
            return null;
          }
          const selectedInterviews = interviews.filter(
            interview => values.interviewIds.includes(interview.id),
          );
          if (selectedInterviews.length === 0) {
            return null;
          }
          const documentError = getInviteUsersFormDuplicateDocumentError(
            activeOrg, values, selectedInterviews, documentOptions,
          );
          if (!documentError) {
            return null;
          }
          return { documents: documentError };
        },
        nextBtnTitle: values => (
          hasSelectedDocumentWithCountersigners(values) ? 'Next' : inviteButtonTitle
        ),
        step: PEOPLE_INVITE_STEP.DETAILS,
        title: 'Details',
      },
      {
        component: InvitePeopleCountersigners,
        componentProps: { documentsWithCountersigning, interviews },
        beforePageChange: async values => {
          const documentIds = getSelectedDocumentIdsFromValues(
            values, interviews, onboardingFormsAreMandatory,
          );
          const errors = {};
          documentIds.forEach(documentId => {
            const document = documentsWithCountersigning[documentId];
            if (!document) {
              return;
            }
            const counterSignersKey = getDocumentCountersignersFieldName(documentId);
            const usedEmail = [];
            for (let i = 0; i < document.countersignersCount; i += 1) {
              const email = values[counterSignersKey]?.[i];
              if (!email || !isEmail(email) || usedEmail.includes(email)) {
                if (!errors[counterSignersKey]) {
                  errors[counterSignersKey] = {};
                }
                errors[counterSignersKey][i] = 'This must be a unique and valid email';
              }
              usedEmail.push(email);
            }
          });
          return Object.keys(errors).length > 0 ? errors : null;
        },
        nextBtnTitle: inviteButtonTitle,
        showStep: hasSelectedDocumentWithCountersigners,
        step: PEOPLE_INVITE_STEP.ESIGN_COUNTERSIGNERS,
        title: 'Signers',
      },
      {
        component: InvitePeopleComplete,
        componentProps: { isFromPPH },
        step: PEOPLE_INVITE_STEP.DONE,
        title: 'Done',
      },
    ];

    const stepIds = WIZARD_STEPS.map(s => s.step);

    return (
      <>
        <InvitePeopleViewHeader />
        { isLoading && <LoadingComponent /> }
        <div className="page page--people">
          <div className="container">
            <Wizard
              defaultPage={step ? stepIds.indexOf(step) : undefined}
              onStepChange={(newStepIndex, formValues = {}, isStepBack) => {
                const steps = WIZARD_STEPS.filter(s => !s.showStep || s.showStep(formValues));
                const { step: nextStep } = steps[newStepIndex];
                if (!isStepBack && nextStep === PEOPLE_INVITE_STEP.DONE) {
                  this.onSubmit(formValues);
                }
              }}
              onCancel={this.onCancel}
              initialValues={this.resolveInitialValues()}
              saveWizardState={this.saveWizardState}
              saveWizardStateOnAnyChanges
              lastStepIsLoader
            >
              {WIZARD_STEPS.map(({
                beforePageChange,
                component: WizardComponent,
                componentProps,
                getSubmissionStatus,
                nextBtnTitle,
                showStep,
                step: wizardStep,
                title,
                validate,
              }) => (
                <Wizard.Page
                  beforePageChange={beforePageChange || (() => Promise.resolve(null))}
                  component={WizardComponent}
                  componentProps={componentProps}
                  getSubmissionStatus={getSubmissionStatus}
                  key={wizardStep}
                  nextBtnTitle={nextBtnTitle}
                  showStep={showStep}
                  title={title}
                  validate={validate}
                />
              ))}
            </Wizard>
          </div>
        </div>
      </>
    );
  }
}

InvitePeopleView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  interviews: PropTypes.arrayOf(interviewSpec).isRequired,
  orgAlias: PropTypes.string,
  peopleType: peopleTypeSpec.isRequired,
  step: PropTypes.string,
};

InvitePeopleView.defaultProps = {
  orgAlias: null,
  step: null,
};

const InvitePeopleViewWithTDApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) => dispatch(fetchListDS({
    url: interviewListApiUrl(params.orgAlias, url), querystring, componentName, authedAxios,
  })),
  duck: 'list',
  storeKey: COMPONENT_NAME,
  loadingEnabled: true,
  blockingLoading: true,
})(InvitePeopleView);

const mapStateToProps = (state, props) => {
  const listState = getListState(state, COMPONENT_NAME);

  return {
    activeOrg: selectActiveOrg(state),
    isAuthenticated: selectAuthenticated(state),
    interviews: listState.items,
    orgAlias: props.match.params?.orgAlias,
    step: props.match.params?.step,
    peopleType: props.match.params.peopleType,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const InvitePeopleViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitePeopleViewWithTDApiConnected);

export default withRouter(InvitePeopleViewConnect);
