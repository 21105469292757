import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { orgTemplatesManageUrl } from 'settings/urls';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';

const ProjectsSettingsTasksTab = ({
  handleSettingsChanged,
  organization: {
    allow_add_providers_directly_to_task: addProvidersDirectlyToTask,
    require_legal_documents: requireLegalDocuments,
    task_deadline_mandatory: taskDeadlineMandatory,
    task_discussions_disabled: taskDiscussionsDisabled,
    task_manual_completion: taskManualCompletion,
    task_template_mandatory: taskTemplateMandatory,
  },
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  return (
    <>
      <TDToggleWithConfirmationModal
        disableHeader="Require legal documents"
        DisableModalBody={() => (
          <>
            <p>
              By deactivating this setting, invited team members will not
              be required to review and agree to any legal documents before obtaining
              access to the task.
            </p>
            <p className="mb-4">Are you sure you want to deactivate this setting?</p>
          </>
        )}
        enableHeader="Require legal documents"
        EnableModalBody={() => (
          <>
            <p>
              By enabling this setting, invited team members will be required
              to review and agree to any legal documents before obtaining
              access to the task.
            </p>
            <p className="mb-4">Are you sure you want to activate this setting?</p>
          </>
        )}
        label="Require legal documents"
        modalId="allow-require-legal-documents"
        onToggled={selected => handleSettingsChanged({
          require_legal_documents: selected,
        })}
        selected={requireLegalDocuments}
        sublabel={(
          'Require invited team members to review and agree to any legal documents before '
          + 'obtaining access to the task'
        )}
      />
      <TDToggleWithConfirmationModal
        disableHeader="Task deadline is mandatory"
        DisableModalBody={() => (
          <>
            <p>
              By deactivating this setting, deadline will not be a mandatory field,
              when creating new tasks.
            </p>
            <p className="mb-4">Are you sure you want to deactivate this setting?</p>
          </>
        )}
        enableHeader="Task deadline is not mandatory"
        EnableModalBody={() => (
          <>
            <p>
              By activating this setting, deadline will be a mandatory field when
              creating new tasks.
            </p>
            <p className="mb-4">Are you sure you want to activate this setting?</p>
          </>
        )}
        label="Mandatory task deadline date"
        modalId="task-deadline-mandatory"
        onToggled={selected => handleSettingsChanged({
          task_deadline_mandatory: selected,
        })}
        selected={taskDeadlineMandatory}
        sublabel={(
          'By activating this option task deadline date becomes mandatory when creating a task'
        )}
      />
      <TDToggleWithConfirmationModal
        disableHeader="Tasks must be approved, before completion"
        DisableModalBody={() => (
          <>
            <p>
              By deactivating this setting, tasks will not need to be approved before completion.
            </p>
            <p className="mb-4">Are you sure you want to deactivate this setting?</p>
          </>
        )}
        enableHeader="Tasks do not need to be approved, before completion"
        EnableModalBody={() => (
          <>
            <p>
              By activating this setting, tasks will need to be approved before completion.
            </p>
            <p>Any existing tasks will be automatically approved.</p>
            <p className="mb-4">Are you sure you want to activate this setting?</p>
          </>
        )}
        label="Tasks must be approved before completion"
        modalId="task-manual-completion"
        onToggled={selected => handleSettingsChanged({
          task_manual_completion: selected,
        })}
        selected={taskManualCompletion}
        sublabel={(
          'By default, when all task assignments are marked as completed, the task is automatically '
          + 'completed. By enabling this feature, all tasks will require a manual approval step to be'
          + ' performed before the task is completed. If extra work is needed on the task, you can '
          + 'add additional providers to perform extra work or reactivate an existing task assignee.'
        )}
      />
      <TDToggleWithConfirmationModal
        disableHeader="Communication within task discussions is not allowed"
        DisableModalBody={() => (
          <>
            <p>
              By deactivating this setting, communication within task discussions will be allowed.
            </p>
            <p className="mb-4">Are you sure you want to deactivate this setting?</p>
          </>
        )}
        enableHeader="Communication within task discussions is allowed"
        EnableModalBody={() => (
          <>
            <p>
              By activating this setting, communication within task discussions will not be allowed.
            </p>
            <p className="mb-4">Are you sure you want to activate this setting?</p>
          </>
        )}
        label="Disable communication within task discussions"
        modalId="task-discussions-disabled"
        onToggled={selected => handleSettingsChanged({
          task_discussions_disabled: selected,
        })}
        selected={taskDiscussionsDisabled}
        sublabel={(
          'Enable this feature if you would like to prevent your team from being able to type '
          + 'messages into Task Discussions. Please note that the Discussions cannot be disabled '
          + 'completely, as they are also used for rate negotiations and completing assignments.'
        )}
      />
      <TDToggleWithConfirmationModal
        disableHeader="Add providers directly to tasks is not allowed"
        DisableModalBody={() => (
          <>
            <p>
              By deactivating this setting, you will not
              allow managers to add providers directly to tasks.
            </p>
            <p className="mb-4">Are you sure you want to deactivate this setting?</p>
          </>
        )}
        enableHeader="Add providers directly to tasks is allowed"
        EnableModalBody={() => (
          <>
            <p>
              By activating this setting, you will
              allow managers to add providers directly to tasks.
            </p>
            <p className="mb-4">Are you sure you want to activate this setting?</p>
          </>
        )}
        label="Allow managers to add providers directly to tasks"
        modalId="allow-directly-add-providers-to-tasks"
        onToggled={selected => handleSettingsChanged({
          allow_add_providers_directly_to_task: selected,
        })}
        selected={addProvidersDirectlyToTask}
        sublabel={(
          'By default, providers are invited to tasks with a rate they must agree to. Enable '
          + 'this setting if you prefer to bypass this and allow managers to add providers '
          + 'to tasks directly.'
        )}
      />
      <TDToggleWithConfirmationModal
        disableHeader="Mandatory task template"
        DisableModalBody={() => (
          <p>
            By deactivating this setting, it will not be mandatory that a task
            template be applied to a task before it can be created.
          </p>
        )}
        enableHeader="Mandatory task template"
        EnableModalBody={() => (
          <p>
            By activating this setting, it will be mandatory that a task template be applied to a
            task before it can be created. Please ensure you have created at least one task
            template before enabling this feature.
          </p>
        )}
        label="Mandatory task template"
        modalId="mandatory-task-template"
        onToggled={selected => handleSettingsChanged({
          task_template_mandatory: selected,
        })}
        selected={taskTemplateMandatory}
        sublabel={(
          <p>
            By activating this setting, it will be mandatory that a task template be applied to a
            task before it can be created. Please ensure you have created at least one task
            template before enabling this feature.
            {' '}
            <a
              href={orgTemplatesManageUrl(activeOrg?.alias, SETTINGS_TEMPLATE_TABS.TEMPLATES)}
            >
              Manage task templates
            </a>
            .
          </p>
        )}
      />
    </>
  );
};

ProjectsSettingsTasksTab.propTypes = {
  handleSettingsChanged: PropTypes.func.isRequired,
  organization: orgSpec.isRequired,
};

export default ProjectsSettingsTasksTab;
