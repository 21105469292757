/* globals FormData */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { getPhoneDefaultCountry } from 'accounts/assets/js/lib/helpers';
import { API_DATE_FORMAT, BS_STYLE, MAX_UPLOAD_FILES, MIME_TYPES } from 'core/assets/js/constants';
import DatePickerSimpleField from 'core/assets/js/components/FinalFormFields/DatePickerSimpleField.jsx';
import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import GeoSuggestField from 'core/assets/js/components/FinalFormFields/GeoSuggestField.jsx';
import PhoneNumberField from 'core/assets/js/components/FinalFormFields/PhoneNumberField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import moment from 'core/assets/js/lib/tdMoment';

const ProfileForm = ({ fromGetStarted, initialValues, onSubmit, profile }) => (
  <Form
    initialValues={initialValues}
    onSubmit={async values => {
      if (values.phone && !isPossiblePhoneNumber(values.phone)) {
        return { phone: 'Invalid phone number' };
      }
      if (Array.isArray(values.attachments) && values.attachments.length > MAX_UPLOAD_FILES) {
        return {
          attachments: `You can only select a maximum of ${MAX_UPLOAD_FILES} attachments`,
        };
      }
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        const value = values[key];
        if (!value) {
          return;
        }
        if (key === 'attachments' && Array.isArray(value) && value.length > 0) {
          value.forEach(attachment => {
            formData.append('attachments[]', attachment);
          });
          return;
        }
        if (['address', 'languages'].includes(key)) {
          formData.append(key, JSON.stringify(value));
          return;
        }
        formData.append(key, value);
      });
      return onSubmit(formData);
    }}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="image-upload-row clearfix">
          <FileUploaderDirectField
            accept={MIME_TYPES.IMAGES}
            label="Photo"
            name="avatar"
          />
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-sm-6">
            <TextInputField label="First name" name="first_name" required />
            <TextInputField label="Middle name" name="middle_name" />
            <TextInputField label="Last name" name="last_name" required />
            <TextInputField label="Job title" name="job_title" />
            <DatePickerSimpleField
              disableAfterDate={moment().format(API_DATE_FORMAT)}
              label="Date of birth"
              name="dateOfBirth"
            />
            <PhoneNumberField
              defaultCountry={getPhoneDefaultCountry(profile)}
              label="Phone"
              name="phone"
            />
            <GeoSuggestField label="Address" name="address" required />
          </div>
          <div className="col-12 col-sm-6">
            <div className="row">
              <div className="col-12">
                <TextAreaField label="Resumé" mdEditorEnabled name="resume" />
              </div>
            </div>
          </div>
          <div className="col-12">
            <FileUploaderDirectField
              accept={['image/*', 'application/pdf', 'video/*', '.ppt', '.pptx']}
              allowMultiple
              label="Attachments"
              name="attachments"
            />
          </div>
        </div>
        {submitError && <span className="help-block d-inline-block mt-3">{submitError}</span>}
        <div className="row">
          <div className="col-12 text-right">
            <TDButton
              data-testid="button-profile-save-or-update"
              className="position-tweak"
              disabled={submitting}
              label={fromGetStarted ? 'Save & continue' : 'Update'}
              type="submit"
              variant={BS_STYLE.PRIMARY}
            />
          </div>
        </div>
      </form>
    )}
  />
);

ProfileForm.propTypes = {
  fromGetStarted: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

ProfileForm.defaultProps = {
  fromGetStarted: false,
};

export default ProfileForm;
