import { REPORT_TABS } from 'analytics/assets/js/constants';
import ReportView from 'analytics/assets/js/ReportView.jsx';
import ReportsView from 'analytics/assets/js/ReportsView.jsx';
import { reportsUrl, reportUrl } from 'analytics/urls';
import { LAYOUTS } from 'core/assets/js/constants';

const analyticsRouteConfig = [
  {
    path: reportsUrl(':orgAlias', `:tab(${Object.values(REPORT_TABS).join('|')})?`),
    component: ReportsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: reportUrl(':orgAlias', ':id'),
    component: ReportView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
];

export default analyticsRouteConfig;
