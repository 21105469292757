import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import FinancialChartWrapper from 'analytics/assets/js/components/FinancialChartWrapper.jsx';
import { FINANCIAL_CHART_TYPES } from 'analytics/assets/js/constants';
import { financialChartApiUrl } from 'analytics/urls';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import moment from 'core/assets/js/lib/tdMoment';
import { SERVICE_ORDER_TYPE_LABEL } from 'projects/assets/js/constants';

const SpendByType = ({ max, min }) => {
  const params = useParams();

  const { hasLoaded, isLoading, item } = fetchDataHook({
    changesArray: [max, min],
    componentName: SpendByType.GetComponentName(),
    url: financialChartApiUrl(params.orgAlias, FINANCIAL_CHART_TYPES.SPEND_BY_TYPE),
    queryStringParams: { max, min },
  });

  const typesSorted = Object.keys(item?.types || {}).reduce(
    (acc, type) => {
      const amount = parseFloat(item.types[type]);
      if (amount > 0) {
        acc.push({ amount, className: kebabCase(`${SERVICE_ORDER_TYPE_LABEL[type]}s`), type });
      }
      return acc;
    },
    [],
  );
  typesSorted.sort((a, b) => b.amount - a.amount);
  const typesCount = typesSorted.length;

  return (
    <FinancialChartWrapper isLoading={!hasLoaded || isLoading} title="Spend by Type">
      <div className="d-flex flex-column spend-by-type-chart">
        <div className="total font-weight-bold mt-2 mb-5 pb-2">
          <NumberTpl
            currency={item.currency}
            value={typesSorted.reduce((acc, { amount }) => acc + amount, 0)}
          />
        </div>
        {typesCount > 0 && (
          <div className="d-flex flex-column key p-3 mb-5">
            {typesSorted.map(({ amount, className, type }) => (
              <div className="d-flex align-items-center justify-content-between" key={type}>
                <div>
                  {`${SERVICE_ORDER_TYPE_LABEL[type]}s`}
                  <div className={`dot ${className} ml-2`} />
                </div>
                <div className="font-weight-bold">
                  <NumberTpl currency={item.currency} value={amount} />
                </div>
              </div>
            ))}
          </div>
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              className: 'spend-by-type-bar-chart',
              height: `${280 - (typesCount > 0 ? 40 : 0) - (typesCount * 20)}px`,
              margin: [10, 0, 25, 50],
              type: 'column',
            },
            credits: { enabled: false },
            legend: { enabled: false },
            plotOptions: {
              column: {
                borderWidth: 0,
                enableMouseTracking: false,
                pointWidth: 240,
                stacking: 'normal',
              },
            },
            series: typesSorted.map(({ amount, className, type }) => ({
              className,
              data: [amount],
              name: [`${SERVICE_ORDER_TYPE_LABEL[type]}s`],
            })),
            subtitle: { text: null },
            xAxis: {
              categories: [`${moment(min).format('D/M')} - ${moment(max).format('D/M')}`],
              gridLineWidth: 1,
            },
            yAxis: {
              allowDecimals: false,
              gridLineWidth: 0,
              labels: { format: `${CURRENCY_SYMBOL[item.currency]}{text}` },
              min: 0,
              title: { enabled: false },
            },
            title: { text: '' },
            tooltip: { enabled: false },
          }}
        />
      </div>
    </FinancialChartWrapper>
  );
};

SpendByType.GetComponentName = () => 'SpendByType';

SpendByType.propTypes = {
  max: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
};

export default SpendByType;
