import { isEmpty } from 'lodash';
import { assert, CURRENCY_SYMBOL, Money } from 'td-finance-ts';

import { SYSTEM, CURRENCY } from 'core/assets/js/constants';
import IdentityCheckFeeAnalysis from 'finance/assets/js/lib/fees/IdentityCheckFeeAnalysis';

class IdentityCheckFeeScheme {
  static getDefaultSystemReg() {
    return SYSTEM.IDENTITY_CHECK_FEES_REGISTRATION_NUMBER;
  }

  static getEmpty(hasSpec = true) {
    return new IdentityCheckFeeScheme({
      currency: CURRENCY.USD,
      basePrice: '0.00',
      hasSpec,
    });
  }

  static getDefault() {
    return new IdentityCheckFeeScheme({
      currency: CURRENCY.USD,
      basePrice: '8.50',
    });
  }

  static fromFormValues(values) {
    const { currency, price, systemReg } = values;
    return new IdentityCheckFeeScheme({
      currency,
      basePrice: new Money(price, currency).toString(),
      systemReg,
    });
  }

  /**
   * It is used for de-serializing a IdentityCheckFeeScheme serialized object
   *
   * @param {Object} serialized
   *
   * @returns {IdentityCheckFeeScheme}
   */
  constructor(serialized) {
    if (isEmpty(serialized)) {
      return IdentityCheckFeeScheme.getEmpty(false);
    }
    if (serialized instanceof IdentityCheckFeeScheme) {
      return new IdentityCheckFeeScheme(serialized.serialize());
    }
    this.init(serialized);
  }

  /**
   * @param {Object} params
   * @param {String} params.currency
   * @param {String} params.basePrice
   * @param {Boolean} params.hasSpec=false
   */
  init({
    currency,
    basePrice = '0.00',
    hasSpec = true,
  } = {}) {
    assert(currency, 'currency is required');
    assert(basePrice, 'basePrice is required');
    const systemReg = IdentityCheckFeeScheme.getDefaultSystemReg();
    this.hasSpec = hasSpec;
    this.details = {
      currency,
      basePrice,
      systemReg,
    };
  }

  copy() {
    return new IdentityCheckFeeScheme(this.serialize());
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getCurrencySymbol() {
    return CURRENCY_SYMBOL[this.getCurrency()];
  }

  getBasePrice() {
    const { basePrice } = this.details;
    return basePrice;
  }

  getSystemRegistrationNumber() {
    const { systemReg } = this.details;
    return systemReg;
  }

  isEmpty() {
    return !this.hasSpec;
  }

  isZero() {
    const { basePrice } = this.details;
    return new Money(basePrice, this.getCurrency()).isZero();
  }

  hasFee() {
    return !this.isZero();
  }

  /**
   * This method applies the metrics passed to the settings set by the Identity Check scheme
   * The result is the applied charges
   *
   * @param {Object} metrics (The metrics for the Identity Check service)
   * @param {Boolean} metrics.hasBeenEnabled=false - if the service has been enabled in an org level
   * @param {Number} metrics.numIdentityChecks - the number of identity checks
   * @param {String} metrics.systemRegistrationNumber - The system reg number used when applying
   * identity check charges.
   *
   * @returns {IdentityCheckFeeAnalysis}
   */
  apply({
    hasBeenEnabled = false,
    numIdentityChecks = 0,
    systemRegistrationNumber,
  } = {}) {
    const { currency, basePrice, systemReg } = this.details;

    if (systemRegistrationNumber !== systemReg) {
      // When system numbers don't match it means that the scheme's generated charges
      // are not applied by the system entity's reg number used. So we return an empty analysis.
      return IdentityCheckFeeAnalysis.getEmptyAnalysis();
    }

    return new IdentityCheckFeeAnalysis({
      currency, basePrice, hasBeenEnabled, numIdentityChecks,
    });
  }

  toFormValues() {
    return {
      price: this.getBasePrice(),
      currency: this.getCurrency(),
      systemReg: this.getSystemRegistrationNumber(),
    };
  }
}

export default IdentityCheckFeeScheme;
