import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { accountSettingsApiUrl } from 'accounts/urls';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { settingsAccountUrl } from 'settings/urls';

const AlertsWidget = () => {
  const activeOrg = useSelector(selectActiveOrg);
  const history = useHistory();

  const componentName = AlertsWidget.GetComponentname();

  const { item: accountSettings } = fetchDataHook({
    componentName: `${componentName}_account-settings`, url: accountSettingsApiUrl(),
  });

  const alerts = [];

  if (!accountSettings.has2faEnabled && !!accountSettings.orgWithMandatory2fa) {
    alerts.push({
      buttonLabel: 'Open account settings',
      buttonOnClick: () => history.push(settingsAccountUrl(activeOrg.alias)),
      className: 'mandatory-2fa',
      description: 'Two-factor authentication is mandatory. Please navigate to your account '
        + 'settings to secure your account.',
      icon: ICON.TRIANGLE_EXCLAMATION_DUOTONE,
      style: BS_STYLE.DANGER,
      title: 'Two-factor authentication is required.',
    });
  }

  if (alerts.length === 0) {
    return null;
  }

  return (
    <div className="dashboard-alerts-wrapper d-flex flex-column mb-5">
      {alerts.map(alert => (
        <Card className={`dashboard-alert ${alert.className} ${alert.style}`} key={alert.title}>
          <div className="stripe w-100" />
          <Card.Body className="d-flex flex-column p-5">
            <div className="d-flex flex-row align-items-center justify-content-start mb-3">
              <i className={`${alert.icon} fa-3x mr-5`} />
              <h2 className="m-0">{alert.title}</h2>
            </div>
            <p>{alert.description}</p>
            <div className="d-flex flex-row align-items-center justify-content-start mt-2">
              <TDButton
                label={alert.buttonLabel}
                onClick={alert.buttonOnClick}
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

AlertsWidget.GetComponentname = () => 'AlertsWidget';

export default AlertsWidget;
