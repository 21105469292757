import React from 'react';
import PropTypes from 'prop-types';

import { BS_STYLE, BS_SIZE, BS_TOOLTIP_PLACEMENT, BUTTON_STYLE } from 'core/assets/js/constants';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';


const TDButton = ({
  block,
  bsSize,
  btnIcon,
  className,
  children,
  'data-testid': dataTestId,
  disabled,
  disabledMsg,
  floatRight,
  id,
  label,
  onClick,
  rest,
  rounded,
  show,
  showIconOnLeft,
  tooltipMsg,
  tooltipPosition,
  type,
  variant,
}) => {
  const useCustomIconImage = typeof btnIcon === 'object';
  const btnClass = ['btn'];

  btnClass.push(BUTTON_STYLE[variant]);

  if (className) {
    btnClass.push(className);
  }
  if (block) {
    btnClass.push('btn-block');
  }
  if (rounded) {
    btnClass.push('btn-rounded');
  }
  if (bsSize) {
    btnClass.push(`btn-${bsSize}`);
  }
  if (floatRight) {
    btnClass.push('float-right');
  }
  if (btnIcon) {
    btnClass.push('d-flex align-items-center');
  }
  if (disabledMsg) {
    /*
    / We add disabled class instead of using disabled attribute due to the
    / fact that we cannot have a tooltip in a disabled button :S
    */
    btnClass.push('disabled');
  }
  if (!show) {
    btnClass.push('d-none');
  }

  const handleClickFn = !disabledMsg ? onClick : () => {};
  let btn = type === 'submit'
    ? (
      <button
        id={id}
        type="submit"
        data-testid={dataTestId}
        disabled={disabled}
        className={btnClass.join(' ')}
        onClick={handleClickFn}
        {...rest}
      >
        { btnIcon && (rounded || showIconOnLeft) && <span className={`btn-icon ${btnIcon}`} /> }
        { label && <span>{label}</span> }
        { btnIcon && !showIconOnLeft && !useCustomIconImage
        && !rounded && <span className={`btn-icon ${btnIcon}`} /> }
        {/* In case of custom icon, btnIcon is an <img /> element */}
        { useCustomIconImage && !rounded && btnIcon }
        {children}
      </button>
    )
    : (
      <button
        id={id}
        type="button"
        data-testid={dataTestId}
        disabled={disabled}
        className={btnClass.join(' ')}
        onClick={handleClickFn}
        {...rest}
      >
        { btnIcon && (rounded || showIconOnLeft) && <span className={`btn-icon ${btnIcon}`} /> }
        { label && <span>{label}</span> }
        { btnIcon && !showIconOnLeft && !useCustomIconImage
        && !rounded && <span className={`btn-icon ${btnIcon}`} /> }
        {/* In case of custom icon, btnIcon is an <img /> element */}
        { useCustomIconImage && !rounded && btnIcon }
        {children}
      </button>
    );

  if (disabledMsg) {
    btn = (
      <TDElementWithTooltip
        el={btn}
        delay={250}
        placement={tooltipPosition}
        tooltipMsg={disabledMsg}
      />
    );
  }

  if (tooltipMsg) {
    btn = (
      <TDElementWithTooltip
        el={btn}
        delay={250}
        placement={tooltipPosition}
        tooltipMsg={tooltipMsg}
      />
    );
  }

  return btn;
};

TDButton.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(BS_STYLE)),
  bsSize: PropTypes.oneOf(Object.values(BS_SIZE)),
  btnIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  block: PropTypes.bool,
  rounded: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledMsg: PropTypes.string,
  tooltipMsg: PropTypes.string,
  floatRight: PropTypes.bool,
  handleClick: PropTypes.func,
  showIconOnLeft: PropTypes.bool,
  show: PropTypes.bool,
  rest: PropTypes.object,
};
TDButton.defaultProps = {
  'data-testid': null,
  type: 'button',
  children: null,
  variant: BS_STYLE.DEFAULT,
  bsSize: BS_SIZE.DEFAULT,
  label: '',
  btnIcon: '',
  block: false,
  className: '',
  disabled: false,
  disabledMsg: '',
  id: null,
  tooltipMsg: null,
  tooltipPosition: BS_TOOLTIP_PLACEMENT.BOTTOM,
  floatRight: false,
  handleClick: () => {},
  show: true,
  showIconOnLeft: false,
  rest: {},
};

export default TDButton;
