import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { CURRENCY } from 'core/assets/js/constants';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { RateCreateFormBody, RateCreateFormButtons } from 'rates/assets/js/components/RateCreateForm.jsx';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import { ratesProposeApiUrl } from 'rates/urls';

const RateCreateModal = ({ dispatch, initialValues, isModalOpen, onSubmitSuccess, orgAlias }) => {
  const formRef = useRef(null);

  const onCloseModal = async () => {
    dispatch(modalCloseAC());
  };

  useEffect(() => {
    if (isModalOpen && formRef.current) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      formRef.current = null;
    }
  }, [isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <ModalSimple
      data-testid="rate-create-modal"
      heading="Propose rate"
      open={isModalOpen}
      onClose={onCloseModal}
      body={(
        <Form
          initialValues={initialValues}
          onSubmit={async values => {
            try {
              const { data: responseRate } = await axios.post(ratesProposeApiUrl(orgAlias), values);
              onCloseModal();

              // Show a success message
              const {
                amount: rate, currencySymbol: responseCurrencySymbol, unit: rateUnit,
              } = responseRate;
              const newRate = getDisplayRate(rate, rateUnit, { currency: responseCurrencySymbol });
              const successMsg = values.setRate
                ? `You just set a new rate of ${newRate} for the provider.`
                : `You proposed a new rate of ${newRate}. Please wait for the provider to review.`;
              toastr.success('Nice!', successMsg);
              onSubmitSuccess(responseRate);
              return null;
            } catch (err) {
              return parseAxiosErrorForFinalForm(err);
            }
          }}
          render={({ handleSubmit, form, submitError }) => {
            if (!formRef.current) {
              // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is broken
              // https://github.com/final-form/react-final-form/issues/483
              formRef.current = form;
            }
            const { submitting, values: { currency, setRate, unit } } = form.getState();
            const rateUnit = unit ? parseInt(unit, 10) : RATE_UNIT.PER_HOUR;
            return (
              <form data-testid="rate-create-modal-form" onSubmit={handleSubmit}>
                {submitting && <LoadingComponent key="loading" />}
                <RateCreateFormBody
                  isInsideModal
                  key="form-body"
                  rateUnit={rateUnit}
                  currencySymbol={CURRENCY_SYMBOL[currency]}
                />
                <TextInputField
                  key="target-user-id"
                  name="target_user_id"
                  type="hidden"
                />
                <RadioField
                  key="set-rate"
                  name="setRate"
                  label="Create and immediately approve the rate"
                  groupClassName="mt-5"
                  options={[{ value: true, text: 'Yes' }, { value: false, text: 'No' }]}
                />
                <RadioField
                  disabled={!setRate}
                  key="skip-notification"
                  label="Notify the user about their new rate?"
                  name="skipNotification"
                  options={[{ text: 'Yes', value: false }, { text: 'No', value: true }]}
                />
                {submitError && (
                  <div key="errors" className="has-error mb-4">
                    <span className="help-block">{submitError}</span>
                  </div>
                )}
              </form>
            );
          }}
        />
      )}
      footer={(
        <RateCreateFormButtons
          handleCancel={() => {
            onCloseModal();
          }}
          handleSubmit={() => formRef.current && formRef.current.submit()}
          submitting={formRef.current !== null && formRef.current.getState().submitting}
        />
      )}
    />
  );
};

RateCreateModal.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    currency: PropTypes.oneOf(Object.values(CURRENCY)).isRequired,
    target_user_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    unit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  }),
  onSubmitSuccess: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

RateCreateModal.defaultProps = {
  isModalOpen: false,
  initialValues: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    isModalOpen: !!ownProps.modalId && getIsModalOpen(state, ownProps.modalId),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RateCreateModal);
