import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import ManagerBudgetBreakdownTable from 'finance/assets/js/components/ManagerBudgetBreakdownTable.jsx';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import { financeManagersBudgetBreakdownApiUrl } from 'finance/urls';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';

const ManagerBudgetBreakdownView = () => {
  const params = useParams();
  const [postings, setPostings] = useState([]);

  const { hasLoaded, item: { info, items } } = fetchDataHook({
    componentName: ManagerBudgetBreakdownView.GetComponentName(),
    url: financeManagersBudgetBreakdownApiUrl(params.orgAlias, params.id),
  });

  useEffect(() => {
    if (hasLoaded) {
      const recalculateBalancePostings = items.map((posting, idx) => {
        const balance = items.slice(0, idx + 1).reduce((acc, p) => (
          acc + Number(p.changeAmt)
        ), 0);

        return {
          ...posting,
          balance: parseFloat(balance).toFixed(2),
        };
      });

      setPostings(recalculateBalancePostings);
    }
  }, [items, hasLoaded]);

  const emptyText = 'No financial actions were found';

  if (!hasLoaded) {
    return <ListSkeleton />;
  }

  return (
    <ReportViewLayout
      heading={info?.fullName}
      reportType={REPORT_TYPES.MANAGER_BUDGET}
    >
      <div className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list mb-xl-0">
        <ManagerBudgetBreakdownTable
          postings={postings || []}
          info={info}
          emptyText={emptyText}
        />
      </div>
      <div className="finance-report__totals">
        <div className="finance-table__summary">
          <div>
            <span>Total Available</span>
          </div>
          <div>
            <span>
              <NumberTpl
                prefix={info?.currency}
                value={postings.length > 0 ? postings[postings.length - 1].balance : '0.00'}
              />
            </span>
          </div>
        </div>
      </div>
    </ReportViewLayout>
  );
};

ManagerBudgetBreakdownView.GetComponentName = () => 'ManagerBudgetBreakdownView';

export default ManagerBudgetBreakdownView;
