import React, { useEffect } from 'react';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import { DOCUMENT_GET_ELEMENT_BY_ID } from 'core/assets/js/config/settings';

const EnterTOTP = () => {
  const fieldName = 'totp';

  useEffect(() => {
    DOCUMENT_GET_ELEMENT_BY_ID(`field-${fieldName}`)?.focus();
  }, []);

  return (
    <>
      <h2>Enter 6-digit code</h2>
      <p className="mb-5">Enter the 6-digit code from your authenticator app</p>
      <TextInputField
        className="d-flex flex-column align-items-center"
        ignoreChange={event => !/^[0-9]{0,6}$/.test(event.target.value)}
        autoComplete="one-time-code"
        name={fieldName}
      />
    </>
  );
};

export default EnterTOTP;
