import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import UserUSTaxFilingsList from 'finance/assets/js/components/UserUSTaxFilingsList.jsx';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';

const ProfileExtraTaxWidgets = ({ parentComponentName, userCard }) => {
  const { orgAlias, userId, userType } = useParams();
  const dispatch = useDispatch();
  return (
    <UserUSTaxFilingsList
      className="mt-5"
      reload={() => {
        const ds = userType === 'providers' ? fetchProviderDS : fetchManagerDS;
        dispatch(ds({ componentName: parentComponentName, params: { orgAlias, userId } }));
      }}
      usTaxFilings={userCard?.usTaxFilings}
    />
  );
};

ProfileExtraTaxWidgets.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
  userCard: PropTypes.object.isRequired,
};

export default ProfileExtraTaxWidgets;
