import { pick } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EnterTOTP from 'accounts/assets/js/components/two-factor-auth/EnterTOTP.jsx';
import ScanQR from 'accounts/assets/js/components/two-factor-auth/ScanQR.jsx';
import { completeStepDS, fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import { enable2FAApiUrl, getEnable2FASettingsApiUrl, orgGetStartedUrl } from 'accounts/urls';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const Enable2FAView = () => {
  const activeOrg = useSelector(selectActiveOrg);
  const history = useHistory();
  const dispatch = useDispatch();

  const getStartedUrl = orgGetStartedUrl(activeOrg.alias);

  const { isLoading, hasLoaded, item } = fetchDataHook({
    componentName: Enable2FAView.GetComponentName(), url: getEnable2FASettingsApiUrl(),
  });

  return (
    <>
      <ContentHeader
        backUrl={getStartedUrl}
        breadcrumbs={[{ title: 'Enable two-factor authentication', url: null }]}
      />
      <div className="page page--classification-questionnaire">
        <div className="container">
          {(isLoading || !hasLoaded) && <SettingsPageSkeleton />}
          {!isLoading && hasLoaded && (
            <Wizard
              className="enable-2fa-wizard"
              onCancel={() => history.push(getStartedUrl)}
              onSubmit={async formValues => {
                try {
                  await axios.post(enable2FAApiUrl(), {
                    ...pick(item, 'recoveryCodes', 'totpSecret'),
                    totp: formValues.totp,
                  });
                  await dispatch(fetchSetupStepsDS());
                  await completeStepDS(dispatch, activeOrg.alias, 'TwoFactorAuthStep', { history });
                  return null;
                } catch (err) {
                  return parseAxiosErrorForFinalForm(err);
                }
              }}
              submitBtnTitle="Verify"
              vertical
            >
              <Wizard.Page
                beforePageChange={async formValues => {
                  if (
                    !Array.isArray(formValues.downloadedRecoveryCodes)
                    || formValues.downloadedRecoveryCodes.length === 0
                    || !formValues.downloadedRecoveryCodes[0].value
                  ) {
                    return {
                      downloadedRecoveryCodes: 'Please confirm that you have downloaded your '
                        + 'recovery codes',
                    };
                  }
                  return null;
                }}
                component={ScanQR}
                componentProps={{
                  email: item.email, recoveryCodes: item.recoveryCodes, secret: item.totpSecret,
                }}
                nextBtnTitle="Continue"
                title="Scan QR code"
              />
              <Wizard.Page
                beforePageChange={async formValues => {
                  if (!/^[0-9]{6}$/.test(formValues.totp)) {
                    return { totp: 'Invalid code' };
                  }
                  return null;
                }}
                component={EnterTOTP}
                nextBtnTitle="Verify"
                title="Verify authentication code"
              />
            </Wizard>
          )}
        </div>
      </div>
    </>
  );
};

Enable2FAView.GetComponentName = () => 'Enable2FAView';

export default Enable2FAView;
