import PropTypes from 'prop-types';
import React from 'react';

import ChangeOnboardingFormModal from 'interviews/assets/js/ChangeOnboardingFormModal.jsx';
import ResetOnboardingFormModal from 'interviews/assets/js/ResetOnboardingFormModal.jsx';

const OnboardingFormSubmissionModals = ({ onSuccess }) => (
  <>
    <ChangeOnboardingFormModal onSuccess={onSuccess} />
    <ResetOnboardingFormModal onSuccess={onSuccess} />
  </>
);

OnboardingFormSubmissionModals.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default OnboardingFormSubmissionModals;
