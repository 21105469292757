import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';

import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { getViewState } from 'core/assets/js/ducks/view';
import { interviewSpec } from 'interviews/assets/js/lib/objectSpecs';
import { interviewListApiUrl } from 'interviews/urls';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { fetchManagerOwns } from 'people/assets/js/ducks/managers';
import { demoteToProviderDS } from 'people/assets/js/ducks/people';
import { projectSpec, projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectViewTaskUrl, projectViewUrl } from 'projects/urls';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

export const getModalId = userId => `demote-manager-id-${userId}`;

export const interviewListKey = componentName => `${componentName}-interviews-list`;

const ConvertToProviderModal = ({
  activeOrg: {
    alias: orgAlias,
    onboarding_forms_are_mandatory: onboardingFormsAreMandatory,
  },
  dispatch,
  interviews,
  isEmployee,
  isModalOpen,
  listViewComponentName,
  projects,
  tasks,
  userId,
  userName,
}) => {
  const [loading, setLoading] = useState(false);

  const closeModal = () => dispatch(modalCloseAC());

  const onSubmit = async formValues => {
    if (onboardingFormsAreMandatory && !formValues.onboardingFormId) {
      toastr.error('Oh Snap!', 'Please select an onboarding form');
      return;
    }
    setLoading(true);
    try {
      await dispatch(demoteToProviderDS({
        componentName: listViewComponentName,
        onboardingFormId: formValues.onboardingFormId || null,
        orgAlias,
        userId,
      }));
      toastr.success('Well Done!', `${userName} is now a provider.`);
      setLoading(false);
      closeModal();
    } catch (error) {
      const message = error.response?.data?._error || error.message;
      toastr.error('Oh Snap!', message);
      setLoading(false);
    }
  };

  const onboardingFormOptions = interviews.map(i => ({ text: i.name, value: i.id }));
  const onboardingFormOptionsLength = onboardingFormOptions.length;
  const hasOnboardingFormOptions = onboardingFormOptionsLength > 0;

  const interviewListStoreKey = interviewListKey(listViewComponentName);

  const ownsProjects = projects.length > 0;
  const ownsTasks = tasks.length > 0;
  const ownsProjectsOrTasks = ownsProjects || ownsTasks;

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form>
          <ModalConfirm
            confirmButtonDisabled={(
              loading || (onboardingFormsAreMandatory && !hasOnboardingFormOptions)
            )}
            confirmLabel="Convert"
            closeOnConfirm={false}
            data-testid="manager-convert-modal"
            heading={`Convert ${userName} to be a provider`}
            onConfirm={handleSubmit}
            onClose={closeModal}
            open={isModalOpen}
            showConfirmButton={hasOnboardingFormOptions && !ownsProjectsOrTasks}
            size={hasOnboardingFormOptions ? MODAL_SIZES.LARGE : MODAL_SIZES.SMALL}
          >
            <TDApiConnected
              duck="list"
              fetchData={({ authedAxios, url }) => Promise.all([
                dispatch(fetchListDS({
                  authedAxios,
                  componentName: interviewListStoreKey,
                  url: interviewListApiUrl(orgAlias, url),
                })),
                dispatch(fetchManagerOwns({
                  authedAxios,
                  componentName: ConvertToProviderModal.GetComponentName({ params: { userId } }),
                  orgAlias,
                  userId,
                })),
              ])}
              storeKey={interviewListStoreKey}
            >
              {ownsProjectsOrTasks && (
                <>
                  <TDSystemMessage
                    data-testid="convert-manager-modal-cannot-owner-message"
                    title="Cannot convert manager"
                    type={BS_STYLE.DANGER}
                  >
                    <p>
                      This manager cannot be converted to be a provider, because they are
                      currently the owner of projects and/or tasks.
                    </p>
                  </TDSystemMessage>
                  {ownsProjects && (
                    <Table
                      containerClass="mt-5"
                      cols={[{ key: 'item', label: 'Projects' }]}
                      items={projects.map(project => ({
                        item: (
                          <Link
                            className="text-dark"
                            to={projectViewUrl(orgAlias, project.id)}
                          >
                            {project.title}
                          </Link>
                        ),
                      }))}
                      tableProps={{ striped: true }}
                    />
                  )}

                  {ownsTasks && (
                    <Table
                      containerClass="mt-5"
                      cols={[{ key: 'item', label: 'Tasks' }]}
                      items={tasks.map(task => ({
                        item: (
                          <Link
                            className="text-dark"
                            to={projectViewTaskUrl(orgAlias, task.projectId, task.id)}
                          >
                            {task.title}
                          </Link>
                        ),
                      }))}
                      tableProps={{ striped: true }}
                    />
                  )}
                </>
              )}
              {!ownsProjectsOrTasks && (
                <>
                  {(hasOnboardingFormOptions || !onboardingFormsAreMandatory) && (
                    <>
                      <p>
                        Are you sure you want to convert
                        {` ${userName} `}
                        to be a provider in this organization?
                      </p>
                      <p>
                        Any roles that the user has been added to will be removed from their
                        profile with this process.
                      </p>
                      {hasOnboardingFormOptions && !isEmployee && (
                        <>
                          <p>
                            A new onboarding submission will be created for them and they will be
                            emailed a link to complete it, before having access to the
                            organization again.
                          </p>
                          <p>
                            {onboardingFormsAreMandatory && 'Please '}
                            {!onboardingFormsAreMandatory && 'Optionally '}
                            select the onboarding form they should be sent:
                          </p>
                          <SelectField
                            data-testid="onboarding-form-selector"
                            label="Onboarding form"
                            name="onboardingFormId"
                            optionsMapping={onboardingFormOptions}
                            required={onboardingFormsAreMandatory}
                          />
                        </>
                      )}
                      <p>You can convert them to be a manager again afterwards, if required.</p>
                    </>
                  )}
                  {!hasOnboardingFormOptions && onboardingFormsAreMandatory && (
                    <TDSystemMessage
                      data-testid="convert-manager-modal-cannot-message"
                      title="Cannot convert manager"
                      type={BS_STYLE.DANGER}
                    >
                      <p>
                        Your organization does not have any onboarding forms, so you cannot
                        convert this manager to be a provider.
                      </p>
                    </TDSystemMessage>
                  )}
                </>
              )}
            </TDApiConnected>
          </ModalConfirm>
        </form>
      )}
    />
  );
};

ConvertToProviderModal.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  interviews: PropTypes.arrayOf(interviewSpec),
  isModalOpen: PropTypes.bool.isRequired,
  isEmployee: PropTypes.bool.isRequired,
  listViewComponentName: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(projectSpec),
  tasks: PropTypes.arrayOf(projectTaskSpec),
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
};

ConvertToProviderModal.defaultProps = {
  interviews: [],
  projects: [],
  tasks: [],
};

ConvertToProviderModal.GetComponentName = ({ params } = {}) => (
  `ConvertToProviderModal${params?.userId ? `-${params.userId}` : ''}`
);

const mapStateToProps = (state, props) => ({
  activeOrg: selectActiveOrg(state),
  interviews: getListState(state, interviewListKey(props.listViewComponentName)).items,
  isModalOpen: getIsModalOpen(state, getModalId(props.userId)),
  ...pick(
    getViewState(
      state, ConvertToProviderModal.GetComponentName({ params: pick(props, 'userId') }),
    ).item,
    'projects',
    'tasks',
  ),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const ConvertToProviderModalConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(ConvertToProviderModal);

export default ConvertToProviderModalConnected;
