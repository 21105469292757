import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import React from 'react';

import FinancialChartWrapper from 'analytics/assets/js/components/FinancialChartWrapper.jsx';

const FinancialChartCountsList = ({ entries, isLoading, title, total: totalIn }) => {
  const sortedEntries = orderBy(entries, 'count', 'desc');
  let total = null;
  if (!!totalIn || totalIn === 0) {
    total = totalIn;
  } else if (Array.isArray(entries)) {
    total = entries.reduce((acc, e) => acc + e.count, 0);
  }
  const largest = sortedEntries.length > 0 ? sortedEntries[0].count : null;

  return (
    <FinancialChartWrapper
      footer={(!!total || total === 0) && (
        <div className="d-flex align-items-center justify-content-between font-weight-bold">
          <b>Total</b>
          <div>{total}</div>
        </div>
      )}
      isLoading={isLoading}
      title={title}
    >
      <div className="d-flex flex-column financial-chart-counts-list">
        {sortedEntries.map((e, index) => (
          <div
            className={(
              `entry d-flex align-items-center justify-content-between${index > 0 ? ' mt-3' : ''}`
            )}
            key={index}
          >
            <div className="w-50 h-100 text-truncate">
              {e.text}
            </div>
            <div className="w-50 h-100 progress-wrapper">
              <div
                className="progress-bar h-100"
                style={{ width: `${(e.count / largest) * 100}%` }}
              />
              <div className="count h-100 pl-3 font-weight-bold">{e.formattedCount || e.count}</div>
            </div>
          </div>
        ))}
      </div>
    </FinancialChartWrapper>
  );
};

FinancialChartCountsList.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number.isRequired,
    formattedCount: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  total: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]),
};

FinancialChartCountsList.defaultProps = {
  total: null,
};

export default FinancialChartCountsList;
