/* eslint import/prefer-default-export:off */
/* eslint react/no-array-index-key:off */
/* eslint react/no-danger:off */
/* globals FileReader */
import Big from 'big.js';
import commonPassword from 'common-password';
import { FORM_ERROR } from 'final-form';
import {
  attempt,
  flatten,
  fromPairs,
  isArray,
  isEmpty,
  isEqual,
  isError,
  isFinite,
  isInteger,
  isNil,
  isNumber,
  isObject,
  isString,
  isUndefined,
  map,
  mapValues,
  omit,
  pick,
  pickBy,
  reduce,
  transform,
  without,
  zip,
} from 'lodash';
import mime from 'mime-types';
import moment from 'moment';
import queryString from 'query-string';

import { USER_ADMIN_ROLES_LABEL } from 'admin/assets/js/constants';
import Logger from 'core/assets/js/lib/Logger';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import {
  DOCUMENT_COOKIE,
  DOCUMENT_GET_ELEMENT_BY_ID,
  DOCUMENT_SET_TITLE,
  GET_BROWSER_LOCALE,
  HAS_DOCUMENT,
  IS_PRODUCTION,
  TIMETRAVEL,
  WINDOW_ATOB,
  WINDOW_REDIRECT,
} from 'core/assets/js/config/settings';
import {
  API_DATE_FORMAT,
  DATE_FORMAT_DEFAULT,
  DATETIME_FORMAT_DEFAULT,
  DEFAULT_METATAGS,
  PASSWORD_MIN_CHARS,
  PASSWORD_STRENGTH_LEVELS,
  PASSWORD_STRENGTH_REWARD_POINTS,
  USER_STATUS_LABEL,
} from 'core/assets/js/constants';
import { ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';
import { SUBSCRIPTION_PLANS, SUBSCRIPTION_PLAN_LABELS } from 'finance/assets/js/constants';

const USER_ADMIN_ROLE_VALUES = Object.values(USER_ADMIN_ROLES_LABEL);

const utilsLogger = new Logger('utils');

/**
 * Counts how many words in a string
 * @param {string} str
 * @returns {number}
 */
export const countWords = str => str && str.split(' ').length;

/**
 * Truncates a string in a certain words length if necessary
 * @param {string} str
 * @param {number} words
 * @returns {string}
 */
export const truncate = (str, words) => {
  if (countWords(str) > words) {
    return `${str.split(' ').splice(0, words).join(' ')}...`;
  }

  return str;
};

export function formatDate(dt, format = DATE_FORMAT_DEFAULT, useUTC = false) {
  if (!dt) {
    return null;
  }

  if (isString(dt)) {
    let date;
    if (useUTC) {
      date = moment.utc(dt).local();
    } else {
      date = moment(dt, moment.ISO_8601, true);
    }
    if (date.isValid()) {
      return date.format(format);
    }
    return dt;
  }

  return moment(dt).format(format);
}

/**
 * Parse a supplied date and return it as a moment.js instance
 *
 * @param {Any} dt
 * @param {String} [format]
 * @param {Boolean} [checkIsValid]
 * @returns {Object}
 */
export function parseDate(dt, format = DATE_FORMAT_DEFAULT, checkIsValid = true) {
  if (!dt) {
    return null;
  }
  if (isString(dt)) {
    const date = moment(dt, format, true);
    if (!checkIsValid || date.isValid()) {
      return date;
    }
    throw new Error('cannot parse date');
  }
  return moment(dt);
}

export const parseDateWithOptions = (dt, {
  format = DATE_FORMAT_DEFAULT,
  checkIsValid = true,
  retryWithUnknownFormat = false,
}) => {
  try {
    return parseDate(dt, format, checkIsValid);
  } catch (e) {
    if (retryWithUnknownFormat) {
      return parseDate(dt, null, checkIsValid);
    }
    throw e;
  }
};

export const getDateTimeDiff = (startTime, endTime) => (
  parseDate(endTime, DATETIME_FORMAT_DEFAULT, false)
    .diff(parseDate(startTime, DATETIME_FORMAT_DEFAULT, false))
);

/**
 * Render seconds as hours:minutes:seconds
 *
 * @param {Number} durationSeconds
 * @returns {String}
 */
export const renderSecondsAsDuration = durationSeconds => {
  const hours = Math.floor(durationSeconds / (60 * 60));
  const hoursInSeconds = hours * 60 * 60;
  const minutes = Math.floor((durationSeconds - hoursInSeconds) / 60);
  const seconds = durationSeconds - (minutes * 60) - hoursInSeconds;

  return [hours, minutes, seconds].map(num => `${num < 10 ? '0' : ''}${num}`).join(':');
};

export function renderDuration(dtStart, dtEnd, emptyText) {
  if (!dtStart || !dtEnd) {
    return emptyText || '-';
  }

  const durationMS = getDateTimeDiff(dtStart, dtEnd);
  if (durationMS <= 0 || Number.isNaN(durationMS)) {
    return emptyText || '-';
  }

  return renderSecondsAsDuration(Math.floor(durationMS / 1000));
}

export function calculateDuration(
  dtStart, dtEnd, { expectedFormat = DATETIME_FORMAT_DEFAULT, format = 'asSeconds' } = {},
) {
  if (!dtStart || !dtEnd) {
    return 0;
  }

  const duration = moment.duration(
    parseDate(dtEnd, expectedFormat, false)
      .diff(parseDate(dtStart, expectedFormat, false)),
  );

  return duration[format]();
}

export function renderPeriod(period) {
  const start = period.period_start || period.start;
  const end = period.period_end || period.end;
  return start && end ? `${formatDate(start)} - ${formatDate(end)}` : null;
}

/**
 * Returns whether a value is a float number
 *
 * @param {Number} value the value to check
 * @returns {Boolean}
 */
export const isFloat = value => (
  isNumber(value) && isFinite(value) && !isInteger(value)
);

/**
 * Returns a sanitized email address used on pusher channel names
 *
 * @param {String} email the email to sanitize
 * @returns {String}
 */
export const sanitizePusherChannelName = email => email
  .replace(/[&\\#+()$~%'":*?<>{}]/g, '_');

/**
 * Converts a float value from string to a valid one
 *
 * @param {Number|String} initial the initial value
 * @param {Number} fallback the value to use when the number is invalid
 * @param {Number} precision the precision to maintain
 * @return {Float}
 */
export const toFloat = (initial, fallback = 0) => {
  const value = String(initial).replace(',', '.');
  const num = parseFloat(value);

  // Number is an integer, we're good to go
  if (Number.isSafeInteger(num)) {
    return num;
  }

  const isOverflown = String(num).match(/[^0-9.]/) !== null;
  if (Number.isNaN(num) || isOverflown) {
    return fallback;
  }

  // number is infinite
  if (!Number.isFinite(num) || num >= Number.MAX_VALUE || num <= Number.MIN_SAFE_INTEGER) {
    return fallback;
  }

  return num;
};

export function updateDocumentTitle(component) {
  // Check if we are in browser
  if (!HAS_DOCUMENT) {
    return;
  }

  // Get Meta tags from component; If exist, set page title.
  if (typeof component !== 'undefined' && typeof component.getMetaTags === 'function') {
    const metaTags = component.getMetaTags();
    // If title is set, append ' | TalentDesk.io'.
    if (!isEmpty(metaTags.title)) {
      DOCUMENT_SET_TITLE(`${metaTags.title} | TalentDesk.io`);
    }
  } else { // Otherwise set default title.
    DOCUMENT_SET_TITLE(DEFAULT_METATAGS.title);
  }
}

/**
 * Process a google maps address object to extract specific information
 *
 * - country
 * - city
 * - post code
 * - street
 * - street number
 *
 * @param {object} address
 * @param {string} componentKey
 * @returns {null}
 */
export const extractAddressComponent = (address, componentKey) => {
  const validAddressKeys = ['place_id'];
  const validAddressComponents = {
    country_code: [{ key: 'country', variation: 'short_name' }],
    country: [{ key: 'country', variation: 'long_name' }],
    city: [
      { key: 'postal_town', variation: 'short_name' },
      { key: 'locality', variation: 'short_name' },
      { key: 'administrative_area_level_1', variation: 'short_name' },
    ],
    postal_code: [{ key: 'postal_code', variation: 'short_name' }],
    street: [
      { key: 'route', variation: 'short_name' },
    ],
    street_long: [
      { key: 'route', variation: 'long_name' },
    ],
    address_line_1: [
      { key: 'address_line_1', variation: 'short_name' },
    ],
    address_line_2: [
      { key: 'address_line_2', variation: 'short_name' },
    ],
    street_number: [{ key: 'street_number', variation: 'short_name' }],
    state: [{ key: 'administrative_area_level_1', variation: 'short_name' }],
    region: [{ key: 'administrative_area_level_3', variation: 'short_name' }],
  };

  if (
    !Object.keys(validAddressComponents).includes(componentKey)
    && !validAddressKeys.includes(componentKey)
  ) {
    return null;
  }

  if (address && address.gmaps) {
    if (validAddressKeys.includes(componentKey)) {
      return address.gmaps[componentKey] || null;
    }

    // address_components
    let textVariation = 'short_name';
    const requestedAddressComponent = validAddressComponents[componentKey];
    const component = (address.gmaps.address_components || []).find(addressComponent => (
      requestedAddressComponent.some((requestedComponent) => {
        const match = addressComponent.types.includes(requestedComponent.key);
        if (match) {
          textVariation = requestedComponent.variation;
        }
        return match;
      })
    ));

    return component
      ? component[textVariation]
      : null;
  }
  return null;
};

export const extractStreet = (address) => {
  const streetName = extractAddressComponent(address, 'street');
  const streetNo = extractAddressComponent(address, 'street_number');
  const street = [];
  if (streetNo) {
    street.push(streetNo);
  }
  if (streetName) {
    street.push(streetName);
  }
  if (isEmpty(street)) {
    return null;
  }
  return street.join(' ');
};

export const extractAllAddressComponents = (address) => {
  let street = null;
  const streetName = extractAddressComponent(address, 'street');
  const streetNo = extractAddressComponent(address, 'street_number');
  const streetComponents = [];
  if (streetNo) {
    streetComponents.push(streetNo);
  }
  if (streetName) {
    streetComponents.push(streetName);
  }
  if (!isEmpty(streetComponents)) {
    street = streetComponents.join(' ');
  }
  return {
    city: extractAddressComponent(address, 'city'),
    country: extractAddressComponent(address, 'country'),
    country_code: extractAddressComponent(address, 'country_code'),
    postal_code: extractAddressComponent(address, 'postal_code'),
    street,
  };
};

/**
 * Parses http headers and extracts pagination information. It reads the headers "Content-Range",
 * "X-Total-Count" and "Link". "X-Total-Count" contains the number of total elements that would
 * be returned if no pagination was present. "Content-Range" is a set of ranges, in our case
 * an items range ("items 1-10/100") and a page range ("2-2/10"). Finally the "Link" header
 * contains a set of links, like previous and next pages in a standard link header format,
 * '<[url]>; rel="next"'
 *
 * @param headers
 * @param instances
 * @returns { count<int>, page<int>, pages<int>, total<int>, next<href>, prev<href> }
 */
export function extractPaginationFromHeaders(headers) {
  const total = parseInt(headers['x-total-count'], 10);
  const ranges = headers['content-range'] ? headers['content-range'].split(',') : [];
  const links = headers.link ? headers.link.split(',') : [];
  let count = 0;
  let pages = 0;
  let page = 0;
  let next = null;
  let prev = null;
  let start = 0;
  let end = total;
  ranges.forEach((r) => {
    // a range looks like "items 1-10/100" or "pages 1-1/10"
    const match = r.match(/^\s*(.*) (\d+)-(\d+)\/(\d+)/);
    if (!match) {
      return;
    }
    const [, type, , , size] = match;
    start = +match[2];
    end = +match[3];
    if (type === 'pages') {
      pages = parseInt(size, 10);
      page = parseInt(start, 10);
    }
    if (type === 'items') {
      count = (parseInt(end, 10) - parseInt(start, 10)) + 1;
    }
  });
  links.forEach((l) => {
    // a link looks like '<page=2>; rel="next"'
    const match = l.match(/<(.*)>; rel="(.*)"/);
    if (!match) {
      return;
    }
    const [, link, rel] = match;
    if (rel === 'next') {
      next = link;
    }
    if (rel === 'prev') {
      prev = link;
    }
  });
  return { count, page, pages, total, next, prev, start, end };
}

/**
 * Parses http headers and extracts search information. It reads the headers "X-TD-Search"
 *
 * @param headers
 * @returns { isActive<bool> }
 */
export function extractSearchFromHeaders(headers) {
  const isActive = headers['x-td-search'] === 'true';
  return { isActive };
}

/**
 * Weak and fast checksum function for quickly determining if anything has changed in the
 * values of the field
 *
 * It accepts an array of strings or a single string and returns a 32bit integer checksum
 */
export function fastChecksum(value) {
  const s = Array.isArray(value) ? value.join('#') : value;
  let chk = 0x12345678;
  let i;
  const len = s.length;
  for (i = 0; i < len; i += 1) {
    chk += (s.charCodeAt(i) * (i + 1));
  }

  return (chk & 0xffffffff).toString(16); // eslint-disable-line no-bitwise
}

export function validateLength(value, maxLength) {
  if (value && value.length > maxLength) {
    return `Length exceeds maximum length (${maxLength})`;
  }

  return undefined;
}

export function validateLegalName(value, profile) {
  const { firstName, middleName, lastName } = profile;
  const trimmedValue = value?.trim().toLowerCase() || '';
  const isValid = (
    (!middleName && trimmedValue === `${firstName.toLowerCase()} ${lastName.toLowerCase()}`)
    || (
      middleName
      && trimmedValue === `${firstName.toLowerCase()} ${middleName.toLowerCase()} ${lastName.toLowerCase()}`
    )
  );

  if (!isValid) {
    return 'The "Full Legal Name" does not match your profile name';
  }
  return null;
}

/**
 * Calculates the strength of a given password.
 *
 * It accepts a password string.
 * @returns { msg<string>, feedback<object>, score<number> }
 */
export function calculatePasswordStrength(password) {
  let score = 0;
  let msg = 'Your password should consist of a lower and upper case letter, a number and a special character';
  const msgkeys = [];

  if ((/[^a-zA-Z0-9]+/.test(password))) {
    score += PASSWORD_STRENGTH_REWARD_POINTS.SPECIAL_CHARACTER;
  } else {
    msgkeys.push('a special character');
  }

  if ((/[0-9]/.test(password))) {
    score += PASSWORD_STRENGTH_REWARD_POINTS.NUMBER;
  } else {
    msgkeys.push('a number');
  }

  if ((/[a-z]/.test(password))) {
    score += PASSWORD_STRENGTH_REWARD_POINTS.LOWER_CASE;
  } else {
    msgkeys.push('lower');
  }

  if ((/[A-Z]/.test(password))) {
    score += PASSWORD_STRENGTH_REWARD_POINTS.UPPER_CASE;
  } else {
    msgkeys.push('upper case');
  }

  if (password.length >= PASSWORD_MIN_CHARS) {
    score += PASSWORD_STRENGTH_REWARD_POINTS.MINIMUM_CHARS;
  } else {
    score = 0;
    msg = `Your password should be at least ${PASSWORD_MIN_CHARS} characters long`;
  }

  // Check if password apears in a list of top 10K common passwords using commonPassword Lib.
  if (password !== '' && !commonPassword(password)) {
    score += PASSWORD_STRENGTH_REWARD_POINTS.COMMON_PASSWORD;
  } else {
    score = 0;
    msg = 'Your password is too easy to guess';
  }

  // Make missing points bold on information text.
  msgkeys.forEach((k) => {
    msg = msg.replace(k, `<strong>${k}</strong>`);
  });

  let feedback = {
    label: PASSWORD_STRENGTH_LEVELS.WEAK.LABEL,
    icon: PASSWORD_STRENGTH_LEVELS.WEAK.ICON,
    className: PASSWORD_STRENGTH_LEVELS.WEAK.CLASSNAME,
    showTextMessage: PASSWORD_STRENGTH_LEVELS.WEAK.SHOW_TEXT,
  };

  Object.keys(PASSWORD_STRENGTH_LEVELS).forEach((levelKey) => {
    const level = PASSWORD_STRENGTH_LEVELS[levelKey];
    if (score >= level.THRESHOLD) {
      feedback = {
        label: level.LABEL,
        icon: level.ICON,
        className: level.CLASSNAME,
        showTextMessage: level.SHOW_TEXT,
      };
    }
  });

  return { msg, feedback, score };
}

/**
 * Finds a country name using IsoCountries consant.
 * Supports backwards compadibility for 'uk' country code.
 *
 * @param countryCode
 * @returns {String}
 */
export const getCountryName = (countryCode) => {
  /* Need to hardcode this check for backwards compatibility.
     (Frozen invoices before the country_code change of talendDesk org set from uk to GB)
  */
  if (countryCode.toLowerCase() === 'uk') {
    return countryNames.GB;
  }
  return countryNames[countryCode];
};

/**
 * Simple word pluralization
 *
 * @example Project -> projects
 * @param {String} word
 * @param {Number} n
 * @returns String
 */
export const pluralize = (word, n = 0) => {
  if (word === 'country') {
    return n === 1 ? 'country' : 'countries';
  }
  const suffix = n !== 1 ? 's' : '';
  return `${word}${suffix}`;
};


/**
 * Validates a password.
 *
 * It accepts a password string.
 * @returns validationError<string> or undefined
 */
export const validatePassword = (password) => {
  if (typeof password === 'undefined' || password === '') {
    return 'Please provide a password';
  }
  if (password.length > 64) {
    return 'Passwords cannot be longer than 64 characters';
  }
  const { score } = calculatePasswordStrength(password);
  if (score < PASSWORD_STRENGTH_LEVELS.OK.THRESHOLD) {
    return 'Password not strong enough';
  }

  return undefined;
};

/**
 * Moves an element inside an array to a new index. It produces a new array
 * with the element moved, so it can be used along immutable operations
 */
export const arrayMove = (arr, from, to) => {
  const clone = [...arr];
  Array.prototype.splice.call(clone, to, 0,
    Array.prototype.splice.call(clone, from, 1)[0],
  );
  return clone;
};

export const isAbsolute = (url) => {
  const regexPat = new RegExp('http(s)?:\\/\\/.+');
  return regexPat.test(url);
};

export const isExternalUrl = (url) => {
  if (!url) {
    return false;
  }
  if (!isAbsolute(url)) {
    return false;
  }
  if (/https?:\/\/[^/]*\/api\/.*/.test(url)) {
    // this is an API url, for downloading files,
    // it is considered an external url for the frontend app
    return true;
  }
  const regexPat = new RegExp('http[s]?:\\/\\/.+[.]{1}[a-z]{2,}');
  if (regexPat.test(url)) {
    // a relative url can be extracted from this absolute url, so it is internal
    return false;
  }
  return true;
};

export const makeRelative = (url) => {
  if (!isAbsolute(url)) {
    return url;
  }

  if (isExternalUrl(url)) {
    return url;
  }

  const splitUrl = url.replace(/^http[s]?:\/\//, '').split('/');
  splitUrl.shift();
  return `/${splitUrl.join('/')}`;
};

export const base64ToUnicode = str => decodeURIComponent(
  Array.prototype.map.call(WINDOW_ATOB(str), (c) => {
    const hexCode = `00${c.charCodeAt(0).toString(16)}`.slice(-2);
    return `%${hexCode}`;
  }).join(''));

export const objectFromBase64 = val => JSON.parse(Buffer.from(val, 'base64').toString('utf8'));

export const objectToBase64 = val => Buffer.from(JSON.stringify(val), 'utf8').toString('base64');

export function locationSetParam(location, paramKey, paramValue) {
  const query = queryString.parse(location.search);
  return {
    path: location.pathname,
    search: queryString.stringify({
      ...query,
      [paramKey]: paramValue,
    }),
  };
}

export function locationUnsetParam(location, paramKey) {
  return locationSetParam(location, paramKey, undefined);
}

export function locationGetParam(location, paramKey) {
  const query = queryString.parse(location.search);
  return query[paramKey];
}


export function makeId(numChars = 5) {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const text = new Array(numChars).fill(0).map(() => (
    possible.charAt(Math.floor(Math.random() * possible.length))
  ));

  return text;
}

/**
 * Validates an object for the presence of a specific set of keys
 *
 * @param {Object}
 * @param {Array} of {String} the keys expected to be found in the object
 * @throws if one of the keys is not found
 */
export const validateObject = (obj, requiredKeys = []) => {
  requiredKeys.forEach((key) => {
    if (typeof obj[key] === 'undefined') {
      throw new Error(
        `The "${key}" key is missing. Expected an object with keys (${requiredKeys.join(', ')}), but found <${Object.keys(obj).join(', ')}>.`,
      );
    }
  });

  return obj;
};

/**
 * Validates a filestack file object
 *
 */
export const validateFilestackObject = (fileInfo) => {
  let obj = fileInfo;

  try {
    if (typeof fileInfo === 'string') {
      obj = JSON.parse(fileInfo);
    }
  } catch (e) {
    throw new Error(`File is not in the correct format (${e})`);
  }

  if (!Array.isArray(obj)) {
    throw new Error('File is not in the correct format');
  }

  obj.forEach((file) => {
    // required properties
    const req = ['handle', 'mimetype', 'originalPath', 'size', 'source', 'url'];
    const missing = req.map(p => (
      ({ property: p, exists: Object.hasOwnProperty.call(file, p) })
    )).filter(p => !p.exists);

    if (missing.length > 0) {
      throw new Error(`File is not in the correct format (missing '${missing[0].property}')`);
    }
  });

  return obj;
};

/**
 * Determine if a menu item is active
 *
 * A NavLink is considered active if:
 * - there is an exact match in the pathname
 * - at least one section defined by NavLink is part of the active pathname
 */
export function isMenuItemActive(match, location, sections, regEx) {
  // Check if is in an active section
  let isActive = sections && sections.some(section => location.pathname.includes(section));

  if (regEx && regEx.some(re => location.pathname.match(re))) {
    isActive = true;
  }
  // Check if is exact match
  if (match && match.isExact) {
    isActive = true;
  }

  return isActive;
}

/**
 * Encode a query string value and prepare the key/value pair that is to be appended to a URI
 *
 * @param key
 * @param value
 * @returns {*}
 */
export function prepareQueryStringComponent(key, value) {
  if (!key || isNil(value)) {
    return new Error(`Invalid key/value pair (${key}/${value})`);
  }
  const v = !Array.isArray(value)
    ? [value]
    : value;

  return `${key}=${encodeURIComponent(v)}`;
}

/**
 * Packs an object with data into a query string
 *
 * @see prepareQueryStringComponent
 * @param qsParams
 * @returns {string}
 */
export function prepareQueryString(qsParams) {
  const qsPairs = [];
  let qs = '';

  if (!qsParams || isEmpty(qsParams)) {
    return qs;
  }

  Object.keys(qsParams).forEach((paramName) => {
    const qsComponent = prepareQueryStringComponent(paramName, qsParams[paramName]);
    if (!isError(qsComponent)) {
      qsPairs.push(qsComponent);
    }
  });

  if (qsPairs.length) {
    qs = `?${qsPairs.join('&')}`;
  }

  return qs;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function diff(object, base) {
  function changes(obj1, obj2) {
    return transform(obj1, (result, value, key) => {
      if (!isEqual(value, obj2[key])) {
        Object.assign(result, {
          [key]: (isObject(value) && isObject(obj2[key])) ? changes(value, obj2[key]) : value,
        });
      }
    });
  }
  return changes(object, base);
}

export function cartesianProduct(...args) {
  const arrays = args.map(a => (Array.isArray(a) ? a : [a]));
  return reduce(arrays, (a, b) => flatten(map(a, x => map(b, y => x.concat([y]),
  ),
  ), false), [[]]);
}

export const browserRedirect = (redirectTo) => {
  WINDOW_REDIRECT(redirectTo);
};

/**
 * Decide whether the main validation error should be displayed
 *
 * When a validation error occurs the error object contains:
 * - errors related to specific fields - these errors can be accessed using the field name
 * - a main error message - this can be accessed using the _error key
 *
 * The main error should be shown only when there are no field errors
 *
 * @param validationErrorObj
 * @returns {boolean}
 */
export const shouldShowMainValidationError = (validationErrorObj) => {
  const requiredKeys = ['_meta', '_error'];
  return without(Object.keys(validationErrorObj), ...requiredKeys).length === 0;
};

export const printPropChanges = (nextProps, prevProps, { logger }) => {
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return null;
        }
        seen.add(value);
      }
      return value;
    };
  };
  const changed = Object.keys(nextProps).filter(k => !isEqual(nextProps[k], prevProps[k]));
  if (changed.length > 0) {
    logger.log('changed:', changed.join(', '));
    logger.log('DIFF', JSON.stringify(pick(nextProps, changed), getCircularReplacer()));
  } else {
    logger.log('no changes');
  }
};

export const nullable = (subRequirement) => {
  const check = (required, props, key, ...rest) => {
    if (props[key] === null) {
      return null;
    }
    const sub = required ? subRequirement.isRequired : subRequirement;
    return sub(props, key, ...rest);
  };
  const fn = check.bind(null, false);
  fn.isRequired = check.bind(null, true);
  return fn;
};

/**
 * Same as Promise.all(), but catches all errors, and passes
 * them to .catch((errors). Like Array.prototype.every() it fails if any
 * promise is rejected
 *
 * @param promises
 * @returns {Promise<Array>}
 */
export const waitAllPromises = (promises, { throwErrors = true } = {}) => new Promise(
  (resolve, reject) => {
    const caughtValidations = promises.map(v => v.catch(err => err));
    return Promise.all(caughtValidations).then((results) => {
      const errors = results.filter(result => result instanceof Error);
      if (throwErrors && errors.length > 0) {
        return reject(errors);
      }
      return resolve(results);
    });
  },
);

/**
 * Same as Promise.all(), but catches all errors, and passes
 * them to .catch((errors). Like Array.prototype.some() it fails if all
 * promises are rejected
 *
 * @param promises
 * @returns {Promise<Array>}
 */
export const waitAnyPromises = (promises, { throwErrors = true } = {}) => new Promise(
  (resolve, reject) => {
    const caughtValidations = promises.map(v => v.catch(err => err));
    return Promise.all(caughtValidations).then((results) => {
      const errors = results.filter(result => result instanceof Error);
      if (throwErrors && errors.length === promises.length) {
        return reject(errors);
      }
      const valid = results.filter(result => !(result instanceof Error));
      return resolve(valid);
    });
  },
);

/**
 * Lodash implementation of JSON.parse
 *
 * It's preferred due to its better error handling.
 *
 * @param str
 * @return The parsed JSON object or an Error object
 */
export function parseJson(str) {
  return attempt(JSON.parse.bind(null, str));
}

/**
 * Check if it's a valid amount
 *
 * @param amount
 * @returns {boolean}
 */
export function isAmount(amount) {
  try {
    return !!amount && !!Big(amount);
  } catch (err) {
    return false;
  }
}

/**
 * Recursive function to convert numbers to letters
 *
 * It's one-based, so 1 is A, 26 is Z, 27 is AA etc
 *
 * @param num
 * @returns {*|string}
 */
export const numberToLetters = (num) => {
  const mod = num % 26;
  const pow = (num / 26) | 0; // eslint-disable-line no-bitwise
  const out = mod ? String.fromCharCode(64 + mod) : (pow - 1, 'Z');
  return pow ? numberToLetters(pow) + out : out;
};

/**
 * Parses query string parameters & attempts to convert to the matching data type
 *
 * @param {String|Object} query the query string to parse
 * @returns {Object}
 */
export const parseQueryStringParams = (query) => {
  const params = isString(query) ? queryString.parse(query) : query;

  const parsed = Object.keys(params).map((key) => {
    const value = params[key];

    if (isArray(value)) {
      return [
        key, value.map((val) => {
          const parsedVal = parseJson(val);
          return !isError(parsedVal) ? parsedVal : val;
        }),
      ];
    }

    const parsedValue = parseJson(value);
    if (!isError(parsedValue)) {
      return [key, parsedValue];
    }

    if (isString(value)) {
      // Comma separated value
      if (value.includes(',')) {
        return [key, value.split(',').map(k => k.trim())];
      }

      // Integer numeric values
      if (value.match(/^[0-9]+$/gi)) {
        return [key, parseInt(value, 10)];
      }

      // Float values
      if (value.match(/^[0-9.]+$/gi)) {
        return [key, parseFloat(value)];
      }

      // Empty values
      if (value === '') {
        return [key, null];
      }
    }

    if (isNil(value) || (isObject(value) && isEmpty(value))) {
      return [key, null];
    }

    return [key, value];
  }).filter(
    ([, value]) => !isNil(value),
  );

  return fromPairs(parsed);
};


/**
 * Stringifies an object to be used in the query string
 *
 * @param {Object} params the params to stringify
 * @returns {String}
 */
export const stringifyQueryParams = (params) => {
  const stringified = Object.keys(params).map((key) => {
    const value = params[key];

    if (isArray(value) && value.some(v => isObject(v))) {
      return [
        key, value.map(v => (isObject(v) ? JSON.stringify(v) : v)),
      ];
    }

    // Arrays, Strings & numbers should be handled by queryString.stringify as they are
    if (isArray(value) || isString(value) || isNumber(value)) {
      return [key, value];
    }

    // The rest (object, dates etc) should be JSON stringified before they're handled by queryString
    return [
      key, JSON.stringify(value),
    ];
  });

  if (isEmpty(stringified)) {
    return '';
  }

  return queryString.stringify(fromPairs(stringified));
};

export const removeUndefinedValues = (obj) => {
  const newObj = mapValues(obj, (val) => {
    if (isArray(val)) {
      return val;
    }
    if (isObject(val)) {
      return removeUndefinedValues(val);
    }
    return val;
  });
  return pickBy(newObj, value => !isUndefined(value));
};

export const removeNilValues = (obj) => {
  const newObj = mapValues(obj, (val) => {
    if (isArray(val)) {
      return val;
    }
    if (isObject(val)) {
      return removeNilValues(val);
    }
    return val;
  });
  return pickBy(newObj, value => !isNil(value));
};

export const checkIfWeWantToSetHubspotData = () => {
  if (!IS_PRODUCTION) {
    // We only want to send user data to hubspot on the live application
    return false;
  }
  // The rattlecage user's role is saved in the session cookie. If it is there, this application
  // user's session is an example of hijacking. So we don't want to send anything to Hubspot
  const isHijacking = typeof DOCUMENT_COOKIE === 'string'
    && USER_ADMIN_ROLE_VALUES.some(role => DOCUMENT_COOKIE.includes(` role=${role};`));
  if (isHijacking) {
    return false;
  }
  return true;
};

/**
 * Generates an object containing information that will be used by marketing team.
 *
 * @param  {Object} reduxState
 * @param  {Object} [options]
 * @param  {Boolean} [options.includeManagerAndContractorsCounts]
 * @return {Object}
 */
export const generateTalentDeskGlobalObject = (
  reduxState, { includeManagerAndContractorsCounts = false } = {},
) => {
  const obj = {
    user: {},
    organization: {},
  };

  if (!checkIfWeWantToSetHubspotData()) {
    return obj;
  }

  let activeUserCard;
  let profile;
  const userCard = reduxState?.organizations?.list?.items?.[0];

  if (reduxState?.auth?.profile) {
    profile = reduxState.auth.profile;
    obj.user = {
      country: profile.address_components?.country,
      createdAt: profile.createdAt,
      email: profile.email,
      firstName: profile.firstName,
      joinedAt: userCard?.joinedAt,
      lastName: profile.lastName,
      middleName: profile.middleName,
      role: userCard?.userRole?.title,
      userId: profile.userId,
    };

    obj.organization = {
      createdAt: userCard?.organization?.created_at,
      isTrial: userCard?.organization?.is_currently_on_trial,
      name: userCard?.organization?.name,
    };

    if (includeManagerAndContractorsCounts) {
      Object.assign(obj.organization, {
        numberOfContractors: reduxState?.list?.ContractorsCounterWidget?.pagination?.total,
        numberOfManagers: reduxState?.list?.ManagersWidget?.pagination?.total,
      });
    }
  }

  if (reduxState?.organizations?.active) {
    activeUserCard = reduxState.organizations.active;
    obj.user = {
      ...obj.user,
      isIncorporated: !!activeUserCard?.user?.hasCompanyRecord,
    };
  }
  return obj;
};

/**
 * Updates the object containing information that will be used by marketing team.
 *
 * @param  {Object} reduxState - Application's dashboard page state
 * @return {Object}
 */
export const updateTalentDeskGlobalObjectFromDashboardView = reduxState => (
  generateTalentDeskGlobalObject(reduxState, { includeManagerAndContractorsCounts: true })
);

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export const getObjectDiff = (obj1, obj2) => {
  const res = Object.keys(obj1).reduce((result, key) => {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return res;
};

/**
 * Sanitize JSON metadata/response of Filestack upload
 *
 * example fileMetadata input:
 * {
 *   "filename":"41de9fa40f58a8e2.jpg",
 *   "handle":"VvcBJ4zXRNONRq7fCuD2",
 *   "mimetype":"image/jpeg",
 *   "originalPath":"41de9fa40f58a8e2.jpg",
 *   "size":13068,
 *   "source":"local_file_system",
 *   "url":"https://cdn.filestackcontent.com/VvcBJ4zXRNONRq7fCuD2",
 *   "status":"Stored"
 * }
 *
 * @param  {Object} fileMetadata File metadata from Filestack
 * @return {Object|Boolean} false if invalid format
 */
export const sanitizeFilestackFile = (fileMetadata) => {
  const allowedAttrs = [
    'filename',
    'handle',
    'size',
    'mimetype',
    'originalPath',
    'source',
    'url',
    'status',
  ];

  // Convert to Object (if not already)
  let file = fileMetadata;
  if (isString(fileMetadata)) {
    try {
      file = JSON.parse(fileMetadata);
    } catch (e) {
      utilsLogger.error('Error parsing fileMetadata. Err(', e, ') input(', fileMetadata, ')');
      return false;
    }
  }

  if (!isObject(file) || !file.url || file.url.indexOf('https://cdn.filestackcontent.com/') !== 0) {
    return false;
  }

  // Filter allowed attributes
  const sanitizedMetadata = {};
  allowedAttrs.forEach((attr) => {
    if (typeof file[attr] !== 'undefined') {
      sanitizedMetadata[attr] = file[attr];
    }
  });

  if (Object.keys(sanitizedMetadata).length === 0) {
    return false;
  }

  return sanitizedMetadata;
};


/**
 * Parses a string to a list of objects
 *
 * @param {String|Array<Object>} value the value to parse
 * @returns {Array<Object>}
 */
export const parseJsonObjectList = (value, { filterInvalid = false } = {}) => {
  let objectList = value;

  if (typeof value === 'string') {
    objectList = JSON.parse(value);
  }

  if (!Array.isArray(objectList)) {
    objectList = [objectList];
  }

  objectList = objectList.map(obj => (!isObject(obj) ? parseJson(obj) : obj));

  return filterInvalid ? objectList.filter(obj => !isError(obj)) : objectList;
};

export const getDatetime = () => {
  if (TIMETRAVEL) {
    return moment().add(TIMETRAVEL, 'days');
  }
  return moment();
};

/**
 * Returns if the specified date is valid
 *
 * @param {String|Object} dateToParse date to parse
 * @param {String} [dateToParseFormat] optional date format to use when parsing dateToParse
 * @returns {Boolean}
 */
export const dateTimeIsValid = (dateToParse, dateToParseFormat) => (
  dateToParse && parseDate(dateToParse, dateToParseFormat).isValid()
);

export const assertDate = (date) => {
  if (!(moment.isMoment(date) || date instanceof Date)) {
    throw new Error(`'${date}' is not a date`);
  }
};

export const parseMinMaxFilter = (values, { defaults, callback } = {}) => {
  if (!values) {
    throw new Error('Please specify an object as values to parse');
  }

  const cb = typeof callback === 'function' ? callback : (v => v);
  const defaultValues = defaults && Object.values(defaults) ? defaults : {};

  const min = !isNil(values.min) ? values.min : defaultValues.min;
  const max = !isNil(values.max) ? values.max : defaultValues.max;

  return {
    min: cb(!isNil(min) ? min : null),
    max: cb(!isNil(max) ? max : null),
  };
};

/**
 *  Generates a stringified json object expected by HubSpot Api.
 *
 * @param profile
 * @param org
 * @param userCard
 * @return {string}
 */
export const prepareHSOrgFormData = ({ profile, org, userCard }) => {
  const { user } = profile;
  const orgAddressComponents = extractAllAddressComponents(org.address);
  let orgDomain = org.unique_alias;
  if (org.website) {
    orgDomain = org.website
      .replace('www', '')
      .replace('https:/', 'https:/')
      .replace('https://', 'https://');
  }

  return JSON.stringify({
    fields: [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: user.email,
      },
      {
        objectTypeId: '0-1',
        name: 'firstname',
        value: profile.first_name || '',
      },
      {
        objectTypeId: '0-1',
        name: 'lastname',
        value: profile.last_name || '',
      },
      {
        objectTypeId: '0-1',
        name: 'jobtitle',
        value: profile.job_title || '',
      },
      {
        objectTypeId: '0-1',
        name: 'country',
        value: '',
      },
      {
        objectTypeId: '0-1',
        name: 'address',
        value: '',
      },
      {
        objectTypeId: '0-1',
        name: 'city',
        value: '',
      },
      {
        objectTypeId: '0-1',
        name: 'zip',
        value: '',
      },
      {
        objectTypeId: '0-1',
        name: 'phone',
        value: profile.phone || '',
      },
      {
        objectTypeId: '0-1',
        name: 'company',
        value: org.name,
      },
      {
        objectTypeId: '0-1',
        name: 'talentdesk_user_id',
        value: profile.user_id,
      },
      {
        objectTypeId: '0-1',
        name: 'talentdesk_user_status',
        value: USER_STATUS_LABEL[user.status],
      },
      {
        objectTypeId: '0-1',
        name: 'talentdesk_user_role',
        value: userCard.userRole.getLabel(),
      },
      {
        objectTypeId: '0-1',
        name: 'talentdesk_user_last_sync_date',
        value: moment().format(API_DATE_FORMAT),
      },
      {
        objectTypeId: '0-1',
        name: 'talentdesk_user_admin_link',
        value: `https://app.talentdesk.io/rattlecage/full_users/${user.id}/show`,
      },
      {
        objectTypeId: '0-1',
        name: 'utm_campaign',
        value: profile.user?.utm_metadata?.utm_campaign || '',
      },
      {
        objectTypeId: '0-1',
        name: 'utm_content',
        value: profile.user?.utm_metadata?.utm_content || '',
      },
      {
        objectTypeId: '0-1',
        name: 'utm_medium',
        value: profile.user?.utm_metadata?.utm_medium || '',
      },
      {
        objectTypeId: '0-1',
        name: 'utm_source',
        value: profile.user?.utm_metadata?.utm_source || '',
      },
      {
        objectTypeId: '0-1',
        name: 'utm_term',
        value: profile.user?.utm_metadata?.utm_term || '',
      },
      {
        objectTypeId: '0-2',
        name: 'name',
        value: org.name,
      },
      {
        objectTypeId: '0-2',
        name: 'domain',
        value: orgDomain,
      },
      {
        objectTypeId: '0-2',
        name: 'country',
        value: orgAddressComponents.country || '',
      },
      {
        objectTypeId: '0-2',
        name: 'state',
        value: extractAddressComponent(org.address, 'state') || '',
      },
      {
        objectTypeId: '0-2',
        name: 'city',
        value: orgAddressComponents.city || '',
      },
      {
        objectTypeId: '0-2',
        name: 'address',
        value: orgAddressComponents.street || '',
      },
      {
        objectTypeId: '0-2',
        name: 'address2',
        value: extractAddressComponent(org.address, 'address_line_2') || '',
      },
      {
        objectTypeId: '0-2',
        name: 'zip',
        value: orgAddressComponents.postal_code || '',
      },
      {
        objectTypeId: '0-2',
        name: 'website',
        value: org.website || '',
      },
      {
        objectTypeId: '0-2',
        name: 'type',
        value: 'PROSPECT',
      },
      {
        objectTypeId: '0-2',
        name: 'hs_lead_status',
        value: 'NEW',
      },
      {
        objectTypeId: '0-2',
        name: 'lifecyclestage',
        value: 'lead',
      },
      {
        objectTypeId: '0-2',
        name: 'talentdesk_company_status',
        value: ORGANIZATION_STATUS_LABEL[org.status],
      },
      {
        objectTypeId: '0-2',
        name: 'talentdesk_organization_id',
        value: org.id,
      },
      {
        objectTypeId: '0-2',
        name: 'talentdesk_invoice_mode',
        value: org.invoicing_mode,
      },
      {
        objectTypeId: '0-2',
        name: 'talentdesk_organization_subscription_type',
        value: SUBSCRIPTION_PLAN_LABELS[SUBSCRIPTION_PLANS.PLUS],
      },
      {
        objectTypeId: '0-2',
        name: 'talentdesk_organization_unique_alias',
        value: org.unique_alias,
      },
      {
        objectTypeId: '0-2',
        name: 'talentdesk_organization_admin_link',
        value: `https://app.talentdesk.io/rattlecage/organizations/${org.id}/show`,
      },
      {
        objectTypeId: '0-2',
        name: 'talentdesk_organization_subscription_link',
        value: `https://app.talentdesk.io/rattlecage/subscriptions?filter=${encodeURIComponent(`{"organization_id":${org.id}}`)}`,
      },
    ],
    context: {
      hutk: profile.user?.utm_metadata?.utm_hstk,
      pageUri: profile.user?.utm_metadata?.utm_referer || '',
      pageId: profile.user?.utm_metadata?.utm_hspageid || '',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Example Company to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Example Company.',
          },
        ],
      },
    },
  });
};

/*
 * Return the elements of an array in subsequent pairs, e.g. [a, b, c, d] => [[a,b], [b,c], [c,d]]
 * @param Array
 * @returns Array<Pair>
 */
export const pairwise = array => zip(array.slice(0, array.length - 1), array.slice(1));

export const assertAllKeysPresent = (obj, { allowNull = false } = {}) => (
  Object.entries(obj).forEach(([key, value]) => {
    if (allowNull ? typeof value === 'undefined' : isNil(value)) {
      throw new Error(`${key} is required`);
    }
  })
);

export const assertObjectHasAllKeys = (obj, keys, {
  throwOnExtras = false,
  allowNull = false,
} = {}) => {
  if (!isObject(obj)) {
    throw new Error('expecting an object');
  }
  if (!Array.isArray(keys)) {
    throw new Error('expecting a list of keys');
  }

  const missing = keys.filter(k => (allowNull ? typeof obj[k] === 'undefined' : isNil(obj[k])));
  if (missing.length > 0) {
    throw new Error(`keys ${missing.join(', ')} are missing from object`);
  }
  if (throwOnExtras) {
    const extras = Object.keys(obj).filter(k => !keys.includes(k));
    if (extras.length > 0) {
      throw new Error(`${obj} has extra keys ${extras.join(', ')}`);
    }
  }
};

/**
 * Format a number with separators ( ie ',' and '.' ) according to the browser locale.
 * @param {string} numberString - number to format.
 * @return {string} formatted number.
 */
export const formatNumberWithSeparatorsAccordingToLocale = numberString => new Intl.NumberFormat(
  GET_BROWSER_LOCALE() || 'en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numberString);

export const isValidFileName = fileName => /\.[A-Z0-9]+$/i.test(fileName);

/**
 * Add the extension to a file name, if necessary
 *
 * @param {String} fileName
 * @param {String} mimeType
 * @returns {String}
 */
export const addFileNameExtension = (fileName, mimeType) => {
  let finalFileName = fileName;
  if (!isValidFileName(finalFileName)) {
    finalFileName += `.${mime.extension(mimeType)}`;
  }
  return finalFileName;
};

/**
 * Parses an error response from axios, to return errors for final form
 *
 * @param {Error} err
 * @returns {Object}
 */
export const parseAxiosErrorForFinalForm = err => {
  if (err.response?.data && isObject(err.response.data)) {
    const errors = omit(err.response.data, '_error', '_meta');
    if (Object.keys(errors).length > 0) {
      return errors;
    }
  }
  return { [FORM_ERROR]: err.response?.data._error || err.message };
};

/**
 * Based on a specified year, return it and any years since
 *
 * @param {Number} startYear
 * @returns {Number[]}
 */
export const getYearsSince = (startYear) => {
  const currentYear = new Date().getFullYear();
  if (startYear > currentYear) {
    return [];
  }
  return [
    startYear,
    ...new Array(currentYear - startYear).fill('').map((e, index) => startYear + index + 1),
  ];
};

/**
 * Capitalizes the first letter of a string.
 * @param {string} str - The input string.
 * @returns {string} The input string with the first letter capitalized.
 */
export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Removes extra spaces from a string by replacing consecutive whitespace characters with a
 * single space.
 * If the input string contains only whitespace characters, an empty string is returned.
 * @param {string} str - The input string to remove extra spaces from.
 * @returns {string} The input string with consecutive whitespace characters replaced
 * by a single space, or an empty string if the input string contains only whitespace
 * characters.
 */
export const removeExtraSpaces = (str) => {
  if (str.trim() === '') {
    return '';
  }
  return str.replace(/\s+/g, ' ').trim();
};

/**
 * Parse a value from a query, which should be an id or array of ids
 *
 * @param {Number|String|Any[]} value
 * @returns {Number[]}
 */
export const parseQueryArrayIds = value => {
  if (typeof value === 'number' && /^\d+$/.test(value.toString())) {
    return [value];
  }
  if (typeof value === 'string' && /^\d+$/.test(value)) {
    return [parseInt(value, 10)];
  }
  if (!Array.isArray(value)) {
    return [];
  }
  return value.reduce(
    (acc, id) => {
      const idString = typeof id === 'string' ? id : id.toString();
      const idInt = /^\d+$/.test(idString) ? parseInt(idString, 10) : null;
      if (typeof idInt === 'number' && !acc.includes(idInt)) {
        acc.push(idInt);
      }
      return acc;
    },
    [],
  );
};

/**
 * Converts a supplied File instance, to a data url
 *
 * @param {File} file
 * @returns {Promise<String>}
 */
export const fileToDataUrl = file => new Promise(resolve => {
  const reader = new FileReader();
  reader.addEventListener('load', () => resolve(reader.result), false);
  reader.readAsDataURL(file);
});

/**
 * Parses a query value as an id
 *
 * @param {String|Number} value
 * @returns {Number| Null}
 */
export const parseIdFromQueryValue = value => {
  if (
    typeof value === 'number' || (typeof value === 'string' && /^\d+$/.test(value))
  ) {
    return parseInt(value, 10);
  }
  return null;
};

/**
 * Pauses for specified milliseconds
 *
 * @param {*} milliseconds
 * @returns {Promise<Void>}
 */
export const pause = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

/**
 * Determine if a clicked DOM element, is a table rows expand cell
 *
 * @param {Object} event
 * @returns {Boolean}
 */
export const isTableRowExpandElement = event => (
  (
    event.target?.tagName === 'TD'
    && event.target?.className?.includes('expand-cell')
  )
  || (
    event.target?.tagName !== 'TD'
    && event.target?.closest('td')?.className?.includes('expand-cell')
  )
);

/**
 * As of writing Google are migrating to a new JSON structure for places:
 * https://developers.google.com/maps/documentation/places/web-service/migrate-response
 *
 * This function takes the new structure and returns it in the old structure
 *
 * @param {Object} place - The Google Place entity as JSON
 * @returns {Object}
 */
export const convertGoogleMapsPlaceNewToOld = place => {
  const result = {
    address_components: (place.addressComponents || []).map(component => ({
      long_name: component.longText, short_name: component.shortText, types: component.types,
    })),
  };
  [
    ['adrFormatAddress', 'adr_address'],
    ['attributions', 'html_attributions'],
    ['displayName', 'name'],
    ['formattedAddress', 'formatted_address'],
    ['googleMapsURI', 'url'],
    ['iconBackgroundColor', 'icon_background_color'],
    ['id', ['place_id', 'reference']],
    ['svgIconMaskURI', 'icon_mask_base_uri'],
    ['types', 'types'],
    ['utcOffsetMinutes', ['utc_offset', 'utc_offset_minutes']],
  ].forEach(([newKey, oldKey]) => {
    const value = place[newKey];
    if (value !== undefined) {
      const oldKeys = Array.isArray(oldKey) ? oldKey : [oldKey];
      oldKeys.forEach(key => {
        result[key] = value;
      });
    }
  });

  if (place.location || place.viewport) {
    result.geometry = {};
    ['location', 'viewport'].forEach(key => {
      if (place[key]) {
        result.geometry[key] = place[key];
      }
    });
  }

  if (Array.isArray(place.photos) && place.photos.length > 0) {
    result.photos = place.photos.map(photo => {
      let reference = null;
      if (typeof photo.name === 'string' && photo.name.length > 0) {
        const referenceMatches = photo.name.match(/places\/[^/]+\/photos\/([^/]+)/);
        if (Array.isArray(referenceMatches) && referenceMatches.length > 1) {
          reference = referenceMatches[1];
        }
      }
      return {
        height: photo.heightPx,
        html_attributions: photo.authorAttributions,
        photo_reference: reference,
        width: photo.widthPx,
      };
    });
  }

  return result;
};

export const submitFormById = domID => DOCUMENT_GET_ELEMENT_BY_ID(domID)?.dispatchEvent(
  // eslint-disable-next-line no-restricted-globals
  new Event('submit', { bubbles: true, cancelable: true }),
);
