import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory, useParams } from 'react-router-dom';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_SIZE, BS_STYLE, ICON, PROJECT_TABS } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import {
  getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { formatDate, renderDuration, renderSecondsAsDuration } from 'core/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import TaskAssignmentStatusTag from 'projects/assets/js/components/TaskAssignmentStatusTag.jsx';
import { projectRemoveMemberDS } from 'projects/assets/js/data-services/form';
import {
  projectRemoveMemberTaskDetailsApiUrl,
  projectOpportunitiesUrl,
  projectViewTaskUrl,
  projectViewUrl,
} from 'projects/urls';

const TRACKED_TIME_MODAL_ID = 'tracked-time-modal';

const ProjectRemoveMemberView = () => {
  const dispatch = useDispatch();
  const { id: projectId, orgAlias, userId } = useParams();
  const activeOrg = useSelector(selectActiveOrg);
  const history = useHistory();
  const [removing, setRemoving] = useState(false);
  const [deletingWorkTimeBlocks, setDeletingWorkTimeBlocks] = useState(false);
  const [raisingWorksheets, setRaisingWorksheets] = useState(false);

  const { hasLoaded, isLoading, item } = fetchDataHook({
    componentName: ProjectRemoveMemberView.GetComponentName(),
    url: projectRemoveMemberTaskDetailsApiUrl(orgAlias, projectId, userId),
  });

  const finishedLoading = hasLoaded && !isLoading;

  const hasUnpaidTrackedTime = Array.isArray(item?.tasks)
    && item.tasks.some(t => t.pendingWorkTimeBlocks.length > 0);

  const modalIsOpen = useSelector(state => getIsModalOpen(state, TRACKED_TIME_MODAL_ID));
  const modalPayload = useSelector(state => getModalPayload(state, TRACKED_TIME_MODAL_ID));

  const projectTeamUrl = projectViewUrl(orgAlias, projectId, PROJECT_TABS.TEAM);

  const remove = async (
    { deleteWorkTimeBlocks = false, raiseWorksheetsForWorkTimeBlocks = false } = {},
  ) => {
    setRemoving(true);
    if (deleteWorkTimeBlocks) {
      setDeletingWorkTimeBlocks(true);
    } else if (raiseWorksheetsForWorkTimeBlocks) {
      setRaisingWorksheets(true);
    }
    try {
      await projectRemoveMemberDS({
        body: { deleteWorkTimeBlocks, raiseWorksheetsForWorkTimeBlocks },
        orgAlias,
        projectId,
        userId,
      });
      toastr.success('Well Done!', 'Project Member removed successfully');
      history.push(projectTeamUrl);
    } catch (e) {
      toastr.error('Oh snap!', e.response?.data?._error || e.message);
    } finally {
      setRemoving(false);
      setDeletingWorkTimeBlocks(false);
      setRaisingWorksheets(false);
    }
  };

  const submitButtonCommonprops = {
    disabled: removing,
    showIconOnLeft: true,
    variant: BS_STYLE.DANGER,
  };

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Projects', url: projectOpportunitiesUrl(orgAlias) },
          { title: item?.projectTitle || 'Project', url: projectViewUrl(orgAlias, projectId) },
          { title: `Remove ${item?.userName || 'provider'} from the project` },
        ]}
      />
      <div className="page page--remove-project-member">
        <div className="container">
          {finishedLoading && (
            <>
              <TDSystemMessage
                className="mb-5"
                title="Removing provider"
                type={BS_STYLE.WARNING}
              >
                {'You are about to remove '}
                <b>{item?.userName || 'this provider'}</b>
                {' from the project. They will also be removed from any '}
                <b>project tasks</b>
                {' they are assigned to.'}
                {hasUnpaidTrackedTime && (
                  <>
                    <br />
                    <br />
                    <b>NOTE:</b>
                    {' This provider has some work times recorded for this project in the time '}
                    tracker. To remove the provider from all tasks and the project you must either
                    create a worksheet for the tracked times or delete the tracked times. Then the
                    user will be removed from the project.
                  </>
                )}
              </TDSystemMessage>
              <Card>
                <Card.Body>
                  {Array.isArray(item.tasks) && item.tasks.length > 0 && (
                    <>
                      <p>
                        {`${item?.userName || 'This provider'} is a part of ${item.tasks.length} `}
                        tasks that are in progress
                      </p>
                      <Table
                        cols={[
                          {
                            dataFormat: (taskTitle, { id: taskId }) => (
                              <Link to={projectViewTaskUrl(orgAlias, projectId, taskId)}>
                                {taskTitle}
                              </Link>
                            ),
                            key: 'title',
                            label: 'Task',
                          },
                          ...(!hasUnpaidTrackedTime ? [] : [{
                            dataFormat: pendingWorkTimeBlocks => {
                              if (pendingWorkTimeBlocks.length === 0) {
                                return null;
                              }
                              const duration = renderSecondsAsDuration(
                                pendingWorkTimeBlocks.reduce(
                                  (acc, wtb) => acc + wtb.durationInSeconds, 0,
                                ),
                              );
                              return (
                                <>
                                  {`${duration} (`}
                                  <span
                                    className="imitate-link"
                                    onClick={() => dispatch(modalOpenAC(
                                      TRACKED_TIME_MODAL_ID,
                                      { workTimeBlocks: pendingWorkTimeBlocks },
                                    ))}
                                  >
                                    view
                                  </span>
                                  )
                                </>
                              );
                            },
                            key: 'pendingWorkTimeBlocks',
                            label: 'Tracked time (unpaid)',
                            width: '260px',
                          }]),
                          {
                            dataFormat: taskAssignmentStatus => (
                              <TaskAssignmentStatusTag status={taskAssignmentStatus} />
                            ),
                            key: 'taskAssignmentStatus',
                            label: 'Status',
                            width: '260px',
                          },
                        ]}
                        containerClass="mb-5"
                        items={item.tasks}
                      />
                    </>
                  )}
                  <p>
                    {'By removing '}
                    {item?.userName || 'this provider'}
                    {' from the project and tasks you revoke their view permissions and so they '}
                    will not be able to view the project, track time for tasks, send messages,
                    collaborate, submit deliverables or raise worksheets. However, you can always
                    add them back should you change your mind.
                  </p>
                  <p>
                    {'Are you sure you want to remove '}
                    {item?.userName || 'this provider'}
                    {' from this project and tasks?'}
                  </p>
                </Card.Body>
              </Card>
              <div className="d-flex align-items-center justify-content-end mt-5">
                <TDButton label="Cancel" onClick={() => history.push(projectTeamUrl)} />
                {hasUnpaidTrackedTime && (
                  <>
                    <TDButton
                      btnIcon={removing && deletingWorkTimeBlocks ? ICON.SPINNER_THIRD : null}
                      className={removing && deletingWorkTimeBlocks ? 'loading' : null}
                      label="Delete all tracked times & Remove user"
                      onClick={() => remove({ deleteWorkTimeBlocks: true })}
                      {...submitButtonCommonprops}
                    />
                    <TDButton
                      btnIcon={removing && raisingWorksheets ? ICON.SPINNER_THIRD : null}
                      className={removing && raisingWorksheets ? 'loading' : null}
                      label="Create worksheet & Remove user"
                      onClick={() => remove({ raiseWorksheetsForWorkTimeBlocks: true })}
                      {...submitButtonCommonprops}
                    />
                  </>
                )}
                {!hasUnpaidTrackedTime && (
                  <TDButton
                    btnIcon={removing ? ICON.SPINNER_THIRD : null}
                    className={removing ? 'loading' : null}
                    label="Remove user"
                    onClick={remove}
                    {...submitButtonCommonprops}
                  />
                )}
              </div>
            </>
          )}
          {!finishedLoading && <SettingsPageSkeleton />}
        </div>
      </div>
      <ModalSimple
        body={(
          <>
            <Table
              cols={[
                {
                  dataFormat: startTime => formatDate(startTime, undefined, true),
                  key: 'startTime',
                  label: 'Day',
                },
                { key: 'description', label: 'Description' },
                {
                  dataFormat: (_, workTimeBlock) => {
                    let value = `${formatDate(workTimeBlock.startTime, 'HH:mm:ss', true)} - `;
                    if (workTimeBlock.endTime) {
                      value += formatDate(workTimeBlock.endTime, 'HH:mm:ss', true);
                    }
                    return value;
                  },
                  key: 'startTime',
                  label: 'Start - end',
                },
                {
                  dataFormat: (_, workTimeBlock) => (
                    !workTimeBlock.endTime ? null : renderDuration(
                      workTimeBlock.startTime, workTimeBlock.endTime,
                    )
                  ),
                  key: 'startTime',
                  label: 'Duration',
                },
                {
                  dataAlign: 'right',
                  dataFormat: (_, workTimeBlock) => workTimeBlock.amount && (
                    <NumberTpl currency={workTimeBlock.currency} value={workTimeBlock.amount} />
                  ),
                  key: 'amount',
                  label: 'Earned',
                },
              ]}
              items={modalPayload?.workTimeBlocks || []}
            />
            <div className="time-tracker-total p-4 d-flex justify-content-end">
              <div className="label">Total amount</div>
              <div className="amount">
                {Array.isArray(modalPayload?.workTimeBlocks) && (
                  <NumberTpl
                    currency={activeOrg.currency}
                    value={modalPayload.workTimeBlocks.reduce(
                      (acc, wtb) => (parseFloat(acc) + parseFloat(wtb.amount)).toFixed(2), '0.00',
                    )}
                  />
                )}
              </div>
            </div>
          </>
        )}
        heading="Tracked times"
        onClose={() => dispatch(modalCloseAC())}
        open={modalIsOpen && Array.isArray(modalPayload?.workTimeBlocks)}
        size={BS_SIZE.LARGE}
      />
    </>
  );
};

ProjectRemoveMemberView.GetComponentName = () => 'ProjectRemoveMemberView';

export default ProjectRemoveMemberView;
