import { isEmpty, omit } from 'lodash';
import moment from 'moment';

import { API_DATE_FORMAT, USER_TYPE } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { AVAILABILITY_WARNING_DAYS_THRESHOLD } from 'accounts/assets/js/constants';
import { TYPE } from 'interviews/assets/js/constants';

const calculateTimeOffWarningMessages = ({ nextTimeoff, firstName, availabilityFilter }) => {
  if (!nextTimeoff) {
    return {
      isOnTimeOff: false,
      isLeavingSoon: false,
      availabilityMessage: null,
    };
  }
  let availabilityMessage;
  let messageTextClassName;
  const { periodStart, periodEnd } = nextTimeoff;
  const filtersEnabled = !!(
    availabilityFilter && (availabilityFilter.min || availabilityFilter.max)
  );
  const relativeToday = (filtersEnabled && availabilityFilter.max)
    ? moment(availabilityFilter.max, API_DATE_FORMAT)
    : moment();

  const isLeavingSoon = moment(relativeToday).clone().add(AVAILABILITY_WARNING_DAYS_THRESHOLD, 'days')
    .isSameOrAfter(moment(periodStart), 'day');

  const isOnTimeOff = relativeToday.isSameOrAfter(moment(periodStart), 'day')
    && relativeToday.isSameOrBefore(moment(periodEnd), 'day');

  if (isLeavingSoon) {
    messageTextClassName = 'text-warning';
    availabilityMessage = `${firstName} is available till ${formatDate(moment(periodStart).subtract(1, 'day'))}`;
  }

  if (isOnTimeOff) {
    if (filtersEnabled && !moment(availabilityFilter.min, API_DATE_FORMAT).isSame(moment(), 'day')) {
      messageTextClassName = 'text-danger';
      availabilityMessage = `${firstName} will be back on ${formatDate(moment(periodEnd).add(1, 'day'))}`;
    } else {
      messageTextClassName = 'text-danger';
      availabilityMessage = `${firstName} is unavailable today but will be back on ${formatDate(moment(periodEnd).add(1, 'day'))}`;
    }
  }

  return {
    isOnTimeOff,
    isLeavingSoon,
    availabilityMessage,
    messageTextClassName,
  };
};

/**
  * Determines if a user can answer a specified question
  *
  * @param {Object} question
  * @param {Number} activeUserType
  * @returns {Boolean}
  */
const getCanUserAnswerQuestion = (question, activeUserType) => (
  // Users cannot answer text blob questions
  question.type !== TYPE.TEXT_BLOB
  && (
    (
      // Managers cannot answer provider questions
      question.answeredByUserType === USER_TYPE.PROVIDER
      && activeUserType === USER_TYPE.PROVIDER
    )
    || (
      // Providers cannot answer manager questions
      question.answeredByUserType !== USER_TYPE.PROVIDER
      && activeUserType !== USER_TYPE.PROVIDER
    )
  )
);

/**
  * Processes additional information form values, to remove any questions that the user cannot
  * answer. So they are not sent to the API for validation.
  * Also determines emptyAnswersForTemplateIds
  *
  * @param {Object} values
  * @param {Object[]} templates
  * @param {Number} activeUserType
  * @returns {Object}
  */
const processAdditionalInformationValues = (values, templates, activeUserType) => {
  const omitTemplateIds = [];
  const omitCustomFieldIds = [];
  const omitPaths = [];
  const emptyAnswersForTemplateIds = [];

  const {
    custom_field_ids: customFieldIds, custom_field_templates: customFieldTemplateIds,
  } = values;

  // Omit any questions the user cannot answer
  customFieldTemplateIds.forEach(templateId => {
    const template = templates.find(t => t.id === templateId);
    if (!template) {
      // Should not happen
      return;
    }
    let templateHasAQuestionTheUserCanAnswer = false;
    let templateHasAValidAnswer = false;
    template.questions.forEach(question => {
      if (getCanUserAnswerQuestion(question, activeUserType)) {
        templateHasAQuestionTheUserCanAnswer = true;
        if (!isEmpty(values[question.path])) {
          templateHasAValidAnswer = true;
        }
      } else {
        omitCustomFieldIds.push(question.id);
        omitPaths.push(question.path);
      }
    });
    if (!templateHasAQuestionTheUserCanAnswer) {
      omitTemplateIds.push(template.id);
    }
    if (!templateHasAValidAnswer && !template.isMandatory) {
      /*
        The user could be emptying the answers for this non-mandatory template
        So remove the template and custom fields from validation and tell the API to remove any
        answers via emptyAnswersForTemplateIds
      */
      emptyAnswersForTemplateIds.push(template.id);
      if (!omitTemplateIds.includes(template.id)) {
        omitTemplateIds.push(template.id);
      }
      template.questions.forEach(question => {
        omitPaths.push(question.path);
        omitCustomFieldIds.push(question.id);
      });
    }
  });

  // Omit any custom ids, for now removed templates
  const selectedTemplates = templates.filter(t => customFieldTemplateIds.includes(t.id));
  omitCustomFieldIds.push(
    ...customFieldIds.filter(cfId => (
      selectedTemplates.every(t => t.questions.every(q => q.id !== cfId))
    )),
  );

  return {
    ...omit(values, omitPaths),
    custom_field_ids: customFieldIds.filter(id => (
      !omitCustomFieldIds.includes(id)
    )),
    custom_field_templates: customFieldTemplateIds.filter(id => (
      !omitTemplateIds.includes(id)
    )),
    emptyAnswersForTemplateIds,
  };
};

const isScrolledToTheTop = (clientHeight, scrollHeight, scrollTop) => {
  return Math.round(scrollHeight - clientHeight)
  <= Math.round(Math.abs(scrollTop));
};

/**
 * Get selected document ids from the selected onboarding form
 *
 * @param {Object} values
 * @param {Number[]} values.interviewIds - The interview ids selected
 * @param {{ value: Boolean }[]} values.onboardingFormEnabled
 * @param {Object[]} interviews
 * @param {Boolean} onboardingFormsAreMandatory - If an onboarding form is mandatory in the org
 * @returns {Number[]}
 */
export const getDocumentIdsFromSelectedOnboardingForms = (
  { interviewIds, onboardingFormEnabled }, interviews, onboardingFormsAreMandatory,
) => {
  if (
    Array.isArray(interviewIds)
    && interviewIds.length > 0
    && (
      onboardingFormsAreMandatory
      || (
        Array.isArray(onboardingFormEnabled)
        && onboardingFormEnabled.length === 1
        && onboardingFormEnabled[0].value === true
      )
    )
  ) {
    return interviews.reduce(
      (acc, interview) => {
        if (
          interviewIds.some(interviewId => parseInt(interview.id, 10) === parseInt(interviewId, 10))
          && Array.isArray(interview.documentIds)
        ) {
          acc.push(...interview.documentIds);
        }
        return Array.from(new Set(acc));
      },
      [],
    );
  }
  return [];
};

/**
 * Get selected document ids from form values
 *
 * @param {Object} values
 * @param {String[]} values.documents
 * @param {Number[]} values.interviewIds - The interview ids selected
 * @param {{ value: Boolean }[]} values.onboardingFormEnabled
 * @param {Object[]} interviews
 * @param {Boolean} onboardingFormsAreMandatory - If an onboarding form is mandatory in the org
 * @returns {Number[]}
 */
const getSelectedDocumentIdsFromValues = (values, interviews, onboardingFormsAreMandatory) => {
  let documentIds = (values.documents || []).slice(0);
  documentIds.push(...getDocumentIdsFromSelectedOnboardingForms(
    values, interviews, onboardingFormsAreMandatory,
  ));
  documentIds = [...new Set(documentIds)];
  return documentIds.map(documentId => parseInt(documentId, 10));
};

/**
 * @param {Number} documentId
 * @param {Number} index
 * @returns {String}
 */
const getDocumentCountersignersFieldName = (documentId, index) => (
  `document-${documentId}-countersigners${index !== undefined ? `[${index}]` : ''}`
);

/**
 * Get the duplicate document name, based on inviting users form values
 *
 * @param {Object} activeOrg
 * @param {Object} formValues
 * @param {Object[]} selectedInterviews
 * @param {Object[]} documents
 * @returns {String|null}
 */
const getInviteUsersFormDuplicateDocumentError = (
  activeOrg, formValues, selectedInterviews, documents,
) => {
  if (
    !Array.isArray(formValues.documents)
    || formValues.documents.length === 0
    || !selectedInterviews.some(
      interview => Array.isArray(interview.documentIds) && interview.documentIds.length > 0,
    )
  ) {
    return null;
  }
  const onboardingFormEnabled = activeOrg.onboarding_forms_are_mandatory || (
    Array.isArray(formValues.onboardingFormEnabled)
      && formValues.onboardingFormEnabled.length === 1
      && formValues.onboardingFormEnabled[0].value
  );
  if (!onboardingFormEnabled) {
    return null;
  }
  const duplicateDocumentIds = selectedInterviews.reduce(
    (acc, interview) => {
      if (Array.isArray(interview.documentIds)) {
        const thisDuplicateDocumentIds = interview.documentIds.filter(
          documentId => formValues.documents.includes(documentId),
        );
        if (thisDuplicateDocumentIds.length > 0) {
          acc.push(...thisDuplicateDocumentIds);
        }
      }
      return Array.from(new Set(acc));
    },
    [],
  );
  if (duplicateDocumentIds.length === 0) {
    return null;
  }
  const duplicateDocumentNames = duplicateDocumentIds.map(documentId => {
    const document = documents.find(d => d.value === documentId);
    return document ? document.label : documentId;
  });
  return [
    `Documents "${duplicateDocumentNames.join('", "')}" are already added as part of the `,
    `onboarding forms "${selectedInterviews.map(i => i.name).join('", "')}"`,
  ].join('');
};

export {
  calculateTimeOffWarningMessages,
  getDocumentCountersignersFieldName,
  getInviteUsersFormDuplicateDocumentError,
  getSelectedDocumentIdsFromValues,
  isScrolledToTheTop,
  getCanUserAnswerQuestion,
  processAdditionalInformationValues,
};
