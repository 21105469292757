import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { submissionDownloadApiUrl } from 'interviews/urls';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { Answer } from 'interviews/assets/js/components/ReviewForm';
import { getAnswerExpiresAtPath } from 'interviews/assets/js/lib/utils';
import { ICON, MODAL_SIZES } from 'core/assets/js/constants';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField';
import TDButton from 'core/assets/js/components/TDButton';


export const SUBMISSION_VIEW_MODAL_ID = 'submission-view-modal';

const SubmissionViewModal = () => {
  const dispatch = useDispatch();
  const activeUserCard = useSelector(selectActiveUserCard);
  const isOpen = useSelector(state => getIsModalOpen(state, SUBMISSION_VIEW_MODAL_ID));
  const { submission } = useSelector(state => getModalPayload(
    state,
    SUBMISSION_VIEW_MODAL_ID,
  )) || {};

  const closeModal = () => dispatch(modalCloseAC());

  if (!submission) {
    return null;
  }

  const {
    id,
    allowedActions,
    answers,
    errors: reviewerComments,
    name,
    questions,
  } = submission;

  return (
    <ModalSimple
      className="submission-view-modal"
      body={(
        <div>
          <div className="d-flex flex-row justify-content-end">
            <TDButton
              btnIcon={ICON.DOWNLOAD}
              label="Download"
              onClick={() => WINDOW_OPEN(
                submissionDownloadApiUrl(activeUserCard.organization.alias, id),
                '_blank',
              )}
            />
          </div>

          {questions && questions.map(question => (
            <div
              className="mb-4"
              key={question.path}
              data-testid={`review-form-answer-${question.path}`}
            >
              <Answer
                activeUserType={activeUserCard.userRole.ofType}
                submissionId={id}
                question={question}
                answer={answers[question.path]}
                expiresAtAnswer={answers[getAnswerExpiresAtPath(question.path)]}
              />

              {reviewerComments[question.path] && (
                <div className="form-group has-error">
                  <span className="help-block">
                    Reviewer comment:
                    {' '}
                    {reviewerComments[question.path]}
                  </span>
                </div>
              ) }
              {allowedActions.canReview && (
                <TextInputField name={question.path} placeholder="Add your comment..." />
              )}
            </div>
          ))}
        </div>
      )}
      heading={name}
      noFooter
      onClose={closeModal}
      open={isOpen}
      size={MODAL_SIZES.EXTRA_LARGE}
    />
  );
};

export default SubmissionViewModal;
