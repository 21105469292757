import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import FinancialChartWrapper from 'analytics/assets/js/components/FinancialChartWrapper.jsx';
import { FINANCIAL_CHART_TYPES } from 'analytics/assets/js/constants';
import { financialChartApiUrl } from 'analytics/urls';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { IMG_SIZE } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { downloadFileApiUrl } from 'files/urls';

const SpendByProviders = ({ max, min }) => {
  const params = useParams();

  const { hasLoaded, isLoading, item } = fetchDataHook({
    changesArray: [max, min],
    componentName: SpendByProviders.GetComponentName(),
    url: financialChartApiUrl(params.orgAlias, FINANCIAL_CHART_TYPES.SPEND_BY_PROVIDERS),
    queryStringParams: { max, min },
  });

  const largestAmount = Array.isArray(item.providers) && item.providers.length > 0
    ? parseFloat(item.providers[0].amount)
    : null;

  return (
    <FinancialChartWrapper
      footer={!!item.currency && (
        <div className="d-flex align-items-center justify-content-between font-weight-bold">
          <b>Total</b>
          <div><NumberTpl currency={item.currency} value={item.total} /></div>
        </div>
      )}
      isLoading={!hasLoaded || isLoading}
      title="Spend by Providers"
    >
      <div className="d-flex flex-column spend-by-providers-list">
        {(item.providers || []).map((provider, index) => (
          <div
            className={(
              // eslint-disable-next-line prefer-template
              'd-flex flex-row align-items-center justify-content-between provider w-100'
                + (index > 0 ? ' mt-4' : '')
            )}
            key={provider.id}
          >
            <div className="d-flex align-items-center provider-wrapper">
              <ProfilePic
                url={provider.avatarFileId && downloadFileApiUrl(provider.avatarFileId)}
                alt={provider.name}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                className="mr-3"
              />
              <div className="d-flex flex-column progress-wrapper">
                <div className="text-truncate">{provider.name}</div>
                <div
                  className="progress"
                  style={{ width: `${(parseFloat(provider.amount) / largestAmount) * 100}%` }}
                />
              </div>
            </div>
            <div className="font-weight-bold">
              <NumberTpl currency={item.currency} value={provider.amount} />
            </div>
          </div>
        ))}
      </div>
    </FinancialChartWrapper>
  );
};

SpendByProviders.GetComponentName = () => 'SpendByProviders';

SpendByProviders.propTypes = {
  max: PropTypes.string.isRequired,
  min: PropTypes.string.isRequired,
};

export default SpendByProviders;
