import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { followBackUrlIfPresent, determineReportEditUrl } from 'finance/assets/js/lib/utils';
import WorksheetViewSkeleton from 'finance/assets/js/skeletons/WorksheetViewSkeleton.jsx';
import { REVIEW_REQUEST_DAYS_INTERVAL } from 'people/assets/js/constants';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import {
  ACCESS_CONTROL_ALLOWED_ACTIONS as ALLOWED_ACTIONS, API_DATE_FORMAT,
  BS_STYLE, ICON, USER_TYPE,
} from 'core/assets/js/constants';
import {
  WORKSHEET_CAN_FUND_REASON, WORKSHEET_STATUS_LABEL, WORKSHEET_STATUS_CLASS,
  WORKSHEET_STATUS, SERVICE_ORDER_PENDING_STATUSES,
} from 'projects/assets/js/constants';
import WorksheetsTable from 'finance/assets/js/components/WorksheetsTable.jsx';
import { worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { fetchFinanceWorksheetDS } from 'finance/assets/js/data-services/view';
import {
  financeWorksheetApproveDS,
  financeWorksheetConfirmDS,
  financeWorksheetRejectDS,
  financeWorksheetVoidDS,
  financeWorksheetRequestAmendmentDS,
} from 'finance/assets/js/data-services/form';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import {
  worksheetAllowedActionsSpec, projectMemberAllowedActionsSpec,
} from 'projects/assets/js/lib/objectSpecs';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import ReportLogo from 'finance/assets/js/components/ReportLogo.jsx';
import ReportDetails from 'finance/assets/js/components/ReportDetails.jsx';
import ReportCompany from 'finance/assets/js/components/ReportCompany.jsx';
import ReportPerson from 'finance/assets/js/components/ReportPerson.jsx';
import ReportFooter from 'finance/assets/js/components/ReportFooter.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ReasonForm from 'core/assets/js/components/ReasonForm.jsx';
import BillingPeriodWidget from 'finance/assets/js/components/BillingPeriodWidget.jsx';
import ReportProjectLink from 'finance/assets/js/components/ReportProjectLink.jsx';
import ReportInvoiceLinks from 'finance/assets/js/components/ReportInvoiceLinks.jsx';
import ReportAuditTrail from 'finance/assets/js/components/ReportAuditTrail.jsx';
import { profileSpec } from 'accounts/assets/js/lib/objectSpecs';
import BillableApproveForm from 'projects/assets/js/components/BillableApproveForm.jsx';
import BillableRequestAmendmentForm from 'projects/assets/js/components/BillableRequestAmendmentForm.jsx';
import { WorksheetCancelForm } from 'projects/assets/js/components/WorksheetCancelForm.jsx';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';
import withModalFinalForm from 'core/assets/js/components/withModalFinalForm.jsx';
import ReportFinancialEntity from 'finance/assets/js/components/ReportFinancialEntity.jsx';
import ReviewRequestItem from 'people/assets/js/components/ReviewRequestItem.jsx';
import { projectRequestBudgetUrl } from 'projects/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import BillableVoid from 'finance/assets/js/components/BillableVoid.jsx';
import BillableVoidNotAllowed from 'finance/assets/js/components/BillableVoidNotAllowed.jsx';

class WorksheetView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchFinanceWorksheetDS({
        orgAlias: params.orgAlias, id: params.id, url, componentName, authedAxios,
      })),
    ]);
  }

  static GetComponentName() {
    return 'WorksheetView';
  }

  constructor(props) {
    super(props);
    this.state = {
      showReviewRequest: false,
    };

    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.onAmendmentRequested = this.onAmendmentRequested.bind(this);
    this.handleWorksheetVoid = this.handleWorksheetVoid.bind(this);
  }

  /**
   * Worksheet Cancel - form submit success callback
   */
  onSubmitSuccess() {
    const { match: { params }, dispatch, history } = this.props;
    const componentName = this.constructor.GetComponentName();

    dispatch(fetchFinanceWorksheetDS({
      orgAlias: params.orgAlias,
      id: params.id,
      componentName,
    }));

    // Update finance pending counts.
    dispatch(refreshFinancePendingCountsDS({
      orgAlias: params.orgAlias,
    }));

    followBackUrlIfPresent(history);
  }

  async onAmendmentRequested(values) {
    const { match: { params: { orgAlias, id } }, dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();

    await dispatch(
      financeWorksheetRequestAmendmentDS({ orgAlias, id, values, componentName }),
    );
  }

  async handleWorksheetVoid() {
    const { match: { params: { orgAlias, id } }, dispatch } = this.props;
    const componentName = this.constructor.GetComponentName();

    await dispatch(
      financeWorksheetVoidDS({ orgAlias, id, componentName }),
    );
  }

  renderTotals() {
    const { report } = this.props;
    return (
      <div className={`finance-report__summary finance-report__summary--${WORKSHEET_STATUS_CLASS[report.statusCode]}`}>
        <div>
          <label>Total</label>
          <span>
            <NumberTpl
              value={report.worksheetAmount}
              decimals={2}
              prefix={report.worksheetCurrencySymbol}
            />
            {report.worksheetCurrency !== report.organizationCurrency && (
              <ExchangeRateInfoPopOver
                className="ml-2"
                exchangeRate={report.exchangeRate}
                exchangeRateDate={report.exchangeRateUpdatedAt}
                exchangeRateService={report.worksheetToOrganizationFxRateSource}
                sourceCurrency={report.worksheetCurrency}
                targetAmount={report.organizationAmount}
                targetCurrency={report.organizationCurrency}
              />
            )}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const {
      dispatch,
      match,
      organization,
      report,
      history,
      allowedActions: {
        [ALLOWED_ACTIONS.WORKSHEET]: worksheetAllowedActions,
        [ALLOWED_ACTIONS.PROJECT_MEMBER]: projectMemberAllowedActions,
      },
    } = this.props;

    const { canApproveAnyWorksheet, canManageAnyWorksheet } = worksheetAllowedActions || {};
    const { canBeVoided: canVoidWorksheet } = report?.allowedActions || {};

    const { showReviewRequest } = this.state;
    const reports = [report];
    const status = WORKSHEET_STATUS_LABEL[report.statusCode];
    const isEmployee = report.provider && report.provider.isEmployee;
    let fe = FinancialEntity.getEmpty();
    if (!isEmpty(report) && get(organization, 'id')) {
      fe = new FinancialEntity({ ...report.provider, orgId: organization.id });
    }


    const shouldRenderCompany = (
      report.provider?.company
      && report.provider.company.invoicable !== false
      && (
        !report.financialContext?.ownerFE
        || new FinancialEntity(report.financialContext.ownerFE)?.getCompany()?.invoicable === false
      )
    );
    const componentName = this.constructor.GetComponentName();

    const canFundWorksheet = report?.allowedActions?.canBeFunded?.value;
    const projectRequiresMoreBudget = (
      !!report.allowedActions
      && !canFundWorksheet
      && report?.allowedActions?.canBeFunded?.reason
      && [
        WORKSHEET_CAN_FUND_REASON.PROJECT_HAS_NO_BUDGET,
        WORKSHEET_CAN_FUND_REASON.PROJECT_HAS_NOT_ENOUGH_BUDGET,
      ].includes(report.allowedActions.canBeFunded.reason)
    );
    const canApproveWorksheet = (
      SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
      && canApproveAnyWorksheet
      && !!report.allowedActions && !!report.allowedActions.canBeApproved
    );
    const canConfirmWorksheet = (
      SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
      && canManageAnyWorksheet
      && !!report.allowedActions && !!report.allowedActions.canBeConfirmed
    );
    const canRejectWorksheet = (
      SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
      && canManageAnyWorksheet
      && !!report.allowedActions && !!report.allowedActions.canBeRejected
    );
    const canCancelWorksheet = report && !!report.allowedActions
      && !!report.allowedActions.canBeCancelled;
    const canRequestWorksheetAmendment = report && !!report.allowedActions
      && !!report.allowedActions.canBeRequestedToBeAmended;

    let approveModalData = null;
    if (canApproveWorksheet) {
      approveModalData = {
        key: 'worksheetApproveForm',
        btnLabel: 'Approve',
        btnVariant: BS_STYLE.SUCCESS,
        btnIcon: ICON.CHECKMARK,
        btnClassName: 'text-success',
      };
      if (canFundWorksheet) {
        const ApproveForm = withModalFinalForm({
          heading: 'Approve worksheet',
          confirmLabel: 'Approve',
          confirmStyle: BS_STYLE.SUCCESS,
          modalId: 'worksheetApproveForm',
          testId: 'worksheet-view-approve-modal',
        })(BillableApproveForm);
        approveModalData.modalId = 'worksheetApproveForm';
        approveModalData.Form = (
          <ApproveForm
            initialValues={{
              process_at: moment().format(API_DATE_FORMAT),
            }}
            isEmployee={isEmployee}
            onSubmit={values => (
              dispatch(financeWorksheetApproveDS({
                data: values,
                componentName,
                orgAlias: match.params.orgAlias,
                documentId: match.params.id,
              }))
            )}
            onSubmitSuccess={() => {
              followBackUrlIfPresent(history);

              if (report.worksheetsApprovedCount % REVIEW_REQUEST_DAYS_INTERVAL === 0) {
                // We want to show the review request item in
                // the first approved ws or every 5 approved
                this.setState({ showReviewRequest: true });
              }
            }}
            warnNotConfirmed={(
              organization.second_level_of_service_order_approval
              && report.statusCode !== WORKSHEET_STATUS.CONFIRMED
            )}
          />
        );
      } else if (projectRequiresMoreBudget) {
        const Content = () => (
          <>
            <p>
              Currently there is not enough available budget in the project. In order to
              approve this worksheet you need first to request more budget by raising a new
              budget request.
            </p>
            {projectMemberAllowedActions.isMember && (
              <>
                <p>
                  If you have enough budget to allocate to the project, choose yourself from the
                  list of managers and the budget will immediately get allocated to the project.
                </p>
                <p>To request more budget click &quot;Request budget&quot;.</p>
              </>
            )}
            {!projectMemberAllowedActions.isMember && (
              <p>
                As you are not a member of the project, you will need to ask the project owner
                to submit a budget request.
              </p>
            )}
          </>
        );
        const ApproveForm = withModalForm({
          confirmLabel: 'Request budget',
          confirmStyle: BS_STYLE.SUCCESS,
          heading: 'More budget is needed in the project',
          showConfirm: projectMemberAllowedActions.isMember,
          testId: 'worksheet-view-request-budget-modal',
        })(Content);
        approveModalData.Form = (
          <ApproveForm
            onSubmit={() => {
              if (!projectMemberAllowedActions.isMember) {
                return;
              }
              const nextLocation = projectRequestBudgetUrl(
                match.params.orgAlias,
                report.projectId,
              );
              history.push(nextLocation);
            }}
          />
        );
      } else {
        const Content = () => (
          <>
            <p>You cannot approve this worksheet:</p>
            <p>{report.allowedActions.canBeFunded.reasonLabel}</p>
          </>
        );
        const ApproveForm = withModalForm({
          heading: 'You cannot approve this worksheet',
          showConfirm: false,
          testId: 'worksheet-view-cannot-approve-modal',
        })(Content);
        approveModalData.Form = <ApproveForm />;
      }
    }

    const ConfirmForm = withModalForm({
      heading: 'Confirm worksheet',
      confirmLabel: 'Confirm',
      confirmStyle: BS_STYLE.SUCCESS,
    })(ReasonForm);

    const confirmModalData = canConfirmWorksheet && canFundWorksheet ? ({
      key: 'worksheetConfirmForm',
      btnLabel: 'Confirm',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      btnClassName: 'text-success',
      Form: (
        <ConfirmForm
          onSubmit={values => (
            dispatch(financeWorksheetConfirmDS({
              data: values,
              componentName,
              orgAlias: match.params.orgAlias,
              documentId: match.params.id,
            }))
          )}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          question="Are you sure you want to confirm this Worksheet?"
        />
      ),
    }) : null;

    const RequestProjectBudgetForm = withModalForm({
      heading: 'Insufficient funds',
      confirmLabel: 'Request more budget',
      confirmStyle: BS_STYLE.SUCCESS,
    })(ReasonForm);

    const requestProjectBudgetModalData = canConfirmWorksheet && !canFundWorksheet ? ({
      key: 'worksheetConfirmForm',
      btnLabel: 'Confirm',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      btnClassName: 'text-success',
      Form: (
        <RequestProjectBudgetForm
          hasReason={false}
          onSubmit={() => {
            const followedBackUrl = followBackUrlIfPresent(history);

            if (!followedBackUrl) {
              const nextLocation = projectRequestBudgetUrl(match.params.orgAlias, report.projectId);
              history.push(nextLocation);
            }
          }}
          question={(
            <React.Fragment>
              <p>There are not enough funds in the project.</p>
              <p>The worksheet will not be processed until you add more budget to the project.</p>
            </React.Fragment>
          )}
        />
      ),
    }) : null;

    const RejectForm = withModalForm({
      heading: 'Reject worksheet',
      confirmLabel: 'Reject',
      confirmStyle: BS_STYLE.DANGER,
    })(ReasonForm);

    const rejectModalData = !canRejectWorksheet ? null : {
      key: 'worksheetRejectForm',
      btnLabel: 'Reject',
      btnVariant: BS_STYLE.DANGER,
      btnIcon: ICON.CROSS,
      btnClassName: 'text-danger',
      Form: (
        <RejectForm
          onSubmit={values => (
            dispatch(financeWorksheetRejectDS({
              data: values,
              componentName,
              orgAlias: match.params.orgAlias,
              documentId: match.params.id,
            }))
          )}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          question="Are you sure you want to reject this worksheet?"
        />
      ),
    };

    const CancelForm = withModalForm({
      heading: 'Cancel worksheet',
      withFooter: false,
    })(WorksheetCancelForm);

    const cancelModalData = !canCancelWorksheet ? null : {
      key: 'worksheetCancelForm',
      btnLabel: 'Cancel worksheet',
      btnVariant: BS_STYLE.SECONDARY,
      btnClassName: 'text-danger',
      Form: (
        <CancelForm
          initialValues={{
            quantity: report.quantity,
            summary: report.summary,
            attachments: report.attachments,
          }}
          worksheet={report}
          orgAlias={match.params.orgAlias}
          onCancelSuccess={this.onSubmitSuccess}
          question="Are you sure you want to cancel this worksheet?"
        />
      ),
    };

    const providerName = report.provider && report.provider.user && report.provider.user.profile
      ? `${report.provider.user.profile.name}’s`
      : 'the';

    const RequestAmendmentForm = withModalForm({
      heading: `Do you require any changes on ${providerName} worksheet?`,
      confirmLabel: 'Submit',
    })(BillableRequestAmendmentForm);

    const requestAmendmentModalData = !canRequestWorksheetAmendment ? null : {
      key: 'worksheetRequestAmendmentForm',
      btnLabel: 'Request amendment',
      btnVariant: BS_STYLE.WARNING,
      btnIcon: ICON.EDIT,
      Form: (
        <RequestAmendmentForm
          initialValues={{}}
          onSubmit={this.onAmendmentRequested}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          billable={report}
          billableLabel="worksheet"
        />
      ),
    };

    let voidModalData = null;
    if (canVoidWorksheet) {
      const VoidWorksheetForm = withModalForm({
        heading: `Void Worksheet #${report.id}`,
        confirmLabel: 'Void',
      })(BillableVoid);

      voidModalData = {
        key: 'worksheetVoidForm',
        btnLabel: 'Void',
        btnVariant: BS_STYLE.WARNING,
        btnIcon: ICON.REMOVE_CIRCLE,
        Form: (
          <VoidWorksheetForm
            initialValues={{}}
            onSubmit={this.handleWorksheetVoid}
            onSubmitSuccess={() => followBackUrlIfPresent(history)}
            billableTypeLabel="worksheet"
          />
        ),
      };
    } else if (!canVoidWorksheet && canManageAnyWorksheet && report?.isInvoiced) {
      const VoidWorksheetIMpossibleForm = withModalForm({
        heading: `Void Worksheet #${report.id}`,
        cancelLabel: 'Close',
        showConfirm: false,
      })(BillableVoidNotAllowed);

      voidModalData = {
        key: 'worksheetVoidImpossible',
        disabled: true,
        btnLabel: 'Void',
        btnVariant: BS_STYLE.WARNING,
        btnIcon: ICON.REMOVE_CIRCLE,
        Form: (
          <VoidWorksheetIMpossibleForm
            billableTypeLabel="worksheet"
          />
        ),
      };
    }

    const projectLink = (
      <ReportProjectLink
        projectId={report.projectId}
        orgAlias={match.params.orgAlias}
        projectReference={report.externalProjectId ? `${report.projectReference} (${report.externalProjectId})` : report.projectReference}
        allowedActions={projectMemberAllowedActions}
      />
    );

    const invoiceInfo = (
      <ReportInvoiceLinks
        orgAlias={match.params.orgAlias}
        invoices={report.invoices}
      />
    );

    const billingPeriod = (
      <BillingPeriodWidget
        period={report.period}
        endDate={report.periodEnd}
        startDate={report.periodStart}
        id={report.id}
      />
    );

    const heading = report.projectReference ? `${report.projectReference}_${report.id}` : '';
    const detailsRows = [
      { label: 'Project Ref', value: projectLink },
      { label: 'Invoice No', value: invoiceInfo },
      { label: 'Worksheet No', value: report.id },
      { label: 'Date', value: report.createdAt },
      { label: 'Billing Period', value: billingPeriod },
      {
        label: 'Status',
        value: (
          <ReportAuditTrail
            heading={heading}
            report={report}
            reportType={REPORT_TYPES.WORKSHEET}
          />
        ),
      },
    ];

    const orgId = report.org && report.org.id;
    const userProfile = get(report, 'provider.user.profile');
    const userType = get(report, 'provider.userCard.userRole.ofType');
    const editUrl = report.org && determineReportEditUrl(report.org.unique_alias, report);

    return (
      <ReportViewLayout
        reportType={REPORT_TYPES.WORKSHEET}
        actions={[
          report.allowedActions && !!report.allowedActions.canBeEdited ? {
            eventKey: 'editWorksheet',
            label: 'Edit',
            onClick: () => history.push(editUrl),
            variant: BS_STYLE.PRIMARY,
          } : null,
          approveModalData || confirmModalData || requestProjectBudgetModalData,
          rejectModalData,
          requestAmendmentModalData,
          cancelModalData,
          voidModalData,
        ]}
        heading={heading}
        status={status}
        report={report}
        reportActionRequest={(
          showReviewRequest
          && report.statusCode === WORKSHEET_STATUS.APPROVED
          && userType === USER_TYPE.PROVIDER
            ? (
              <ReviewRequestItem
                profile={userProfile}
                project={{ id: report.projectId, title: report.projectTitle }}
                orgId={orgId}
                userType={userType}
              />
            ) : null
        )}
      >
        <TDApiConnected
          duck="view"
          component={this.constructor}
          blockingLoading
          skeletonComponent={WorksheetViewSkeleton}
        >
          <div className="finance-report__head row">
            {report.provider && (
              <ReportLogo
                className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0"
                company={report.provider.company}
                user={report.provider.user}
                profilePicFallback
              />
            )}
            <div className="finance-report__meta col-12 col-md-6 col-lg-4">
              <ReportDetails rows={detailsRows} />
            </div>
          </div>

          <hr />

          {report.financialContext && (
            <div className="row mb-4">
              <div className="finance-report__provider col-12 col-md-8">
                <ReportFinancialEntity label="From" financialEntity={report.financialContext.ownerFE} />
              </div>
              <div className="finance-report__provider__details col-12 col-md-4">
                <ReportFinancialEntity label="Addressed to" financialEntity={report.financialContext.recipientFE} />
              </div>
            </div>
          )}

          <div className="row mb-4">
            <div className="finance-report__provider col-12">
              <div>
                {shouldRenderCompany && (
                  <ReportCompany {...report.provider} />
                )}
                <div className="finance-report__provider__details">
                  <h5>Personal Details</h5>
                  <ReportPerson {...report.provider} financialEntity={fe} withJobTitle />
                </div>


                {report && report.attachments && report.attachments.length > 0 && (
                  <div className="mt-5">
                    <h5>Attachments</h5>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <AttachmentsList label={null} attachments={report.attachments} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="finance-report__table-wrapper finance-report__table-wrapper--list finance-report__table-wrapper--responsive">
            <WorksheetsTable
              rateUnit={report.rateUnit}
              list={reports}
              allowedActions={projectMemberAllowedActions}
              emptyText="No worksheets found"
              isWorksheetView
            />
            <div className="finance-report__totals">
              {this.renderTotals()}
            </div>
          </div>

          {report.financialContext && (
            <div className="finance-report__payment__terms mt-5">
              <span>
                Upon approval, the worksheet will be included in the next invoice and an additional
                {' '}
                {report.financialContext.vatPercent}
                % VAT will be applied to the amount
              </span>
            </div>
          )}
          <div className="mt-4 finance-report__organization-footer-info">
            <ReportFooter owner={report.provider} />
          </div>
        </TDApiConnected>
      </ReportViewLayout>
    );
  }
}

WorksheetView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  organization: orgSpec.isRequired,
  profile: profileSpec,
  history: routerHistorySpec.isRequired,
  report: worksheetSpec,
  allowedActions: PropTypes.shape({
    [ALLOWED_ACTIONS.WORKSHEET]: worksheetAllowedActionsSpec,
    [ALLOWED_ACTIONS.PROJECT_MEMBER]: projectMemberAllowedActionsSpec,
  }),
};

WorksheetView.defaultProps = {
  profile: {},
  report: {},
  allowedActions: {
    [ALLOWED_ACTIONS.WORKSHEET]: {},
    [ALLOWED_ACTIONS.PROJECT_MEMBER]: {},
  },
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, WorksheetView.GetComponentName());

  return {
    match: props.match,
    organization: selectActiveOrg(state),
    profile: selectProfile(state),
    report: viewState.item,
    allowedActions: getViewStateExtras(state, WorksheetView.GetComponentName(), 'accessControl'),
  };
};
const WorksheetViewConnect = connect(
  mapStateToProps, mapDispatchToProps,
)(WorksheetView);

export default withRouter(WorksheetViewConnect);
