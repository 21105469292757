import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchListDS, getListState, listUpdateItemAC } from 'core/assets/js/ducks/list';
import { paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { userDocumentsApiUrl } from 'people/urls';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { getAorInfoApiUrl } from 'documents/urls';
import { BS_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_CLASS, SUBMISSION_STATUS_LABEL } from 'interviews/assets/js/constants';
import { Dropdown } from 'react-bootstrap';
import { formatDate } from 'core/assets/js/lib/utils';
import PeopleRatesTabSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import DocumentAssignmentsListItem from 'core/assets/js/components/DocumentAssignmentsListItem.jsx';
import AorDocumentListItem from 'core/assets/js/components/AorDocumentListItem.jsx';
import StatusTag from 'core/assets/js/components/StatusTag';
import SubmissionViewModal, { SUBMISSION_VIEW_MODAL_ID } from 'interviews/assets/js/components/SubmissionViewModal';
import TDButton from 'core/assets/js/components/TDButton';
import TDDropdown from 'core/assets/js/components/TDDropdown';
import TDList from 'core/assets/js/components/TDList.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { submissionDownloadApiUrl } from 'interviews/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const COMPONENT_NAME = 'documentAssignments';

const PeopleDocumentsTab = ({ activeOrg, items, aor, pagination, userCard }) => {
  const dispatch = useDispatch();
  const { submissions } = userCard;
  return (
    <div className="tab-content w-100 p-4">
      { (aor.aorEnabled && aor.aorContract.fileUrl)  && (
        <div className="aor-list pb-6">
          <h2>Agent of Record</h2>
          <AorDocumentListItem aorData={
            {
              signedAt: aor.aorContract.signedAt,
              status: aor.aorContract.status,
              docUrl: aor.aorContract.fileUrl,
              userFullName: aor.aorContract.user.fullName,
            }
          }
          />
        </div>
      )}
      <div className="documents-list w-100">
        <h2>Required documents</h2>
        {items?.length > 0 && (
          <TDList
            listClassName="profile-documents-list"
            items={items}
            pagination={pagination}
            cardItem={{
              // eslint-disable-next-line react/prop-types, react/no-multi-comp
              component: ({ item }) => (
                <DocumentAssignmentsListItem
                  documentAssignment={item}
                  onUpdated={updatedAssignment => {
                    dispatch(listUpdateItemAC(item.id, updatedAssignment, COMPONENT_NAME));
                  }}
                />
              ),
            }}
            emptyListMessage="There aren't any documents yet."
          />
        )}

        {submissions.map(submission => {
          const { id, allowedActions, status, name, reviewedAt } = submission;
          // Show only approved submissions
          if (![
            SUBMISSION_STATUS.SUBMITTED,
            SUBMISSION_STATUS.APPROVED,
          ].includes(status)) {
            return null;
          }

          if (!allowedActions.canView) {
            return null;
          }

          return (
            <React.Fragment key={id}>
              <div className="bg-light-grey px-4 py-3 mb-3 rounded">
                <div className="d-flex row">
                  <div className="col-12 col-sm">
                    <a
                      className="text-secondary font-weight-bold cursor-pointer"
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(modalOpenAC(SUBMISSION_VIEW_MODAL_ID, { submission }));
                      }}
                    >
                      {name}
                    </a>
                    <br />
                    <span className="discreet font-smaller">Onboarding form</span>
                    <br />
                    <span className="discreet font-smaller">
                      {`Approved: ${reviewedAt ? formatDate(reviewedAt) : '-'}`}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-auto mt-3 mt-md-0 align-self-center align-items-center ml-auto me-5 pe-5 d-flex flex-nowrap"
                  >
                    <div className="mr-5 pr-5 d-flex text-nowrap align-items-center">
                      <div className="mr-5 pr-5 px-5" />
                      <StatusTag
                        label={SUBMISSION_STATUS_LABEL[status]}
                        statusClass={SUBMISSION_STATUS_CLASS[status]}
                      />
                    </div>
                    <div className="ml-auto">
                      <TDDropdown
                        variant={BS_STYLE.LINK}
                        noCaret
                        alignRight
                        toggle={(
                          <TDButton
                            bsSize={BS_SIZE.SMALL}
                            variant={BS_STYLE.DEFAULT}
                            btnIcon={<i className={ICON.CHEVRON_DOWN} />}
                          />
                        )}
                      >
                        <Dropdown.Item
                          key="view-submission"
                          onClick={() => dispatch(modalOpenAC(
                            SUBMISSION_VIEW_MODAL_ID,
                            { submission }),
                          )}
                        >
                          View
                        </Dropdown.Item>

                        <Dropdown.Item
                          key="download-submission"
                          onClick={() => WINDOW_OPEN(
                            submissionDownloadApiUrl(activeOrg.alias, id),
                            '_blank',
                          )}
                        >
                          Download
                        </Dropdown.Item>
                      </TDDropdown>
                    </div>
                  </div>
                </div>
              </div>

              <SubmissionViewModal />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

PeopleDocumentsTab.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  items: PropTypes.array,
  aor: PropTypes.object,
  userCard: PropTypes.object.isRequired,
  pagination: paginationSpec.isRequired,
};

PeopleDocumentsTab.defaultProps = {
  items: [],
  aor: {},
};

const PeopleDocumentsTabTDApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params, authedAxios, querystring, componentName,
  }) => {
    const { orgAlias, userId, userType } = params;

    return Promise.all([
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        params,
        querystring,
        url: userDocumentsApiUrl({ orgAlias, userId, userType }),
      })),
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        url: getAorInfoApiUrl(orgAlias, userId),
        extrasKey: 'aor',
      })),
    ]);
  },
  storeKey: COMPONENT_NAME,
  duck: 'list',
  skeletonComponent: () => <PeopleRatesTabSkeleton className="px-4 mt-5" />,
})(PeopleDocumentsTab);

const mapStateToProps = (state) => {
  const listState = getListState(state, COMPONENT_NAME);

  return {
    activeOrg: selectActiveOrg(state),
    pagination: listState.pagination,
    aor: listState.extras?.aor,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PeopleDocumentsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleDocumentsTabTDApiConnected);
export default withRouter(PeopleDocumentsTabConnected);
