import { intersection, isEmpty, isNil, isObject, omit } from 'lodash';

import { US_STATES } from 'core/assets/js/constants';
import { countryNames } from 'core/assets/js/lib/isoCountries';

class AddressComponent {
  static fromGMaps(component) {
    const params = {
      shortName: component.short_name,
      longName: component.long_name,
      types: component.types,
    };
    return new AddressComponent(params);
  }

  constructor({ name, shortName, longName, types }) {
    this.shortName = shortName || name;
    this.longName = longName || name;
    this.types = types;
  }

  isAddressLine1() {
    const { types } = this;
    return types.includes('address_line_1');
  }

  isAddressLine2() {
    const { types } = this;
    return types.includes('address_line_2');
  }

  isStreet() {
    const { types } = this;
    return types.includes('route');
  }

  isStreetNumber() {
    const { types } = this;
    return types.includes('street_number');
  }

  isCity() {
    const { types } = this;
    return !isEmpty(intersection(types, ['sublocality', 'locality']));
  }

  isPostcode() {
    const { types } = this;
    return types.includes('postal_code');
  }

  isRegion() {
    const { types } = this;
    return !isEmpty(intersection(types, [
      'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3',
    ]));
  }

  isCountry() {
    const { types } = this;
    return types.includes('country');
  }

  isState() {
    const { types } = this;
    return types.includes('administrative_area_level_1');
  }

  toObject() {
    const { types, shortName, longName } = this;
    return {
      short_name: shortName,
      long_name: longName,
      types,
    };
  }
}

class Address {
  static parseComponents(components) {
    if (!components || isEmpty(components)) {
      return [];
    }
    const addressComponents = components.map(c => AddressComponent.fromGMaps(c));
    return addressComponents;
  }

  constructor(address, { pristine = true } = {}) {
    this.address = isObject(address) && address !== null ? address : {};
    this.components = isEmpty(this.address)
      ? []
      : Address.parseComponents(this.address.gmaps.address_components);
    const hasAddressLine = this.components.find(c => (c.isAddressLine1() || c.isAddressLine2()));
    this.pristine = isNil(this.address.pristine)
      ? (pristine || hasAddressLine)
      : this.address.pristine;
    this.pristineLocation = isNil(this.address.pristineLocation)
      ? pristine : this.address.pristineLocation;
    if (isEmpty(this.components)) {
      this.mapped = {};
    } else {
      this.mapped = {
        addressLine1: this.components.find(c => c.isAddressLine1()),
        addressLine2: this.components.find(c => c.isAddressLine2()),
        street: this.components.find(c => c.isStreet()),
        streetNumber: this.components.find(c => c.isStreetNumber()),
        city: this.components.find(c => c.isCity()),
        postcode: this.components.find(c => c.isPostcode()),
        region: this.components.find(c => c.isRegion()),
        country: this.components.find(c => c.isCountry()),
        state: this.components.find(c => c.isState()),
      };
    }
  }

  get formatted() {
    const { pristine, address } = this;
    if (pristine) {
      return address.description;
    }
    const { addressLine1, addressLine2, city, country, postcode, region, state } = this;
    const nameParts = [
      addressLine1, addressLine2, postcode, city, region, country, state,
    ].filter(c => !!c);
    const name = nameParts.join(', ');
    return name;
  }

  get gmapsAddressLine() {
    const { mapped: { street, streetNumber } } = this;
    const name = [street, streetNumber].filter(c => !!c).map(c => c.longName).join(' ');
    return name;
  }

  get addressLine1() {
    const { gmapsAddressLine, mapped: { addressLine1 } } = this;
    if (addressLine1) {
      return addressLine1.longName;
    }
    return gmapsAddressLine;
  }

  set addressLine1(value) {
    // we deliberately leave 'pristineLocation' as it were and change only 'pristine'
    // because this change is only in the address line and should probably have quite a
    // close location regarding coordinates
    this.pristine = false;
    this.mapped.addressLine1 = new AddressComponent({ types: ['address_line_1'], name: value });
    // delete previous
    this.mapped.street = undefined;
    this.mapped.streetNumber = undefined;
  }

  get addressLine2() {
    const { mapped: { addressLine2 } } = this;
    return addressLine2 ? addressLine2.longName : '';
  }

  set addressLine2(value) {
    // we deliberately leave 'pristineLocation' as it were and change only 'pristine'
    // because this change is only in the address line and should probably have quite a
    // close location regarding coordinates
    this.pristine = false;
    this.mapped.addressLine2 = new AddressComponent({ types: ['address_line_2'], name: value });
  }

  get city() {
    const { mapped: { city } } = this;
    return city ? city.longName : '';
  }

  set city(value) {
    this.pristine = false;
    this.pristineLocation = false;
    this.mapped.city = new AddressComponent({ types: ['locality'], name: value });
  }

  get postcode() {
    const { mapped: { postcode } } = this;
    return postcode ? postcode.longName : '';
  }

  set postcode(value) {
    this.pristine = false;
    this.pristineLocation = false;
    this.mapped.postcode = new AddressComponent({ types: ['postal_code'], name: value });
  }

  get region() {
    const { mapped: { region } } = this;
    return region ? region.longName : '';
  }

  set region(value) {
    this.pristine = false;
    this.pristineLocation = false;
    this.mapped.region = new AddressComponent({
      types: ['administrative_area_level_3'], name: value,
    });
  }

  get state() {
    const { mapped: { state } } = this;
    return state ? state.longName : '';
  }

  get stateCode() {
    const { mapped: { state } } = this;
    return state ? state.shortName : '';
  }

  set stateCode(value) {
    this.pristine = false;
    this.pristineLocation = false;
    this.mapped.state = new AddressComponent({
      types: ['administrative_area_level_1'], shortName: value, longName: US_STATES[value],
    });
  }

  get country() {
    const { mapped: { country } } = this;
    return country ? country.longName : '';
  }

  get countryCode() {
    const { mapped: { country } } = this;
    return country ? country.shortName : '';
  }

  set countryCode(value) {
    this.pristine = false;
    this.pristineLocation = false;
    this.mapped.country = new AddressComponent({
      types: ['country'], shortName: value, longName: countryNames[value],
    });
  }

  toObject() {
    const { formatted, pristine, pristineLocation, address } = this;
    const omittedKeys = [];
    if (!pristineLocation) {
      omittedKeys.push('location');
    }
    if (!pristine) {
      omittedKeys.push('placeId');
    }
    const res = {
      ...omit(address, omittedKeys),
      label: formatted,
      description: formatted,
      gmaps: {
        ...(pristine ? address.gmaps : omit(address.gmaps, ['place_id', 'address_components'])),
        address_components: this.componentsToArray(),
        formatted_address: formatted,
      },
      pristine,
      pristineLocation,
    };
    return res;
  }

  componentsToArray() {
    const { pristine, mapped, components } = this;
    if (pristine) {
      return components.map(c => c.toObject());
    }
    const res = Object.values(mapped).filter(c => !!c).map(c => c.toObject());
    return res;
  }
}


export default Address;
