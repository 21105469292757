/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  modalOpenAC, getIsModalOpen, modalCloseAC,
} from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { BS_STYLE } from 'core/assets/js/constants';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';

const MEMBERS_ACCESS_TEAM_PAGE_ENABLE_MODAL_ID = 'members-access-team-page-enable-modal';
const MEMBERS_ACCESS_TEAM_PAGE_DISABLE_MODAL_ID = 'members-access-team-page-disable-modal';

const MembersAccessTeamPageEnableModalBody = () => (
  <>
    <p>
      By default, allow all project members to interact and collaborate with each other by giving
      them access to the project team members page, when creating a new project.
    </p>
    <p className="mb-4">Are you sure you want to activate this setting?</p>
  </>
);

const MembersAccessTeamPageDisableModalBody = () => (
  <>
    <p>
      By deactivating this setting, when creating a new project, by default only the project
      managers will be able to view the full list of project members. The providers will not be able
      to see or interact with any other project member. They will only interact with a project
      manager when the latter assigns them to a task.
    </p>
    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
  </>
);

class OrgPrivacySettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };

    this.handleSaveReviewView = this.handleSaveReviewView.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSaveInstantApproval = this.handleSaveInstantApproval.bind(this);
  }

  getReviewViewNewValue() {
    const { organization } = this.props;
    return !organization.should_provider_view_other_providers_review;
  }

  getInstantApprovalNewValue() {
    const { organization } = this.props;
    return !organization.should_high_manager_manage_instant_approval;
  }

  handleSaveReviewView() {
    return this.submitChanges({
      should_provider_view_other_providers_review: this.getReviewViewNewValue(),
    });
  }

  handleSaveInstantApproval() {
    return this.submitChanges({
      should_high_manager_manage_instant_approval: this.getInstantApprovalNewValue(),
    });
  }

  async submitChanges(payload) {
    const { onSettingsChanged } = this.props;

    this.setState({ isSubmitting: true });
    await onSettingsChanged(payload);
    this.handleModalClose();
    this.setState({ isSubmitting: false });
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      dispatch,
      isDisableAllowTeamMembersAccessTeamPageOpen,
      isEnableAllowTeamMembersAccessTeamPageOpen,
      organization: {
        allow_providers_access_team_page: allowProvidersAccessTeamPage,
      },
    } = this.props;

    const { isSubmitting } = this.state;

    return (
      <div className="settings-row">
        {
          /*
           * Hide when roles & permissions feature is enabled as it will be
           * ignored by submissions access control.
          */
        }

        <h3 className="heading-block">Projects</h3>
        <div className="d-flex flex-container--standalone align-items-center">
          <div className="settings-row__content">
            <label>Allow all project team members to access the project team page</label>
            <p className="discreet">
              By activating this option both managers and providers can view the project team
              members page of a project
            </p>
          </div>

          <div className="settings-row__cta">
            <TDSwitch
              selected={allowProvidersAccessTeamPage}
              onClick={() => {
                dispatch(modalOpenAC(allowProvidersAccessTeamPage
                  ? MEMBERS_ACCESS_TEAM_PAGE_DISABLE_MODAL_ID
                  : MEMBERS_ACCESS_TEAM_PAGE_ENABLE_MODAL_ID,
                ));
              }}
            />
          </div>
        </div>

        <>
          {[
            {
              content: <MembersAccessTeamPageEnableModalBody />,
              heading: 'Access to the project team is restricted to managers only',
              open: isEnableAllowTeamMembersAccessTeamPageOpen,
              submit: () => this.submitChanges({ allow_providers_access_team_page: true }),
              submitLabel: 'Activate',
              submitVariant: BS_STYLE.PRIMARY,
            },
            {
              content: <MembersAccessTeamPageDisableModalBody />,
              heading: 'Access to the project team is open to all project members',
              open: isDisableAllowTeamMembersAccessTeamPageOpen,
              submit: () => this.submitChanges({ allow_providers_access_team_page: false }),
              submitLabel: 'Deactivate',
              submitVariant: BS_STYLE.DANGER,
            },
          ].map(props => (
            <ModalSimple
              footer={[
                <TDButton
                  key={2}
                  className="mr-4 float-right"
                  label="Cancel"
                  disabled={isSubmitting}
                  onClick={() => this.handleModalClose()}
                />,
                <TDButton
                  key={1}
                  className="float-right"
                  label={props.submitLabel}
                  variant={props.submitVariant}
                  disabled={isSubmitting}
                  onClick={() => props.submit()}
                />,
              ]}
              heading={props.heading}
              key={props.heading}
              onClose={() => this.handleModalClose()}
              open={props.open}
            >
              {props.content}
            </ModalSimple>
          ))}
        </>
      </div>
    );
  }
}

OrgPrivacySettings.propTypes = {
  organization: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSettingsChanged: PropTypes.func.isRequired,
  isEnableAllowTeamMembersAccessTeamPageOpen: PropTypes.bool,
  isDisableAllowTeamMembersAccessTeamPageOpen: PropTypes.bool,
};

OrgPrivacySettings.defaultProps = {
  isEnableAllowTeamMembersAccessTeamPageOpen: false,
  isDisableAllowTeamMembersAccessTeamPageOpen: false,
};

const mapStateToProps = state => ({
  isEnableAllowTeamMembersAccessTeamPageOpen: getIsModalOpen(
    state,
    MEMBERS_ACCESS_TEAM_PAGE_ENABLE_MODAL_ID,
  ),
  isDisableAllowTeamMembersAccessTeamPageOpen: getIsModalOpen(
    state,
    MEMBERS_ACCESS_TEAM_PAGE_DISABLE_MODAL_ID,
  ),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgPrivacySettings);
