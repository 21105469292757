import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import { toastr } from 'react-redux-toastr';

import { totpSetupUrl } from 'accounts/urls';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import {
  IS_DEVELOPMENT, IS_STAGING, WINDOW_HOSTNAME, WINDOW_OPEN, WRITE_TO_CLIPBOARD,
} from 'core/assets/js/config/settings';
import { ICON } from 'core/assets/js/constants';

const ScanQR = ({ email, recoveryCodes, secret }) => {
  const [totpSecretCopied, setTOTPSecretCopied] = useState(false);
  const qrCodeRef = useRef();

  let issuer = 'TalentDesk';
  if (IS_STAGING) {
    const subdomain = WINDOW_HOSTNAME.split('.')[0];
    issuer += ` (${['app', 'staging'].includes(subdomain) ? 'next' : subdomain})`;
  } else if (IS_DEVELOPMENT) {
    issuer += ' (local)';
  }
  const totpUrl = totpSetupUrl(email, secret, issuer);

  useEffect(() => {
    if (secret && qrCodeRef.current) {
      QRCode.toCanvas(qrCodeRef.current, totpUrl, error => {
        if (error) {
          toastr.error('Oh Snap!', error.message);
        }
      });
    }
  }, [secret, qrCodeRef.current]);

  return (
    <>
      <h2>Setup authenticator app</h2>
      <p>Open authenticator app and choose scan QR code</p>
      <div className="d-flex flex-column align-items-center w-100">
        <canvas
          className="qr-code-2fa"
          onClick={() => WINDOW_OPEN(totpUrl, '_blank')}
          ref={qrCodeRef}
        />
        <p className="mt-3">Or enter the code manually</p>
        <div className="totp-secret d-flex align-items-center mb-5">
          <div className="px-3 text-center">{secret}</div>
          <div className="px-3 copy">
            <i
              className={(
                totpSecretCopied ? ICON.CHECKMARK : ICON.COPY_LIGHT
              )}
              onClick={async () => {
                await WRITE_TO_CLIPBOARD(secret);
                setTOTPSecretCopied(true);
                setTimeout(() => setTOTPSecretCopied(false), 3000);
              }}
            />
          </div>
        </div>
        <a
          className="mb-5"
          download="talentdesk-recovery-codes.txt"
          href={`data:text/plain;charset=utf-8,${encodeURIComponent(recoveryCodes.join('\n'))}`}
        >
          <i className={`${ICON.DOWNLOAD} mr-3`} />
          Download recovery codes
        </a>
        <CheckboxField
          name="downloadedRecoveryCodes"
          options={[{
            text: 'I have downloaded my recovery codes', value: true,
          }]}
          wrapperClasses="text-center mb-0"
        />
      </div>
    </>
  );
};

ScanQR.propTypes = {
  email: PropTypes.string.isRequired,
  recoveryCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  secret: PropTypes.string.isRequired,
};

export default ScanQR;
