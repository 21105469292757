import { isEmpty, isEqual } from 'lodash';
import { Money } from 'td-finance-ts';

import { DEFAULT_BALANCE_CURRENCY } from 'finance/assets/js/constants';


/**
 * This class is used to calculate the IdentityCheck charges applied to an organization.
 * While the IdentityCheckFeeScheme is the record of the service settings,
 * the IdentityCheckFeeAnalysis is the actual calculated charges.
 */
class IdentityCheckFeeAnalysis {
  static getEmptyAnalysis() {
    return new IdentityCheckFeeAnalysis();
  }

  /**
   * It is used for de-serializing a IdentityCheckFeeAnalysis serialized object
   *
   * @param {Object} serialized
   *
   * @returns {IdentityCheckFeeAnalysis}
   */
  constructor(...serialized) {
    if (isEmpty(serialized) || serialized.every(s => isEmpty(s))) {
      return this.init();
    }
    return this.init(...serialized);
  }

  /**
   *
   * @param {Object} params
   * @param {String} params.currency - Identity check service currency
   * @param {Number} params.basePrice - Price which will be charged for Identity check usage
   * @param {Boolean} params.hasBeenEnabled - Check if Identity check service was enabled
   * @returns {IdentityCheckFeeAnalysis}
   */
  init({
    currency = DEFAULT_BALANCE_CURRENCY,
    basePrice = '0.00',
    hasBeenEnabled = false,
    numIdentityChecks = 0,
    total,
  } = {}) {
    this.details = {
      currency,
      basePrice,
      hasBeenEnabled,
      numIdentityChecks,
    };

    Object.assign(this.details, {
      total: total || this.aggregate(),
    });
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  aggregate() {
    const {
      basePrice,
      hasBeenEnabled,
      currency,
      numIdentityChecks,
    } = this.details;
    if (!hasBeenEnabled) {
      return '0.00';
    }
    const fee = new Money(basePrice, currency).mul(numIdentityChecks);
    return fee.toString();
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getTotal() {
    return this.aggregate();
  }

  getNumChecks() {
    const { numIdentityChecks } = this.details;
    return numIdentityChecks || 0;
  }

  /**
   * Compares the analysis passed with the current one
   *
   * @param {IdentityCheckFeeAnalysis} analysisToCompareWith
   * @returns {boolean}
   */
  isEqual(analysisToCompareWith) {
    return isEqual(this, analysisToCompareWith);
  }

  /**
   * Checks if the total calculated as the analysis total is zero
   *
   * @returns {Boolean}
   */
  isZero() {
    return new Money(this.getTotal(), this.getCurrency()).isZero();
  }

  /**
   * Checks if an analysis is empty
   *
   * @returns {Boolean}
   */
  isEmpty() {
    const emptyAnalysis = IdentityCheckFeeAnalysis.getEmptyAnalysis();
    return this.isEqual(emptyAnalysis);
  }

  /**
   * Returns whether the service has been enabled
   *
   * @returns {Boolean}
   */
  isEnabled() {
    const { hasBeenEnabled } = this.details;
    return hasBeenEnabled;
  }

  /**
   * @returns {String}
   */
  getServiceTitle() {
    return `Identity checks (${this.getNumChecks()})`;
  }

  /**
   * @returns {String}
   */
  getServiceMetricsDescriptions() {
    return [{
      description: 'Identity checks',
      fee: this.getTotal(),
      quantity: this.getNumChecks(),
    }];
  }
}

export default IdentityCheckFeeAnalysis;
