import { isEmpty, omit } from 'lodash';
import { assert, Money } from 'td-finance-ts';

import { DEFAULT_BALANCE_CURRENCY, SERVICE_KEY_NAME } from 'finance/assets/js/constants';
import AORFeeAnalysis from 'finance/assets/js/lib/fees/AORFeeAnalysis';
import ApiFeeAnalysis from 'finance/assets/js/lib/fees/ApiFeeAnalysis';
import CodatFeeAnalysis from 'finance/assets/js/lib/fees/CodatFeeAnalysis';
import ESignFeeAnalysis from 'finance/assets/js/lib/fees/ESignFeeAnalysis';
import Ten99FeeAnalysis from 'finance/assets/js/lib/fees/Ten99FeeAnalysis';
import TINFeeAnalysis from 'finance/assets/js/lib/fees/TINFeeAnalysis';
import SeatFeeAnalysis from 'finance/assets/js/lib/fees/SeatFeeAnalysis';
import IdentityCheckFeeAnalysis from 'finance/assets/js/lib/fees/IdentityCheckFeeAnalysis';


class LicenceFeeAnalysis {
  /**
   * This constructor is using set for backwards compatibility
   * It is used for de-serializing a LicenceFeeAnalysis serialized object
   *
   * @param {Object} args - the LicenceFeeAnalysis -> serialized
   *
   * @returns {LicenceFeeAnalysis}
   *
   */
  constructor(args) {
    if (!args || isEmpty(args)) {
      this.init({
        currency: DEFAULT_BALANCE_CURRENCY,
        seats: null,
      });
    } else if (Array.isArray(args) && !isEmpty(args)) {
      this.init({
        currency: args[0].currency,
        seats: args[0],
      });
    } else if (!isEmpty(args.parts)) {
      this.init({
        ...omit(args, ['parts']),
        seats: args.parts[0],
      });
    } else {
      // this is for the newer cases
      this.init(args);
    }
  }

  /**
   *
   * @param {params} params
   * @param {String} params.currency - The currency which will be used for the main conversion
   * of all service totals
   * @param {Object} params.seats - The serialized result of SeatsFeeAnalysis
   * @param {Object} params.aor - The serialized result of AORFeeAnalysis
   * @param {Object} params.api - The serialized result of ApiFeeAnalysis
   * @param {Object} params.codat - The serialized result of CodatFeeAnalysis
   * @param {Object} params.esign - THe serialised result of ESignFeeAnalysis
   * @param {Object} params.ten99 - THe serialised result of Ten99FeeAnalysis
   * @param {Object} params.tin - THe serialised result of TINFeeAnalysis
   * @param {Object} params.identityCheck - THe serialised result of IdentityCheckFeeAnalysis
   */
  init({
    currency, seats, aor, api, codat, esign, ten99, tin,
    identityCheck,
  }) {
    if (!currency) {
      throw new Error('currency is required');
    }
    this.details = {
      currency,
      seats: new SeatFeeAnalysis(seats),
      aor: new AORFeeAnalysis(aor),
      api: new ApiFeeAnalysis(api),
      codat: new CodatFeeAnalysis(codat),
      esign: new ESignFeeAnalysis(esign),
      ten99: new Ten99FeeAnalysis(ten99),
      tin: new TINFeeAnalysis(tin),
      identityCheck: new IdentityCheckFeeAnalysis(identityCheck),
    };
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    const { currency, seats, aor, api, codat, esign, ten99, tin, identityCheck } = this.details;
    return {
      currency,
      seats: seats.serialize(),
      aor: aor.serialize(),
      api: api.serialize(),
      codat: codat.serialize(),
      esign: esign.serialize(),
      ten99: ten99.serialize(),
      tin: tin.serialize(),
      identityCheck: identityCheck.serialize(),
      total: this.getTotal(),
    };
  }

  getComponents() {
    const { seats, aor, api, codat, esign, ten99, tin, identityCheck } = this.details;
    return [seats, aor, api, codat, esign, ten99, tin, identityCheck];
  }

  /**
   * Returns an array of all service analyses that contain charges
   *
   * @returns {(SeatFeeAnalysis|*|AORFeeAnalysis|ApiFeeAnalysis|CodatFeeAnalysis)[]}
   */
  getChargedServiceAnalyses() {
    const components = this.getComponents();
    const chargedServiceAnalyses = components.filter(comp => !comp.isZero());

    return chargedServiceAnalyses;
  }

  aggregate() {
    const components = this.getComponents();
    if (isEmpty(components)) {
      return '0.00';
    }
    let fee = new Money(0, this.details.currency);
    components.forEach((part) => {
      fee = fee.add(part.getTotal());
    });
    return fee.toString();
  }

  reapply(licenceFeeScheme) {
    return licenceFeeScheme.apply({
      numManagers: this.getNumManagers(),
      numProviders: this.getNumProviders(),
    });
  }

  makeConsistentScheme(licenceFeeScheme) {
    const newScheme = licenceFeeScheme.copy();
    this.getComponents().forEach(p => p.makeConsistentScheme(newScheme));
    return newScheme;
  }

  isConsistent() {
    return this.getComponents().every(p => p.isConsistent());
  }

  isConsistentWithScheme(licenceFeeScheme) {
    return this.getComponents().every(p => p.isConsistentWithScheme(licenceFeeScheme));
  }

  getProvidersFee() {
    const { seats } = this.details;
    return seats.getProvidersFee();
  }

  getManagersFee() {
    const { seats } = this.details;
    return seats.getManagersFee();
  }

  getBaseFee() {
    const { seats } = this.details;
    return seats.getBaseFee();
  }

  getNumManagers() {
    const { seats } = this.details;
    return seats.getNumManagers();
  }

  getNumProviders() {
    const { seats } = this.details;
    return seats.getNumProviders();
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getTotal() {
    return this.aggregate();
  }

  isEmpty() {
    const total = this.getTotal();
    return new Money(total, this.getCurrency()).isZero();
  }

  /**
   * @returns {SeatFeeAnalysis}
   */
  getSeatAnalysis() {
    const { seats } = this.details;
    return seats;
  }

  /**
   * Get analysis for service key.
   *
   * @param {SERVICE_KEY_NAME} key - service to get analysis for.
   * @return {object} analysis for service.
   */
  getAnalysisForServiceKey(key) {
    assert(Object.values(SERVICE_KEY_NAME).includes(key), `Unhandled service key - ${key}`);
    return this.details[key];
  }
}

export default LicenceFeeAnalysis;
