import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { PERMISSIONS } from 'roles/assets/js/constants';
import { documentOptionsSpec } from 'documents/assets/js/lib/objectSpecs';
import { documentViewUrl } from 'documents/urls';
import { getActiveUserCardPermissionChecker, selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const SelectedDocumentsLinks = ({
  className, documentOptions, label, selectedDocumentIds: selectedDocumentIdsIn,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const canViewTemplates = hasPermission(PERMISSIONS.CAN_VIEW_TEMPLATES_AND_CONFIRMATIONS);

  const selectedDocumentIds = (
    Array.isArray(selectedDocumentIdsIn) ? selectedDocumentIdsIn : [selectedDocumentIdsIn]
  ).reduce(
    (acc, id) => {
      if (id && /^\d+$/.test(id.toString())) {
        acc.push(parseInt(id, 10));
      }
      return acc;
    },
    [],
  );

  const selectedDocumentIdsLength = selectedDocumentIds.length;
  if (selectedDocumentIdsLength === 0) {
    return null;
  }

  return (
    <p className={className}>
      {`${label}: `}
      {selectedDocumentIds.map((id, index) => {
        const name = documentOptions
          .find(o => parseInt(o.value, 10) === parseInt(id, 10))?.label;

        return (
          <Fragment key={id}>
            {!canViewTemplates && (<strong>{name}</strong>)}

            {canViewTemplates && (
              <Link to={documentViewUrl(activeOrg.alias, id)}>
                {name}
              </Link>
            )}
            {index < selectedDocumentIdsLength - 1 && ', '}
          </Fragment>
        );
      })}
    </p>
  );
};

SelectedDocumentsLinks.propTypes = {
  className: PropTypes.string,
  documentOptions: PropTypes.arrayOf(documentOptionsSpec).isRequired,
  label: PropTypes.string,
  selectedDocumentIds: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]).isRequired,
};

SelectedDocumentsLinks.defaultProps = {
  className: null,
  label: 'Selected documents',
};

export default SelectedDocumentsLinks;
