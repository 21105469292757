// PROJECTS

// API
export const fetchBudgetBurnDownAnalyticsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/budget-burn-down-analytics`
);

export const fetchTasksBurnDownAnalyticsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/tasks-burn-down-analytics`
);

export const purchaseOrdersProjectAnalyticsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/purchase-orders`
);

export const stackedWorksheetAnalyticsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/stacked-worksheets`
);

export const lineWorksheetAnalyticsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/line-worksheets`
);

export const linePurchaseOrderAnalyticsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/line-purchase-orders`
);

export const upcomingPaymentsProjectAnalyticsApiUrl = (orgAlias, projectId, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/upcoming-payments`
);

export const worksheetsProjectAnalyticsApiUrl = (orgAlias, projectId, dateDuration, url = '') => (
  `${url}/api/${orgAlias}/analytics/project/${projectId}/worksheets?dateDuration=${dateDuration}`
);

// DATA REPORTS

// VIEWS
export const reportsUrl = (orgAlias, tab) => (
  `/${orgAlias}/reports${tab ? `/${tab}` : ''}`
);

export const reportUrl = (orgAlias, id, url = '') => (
  `${url}/${orgAlias}/reports/${id}`
);

// API

export const reportsFetchReportsInfoApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/analytics/reports/info`
);

export const reportsFetchReportInfoApiUrl = (orgAlias, id, url = '') => (
  `${url}/api/${orgAlias}/analytics/reports/${id}/info`
);

export const reportsDownloadReportApiUrl = (orgAlias, id, date, dateEnd = '', url = '') => (
  `${url}/api/${orgAlias}/analytics/reports/${id}/csv/${date}${dateEnd ? `/${dateEnd}` : ''}`
);

export const financialChartApiUrl = (orgAlias, chartType) => (
  `/api/${orgAlias}/analytics/charts/${chartType}`
);
