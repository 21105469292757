import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-final-form';

import DragHandle from 'core/assets/js/components/DragHandle.jsx';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSortable from 'core/assets/js/components/TDSortable.jsx';
import { finalFormFieldMetaSpec, finalFormFieldLabelSpec } from 'core/assets/js/lib/objectSpecs';
import withField from 'core/assets/js/components/withField.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import { ICON, BS_SIZE, BS_STYLE } from 'core/assets/js/constants';

const MultitextField = ({
  addButtonTestId,
  className,
  input,
  label,
  maxLength,
  meta: { error, submitError, touched },
  onAutoIncrementUpdate,
  required,
  sublabel,
}) => {
  const [newLineValue, setNewLineValue] = useState('');
  const inputField = useRef(null);
  const [autoIncrementCount, setAutoIncrementCount] = useState(0);
  const hasError = touched && (error || submitError);
  const classNames = ['form-group'];
  const { change } = useForm();
  // Using input.value, useFormState or useState to manage the current value kept resulting in an
  // empty array in onSortEnd. useRef works though
  const choicesRef = useRef([]);

  const updateChoices = newChoices => {
    choicesRef.current = newChoices;
    change(input.name, newChoices);
  };

  useEffect(() => {
    if (JSON.stringify(input.value) !== JSON.stringify(choicesRef.current)) {
      choicesRef.current = input.value || [];
    }
  }, [JSON.stringify(input.value)]);

  if (className) {
    classNames.push(className);
  }

  if (hasError) {
    classNames.push('has-error');
  }

  const handleMakeLink = async () => {
    const linkRegExp = /\[([^\]]+)\]\(([^)]+)\)/g;
    const { value: text, selectionStart: start, selectionEnd: end } = inputField.current;

    let selectedText = text.slice(start, end);
    let urlInserted = 'url';
    let highlightStart = null;
    let highlightEnd = null;
    let textWithLink = `[label](${urlInserted})`;

    if (selectedText) {
      const containsLink = selectedText.match(linkRegExp);

      if (containsLink) {
        urlInserted = selectedText.replace(linkRegExp, '$2');
        selectedText = selectedText.replace(linkRegExp, '$1');
      }

      const firstPart = `${text.slice(0, start).trim()} [${selectedText}](`.trim();
      const secondPart = `) ${text.slice(end).trim()}`.trim();

      highlightStart = firstPart.length;
      highlightEnd = highlightStart + urlInserted.length;

      textWithLink = `${firstPart}${urlInserted}${secondPart}`;
    } else {
      textWithLink = text.endsWith(textWithLink) ? text : `${text.trim()} ${textWithLink}`.trim();
      highlightStart = textWithLink.indexOf(urlInserted);
      highlightEnd = highlightStart + urlInserted.length;
    }
    inputField.current.value = textWithLink.trim();

    if (highlightStart !== highlightEnd) {
      inputField.current.selectionStart = highlightStart;
      inputField.current.selectionEnd = highlightEnd;
      inputField.current.focus();
    }
  };

  const currentValue = input.value || [];

  const updateOrder = thisChoices => {
    // Loop through the new array and update the "value" (order)
    thisChoices.forEach((thisChoice, index) => {
      thisChoice.value = index + 1; // eslint-disable-line no-param-reassign
    });
    return thisChoices;
  };

  return (
    <div className={classNames.join(' ')}>
      <TDLabel name={input.name} label={label} required={required} sublabel={sublabel} />

      <div className="col-12 col-md-10 col-lg-8 col-xl-6 px-0">
        <div>
          {currentValue.length === 0 && (
            <p className="discreet">No choices added yet</p>
          )}
          <TDSortable
            containerClassName={(
              'interview-builder__questions-list template-builder__choices-list py-0'
            )}
            onSortEnd={({ oldIndex, newIndex }) => {
              const newChoices = choicesRef.current.slice(0);
              const oldItem = newChoices[oldIndex];
              newChoices[oldIndex] = newChoices[newIndex];
              newChoices[newIndex] = oldItem;
              updateOrder(newChoices);
              updateChoices(newChoices);
            }}
          >
            {currentValue.map((choice, index) => (
              <div
                className={(
                  'interview-builder__question template-builder__choice p-0 m-0 mb-2'
                )}
                key={JSON.stringify(choice)}
              >
                <i
                  className={` ${ICON.CROSS} template-builder__question-remove`}
                  onClick={() => {
                    const newChoices = choicesRef.current.slice(0);
                    newChoices.splice(index, 1);
                    updateOrder(newChoices);
                    updateChoices(newChoices);
                  }}
                />
                <div className="template-builder__choice">
                  <DragHandle classes="template-builder__drag-handle px-3 mr-1" />
                  <MarkdownText
                    disallowedTypes={['paragraph']}
                    text={choice.text}
                  />
                </div>
              </div>
            ))}
          </TDSortable>

          {hasError && <span className="help-block d-inline-block mt-3">{submitError || error}</span>}

          <div className="border-top pt-3 mt-3">
            <div className="template-builder__new-choice-line">
              <div className="w-100 pr-3 position-relative">
                <input
                  className="form-control"
                  maxLength={maxLength}
                  name="newLine"
                  onChange={e => setNewLineValue(e.target.value)}
                  placeholder="Add new choice..."
                  ref={inputField}
                  type="text"
                  value={newLineValue}
                />

                <TDButton
                  variant={BS_STYLE.DEFAULT}
                  bsSize={BS_SIZE.XSMALL}
                  className="text-uppercase add-link"
                  onClick={handleMakeLink}
                  label="Link"
                />
              </div>

              <TDButton
                data-testid={addButtonTestId}
                disabled={newLineValue === ''}
                label="Add"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  let newChoices = choicesRef.current.slice(0);
                  newChoices.push({ value: newChoices.length, text: newLineValue });
                  newChoices = updateOrder(newChoices);
                  updateChoices(newChoices);
                  const newAutoIncrement = autoIncrementCount + 1;
                  setAutoIncrementCount(newAutoIncrement);
                  onAutoIncrementUpdate(newAutoIncrement);
                  setNewLineValue('');
                }}
                type="submit"
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MultitextField.propTypes = {
  addButtonTestId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: finalFormFieldLabelSpec,
  maxLength: PropTypes.number,
  meta: finalFormFieldMetaSpec,
  onAutoIncrementUpdate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  sublabel: finalFormFieldLabelSpec,
  suffix: PropTypes.string,
  type: PropTypes.string,
};

MultitextField.defaultProps = {
  addButtonTestId: 'multi-text-field-add-button',
  className: null,
  disabled: false,
  label: '',
  maxLength: null,
  meta: {
    error: '',
    pristine: true,
    submitError: '',
  },
  placeholder: '',
  prefix: null,
  required: false,
  sublabel: null,
  suffix: null,
  type: 'text',
};


export default withField(MultitextField);
