import React from 'react';
import { useParams } from 'react-router-dom';

import FinancialChartCountsList from 'analytics/assets/js/components/FinancialChartCountsList.jsx';
import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { fetchProviderAnalyticsApiUrl } from 'people/urls';

const ProvidersPerCountry = () => {
  const params = useParams();

  const { hasLoaded, isLoading, item } = fetchDataHook({
    componentName: ProvidersPerCountry.GetComponentName(),
    url: fetchProviderAnalyticsApiUrl(params.orgAlias),
  });

  return (
    <FinancialChartCountsList
      entries={(item?.counting || []).map(e => ({
        count: e.count,
        text: (
          <>
            {e.country_code && (
              <CountryFlagIcon className="mr-3" countryISOCode={e.country_code} />
            )}
            {e.country_code ? countryNames[e.country_code] : 'Unknown'}
          </>
        ),
      }))}
      isLoading={isLoading || !hasLoaded}
      title={(
        <>
          Providers per Country
          <span className="ml-2 font-smaller">(Current)</span>
        </>
      )}
    />
  );
};

ProvidersPerCountry.GetComponentName = () => 'ProvidersPerCountry';

export default ProvidersPerCountry;
