/* eslint-disable no-case-declarations */
import { setWith, startCase, isEmpty } from 'lodash';

export default class ErrorSerializer {
  static serialize(error, mainErrMsg) {
    if (!error) {
      throw new Error('Cannot serialize empty error');
    }
    if (error && error._meta && error._meta.name) {
      // already serialized
      return error;
    }
    let _meta = {
      name: error.name,
      message: error.message,
    };

    // Try and get the metadata for this error: Check the error name,
    // the constructor's name and the parent constructor in this specific order.
    let errorMeta = {};
    const errorClasses = [
      error.name,
      error.constructor.name,
      Object.getPrototypeOf(error.constructor).name,
    ];

    while (errorClasses.length > 0 && isEmpty(errorMeta)) {
      const errorClass = errorClasses.shift();
      errorMeta = ErrorSerializer.getErrorMeta(errorClass, error);
    }

    if (isEmpty(errorMeta)) {
      // If we failed to retrieve meta information about this error, mark it as unexpected.
      _meta = {
        ..._meta,
        defaultMessage: 'Unexpected error',
        isUnexpected: true,
        status: 500,
        stack: error.stack || JSON.stringify(error, null, '\t'),
      };
    } else {
      _meta = {
        ..._meta,
        ...errorMeta,
      };
    }

    if (error._addToMeta) {
      _meta = { ..._meta, ...error._addToMeta };
    }

    const processed = {
      ...ErrorSerializer.serializeInnerErrors(error.errors, error.message),
      _error: mainErrMsg || error.message || _meta.defaultMessage,
      _meta,
    };

    return processed;
  }

  /**
   * @param {String} errorClass error class to check
   * @param {Error} error the error instance
   * @return {Object}
   */
  static getErrorMeta(errorClass, error) {
    switch (errorClass) {
      case 'RestApiError':
        return {
          status: error.statusCode,
        };
      case 'SequelizeUniqueConstraintError':
        return {
          defaultMessage: 'Unique constraint error',
          isUnprocessable: true,
          status: 422,
        };
      case 'SequelizeValidationError':
      case 'MultiValidationError':
        return {
          defaultMessage: 'Multiple validation error',
          isValidation: true,
          status: 400,
        };
      case 'SingleValidationError':
      case 'ValidationError':
      case 'NestedValidationErrorListItem':
        return {
          defaultMessage: 'Validation error',
          isValidation: true,
          status: 400,
        };
      case 'UnauthorizedError':
        return {
          defaultMessage: 'Access denied',
          accessDenied: true,
          isFatal: !error.namespace,
          status: 403,
        };
      case 'SequelizeEmptyResultError':
      case 'NotFoundError':
        return {
          defaultMessage: 'Record not found',
          notFound: true,
          status: 404,
        };
      case 'FatalError':
        return {
          defaultMessage: 'Not authorized',
          isFatal: true,
          status: 403,
        };
      default:
        return {};
    }
  }

  /**
   * Create an object with the faulty parameters as keys and their error messages as values
   * @param  {Array} errors        Array<Sequelize.ValidationErrorItem>
   * @return {Object}              Object containing the error messages
   */
  static serializeInnerErrors(errors = {}, message = null) {
    let errorObj = {};

    Object.values(errors).filter(err => err.path !== undefined).forEach((err) => {
      let pathError;

      if (err.errors) {
        pathError = ErrorSerializer.serializeInnerErrors(err.errors, err.message);
      } else {
        pathError = ErrorSerializer.extractMessage(err);
      }

      setWith(errorObj, err.path, pathError, Object);
    });

    if (message) {
      errorObj = {
        ...errorObj,
        _error: message,
      };
    }

    return errorObj;
  }

  /**
   * Convert a ValidationErrorItem to a presentable error message for the User
   *
   * @param  {Object} error Sequelize.ValidationErrorItem
   * @return {String}           Message for the user
   */
  static extractMessage(error) {
    const field = startCase((error.path.replace('_', ' ')));
    const { type, message } = error;

    switch (type) {
      case 'notNull Violation':
        return message || `Please fill the ${field} field`;
      case 'unique violation':
        return message || `The ${field} you entered is already in use`;
      case 'Validation error':
        if (!message) {
          return `Please correct the ${field}`;
        }
        const errorBaseMsg = message.split(' ').slice(0, 2).join(' ');
        switch (errorBaseMsg) {
          case 'Validation len':
            return `Please enter ${field} of appropriate length`;
          case 'Validation isEmail':
            return 'Please enter a valid email';
          default:
            if (message.match(/^Validation [^ ]+ failed$/)) {
              return `Please correct the ${field}`;
            }
            // Custom error message provided
            return message;
        }
      default:
        return message || `Please correct the ${field}`;
    }
  }
}
