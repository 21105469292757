import { FORM_ERROR } from 'final-form';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useLocation } from 'react-router-dom';

import ModalResendVerification from 'accounts/assets/js/components/ModalResendVerification.jsx';
import { VERIFY_TOTP_ERROR_REASON } from 'accounts/assets/js/constants';
import { onAuthSuccess } from 'accounts/assets/js/data-services/account';
import { loginApiUrl, orgLoginApiUrl, passwordForgotUrl, verify2faApiUrl } from 'accounts/urls';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { WINDOW_REDIRECT } from 'core/assets/js/config/settings';
import { BS_STYLE, customerSupportEmail, ICON } from 'core/assets/js/constants';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { organizationsUrl } from 'organizations/urls';

const LoginForm = ({ initialValues, orgAlias, submitSucceeded, testNotMounted }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [mounted, setIsMounted] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [totpSessionKey, setTotpSessionKey] = useState(null);
  const [submitRecoveryCode, setSubmitRecoveryCode] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    setIsMounted(!testNotMounted);
  }, []);

  const onLoginSuccess = response => {
    onAuthSuccess(dispatch, response);

    const parsed = queryString.parse(location.search);
    let targetUrl = organizationsUrl(orgAlias);
    if (parsed.next) {
      targetUrl = parsed.next;
    } else if (response.auth?.userProfile?.homeUrl) {
      targetUrl = response.auth.userProfile.homeUrl;
    }

    WINDOW_REDIRECT(targetUrl);
  };

  const submit2FA = async values => {
    try {
      const { data: responseData } = await axios.post(
        verify2faApiUrl(), { rememberMe, totpSessionKey, ...pick(values, 'recoveryCode', 'totp') },
      );
      onLoginSuccess(responseData);
      return null;
    } catch (e) {
      if (
        e.response?.data?._meta?.errorCode === VERIFY_TOTP_ERROR_REASON.SESSION_NOT_FOUND
      ) {
        setSubmitRecoveryCode(false);
        setTotpSessionKey(null);
        toastr.error('Oh snap!', 'Your code has expired, please log in again');
        return null;
      }
      return parseAxiosErrorForFinalForm(e);
    }
  };

  if (totpSessionKey) {
    if (submitRecoveryCode) {
      return (
        <div className="login-form">
          <div className="recovery-code-back" onClick={() => setSubmitRecoveryCode(false)}>
            <i className={`${ICON.ARROW_LEFT} mr-3`} />
            Back
          </div>
          <h2 className="text-center">Two-factor recovery</h2>
          <Form
            onSubmit={async values => {
              if (!/^[0-9a-z]+$/.test(values.recoveryCode || '')) {
                return { recoveryCode: 'Invalid code' };
              }
              return submit2FA(values);
            }}
            render={({ handleSubmit, submitError, submitting }) => (
              <form onSubmit={handleSubmit}>
                <TextInputField label="Recovery code" name="recoveryCode" />
                {submitError && <div className="text-danger text-center mb-5">{submitError}</div>}
                <div className="form-group">
                  <TDButton
                    block
                    disabled={submitting}
                    label="Verify code"
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                  />
                </div>
              </form>
            )}
          />
          <p className="mt-5 text-center">
            {"Don't have a recovery code? "}
            <a href={`mailto:${customerSupportEmail}`}>Contact support</a>
          </p>
        </div>
      );
    }

    return (
      <div className="login-form">
        <h2 className="text-center">Enter 6-digit code</h2>
        <p className="text-center mb-5">Enter the 6-digit code from your authenticator app</p>
        <Form
          onSubmit={async values => {
            if (!/^[0-9]{6}$/.test(values.totp || '')) {
              return { totp: 'Invalid code' };
            }
            return submit2FA(values);
          }}
          render={({ handleSubmit, submitError, submitting }) => (
            <form
              className="d-flex flex-column align-items-center text-center"
              onSubmit={handleSubmit}
            >
              <TextInputField
                ignoreChange={event => !/^[0-9]{0,6}$/.test(event.target.value)}
                autoComplete="one-time-code"
                name="totp"
              />
              {submitError && <div className="text-danger mb-5">{submitError}</div>}
              <div className="form-group">
                <TDButton
                  block
                  disabled={submitting}
                  label="Verify code"
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />
              </div>
              <span className="imitate-link mt-3" onClick={() => setSubmitRecoveryCode(true)}>
                Use a recovery code
              </span>
            </form>
          )}
        />
      </div>
    );
  }

  return (
    <div className="login-form">
      <Form
        initialValues={initialValues}
        onSubmit={async values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Please enter your email';
          }
          if (!values.password) {
            errors.password = 'Please enter your password';
          }
          if (Object.keys(errors).length > 0) {
            return errors;
          }
          try {
            const loginBody = {
              rememberMe: !!values.rememberMe?.[0]?.value, ...pick(values, 'email', 'password'),
            };
            const { data: responseData } = await axios.post(
              orgAlias ? orgLoginApiUrl(orgAlias) : loginApiUrl(), loginBody,
            );
            if (responseData.totpSessionKey) {
              setRememberMe(loginBody.rememberMe);
              setTotpSessionKey(responseData.totpSessionKey);
              return null;
            }
            if (responseData._error) {
              return { [FORM_ERROR]: responseData._error };
            }
            onLoginSuccess(responseData);
            return null;
          } catch (e) {
            return parseAxiosErrorForFinalForm(e);
          }
        }}
        render={({ handleSubmit, submitError, submitErrors, submitting }) => (
          <>
            {submitErrors?.verified && (
              <TDSystemMessage
                type={BS_STYLE.WARNING}
                title="Verify your Email"
                className="mb-3"
              >
                <p>
                  Please verify your email address before you log in.
                </p>

                <p>
                  Search your inbox and click on the verification link of the TalentDesk.io
                  account verification email. If you can&apos;t find the relevant email,
                  please click
                  {' '}
                  <a
                    onClick={() => setShowVerificationModal(true)}
                    className="imitate-link"
                  >
                    here
                  </a>
                  {' '}
                  to re-send it.
                </p>

                <ModalResendVerification
                  onClick={() => setShowVerificationModal(false)}
                  open={showVerificationModal}
                />
              </TDSystemMessage>
            )}
            {submitErrors?.deactivated && (
              <TDSystemMessage
                type={BS_STYLE.WARNING}
                title="Your account is deactivated."
                className="mb-4"
              >
                <p>
                  To enquire regarding the status of your account contact us at
                  {' '}
                  <a href="mailto:support@talentdesk.io">support@talentdesk.io</a>
                </p>
              </TDSystemMessage>
            )}
            <form onSubmit={handleSubmit}>
              <TextInputField
                autoComplete="username"
                label="Email"
                name="email"
                type="email"
              />
              <TextInputField
                autoComplete="password"
                label="Password"
                name="password"
                type="password"
              />
              <div className="d-flex align-items-start justify-content-between">
                <CheckboxField name="rememberMe" options={[{ text: 'Remember me', value: true }]} />
                <Link data-testid="login-form-reset-password" to={passwordForgotUrl()}>
                  Forgot your password?
                </Link>
              </div>
              {submitError && (
                <div className="form-group has-error">
                  <span className="help-block">{submitError}</span>
                </div>
              )}
              <div className="form-group">
                <TDButton
                  block
                  data-testid="button-login"
                  disabled={submitting || submitSucceeded || !mounted}
                  label={`LOG IN${mounted ? '' : ' LOADING...'}`}
                  type="submit"
                  variant={BS_STYLE.PRIMARY}
                />
              </div>
            </form>
          </>
        )}
      />
    </div>
  );
};

LoginForm.propTypes = {
  initialValues: PropTypes.object,
  orgAlias: PropTypes.string,
  submitSucceeded: PropTypes.bool.isRequired,
  testNotMounted: PropTypes.bool,
};

LoginForm.defaultProps = {
  initialValues: null,
  orgAlias: null,
  testNotMounted: false,
};

export default LoginForm;
