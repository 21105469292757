/* eslint-disable react/no-multi-comp */
/*
eslint react/prop-types: [
  "error",
  {
    "ignore": [
      "input", "name", "type", "label", "placeholder", "meta", "error", "handleSubmit", "submitting"
    ]
  }
]
*/
import { pickBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';

import axios from 'core/assets/js/lib/tdAxios';
import MarkdownText from 'core/assets/js/components/MarkdownText.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import RejectSubmissionModal from 'interviews/assets/js/components/RejectSubmissionModal.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON, USER_TYPE } from 'core/assets/js/constants';
import { changeSubmissionStatusToSubmittedApiUrl, submissionReviewApiUrl } from 'interviews/urls';
import { concatNodes } from 'core/assets/js/lib/utils-jsx';
import { getAnswerExpiresAtPath, isCustomFieldPath } from 'interviews/assets/js/lib/utils';
import { modalCloseAC, modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { SUBMISSION_STATUS, SUBMISSION_STATUS_LABEL, TYPE } from 'interviews/assets/js/constants';
import { submissionSpec } from 'interviews/assets/js/lib/objectSpecs';

const AnswerBlock = ({ answerEl, questionLabel, userCannotViewAnswer }) => {
  return (
    <>
      <label>
        <MarkdownText text={questionLabel} disallowedTypes={['paragraph']} />
      </label>
      {userCannotViewAnswer && (
        <div className="text-warning p-3 mt-3 mb-3">
          Your user type does not have permission to view this answer.
        </div>
      )}
      {!userCannotViewAnswer && (
        <div className="interview-answer">
          {answerEl}
        </div>
      )}
    </>
  );
};
AnswerBlock.propTypes = {
  answerEl: PropTypes.node.isRequired,
  questionLabel: PropTypes.string.isRequired,
  userCannotViewAnswer: PropTypes.bool,
};
AnswerBlock.defaultProps = {
  userCannotViewAnswer: false,
};

export const Answer = ({ activeUserType, answer, expiresAtAnswer, question }) => {
  if (!answer) {
    const userCannotViewAnswer = !question.payload.visibleTo.includes(activeUserType);

    return (
      <AnswerBlock
        answerEl={<p>-</p>}
        questionLabel={question.label}
        userCannotViewAnswer={userCannotViewAnswer}
      />
    );
  }

  let answerEl = null;
  if ([TYPE.FILE, TYPE.MULTIFILE].includes(question.type)) {
    const filesArray = Array.isArray(answer) ? answer : JSON.parse(answer);
    const files = filesArray?.map(a => (
      <li key={a.handle}>
        <a href={a.url} rel="noopener noreferrer" target="_blank">{a.filename}</a>
      </li>
    ));
    answerEl = (<ul>{files}</ul>);
  } else if (question.type === TYPE.YESNO) {
    if (answer.isYes) {
      const lines = answer.answers.filter(a => a.text !== '').map(a =>
        <li key={a.id}>{a.text}</li>,
      );
      answerEl = (<ul>{lines}</ul>);
    } else {
      answerEl = (<p>No</p>);
    }
  } else if (question.type === TYPE.SELECT) {
    const answers = answer.map((a) => {
      if (a.text) {
        if (a.userInput) {
          return `${a.text}: ${a.userInput}`;
        }
        return a.text;
      }
      return question.payload.choices.find(c => c.value === a).text;
    });

    const text = Object.keys(answers).map((idx) => {
      const txt = answers[idx];
      return (
        <MarkdownText
          disallowedTypes={['paragraph']}
          key={`q-${question.id}-answer-${idx}`}
          text={txt}
        />
      );
    });

    answerEl = (<p>{concatNodes(text)}</p>);
  } else {
    answerEl = (<p>{answer}</p>);
  }

  return (
    <>
      <AnswerBlock
        answerEl={answerEl}
        questionLabel={question.label}
        questionPath={question.path}
      />
      {expiresAtAnswer && (
        <AnswerBlock
          answerEl={<p>{expiresAtAnswer}</p>}
          questionLabel="Expiry date"
          questionPath={getAnswerExpiresAtPath(question.path)}
        />
      )}
    </>
  );
};

Answer.propTypes = {
  activeUserType: PropTypes.oneOf(Object.values(USER_TYPE)).isRequired,
  question: PropTypes.object.isRequired,
  answer: PropTypes.any,
  expiresAtAnswer: PropTypes.any,
};

Answer.defaultProps = {
  answer: null,
  expiresAtAnswer: null,
};

const reviewApprovalModalId = 'review-onboarding-form-approval-modal';
const reviewRejectionModalId = 'review-onboarding-form-rejection-modal';

const ReviewForm = ({
  displayOnboardingFormName,
  onChangeManagerAnswers,
  onReOpen,
  onSubmit: onSubmitIn,
  providerFirstName,
  submission: {
    allowedActions,
    answers,
    errors: reviewerComments,
    id,
    name,
    questions,
  },
}) => {
  const { orgAlias } = useParams();
  const activeUserCard = useSelector(selectActiveUserCard);
  const dispatch = useDispatch();

  const isReviewApprovalModalOpen = useSelector(
    state => getIsModalOpen(state, reviewApprovalModalId),
  );

  const openModal = modalId => dispatch(modalOpenAC(modalId));
  const closeModal = () => dispatch(modalCloseAC());

  const onSubmit = async ({ reopen, reject, reason, ...values }) => {
    try {
      const comments = pickBy(values, (value, key) => isCustomFieldPath(key));
      const { data } = await axios.post(
        submissionReviewApiUrl(orgAlias, id),
        {
          errors: comments,
          reason,
          reject,
          reopen,
        },
      );
      closeModal();
      (reopen ? onReOpen : onSubmitIn)(data.submission);
      let message = 'The onboarding form submission has been approved';
      if (reopen) {
        message = 'The onboarding form submission can be reviewed again';
      } else if (reject) {
        message = 'The onboarding form submission has been rejected';
      } else if (Object.keys(comments).length > 0) {
        message = 'You have requested amendments to the onboarding form submission';
      }
      toastr.success('Well Done', message);
      return null;
    } catch (err) {
      if (reject) {
        return parseAxiosErrorForFinalForm(err);
      }
      toastr.error('Oh snap!', err.response?.data?._error || err.message);
      return null;
    }
  };

  return (
    <>
      {allowedActions.canReview && (
        <RejectSubmissionModal
          modalId={reviewRejectionModalId}
          onSubmit={onSubmit}
          providerFirstName={providerFirstName}
        />
      )}
      <Form
        onSubmit={onSubmit}
        render={({ form, handleSubmit, submitError, submitting }) => {
          const { dirty, values } = form.getState();
          // we don't want to change the 'reject' to 'request amend' label when review modal is open
          const onlyModalAnswerChanged = (
            values && 'reason' in values && Object.keys(values).length === 1
          );
          let answerFields = [];

          if (questions) {
            answerFields = questions.map(question => (
              <div key={question.path} data-testid={`review-form-answer-${question.path}`}>
                <Answer
                  activeUserType={activeUserCard.userRole.ofType}
                  submissionId={id}
                  orgAlias={orgAlias}
                  question={question}
                  answer={answers[question.path]}
                  expiresAtAnswer={answers[getAnswerExpiresAtPath(question.path)]}
                />
                {reviewerComments[question.path] && (
                  <div className="form-group has-error">
                    <span className="help-block">
                      Reviewer comment:
                      {' '}
                      {reviewerComments[question.path]}
                    </span>
                  </div>
                )}
                {allowedActions.canReview && (
                  <TextInputField name={question.path} placeholder="Add your comment..." />
                )}
              </div>
            ));
          }

          return (
            <div className="interview-review">
              {name && (
                <div
                  className={(
                    'interview-header d-flex flex-column flex-sm-row align-items-sm-center '
                    + 'justify-content-sm-between'
                  )}
                >
                  {displayOnboardingFormName && <h3 className="my-0 mr-4">{name}</h3>}

                  {allowedActions.canReopen && (
                    <span
                      className="imitate-link"
                      data-testid="reopen-onboarding-form"
                      onClick={() => onSubmit({ reopen: true })}
                    >
                      Reopen onboarding form
                    </span>
                  )}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                {answerFields}
                {submitError && (
                  <div className="form-group has-error">
                    <span className="help-block">{submitError}</span>
                  </div>
                )}
                {allowedActions.canReview && (
                  <section id="buttons" className="d-flex mt-4 text-right">
                    <ModalConfirm
                      data-testid="review-form-approve-modal"
                      key="approveOnboardingFormModal"
                      onConfirm={() => onSubmit({ ...values })}
                      onClose={closeModal}
                      closeOnConfirm={false}
                      heading={`Approve ${providerFirstName}'s onboarding form`}
                      body={(
                        <>
                          <p>
                            Approving the user&apos;s onboarding form means that they will have
                            access to your organisation.
                          </p>
                          <p>
                            If you would like please enter a descriptive reason for your approval.
                          </p>
                          <TextAreaField name="reason" />
                          <p>
                            Are you sure you want to approve this onboarding form?
                          </p>
                        </>
                      )}
                      confirmLabel="Approve"
                      confirmStyle={BS_STYLE.SUCCESS}
                      open={isReviewApprovalModalOpen}
                    />
                    {allowedActions.canAmendManagerAnswers && (
                      <TDButton
                        disabled={submitting}
                        label="Change manager answers"
                        onClick={async () => {
                          try {
                            const {
                              data,
                            } = await axios.put(changeSubmissionStatusToSubmittedApiUrl({
                              orgAlias, submissionId: id,
                            }));
                            onChangeManagerAnswers(data);
                            toastr.success(
                              'Well Done!',
                              'Onboarding submission successfully changed to'
                              + ` "${SUBMISSION_STATUS_LABEL[SUBMISSION_STATUS.SUBMITTED]}"`,
                            );
                          } catch (e) {
                            toastr.error('Oh Snap!', e.response?.data?._error || e.message);
                          }
                        }}
                        variant={BS_STYLE.SECONDARY}
                      />
                    )}
                    <TDButton
                      data-testid="review-form-button-reject"
                      variant={BS_STYLE.DANGER}
                      btnIcon={ICON.CROSS}
                      disabled={submitting}
                      label="Reject"
                      onClick={() => openModal(reviewRejectionModalId)}
                    />
                    {dirty && !onlyModalAnswerChanged && (
                      <TDButton
                        btnIcon={ICON.EDIT}
                        data-testid="review-form-button-request-amend"
                        disabled={submitting}
                        label="Request Amend"
                        type="submit"
                        variant={BS_STYLE.WARNING}
                      />
                    )}
                    {(!dirty || onlyModalAnswerChanged) && (
                      <TDButton
                        data-testid="review-form-button-approve"
                        onClick={() => openModal(reviewApprovalModalId)}
                        variant={BS_STYLE.SUCCESS}
                        btnIcon={ICON.CHECKMARK}
                        disabled={submitting}
                        label="Approve"
                      />
                    )}
                  </section>
                )}
              </form>
            </div>
          );
        }}
      />
    </>
  );
};

ReviewForm.propTypes = {
  displayOnboardingFormName: PropTypes.bool,
  onChangeManagerAnswers: PropTypes.func,
  onReOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  providerFirstName: PropTypes.string.isRequired,
  submission: submissionSpec.isRequired,
};

ReviewForm.defaultProps = {
  displayOnboardingFormName: true,
  onChangeManagerAnswers: () => null,
  onReOpen: () => null,
  onSubmit: () => null,
};

export default ReviewForm;
