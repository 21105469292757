import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { kebabCase } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import FinancialChartWrapper from 'analytics/assets/js/components/FinancialChartWrapper.jsx';
import { FINANCIAL_CHART_TYPES } from 'analytics/assets/js/constants';
import { financialChartApiUrl } from 'analytics/urls';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';

const UpcomingPayments = () => {
  const params = useParams();

  const { hasLoaded, isLoading, item } = fetchDataHook({
    componentName: UpcomingPayments.GetComponentName(),
    url: financialChartApiUrl(params.orgAlias, FINANCIAL_CHART_TYPES.UPCOMING_PAYMENTS),
  });

  const entriesWithClassName = (item.entries || []).reduce(
    (acc, e) => {
      const amount = parseFloat(e.amount);
      if (amount > 0) {
        acc.push({ ...e, amount, className: kebabCase(e.label) });
      }
      return acc;
    },
    [],
  );
  const sortedEntries = [
    'approved-worksheets',
    'approved-expenses',
    'approved-pro-forma',
    'pending-worksheets',
    'pending-expenses',
    'pending-pro-forma',
  ].reduce(
    (acc, className) => {
      const entry = entriesWithClassName.find(e => e.className === className);
      if (entry) {
        acc.push(entry);
      }
      return acc;
    },
    [],
  );
  const sortedEntriesLength = sortedEntries.length;

  return (
    <FinancialChartWrapper isLoading={!hasLoaded || isLoading} title="Upcoming Payments">
      <div className="d-flex flex-column upcoming-payments-chart">
        <div className="total font-weight-bold mt-2 mb-5 pb-2">
          <NumberTpl currency={item.currency} value={item.total} />
        </div>
        {sortedEntriesLength > 0 && (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  className: 'upcoming-payments-pie-chart',
                  height: `${250 - ((sortedEntriesLength > 3 ? 2 : 1) * 60)}px`,
                  margin: [0, 0, 0, 0],
                  padding: [0, 0, 0, 0],
                  type: 'pie',
                },
                credits: { enabled: false },
                plotOptions: {
                  pie: {
                    center: ['50%', '50%'],
                    dataLabels: { enabled: false },
                    shadow: false,
                  },
                  series: { enableMouseTracking: false },
                },
                series: [{
                  data: sortedEntries.map(e => ({
                    className: e.className,
                    name: e.label,
                    y: (e.amount / parseFloat(item.total)) * 100,
                  })),
                  innerSize: '65%',
                  size: '100%',
                }],
                subtitle: { text: null },
                title: { text: '' },
                tooltip: { enabled: false },
              }}
            />
            <div className="keys mt-4">
              {sortedEntries.map(e => (
                <div className="key d-flex justify-content-between mt-2" key={e.label}>
                  <div className={`dot ${e.className} mt-2`} />
                  <div className="d-flex flex-column label-wrapper">
                    <div>{e.label}</div>
                    <div className="font-weight-bold">
                      <NumberTpl currency={item.currency} value={e.amount} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </FinancialChartWrapper>
  );
};

UpcomingPayments.GetComponentName = () => 'UpcomingPayments';

export default UpcomingPayments;
