import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { submitFormById } from 'core/assets/js/lib/utils';

const withModalFinalForm = ({
  cancelLabel, confirmLabel, confirmStyle, heading, modalId, showConfirm, size, testId,
} = {}) => WrappedComponent => {
  const _withModalFinalForm = ({ initialValues, onSubmit, onSubmitSuccess, ...formProps }) => {
    const isOpen = useSelector(state => getIsModalOpen(state, modalId));
    const dispatch = useDispatch();
    const formId = `${modalId}-form`;
    const closeModal = () => dispatch(modalCloseAC());
    return (
      <Form
        initialValues={initialValues}
        onSubmit={async values => {
          const errors = await onSubmit(values);
          if (errors) {
            return errors;
          }
          closeModal();
          if (onSubmitSuccess) {
            onSubmitSuccess();
          }
          return null;
        }}
        render={({ handleSubmit, submitting }) => (
          <form id={formId} onSubmit={handleSubmit}>
            <ModalConfirm
              body={<WrappedComponent {...formProps} />}
              cancelLabel={cancelLabel}
              closeOnConfirm={false}
              confirmStyle={confirmStyle}
              confirmLabel={confirmLabel}
              confirmButtonDisabled={submitting}
              data-testid={testId}
              heading={heading}
              onClose={closeModal}
              onConfirm={async () => submitFormById(formId)}
              open={isOpen}
              showConfirmButton={showConfirm}
              size={size}
            />
          </form>
        )}
      />
    );
  };

  _withModalFinalForm.propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func,
  };

  _withModalFinalForm.defaultProps = {
    initialValues: null,
    onSubmitSuccess: null,
  };

  return _withModalFinalForm;
};

withModalFinalForm.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
  heading: PropTypes.string,
  modalId: PropTypes.string.isRequired,
  showConfirm: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(MODAL_SIZES)),
  testId: PropTypes.string,
};

withModalFinalForm.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Accept',
  confirmStyle: BS_STYLE.PRIMARY,
  heading: 'Update',
  showConfirm: true,
  size: MODAL_SIZES.LARGE,
  testId: null,
};

export default withModalFinalForm;
