import { TYPE } from 'interviews/assets/js/constants';

export const IMPORT_TYPE = {
  PROJECTS: 'projects',
  SKILLS: 'skills',
  TASKS: 'tasks',
  USERS: 'users',
  WORKSHEETS: 'worksheets',
  PROJECT_AND_TASK_ASSIGNMENTS: 'projects-and-tasks-assignments',
};

export const IMPORT_TYPE_LABEL = {
  [IMPORT_TYPE.PROJECTS]: 'Projects',
  [IMPORT_TYPE.SKILLS]: 'Skills',
  [IMPORT_TYPE.TASKS]: 'Tasks',
  [IMPORT_TYPE.USERS]: 'Team',
  [IMPORT_TYPE.WORKSHEETS]: 'Worksheets',
  [IMPORT_TYPE.PROJECT_AND_TASK_ASSIGNMENTS]: 'Project & Task Assignments',
};

export const IMPORT_TYPE_VALUES = Object.values(IMPORT_TYPE);

export const IMPORT_TYPE_PROCESS_SEQUENTIALLY = [IMPORT_TYPE.SKILLS];

export const IMPORT_STATUS = {
  PENDING: 'pending',
  COMPLETE: 'complete',
};

export const IMPORT_STATUS_VALUES = Object.values(IMPORT_STATUS);

export const IMPORT_STATUS_LABEL = {
  [IMPORT_STATUS.PENDING]: 'Pending',
  [IMPORT_STATUS.COMPLETE]: 'Complete',
};

export const IMPORT_STATUS_CLASS = {
  [IMPORT_STATUS.PENDING]: 'pending',
  [IMPORT_STATUS.COMPLETE]: 'approved',
};

export const IMPORT_ROW_STATUS = {
  PENDING: 'pending',
  INPROGRESS: 'in-progress',
  COMPLETE: 'complete',
  ERRORED: 'errored',
  CANCELLED: 'cancelled',
};

export const IMPORT_ROW_STATUS_VALUES = Object.values(IMPORT_ROW_STATUS);

export const IMPORT_ROW_STATUS_LABEL = {
  [IMPORT_ROW_STATUS.INPROGRESS]: 'In progress',
  [IMPORT_ROW_STATUS.COMPLETE]: 'Complete',
  [IMPORT_ROW_STATUS.ERRORED]: 'Errored',
};

export const IMPORT_COLUMNS = {
  [IMPORT_TYPE.WORKSHEETS]: [
    'Project reference',
    'Task reference',
    'User ID or email',
    'Claiming amount',
    'Task rate quantity',
    'Description',
    'Currency',
    'Summary',
    'Period start',
    'Period end',
  ],
  [IMPORT_TYPE.USERS]: [
    'Email',
    'First name',
    'Middle name',
    'Last name',
    'Full legal name',
    'Job title',
    'Date of birth',
    'Phone number',
    'Address',
    'Avatar',
    'Default rate currency',
    'Default rate amount',
    'Default rate unit',
    'Group names',
    'Project titles',
  ],
  [IMPORT_TYPE.SKILLS]: ['Skill name', 'Skill directory name'],
  [IMPORT_TYPE.PROJECT_AND_TASK_ASSIGNMENTS]: [
    'User ID or email', 'Project reference', 'Task reference', 'Action',
  ],
  [IMPORT_TYPE.PROJECTS]: [
    'Title',
    'Brief',
    'Deadline',
    'Start date',
    'Skills',
    'Budget',
    'External ID',
    'Client names',
    'Tags',
    'All managers can manage members',
    'Providers can view team',
    'Is opportunity',
    'Opportunity is invitations only',
    'Rate guide unit',
    'Rate guide fixed amount',
    'Rate guide minimum amount',
    'Rate guide maximum amount',
    'Max applications',
  ],
  [IMPORT_TYPE.TASKS]: [
    'Project reference',
    'Title',
    'Description',
    'Starts on',
    'Deadline',
    'Checklist',
    'Skills',
    'Tags',
  ],
};

export const IMPORT_DATE_FORMAT = {
  ISO: 'YYYY-MM-DD',
  GB: 'DD/MM/YYYY',
  US: 'MM/DD/YYYY',
};

export const IMPORT_DATE_FORMAT_LABEL = {
  [IMPORT_DATE_FORMAT.ISO]: 'ISO format',
  [IMPORT_DATE_FORMAT.GB]: 'British format',
  [IMPORT_DATE_FORMAT.US]: 'US format',
};

export const IMPORT_VIEW_TABS = {
  DETAILS: 'details',
  CANCELLED: 'cancelled',
  ERRORS: 'errors',
};

export const IMPORT_PROJECT_AND_TASK_ACTIONS = {
  ADD: 'add',
  INVITE: 'invite',
  REMOVE: 'remove',
};

export const CUSTOM_FIELD_EXAMPLE = {
  [TYPE.TEXT]: 'My answer',
  [TYPE.MONEY]: '123.45',
  [TYPE.SELECT]: 'Choice 1, Choice 2',
  [TYPE.RADIO]: 'Only choice',
  [TYPE.FILE]: 'https://url-to-file.com',
  [TYPE.TEXTAREA]: 'My longer answer',
  [TYPE.CHECKBOX]: 'Choice 1, Choice 2',
  [TYPE.DATE]: '2022-11-10',
  [TYPE.NUMBER]: '123',
  [TYPE.YESNO]: '"My answer" or "false"',
  [TYPE.MULTIFILE]: 'https://url-to-file-1.com, https://url-to-file-2.com',
};
