import React from 'react';

const FinancialChartSkeleton = () => (
  <div className="financial-chart-skeleton d-flex align-items-center justify-content-center">
    <div className="bars-wrapper d-flex flex-column">
      <div />
      <div className="mt-3" />
      <div className="mt-3" />
    </div>
  </div>
);

export default FinancialChartSkeleton;
