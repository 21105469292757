import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { reportsFetchReportsInfoApiUrl, reportUrl } from 'analytics/urls';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';

const ReportsList = () => {
  const { orgAlias } = useParams();
  const history = useHistory();

  const { hasLoaded, isLoading, item: reports } = fetchDataHook({
    componentName: ReportsList.GetComponentName(),
    url: reportsFetchReportsInfoApiUrl(orgAlias),
  });

  if (!hasLoaded || isLoading) {
    return <SettingsPageSkeleton />;
  }

  if (!Array.isArray(reports) || reports.length === 0) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center empty-list-message">
        <i className={ICON.FILE_CHART_LINE} />
        There are no data reports available yet in your account.
      </div>
    );
  }

  return (
    <ul className="report-list__container">
      {reports.map(({ columns, id, title, description }, index) => (
        <li
          className={index === 0 ? '' : 'mt-4'}
          data-testid="checkable-list-select-org-for-payment-method"
          key={`checkable-list-item-${id}`}
          id={`checkable-list-item-${id}`}
        >
          <a
            className="d-flex p-4 w-100"
            onClick={() => history.push(reportUrl(orgAlias, id))}
          >
            <div>
              <h4 className="mt-0 mb-2 font-weight-bolder">{title}</h4>
              <p className="mb-0">{description}</p>
              <p className="mb-0 hint">
                {`Report headers: ${columns.join(', ')}`}
              </p>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
};

ReportsList.GetComponentName = () => 'ReportsList';

ReportsList.propTypes = {
  onSubmitSelectedReport: PropTypes.func.isRequired,
};

export default ReportsList;
