import queryString from 'query-string';

import { getUrlParamByEntityType } from 'interviews/assets/js/lib/utils';
import { orgPeopleInviteesUrl } from 'people/urls';

export const interviewCreateUrl = (orgAlias, backUrl = '', url = '') => {
  if (backUrl) {
    const queries = { backUrl };
    if (backUrl) {
      queries.backUrl = backUrl;
    }
    const query = queryString.stringify(queries, { encode: false });
    return `${url}/${orgAlias}/interviews/create?${query}`;
  }
  return `${url}/${orgAlias}/interviews/create`;
};

export const interviewEditUrl = (orgAlias, interviewId, url = '') => (
  `${url}/${orgAlias}/interviews/${interviewId}`
);

export const interviewPreviewUrl = (orgAlias, interviewId, url = '') => (
  `${url}/${orgAlias}/interviews/${interviewId}?tab=preview`
);

export const interviewUrl = (orgAlias, interviewId, url = '') => (
  `${url}/${orgAlias}/interviews/${interviewId}`
);

export const interviewSubmitUrl = (orgAlias, interviewId, url = '') => (
  `${url}/${orgAlias}/interviews/${interviewId}/submit`
);

export const interviewInviteesUrl = (orgAlias, interviewId, status = '') => (
  `${orgPeopleInviteesUrl(orgAlias)}?interviewId=${interviewId}&status=${status}`
);

export const submissionListUrl = (orgAlias, url = '') => (
  `${url}/${orgAlias}/submissions`
);

export const submissionUrl = (orgAlias, submissionId, url = '', next = null) => {
  const qs = next ? `?${queryString.stringify({ next })}` : '';
  return `${url}/${orgAlias}/submissions/${submissionId}${qs}`;
};

export const answerUrl = (orgAlias, submissionId, questionId, url = '') => (
  `${url}/${orgAlias}/submissions/${submissionId}/${questionId}`
);

// API
export const interviewListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/interviews`
);

export const interviewApiUrl = (orgAlias, interviewId, url = '') => (
  `${url}/api/${orgAlias}/interviews/${interviewId}`
);

export const interviewPublicInviteApiUrl = (orgAlias, interviewId, url = '') => (
  `${url}/api/${orgAlias}/interviews/${interviewId}/public-invite`
);

export const submissionListApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/submissions`
);

export const submissionApiUrl = (orgAlias, submissionId, url = '') => (
  `${url}/api/${orgAlias}/submissions/${submissionId}`
);

export const submissionChangedDetailsApiUrl = (orgAlias, submissionId) => (
  `/api/${orgAlias}/submissions/${submissionId}/changed-details`
);

export const submissionReviewApiUrl = (orgAlias, submissionId, url = '') => (
  `${url}/api/${orgAlias}/submissions/${submissionId}/review`
);

export const answerSyncApiUrl = (orgAlias, submissionId, questionId, url = '') => (
  `${url}/api/${orgAlias}/submissions/${submissionId}/${questionId}/sync`
);

export const submissionByInterviewIdApiUrl = (orgAlias, interviewId, url = '') => (
  `${url}/api/${orgAlias}/interviews/${interviewId}/submission`
);
export const submissionDownloadApiUrl = (orgAlias, submissionId, url = '') => (
  `${url}/api/${orgAlias}/submissions/${submissionId}/download`
);

export const interviewInviteesListApiUrl = ({ orgAlias, interviewId, querystring }, url = '') => {
  const query = queryString.stringify({
    interviewId,
    ...queryString.parse(querystring),
  }, { encode: false });
  return `${url}/api/${orgAlias}/people/managers/invitees?${query}`;
};

export const interviewPendingInviteesCountApiUrl = ({ orgAlias, interviewId }, url = '') => {
  const query = queryString.stringify({ interviewId }, { encode: false });
  return `${url}/api/${orgAlias}/people/managers/invitee-pending-count?${query}`;
};

export const customFieldsTemplateApiUrl = ({ orgAlias, entityType, id, url = '' }) => (
  `${url}/api/${orgAlias}/custom-fields/${getUrlParamByEntityType(entityType)}/templates/${id}`
);

export const customFieldsTemplateListApiUrl = ({ orgAlias, entityType = '', url = '' }) => (
  `${url}/api/${orgAlias}/custom-fields/templates${entityType ? `?templateType=${entityType}` : ''}`
);

export const customFieldsTemplateListApiUrlAll = ({ orgAlias, entityType = '', url = '' }) => (
  `${url}/api/${orgAlias}/custom-fields/templates?fetchAll=1${entityType ? `&templateType=${entityType}` : ''}`
);

export const entityCustomFieldsTemplateAdditionsApiUrl = ({
  orgAlias, entityType, entityId, url = '',
}) => (
  `${url}/api/${orgAlias}/custom-fields/${getUrlParamByEntityType(entityType)}/${entityId}/additions`
);

export const customFieldsCreateTemplateApiUrl = ({ orgAlias, entityType, url = '' }) => (
  `${url}/api/${orgAlias}/custom-fields/${entityType}/templates`
);

export const customFieldsUpdateTemplateApiUrl = ({ orgAlias, entityType, templateId, url = '' }) => (
  `${url}/api/${orgAlias}/custom-fields/${entityType}/templates/${templateId}`
);

export const customFieldsDeleteTemplateApiUrl = ({ orgAlias, entityType, templateId, url = '' }) => (
  `${url}/api/${orgAlias}/custom-fields/${entityType}/templates/${templateId}`
);

export const changeOnboardingFormApiUrl = ({ orgAlias, submissionId }) => (
  `/api/${orgAlias}/submissions/${submissionId}/change-onboarding-form`
);

export const resetOnboardingFormApiUrl = ({ orgAlias, submissionId }) => (
  `/api/${orgAlias}/submissions/${submissionId}/reset-onboarding-form`
);

export const changeSubmissionStatusToSubmittedApiUrl = ({ orgAlias, submissionId }) => (
  `/api/${orgAlias}/submissions/${submissionId}/change-status-to-submitted`
);

export const interviewDuplicateUrl = (orgAlias, interviewId) => (
  `/api/${orgAlias}/interviews/${interviewId}/duplicate`
);
