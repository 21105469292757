import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import {
  getActiveUserCardPermissionChecker,
  selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import UserUSTaxFormsList from 'finance/assets/js/components/UserUSTaxFormsList.jsx';
import {
  US_TAX_FORM_STATUS, US_TAX_FORM_STATUS_LABEL,
} from 'finance/assets/js/constants';
import { PERMISSIONS } from 'roles/assets/js/constants';
import { Card } from 'react-bootstrap';

const USTaxFormsCollapsible = ({ parentComponentName, userCard }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const activeUserCard = useSelector(selectActiveUserCard);
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const { search } = useLocation();

  const { taxInformationDocumentOpen } = queryString.parse(search);
  const canCanViewTaxInfo = hasPermission(PERMISSIONS.CAN_VIEW_PROVIDER_TAX_INFORMATION);
  const canReviewUSTaxForm = hasPermission(PERMISSIONS.CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS);
  const isThemself = activeUserCard.user.id === parseInt(params.userId, 10);

  const useTaxForms = userCard.user.usTaxForms || [];

  const hasPendingReviewUSTaxForms = (useTaxForms || []).some(form => (
    form.status === US_TAX_FORM_STATUS.PENDING_REVIEW
  ));

  const onlyHasApprovedUSTaxForms = (useTaxForms || []).every(form => (
    form.status === US_TAX_FORM_STATUS.APPROVED
  ));

  const reloadProfile = () => {
    const fetchDS = params.userType === PEOPLE_TYPE.PROVIDERS ? fetchProviderDS : fetchManagerDS;
    dispatch(fetchDS({ componentName: parentComponentName, params }));
  };

  return (
    <Card className="mb-5">
      {useTaxForms.length > 0 && (canCanViewTaxInfo || isThemself) && (
        <AreaCollapsible
          baseHeadingClassName="w-100"
          className="profile-onboarding-collapsible"
          contentChildren={(
            <>
              {hasPendingReviewUSTaxForms && (
                <TDSystemMessage
                  className="mb-5 mx-3"
                  title="Tax information document is pending your review!"
                  type={BS_STYLE.WARNING}
                >
                  The provider has uploaded a tax information document that requires revision.
                </TDSystemMessage>
              )}
              {onlyHasApprovedUSTaxForms && (
                <TDSystemMessage
                  className="mb-5 mx-3"
                  title="Tax information document approved"
                  type={BS_STYLE.SUCCESS}
                >
                  Provider&apos;s information document has been approved.
                </TDSystemMessage>
              )}
              <UserUSTaxFormsList
                reload={reloadProfile}
                usTaxForms={useTaxForms}
              />
              {!canReviewUSTaxForm && (
                <div className="profile-onboarding_us-tax-permission-warning m-3 text-right">
                  <span className={`${ICON.WARNING} mr-3`} />
                  <span>You do not have permission to review tax information document</span>
                </div>
              )}
            </>
          )}
          headingChildren={(
            <div
              className={(
                'people-onboarding-collapsible-header-content d-flex align-items-center '
                + 'justify-content-between'
              )}
            >
              <div className="d-flex align-items-center justify-content-start float-left">
                <div>Tax information document</div>
                {hasPendingReviewUSTaxForms && (
                  <StatusTag
                    className="ml-5"
                    label={US_TAX_FORM_STATUS_LABEL[US_TAX_FORM_STATUS.PENDING_REVIEW]}
                    statusClass="pending"
                  />
                )}
                {onlyHasApprovedUSTaxForms && (
                  <StatusTag
                    className="ml-5"
                    label={US_TAX_FORM_STATUS_LABEL[US_TAX_FORM_STATUS.APPROVED]}
                    statusClass="approved"
                  />
                )}
              </div>
            </div>
          )}
          headingClassName="px-5 d-flex align-items-center justify-content-between"
          toggleElementClassName={null}
          initiallyExpanded={taxInformationDocumentOpen === 'true'}
        />
      )}
    </Card>
  );
};

USTaxFormsCollapsible.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
  userCard: PropTypes.object.isRequired,
};

export default USTaxFormsCollapsible;
