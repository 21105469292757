import PropTypes from 'prop-types';
import React from 'react';

import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import {
  TASK_ASSIGNMENT_STATUS, TASK_ASSIGNMENT_STATUS_CLASS, TASK_ASSIGNMENT_STATUS_LABEL,
} from 'projects/assets/js/constants';

const TaskAssignmentStatusTag = ({ status }) => (
  <StatusTag
    label={TASK_ASSIGNMENT_STATUS_LABEL[status]}
    statusClass={TASK_ASSIGNMENT_STATUS_CLASS[
      status === TASK_ASSIGNMENT_STATUS.PENDING_RATE_AGREEMENT
        ? TASK_ASSIGNMENT_STATUS.PENDING
        : status
    ]}
  />
);

TaskAssignmentStatusTag.propTypes = {
  status: PropTypes.oneOf(Object.values(TASK_ASSIGNMENT_STATUS)).isRequired,
};

export default TaskAssignmentStatusTag;
