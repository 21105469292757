import { uniq, pickBy, get, invert, isEmpty, isString, fromPairs, toPairs } from 'lodash';

import { BS_STYLE, MIME_TYPES } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  CHANGE_ONBOARDING_FORM_MODAL_ID,
  FIELD_ENTITY_TYPE_TO_URL_PARAM,
  PATH_PREFIX,
  RESET_ONBOARDING_FORM_MODAL_ID,
  SEARCH_KEY_PREFIX,
} from 'interviews/assets/js/constants';

export const createSearchKey = customField => (
  `${SEARCH_KEY_PREFIX}${customField.id}`
);

export const getCustomFieldPath = id => `${PATH_PREFIX}${id}`;

export const isCustomFieldPath = value => (
  isString(value) && value.startsWith(PATH_PREFIX)
);

export const pickCustomFieldsAnswers = obj => (
  fromPairs(toPairs(obj).filter(([key]) => isCustomFieldPath(key)))
);

export const mapValuesToPostFields = (values) => {
  const toSubmit = {
    interviewAnswers: pickBy(values, (value, key) => isCustomFieldPath(key)),
    draft: values.draft === false ? values.draft : true,
  };
  return toSubmit;
};

export const getAnswerExpiresAtPath = (questionPath) => {
  if (!questionPath) {
    throw new Error(`Provide a valid question path (${questionPath})`);
  }

  return `${questionPath}-expires_at`;
};

export const getUrlParamByEntityType = entityType => (
  FIELD_ENTITY_TYPE_TO_URL_PARAM[entityType] || null
);

export const getEntityTypeByUrlParam = (paramValue) => {
  const strVal = get(invert(FIELD_ENTITY_TYPE_TO_URL_PARAM), paramValue);
  return strVal ? parseInt(strVal, 10) : null;
};

export const getAnswersMapping = (answers) => {
  if (isEmpty(answers)) {
    return [];
  }

  return fromPairs(
    answers.map(ans => [ans.path, ans.body]),
  );
};

export const extractQuestionsFromAnswers = (answers, templates) => (
  isEmpty(answers) ? [] : answers.map(({ question }) => {
    let { answeredByUserType } = question;
    let choices = question.payload.choices;
    const { customFieldTemplateId } = question.customField || {};
    const template = templates.find(t => customFieldTemplateId && t.id === customFieldTemplateId);
    if (template) {
      const customField = template.questions.find(q => q.id === question.customFieldId);
      if (customField) {
        // We always want to use the current answeredByUserType value, not the one frozen by the
        // answer
        ({ answeredByUserType } = customField);
        choices = customField?.payload?.choices;
      }
    }
    return {
      ...question,
      payload: {
        ...question.payload,
        choices,
      },
      answeredByUserType,
    };
  })
);

export const extractCustomFieldsFromAnswers = answers => (
  !isEmpty(answers) ? answers.map(ans => ans.question.customField) : []
);

export const extractCustomFieldTemplatesFromAnswers = (answers, templates) => {
  const usedTemplateIds = uniq(answers.map(ans => ans.question.customFieldTemplateId));
  return templates.filter(t => usedTemplateIds.includes(t.id));
};

export const getPreselectedTemplateIds = (customFieldTemplates) => {
  if (isEmpty(customFieldTemplates)) {
    return [];
  }

  return customFieldTemplates.filter(t => t.isMandatory).map(t => t.id);
};

/**
 * Returns the custom fields, templates and initial values, to be used when updating custom fields
 * for an entity. If answers are in place, we extract information from them, otherwise we use
 * the custom fields and templates available
 *
 * @param {Object} options
 * @param {Number[]} [options.additionalSelectedTemplateIds]
 * @param {Object[]} options.answers - the answer objects attached to an entity
 * @param {Object[]} options.templates - the custom fields templates that are available
 * @param {Boolean} [options.useCurrentTemplates] - if we should only get template ids and custom
 *                                                fields from the current templates
 * @returns {Object}
 */
export const extractCustomFieldSetup = ({
  additionalSelectedTemplateIds = [],
  answers = [],
  templates = [],
  useCurrentTemplates = false,
} = {}) => {
  const selectedTemplateIds = !isEmpty(answers) && !useCurrentTemplates
    ? extractCustomFieldTemplatesFromAnswers(answers, templates).map(t => t.id)
    : getPreselectedTemplateIds(templates);
  selectedTemplateIds.push(...additionalSelectedTemplateIds);

  const selectedCustomFields = !isEmpty(answers) && !useCurrentTemplates
    ? extractQuestionsFromAnswers(answers, templates)
    : templates.filter(t => selectedTemplateIds.includes(t.id)).map(t => t.questions).flat();

  // get the path => value mapping from answers
  const answerMapping = getAnswersMapping(answers);

  const answersInitialValues = fromPairs(
    selectedCustomFields.map(
      cf => [cf.path, answerMapping[cf.path] || ''],
    ),
  );

  const initialValues = {
    custom_field_ids: selectedCustomFields.map(cf => cf.id),
    custom_field_templates: selectedTemplateIds,
    ...answersInitialValues,
  };

  return {
    initialValues,
    selectedCustomFields,
    selectedTemplateIds,
  };
};

export const getSelectedChoicesAsText = (
  choices, { separator = ', ', defaultValue = '-' } = {},
) => {
  if (isEmpty(choices)) {
    return '';
  }

  if (!Array.isArray(choices)) {
    return choices;
  }

  return choices.map(ch => (
    (ch.userInput ? ch.userInput : ch.text) || defaultValue
  )).join(separator);
};

export const isFieldVisibleToUserType = (field, userType) => (
  isEmpty(field.payload?.visibleTo) || field?.payload?.visibleTo.includes(userType)
);

export const filterCustomFieldsByUserType = (customFields, userType) => (
  customFields.filter(cf => isFieldVisibleToUserType(cf, userType))
);

export const parseInterviewBody = values => ({
  documentIds: values.documentIds || [],
  interviewQuestions: values.questions.map(q => {
    const parsedQuestion = {
      ...q,
      payload: {
        ...q.payload,
        visibleTo: q.payload.visibleTo.map(option => option.value),
      },
    };
    if (Array.isArray(parsedQuestion.payload.attachment_types)) {
      const mimeTypes = [];
      parsedQuestion.payload.attachment_types = parsedQuestion
        .payload
        .attachment_types.map(({ value }) => {
          mimeTypes.push(...MIME_TYPES[value]);
          return value;
        });
      parsedQuestion.payload.mimetypes = mimeTypes;
    }
    return parsedQuestion;
  }),
  name: values.name,
});

/**
 * Stringify searchable custom field filters in a supplied query object
 *
 * @param {Object} query
 * @returns {Object}
 */
export const parseSearchableFieldsInQuery = query => {
  const parsedQuery = { ...query };
  Object.keys(parsedQuery).forEach(key => {
    if (key.startsWith(SEARCH_KEY_PREFIX)) {
      parsedQuery[key] = JSON.stringify(parsedQuery[key]);
    }
  });
  return parsedQuery;
};

/**
 * Gets the CTA actions for an interview submission
 *
 * @param {Object} submission
 * @param {Function} dispatch
 * @returns {Object[]}
 */
export const getSubmissionCTAButtonActions = (submission, dispatch) => {
  const ctaButtonItems = [];

  if (submission?.allowedActions?.canChangeOnboardingForm) {
    ctaButtonItems.push({
      label: 'Change form',
      onClick: () => dispatch(modalOpenAC(CHANGE_ONBOARDING_FORM_MODAL_ID, { submission })),
      variant: BS_STYLE.PRIMARY,
    });
  }

  if (submission?.allowedActions?.canReset) {
    ctaButtonItems.push({
      label: 'Reset form',
      onClick: () => dispatch(modalOpenAC(RESET_ONBOARDING_FORM_MODAL_ID, { submission })),
      variant: BS_STYLE.PRIMARY,
    });
  }

  return ctaButtonItems;
};
